import React from 'react';
import TaimerComponent from "./../TaimerComponent";
import styles from "./GlobalSearch.module.scss";
import DataHandler from "./DataHandler";
import { SettingsContext } from './../SettingsContext';
import { 
    reduceSum
} from "./MathUtils";
import ClickAwayWrapper from "./ClickAwayWrapper";

import { CircleSpinner } from "react-spinners-kit";

import actionIcons from './../navigation/ActionIcons';
import SearchIcon from '@mui/icons-material/Search';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import AddIcon from '@mui/icons-material/Add';
import ExploreIcon from '@mui/icons-material/Explore';
import LogoutIcon from '@mui/icons-material/Logout';

import WorkIcon from '@mui/icons-material/Work'; 
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DescriptionIcon from '@mui/icons-material/Description';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

// Some utility functions. Consider moving 
// into their own file if there's a lot.

function joinBottomLabelParts(parts: (string | null)[], by = " • "): string {
    return parts.filter(p => p).join(by);
}

// TODO: Refactor?
interface TaimerJS {
    addAccount: Function;
    addProject: Function;
    addContact: Function;
    addUser: Function;
    addExpense: Function;
    addTravelExpense: Function;
    addInvoice: Function;
    addHours: Function;
    addResource: Function;
    updateView: Function;
    logOut: Function;
    checkPrivilegeAny: (group: string, permission?: string | string[] | undefined, companyId?: number | string | undefined) => boolean;
    checkPrivilege: (group: string, permission: string, companyId: number | string) => boolean;
    // Ahh.
    userObject: any;
    taimerAccount: any;
    versionId: number;
}

// Could have the subtypes ActionResult and GoToResult
// instead of having optional properties.
interface GlobalSearchResult {
    type: string;
    label: string;
    properties?: { [key: string]: any; };
    additionalSearchString?: string;
    matchPercentage?: number;
    id: string | number;
    updateViewParameters?: {
        [key: string]: string;
    };
    iconOverride?: React.ComponentType;
    action?: (taimerJS: TaimerJS) => void;
    visible?: (taimerJS: TaimerJS) => boolean;
}

interface LabelParts {
    top: React.ReactNode;
    bottom: React.ReactNode;
}

interface GlobalSearchProps {
    // TODO: request url
}

type GlobalSearchResultMap = {
    [type: string]: GlobalSearchResult[]
};

interface GlobalSearchState {
    input: string;
    open: boolean;
    results: GlobalSearchResultMap;
    searching: boolean;
    activeIndex: number;
}

interface GlobalSearchListElementProps {
    result: GlobalSearchResult;
    highlighted?: boolean;
    onClick?: () => void;
    onMouseOver?: () => void;
    icon?: string | React.ComponentType;
    iconClassName?: string;
    domId?: string;
    type?: string;
}

class GlobalSearchListElement extends TaimerComponent<GlobalSearchListElementProps> {
    private readonly LABEL_PART_DEFINERS: {
        [type: string]: (r: GlobalSearchResult) => LabelParts
    } = {
        project: (r: GlobalSearchResult): LabelParts => {
            return {
                top: r.label,
                bottom: joinBottomLabelParts([
                    this.context.taimerAccount.isMulticompany ? r.properties?.company : null, 
                    r.properties?.customer
                ])
            };
        },
        expense: (r: GlobalSearchResult): LabelParts => {
            return {
                top: r.label,
                bottom: joinBottomLabelParts([
                    r.properties?.creator
                ])
            };
        },
        invoice: (r: GlobalSearchResult): LabelParts => {
            return {
                top: r.label,
                bottom: joinBottomLabelParts([
                    r.properties?.customer,
                    this.context.functions.presentCurrency(r.properties?.total, r.properties?.currency_code),
                ])
            };
        },
        user: (r: GlobalSearchResult): LabelParts => {
            return {
                top: r.label,
                bottom: r.properties?.company
            };
        }
    }

    constructor(props: GlobalSearchListElementProps, context: any) {
		super(props, context, "general/GlobalSearch");
    }

    renderLabel = (type: string | null, mainLabel: string): React.ReactNode => {
        if(type === null) {
            return mainLabel;
        }

        const parts: LabelParts | null =  this.LABEL_PART_DEFINERS?.[type]?.(this.props.result) ?? null;

        return parts === null 
            ? (<div className={styles.top}>{mainLabel}</div>)
            : (
                <>
                    <div className={styles.top}>{parts.top}</div>
                    <div className={styles.bottom}>{parts.bottom}</div>
                </>
            );
    }

    render() {
        const className: string = [
            styles.result,
            this.props.highlighted ? styles.highlighted : ""
        ].join(" ");

        const Icon = typeof(this.props.icon) === "string"
            ? actionIcons?.[this.props?.icon] 
            : this.props.icon;

        const { 
            type,
            result
        }: { 
            type?: string;
            result: GlobalSearchResult;
        } = this.props;

        const iconClassName: string = this.props.iconClassName
            ? `${styles.icon} ${this.props.iconClassName}`
            : styles.icon;

        return (
            <div 
                className={className}
                id={this.props?.domId ?? ""}
                onClick={this.props.onClick ?? (() => null)}
                onMouseOver={this.props.onMouseOver ?? (() => null)}>
                <div className={iconClassName}>
                    {Icon ? <Icon /> : null}
                </div>
                <div className={styles.data}>
                    {this.renderLabel(type ?? null, result.label)}
                </div>
                <div className={styles.chev}>
                    <ChevronRightIcon />
                </div>
            </div>
        );
    }
}

class GlobalSearch extends TaimerComponent<GlobalSearchProps, GlobalSearchState> {
    static contextType = SettingsContext;

    private readonly SELECTION_HANDLERS: {
        [type: string]: (r: GlobalSearchResult, globalSearch: GlobalSearch, taimerJS: TaimerJS) => void
    } = {
        project: (r: GlobalSearchResult, globalSearch: GlobalSearch, taimerJS: TaimerJS) => {
            taimerJS.updateView({ 
                module: "projects", 
                action: "view", 
                id: r.id 
            });

            globalSearch.close();
        },
        expense: (r: GlobalSearchResult, globalSearch: GlobalSearch, taimerJS: TaimerJS) => {
            taimerJS.updateView({ 
                module: "worktrips", 
                action: "modify", 
                expenseType: r.properties?.type === "pe" 
                    ? "1" 
                    : "2",
                id: r.id 
            });

            globalSearch.close();
        },
        invoice: (r: GlobalSearchResult, globalSearch: GlobalSearch, taimerJS: TaimerJS) => {
            taimerJS.updateView({ 
                module: "invoices", 
                action: "view", 
                id: r.id 
            });

            globalSearch.close();
        },
        account: (r: GlobalSearchResult, globalSearch: GlobalSearch, taimerJS: TaimerJS) => {
            const userCompanyId: number | string = this.context.userObject.companies_id;

            taimerJS.updateView({ 
                module: "customers", 
                action: "view", 
                id: r.id,
                // If the user's company is in the array of company ids
                // the customer has a relation to, use their own company id,
                // otherwise just pick the first "available" one.
                company: r?.properties?.companies.indexOf(userCompanyId) > -1
                    ? userCompanyId
                    : r?.properties?.companies[0]
            });

            globalSearch.close();
        },
        user: (r: GlobalSearchResult, globalSearch: GlobalSearch, taimerJS: TaimerJS) => {
            taimerJS.updateView({ 
                module: "users", 
                action: "view", 
                id: r.id,
                // TODO: ?
                company: this.context.userObject.companies_id
            });

            globalSearch.close();
        },
        contact: (r: GlobalSearchResult, globalSearch: GlobalSearch, taimerJS: TaimerJS) => {
            taimerJS.updateView({ 
                module: "contact", 
                action: "view", 
                id: r.id,
                // TODO: ?
                company: this.context.userObject.companies_id
            });

            globalSearch.close();
        },
        goto: (r: GlobalSearchResult, globalSearch: GlobalSearch, taimerJS: TaimerJS) => {
            taimerJS.updateView(r?.updateViewParameters ?? {});

            globalSearch.close();
        },
        action: (r: GlobalSearchResult, globalSearch: GlobalSearch, taimerJS: TaimerJS) => {
            globalSearch.close(() => {
                setTimeout(() => r.action?.(taimerJS), 0);
            });
        },
    };

    private readonly RESULT_RENDERERS: {
        [type: string]: (r: GlobalSearchResult) => React.ReactElement
    } = {
        project: (r: GlobalSearchResult): React.ReactElement => {
            return <GlobalSearchListElement 
                result={r}
                icon={actionIcons.project}
                iconClassName={styles.resizedIcon}
                type={"project"}
            />
        },
        expense: (r: GlobalSearchResult): React.ReactElement => {
            return <GlobalSearchListElement 
                result={r}
                icon={actionIcons.expenses}
                iconClassName={styles.resizedIcon}
                // This will be back, I know it.
                // icon={r.properties?.type === "te" 
                    // ? DirectionsCarIcon
                    // : CreditCardIcon
                // }
                type={"expense"}
            />
        },
        invoice: (r: GlobalSearchResult): React.ReactElement => {
            return <GlobalSearchListElement 
                result={r}
                icon={actionIcons.invoice}
                iconClassName={styles.resizedIcon}
                type={"invoice"}
            />
        },
        account: (r: GlobalSearchResult): React.ReactElement => {
            return <GlobalSearchListElement 
                result={r}
                icon={AccountBalanceIcon}
                iconClassName={styles.resizedIcon}
                type={"account"}
            />
        },
        user: (r: GlobalSearchResult): React.ReactElement => {
            return <GlobalSearchListElement 
                result={r}
                icon={PersonIcon}
                iconClassName={styles.resizedIcon}
                type={"user"}
            />
        },
        contact: (r: GlobalSearchResult): React.ReactElement => {
            return <GlobalSearchListElement 
                result={r}
                icon={PeopleIcon}
                iconClassName={styles.resizedIcon}
                type={"contact"}
            />
        },
        action: (r: GlobalSearchResult): React.ReactElement => {
            return <GlobalSearchListElement 
                result={r}
                icon={r?.iconOverride ?? AddIcon}
                type={"action"}
            />
        },
        goto: (r: GlobalSearchResult): React.ReactElement => {
            return <GlobalSearchListElement 
                result={r}
                icon={ExploreIcon}
                type={"goto"}
            />
        }
    };

    private readonly GOTO_RESULTS: GlobalSearchResult[] = [
        {
            id: "projects",
            label: this.tr("Go to: Project list"),
            additionalSearchString: this.tr("Go to projects"),
            type: "goto",
            updateViewParameters: {
                module: "projects",
                action: "list"
            },
            visible: (t: TaimerJS): boolean => t.checkPrivilegeAny("projects", "read") 
                || t.checkPrivilegeAny("projects", "special_permissions")
                || t.userObject.project_read_companies?.length > 0
        },
        {
            id: "accounts",
            label: this.tr("Go to: Account list"),
            additionalSearchString: this.tr("Go to accounts"),
            type: "goto",
            updateViewParameters: {
                module: "contacts",
                action: "main",
                selectedTab: "accounts",
            },
            visible: (t: TaimerJS): boolean => t.checkPrivilegeAny("customers", "read")
                || t.checkPrivilegeAny("customers", "special_permissions")
                || t.userObject.customer_read_companies?.length > 0
        },
        {
            id: "invoices",
            label: this.tr("Go to: Invoice list"),
            additionalSearchString: this.tr("Go to invoices"),
            type: "goto",
            updateViewParameters: {
                module: "invoices",
                action: "main"
            },
            visible: (t: TaimerJS): boolean => t.checkPrivilegeAny("invoices", ["write_simple", "write_full"])
        },
        {
            id: "expenses",
            label: this.tr("Go to: Expense list"),
            additionalSearchString: this.tr("Go to expenses"),
            type: "goto",
            updateViewParameters: {
                module: "costs",
                action: "main",
                selectedTab: "expenses"
            },
            visible: (t: TaimerJS): boolean => {
                return t.checkPrivilegeAny("worktrips", [
                    "write", 
                    "approve_superior", 
                    "approve_projectmanager", 
                    "approve", 
                    "modify"
                ]);
            }
        },
        {
            id: "travel-expenses",
            label: this.tr("Go to: Travel Expense list"),
            additionalSearchString: this.tr("Go to travel expenses / Go to traveling expenses / traveling expenses / travel expenses"),
            type: "goto",
            updateViewParameters: {
                module: "costs",
                action: "main",
                selectedTab: "travel-expenses"
            },
            visible: (t: TaimerJS): boolean => {
                return [4, 12].indexOf(t.versionId) > -1 && t.checkPrivilegeAny("worktrips", [
                    "write", 
                    "approve_superior", 
                    "approve_projectmanager", 
                    "approve", 
                    "modify"
                ]);
            }
        },
        {
            id: "contacts",
            label: this.tr("Go to: Contact list"),
            additionalSearchString: this.tr("Go to contacts / contact list"),
            type: "goto",
            updateViewParameters: {
                module: "contacts",
                action: "main",
                selectedTab: "contacts",
            },
            visible: (t: TaimerJS): boolean => t.checkPrivilegeAny("persons", ["read", "contact_owner_read"])
        },
        {
            id: "users",
            label: this.tr("Go to: Staff list"),
            additionalSearchString: this.tr("Go to staff / staff list"),
            type: "goto",
            updateViewParameters: {
                module: "contacts",
                action: "main",
                selectedTab: "users",
            },
            visible: (t: TaimerJS): boolean => t.checkPrivilegeAny("admin", "admin")
        },
        {
            id: "settings-general",
            label: this.tr("Go to: Settings"),
            additionalSearchString: this.tr("Open settings"),
            type: "goto",
            updateViewParameters: {
                module: "settings",
                action: "index"
            }
        },
        {
            id: "my-profile-password",
            label: this.tr("Go to: My Profile -> Password"),
            additionalSearchString: this.tr("Open my profile / change password / new password"),
            type: "goto",
            updateViewParameters: {
                module: "settings",
                action: "index",
                group: "my-profile",
                page: "password"
            }
        },
    ];

    private readonly ACTION_RESULTS: GlobalSearchResult[] = [
        {
            id: "account",
            label: this.tr("Create: Account"),
            additionalSearchString: this.tr("Add an account / add a new customer"),
            type: "action",
            action: (t: TaimerJS) => t.addAccount(),
            visible: (t: TaimerJS): boolean => t.checkPrivilegeAny("customers", "write")
        },
        {
            id: "project",
            label: this.tr("Create: Project"),
            additionalSearchString: this.tr("Add a new project / create a new lead / add a new lead"),
            type: "action",
            action: (t: TaimerJS) => t.addProject(),
            visible: (t: TaimerJS): boolean => t.checkPrivilegeAny("projects", "write")
        },
        {
            id: "expense",
            label: this.tr("Create: Expense"),
            additionalSearchString: this.tr("Add an expense / new expense"),
            type: "action",
            action: (t: TaimerJS) => t.addExpense(),
            visible: (t: TaimerJS): boolean => t.checkPrivilegeAny("worktrips", ["write", "modify_all"])
        },
        {
            id: "travel-expense",
            label: this.tr("Create: Travel Expense"),
            additionalSearchString: this.tr("Add a travel expense / new travel expense"),
            type: "action",
            action: (t: TaimerJS) => t.addTravelExpense(),
            visible: (t: TaimerJS): boolean => [4, 12].indexOf(t.versionId) > -1 
                && t.checkPrivilegeAny("worktrips", ["write", "modify_all"])
        },
        {
            id: "invoice",
            label: this.tr("Create: Invoice"),
            additionalSearchString: this.tr("Add invoice / new invoice"),
            type: "action",
            action: (t: TaimerJS) => t.updateView({
                module: "invoices",
                action: "view",
                editMode: "1",
                companies_id: this.context.userObject.companies_id
            }),
            visible: (t: TaimerJS): boolean => t.checkPrivilegeAny("invoices", ["write_simple", "write_full"])
        },
        {
            id: "time-tracker",
            label: this.tr("Create: Hour Entry"),
            additionalSearchString: this.tr("Add a hour entry / track hours / time tracker / timetracker / working hours / new workhour"),
            type: "action",
            action: (t: TaimerJS) => t.addHours(),
            visible: (t: TaimerJS): boolean => t.checkPrivilegeAny("workhours", "write")
        },
        {
            id: "user",
            label: this.tr("Create: User"),
            additionalSearchString: this.tr("Add a new user"),
            type: "action",
            action: (t: TaimerJS) => t.addUser(),
            visible: (t: TaimerJS): boolean => t.checkPrivilegeAny("admin", "admin")
        },
        {
            id: "contact",
            label: this.tr("Create: Contact"),
            additionalSearchString: this.tr("Add a new contact / create contact"),
            type: "action",
            action: (t: TaimerJS) => t.addContact(),
            visible: (t: TaimerJS): boolean => t.checkPrivilegeAny("persons", "read")
        },
        {
            id: "logout",
            label: this.tr("Log out"),
            additionalSearchString: this.tr("Quit exit logout"),
            type: "action",
            action: (t: TaimerJS) => t.logOut(),
            iconOverride: LogoutIcon
        },
    ];

    private inputRef: any;
    private timeout: any;
    private request: any = null;

    constructor(props: GlobalSearchProps, context: any) {
		super(props, context, "general/GlobalSearch");

        this.inputRef = React.createRef();

        this.state = {
            input: "",
            open: false,
            results: {},
            searching: false,
            activeIndex: -1
        };
    }

    componentDidMount() {
        window.addEventListener("keydown", this.handleShortcut);
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.handleShortcut);
    }

    componentDidUpdate(prevProps: GlobalSearchProps, prevState: GlobalSearchState) {
        if(!prevState.open && this.state.open) {
            this?.inputRef?.current?.focus();
            this?.inputRef?.current?.select();
        }

        // Has to be done in the setActiveIndex function,
        // since we need the information on whether the active
        // row is being changed using the keyboard or a mouse.
        // if(this.state.activeIndex !== -1 
            // && prevState.activeIndex !== this.state.activeIndex) {
                // this.scrollResultIntoView(this.state.activeIndex);
        // }
    }

    formTaimerJS = (): TaimerJS => {
        return {
            ...this.context.functions,
            userObject: this.context.userObject,
            taimerAccount: this.context.taimerAccount,
            versionId: Number(this.context.versionId),
            updateView: (...params) => {
                this.context.functions.unsetOverlayComponent();
                this.context.functions.updateView(...params);
            }
        };
    }

    handleShortcut = (event: KeyboardEvent): void => {
        if(!this.context.userObject.useGlobalSearchShortcut) {
            return;
        }

        const {
            keyCode,
            ctrlKey
        }: {
            keyCode: number;
            ctrlKey: boolean;
        } = event;

        const { open }: { open: boolean; } = this.state;

        if(open && !ctrlKey && keyCode === 27) {
            this.close();

            return;
        }

        if(!ctrlKey || keyCode !== 32) {
            return;
        } 

        !open ? this.open() : this.close();

        // Closing.
        if(open) {
            return;
        }

        // Opening.
        this.handleOpenCallbacks();
    }

    handleOpenCallbacks = (): void => {
        this.context.functions.sendMixpanelEvent('open_quick_search', {
            open_method: "keyboard_shortcut"
        });
    }

    open = (): void => {
        this.setState({ open: true });
    }

    close = (callback: () => void = () => null): void => {
        this.setState({ open: false }, callback);
    }

    setActiveIndex = (index: number, scrollResultIntoView = true): void => {
        if(this.state.activeIndex === index) {
            return;
        }

        this.setState({
            activeIndex: index
        }, () => {
            if(!scrollResultIntoView) {
                return;
            }

            this.scrollResultIntoView(index) 
        });
    }

    scrollResultIntoView = (index: number): void => {
        document.getElementById(`global-search-result-${index}`)?.scrollIntoView({
            block: "center"
        });
    }

    handleInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value: string = event.target.value;

        this.setState({ 
            input: value,
            activeIndex: -1
        }, () => this._triggerSearch(value));
    }

    handleKeyDown = (event: React.KeyboardEvent): boolean => {
        if(!(["ArrowUp", "ArrowDown"].indexOf(event.code) > -1)) {
            return true;
        }

        // Moved from handleKeyUp to make
        // using the arrow keys feel snappier.
        
        if(event.code === "ArrowUp") {
            this.moveSelectionUp();
        }

        if(event.code === "ArrowDown") {
            this.moveSelectionDown();
        }

        event.preventDefault();

        return false;
    }

    handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if(event.code === "Enter") {
            this.selectResult(this.state.activeIndex);
        }
    }

    getResultAmount = (): number => {
        return Object.keys(this.state.results)
            .map((k: string) => this.state.results[k].length)
            .reduce(reduceSum, 0);
    }

    moveSelectionUp = (): void => {
        const newIndex: number = this.state.activeIndex <= 0
            ? this.getResultAmount() - 1
            : this.state.activeIndex - 1;

        this.setActiveIndex(newIndex);
    }

    moveSelectionDown = (): void => {
        const amount: number   = this.getResultAmount();
        const newIndex: number = this.state.activeIndex >= (amount - 1)
            ? 0
            : this.state.activeIndex + 1;

        this.setActiveIndex(newIndex);
    }

    selectResult = (resultIndex: number): void => {
        const {
            results
        }: { results: GlobalSearchResultMap } = this.state;

        const keys: string[] = Object.keys(results);
        let prevIndicesSum   = 0;

        for(const key of keys) {
            const result: GlobalSearchResult | undefined = results[key]
                .find((r: GlobalSearchResult, index: number) => {
                    return index + prevIndicesSum === resultIndex;
                });

            if(result) {
                this.SELECTION_HANDLERS[key](
                    result, 
                    this, 
                    this.formTaimerJS()
                );

                return;
            }
            
            prevIndicesSum += results[key].length;
        }
    }

    reset = (): void => {
        this.setState({
            searching: false,
            results: {},
            input: ""
        });
    }

    _triggerSearch = (term: string): void => {
        if(term.trim() === "") {
            this.reset();

            return;
        }

        this.setState({
            searching: true
        }, () => {
            clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.triggerSearch(term);
            }, 300);
        })
    }

    splitSearchTerm = (term: string): string[] => {
        return term.split(" ")
            .filter((s: string) => s.length > 0)
            .map((s: string) => s.toLowerCase());
    }

    searchPool = (term: string, pool: GlobalSearchResult[]): GlobalSearchResult[] => {
        const terms: string[] = this.splitSearchTerm(term);

        const options: GlobalSearchResult[] = pool.filter((osr: GlobalSearchResult) => {
            return terms.filter((term: string) => {
                const additional: string = osr?.additionalSearchString ?? "";

                return osr.label.toLowerCase().indexOf(term) > -1
                    || additional.toLowerCase()?.indexOf(term) > -1
            }).length === terms.length;
        }).map((osr: GlobalSearchResult): GlobalSearchResult => {
            const additional: string = osr?.additionalSearchString ?? "";

            osr.matchPercentage = term.length / (osr.label.length + additional.length);

            return osr;
        });

        options.sort((a: GlobalSearchResult, b: GlobalSearchResult): number => {
            if((a?.matchPercentage ?? 0) > (b?.matchPercentage ?? 0)) {
                return -1;
            }

            if((a?.matchPercentage ?? 0) < (b?.matchPercentage ?? 0)) {
                return +1;
            }

            return 0;
        });

        return options;
    }

    triggerSearch = (term: string): void => {
        // Cancel the current request if there is one.
        this.request?.abort();

        // Await not used since we need to be 
        // able to abort this request very often.
        this.request = DataHandler.get({ url: `globalsearch/${term}` }).done(response => {
            this.handleSearchRequestResponse(response, term);
        });
    }

    getGoToResults = (): GlobalSearchResult[] => {
        return this.GOTO_RESULTS.filter((r: GlobalSearchResult): boolean => {
            return r?.visible?.(this.formTaimerJS()) ?? true;
        });
    }

    getActionResults = (): GlobalSearchResult[] => {
        return this.ACTION_RESULTS.filter((r: GlobalSearchResult): boolean => {
            return r?.visible?.(this.formTaimerJS()) ?? true;
        });
    }

    handleSearchRequestResponse = (response: any, term: string): void => {
        const results: GlobalSearchResultMap     = {};

        results.goto   = this.searchPool(term, this.getGoToResults());
        results.action = this.searchPool(term, this.getActionResults());

        let resultCount = results.goto.length + results.action.length;

        const nonExtraProperties: Readonly<string[]> = [
            "id", 
            "label", 
            "name", 
            "match_percentage"
        ];

        Object.keys(response).forEach((type: string) => {
            results[type] = response[type].map(r => {
                return {
                    id: r.id,
                    label: r.label,
                    type: type,
                    properties: Object.keys(r)
                        .filter((f: string) => nonExtraProperties.indexOf(f) === -1)
                        .reduce((acc, cur: string) => ({ ...acc, [cur]: r[cur]}) , {})
                };
            });

            resultCount += results[type].length;
        });

        this.setState({ 
            results: results,
            searching: false,
            activeIndex: resultCount > 0 ? 0 : -1
        });
    }

    hasResults = (): boolean => {
        const rMap: GlobalSearchResultMap = this.state.results;

        return Object.keys(rMap)
            .find((type: string) => rMap[type].length > 0) !== undefined;
    }

    inputIsEmpty = (): boolean => {
        const input: string = this.state.input.trim();

        return input === "";
    }

    handleClickAway = () => {
        this.close();
    }

    renderContent = (): React.ReactElement | null => {
        if(this.inputIsEmpty()) {
            return null;
        }

        const hasResults: boolean = this.hasResults();

        const { 
            activeIndex,
            results
        }: { 
            activeIndex: number;
            results: GlobalSearchResultMap;
        } = this.state;

        const lengths: number[] = Object.keys(results)
            .map((k: string) => results[k].length)
            .filter((n: number) => n > 0);

        return (
            <div className={styles.results}>
                <div className={styles.resultsInner}>
                    {this.state.searching && (
                        <div className={styles.flexContainer}>
                            <div className={styles.message}>
                                <CircleSpinner size={30} color={"#00b8e4"} loading={true} />
                                <div className={`${styles.messageText} ${styles.margin}`}>
                                    {this.tr("Loading...")}
                                </div>
                            </div>
                        </div>
                    )}
                    {hasResults && !this.state.searching &&
                        (Object.keys(results)
                            .filter((type: string) => results[type].length > 0)
                            .map((type: string, typeIndex: number) => {
                                // For calculating the actual index of the
                                // result row when results are grouped
                                // by type ------------------------>
                                let prevIndicesSum = 0;

                                for(let i = 0; i < typeIndex; ++i) {
                                    prevIndicesSum += lengths[i];
                                }
                                // <--------------------------------

                                return (
                                    <>
                                        {<div className={styles.typeIndicator}>
                                            {this.tr(`${type} plural`)}
                                        </div>}
                                        {results[type].map((r: GlobalSearchResult, index: number) => {
                                            const realIndex             = index + prevIndicesSum;
                                            const c: React.ReactElement = this.RESULT_RENDERERS?.[type]?.(r)
                                                ?? <GlobalSearchListElement result={r} />;

                                            return React.cloneElement(c, {
                                                key: realIndex,
                                                domId: `global-search-result-${realIndex}`,
                                                highlighted: activeIndex === realIndex,
                                                onClick: () => this.selectResult(realIndex),
                                                onMouseOver: () => this.setActiveIndex(realIndex, false)
                                            });
                                        })}
                                    </>
                                )
                            })
                    )}
                    {!hasResults && !this.state.searching && (
                        <div className={styles.flexContainer}>
                            <div className={styles.message}>
                                <div className={styles.messageText}>
                                    {this.tr("No search results")}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    render() {
        const { 
            open 
        }: { 
            open: boolean; 
        } = this.state;

        const wrapperClassNames: string = [
            styles.wrapper,
            open ? styles.open : styles.closed
        ].join(" ");

        return (
            <div className={wrapperClassNames}>
                <ClickAwayWrapper 
                    active={open} 
                    onClickAway={this.handleClickAway}>
                    <div className={styles.contentWrapper}>
                        <div className={styles.searchInputWrapper}>
                            <div className={styles.icon}>
                                <SearchIcon />
                            </div>
                            <input 
                                ref={this.inputRef}
                                className={styles.searchInput} 
                                placeholder={this.tr("Search for anything...")}
                                type="text" 
                                onChange={this.handleInput} 
                                onKeyDown={this.handleKeyDown}
                                onKeyUp={this.handleKeyUp}
                            />
                        </div>
                        {this.renderContent()}
                    </div>
                </ClickAwayWrapper>
            </div>
        );
    }
}

export default GlobalSearch;

import React from 'react';

/* Css */
import './InvoiceView.css';

/* Material-ui */
import { AddCircleOutlined, AddIcon, Email, Print, RemoveIcon, ReportProblemRounded, Edit, WarningRounded } from '@mui/icons-material';
import Chip from '@mui/material/Chip';

import { Assignment, Attachment, Message, NavigateBefore, Settings } from '@mui/icons-material';
import { Button, MenuItem, Switch, Tooltip, Typography, Badge } from '@mui/material';

/* Others */
import { addDays, differenceInCalendarDays, format, isValid } from "date-fns";
import _, { remove } from 'lodash';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import PropTypes from "prop-types";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import colors from '../colors';
import { validEmail, validEmailMultiple } from "../dialogs/Validate";
import InvoiceTranslations from '../general/backendTranslations/InvoiceTranslations';
import DropdownMenuButton from '../general/DropdownMenuButton';
import { FlexChild, FlexContainer } from "../general/FlexUtils";
import { ReactComponent as BookkeepingIcon } from '../general/icons/bookkeeping.svg';
import LabelFieldGroup from '../general/LabelFieldGroup';
import LoaderButton from '../general/LoaderButton';
import NoteDrawer from '../general/NoteDrawer';
import StatusTag from '../general/StatusTag';
import TextFieldWithLimit from '../general/TextFieldWithLimit';
import { checkStringRestrictedChars, formatInputNumber, formatVatNumber, isCompanyUsingInvoiceCountryCode, validateVatNumber } from '../helpers';
import { getMultiDimensionHeaders, getDefaultDimensionValuesForHeaders, getProductDimensionValuesForHeaders, getProjectIdsForInvoiceRows } from './helpers';
import { getProcountorProjectDimension, updateProjectDimensionValueToRows } from './AccountingUtils';
import ConfirmationDialog from "../list/dialogs/ConfirmationDialog";
import List from "../list/List";
import EntityCardTabs from '../navigation/EntityCardTabs';
import NoPermissionOverlay from '../overlays/NoPermissionOverlay';
import { ReactComponent as NoteIcon } from '../projects/images/note.svg';
import TaimerComponent from "../TaimerComponent";
import ContextMenu from './../general/ContextMenu';
import { CurrencyUtils } from "./../general/CurrencyUtils";
import DataHandler from "./../general/DataHandler";
import DataList from './../general/DataList';
import { AddAccount } from './../general/no-options/AddItemComponents';
import OutlinedField from "./../general/OutlinedField";
import Paper from "./../general/Paper";
import { DatePicker, DateRangePicker } from './../general/react-date-range/src';
import TabAttachments from "./../general/TabAttachments";
import Utils from "./../general/Utils";
import RateChanceForInvoiceDialog from "./../settings/dialogs/RateChanceForInvoiceDialog";
import { SettingsContext } from './../SettingsContext';
import AccountingCell from './AccountingCell';
import AccountingSlider from './AccountingSlider';
import AccountingButton from './AccountingButton';
import CompanyAddress from "./CompanyAddress";
import InitialDialog from "./InitialDialog";
import InvoiceFooter from "./InvoiceFooter";
import InvoiceLogView from './InvoiceLogView';
import InvoiceMaterial from "./InvoiceMaterial";
import InvoiceMaterialPdf from "./InvoiceMaterialPdf";
import InvoiceRow from "./InvoiceRow";
import InvoiceTotals from "./InvoiceTotals";
import styles from './InvoiceView.module.scss';
import Logo from "./Logo";
import SendInvoiceWizard from './SendInvoiceWizard';
import HandleErrorDialog from "./dialogs/HandleErrorDialog";
import SendInvoicesHelper from "./SendInvoicesHelper";
import CoreDialog from '../dialogs/mass_operations/CoreDialog';
import SliderFieldGroup from '../general/SliderFieldGroup';
import ErrorsList from '../dialogs/elements/ErrorsList';

const convertDateFormat = (format) => format?.replace('%d', 'DD').replace('%m', 'MM').replace('%Y', 'YYYY');
export const convertDateFormatToPHP = (format) => format?.replace('DD', '%d').replace('MM', '%m').replace('YYYY', '%Y');

function Footer(props) {
    const Wrapper = props.noFlex !== true ? FlexContainer : "div";
    return <Wrapper className="footer">{props.children}</Wrapper>;
}

function ProjectOption(props) {
  
    const rows = props.options;
    let menuAutoWidth = props.menuWidth || 'auto';
    if (rows && rows.length > 0 && !props.menuWidth) {
        const len = Math.max(...rows.map(x => String(x.label || "").length));
        menuAutoWidth = (len + 4) + "ch";
    }

    const showDisabledSubtext = props.isDisabled && props.selectProps.disabledSubtext;

    return (
        <MenuItem
            data-testid={`datalist_option_${props.data.label}`}
            buttonRef={props.innerRef}
            selected={props.isFocused}
            disabled={props.isDisabled}
            component="div"
            {...props.innerProps}
            data-testid={props.data['data-testid'] || `datalist_option_${props.data.name}`}
            style={{
                fontWeight: props.isSelected ? 500 : 400,
                width: menuAutoWidth,
                minWidth: "-webkit-fill-available",
                paddingBottom: showDisabledSubtext ? 20 : 11
            }} >
          
          <span className="project-menu-parent-project">{props.data.parent_name}</span>
          
          {props.children}
          
          {showDisabledSubtext && 
            <div className="listitem-subtext">{props.selectProps.disabledSubtext}</div>}
    
        </MenuItem>
    
    );
}

class InvoiceView extends TaimerComponent {

    static contextType = SettingsContext;
    headerTitle = this.tr("Invoice");

    constructor(props, context) {
        super(props, context, "invoices/InvoiceView");
 
        const billingZone = props.billingZone ? props.billingZone : context.taimerAccount.ourCompanyLang;

        this.dialogs = {
            initial: InitialDialog,
            invoiceMaterial: InvoiceMaterial,
            invoiceMaterialPdf: InvoiceMaterialPdf,
            rateChanceForInvoiceDialog: RateChanceForInvoiceDialog,
            handleError: HandleErrorDialog
        };

        let initialCompany = context.functions.getPreferedCompany([
            context.functions.getCompany("invoices", "write_full", false, true),
            context.functions.getCompany("invoices", "write_simple", false, true),
        ]);

        if (props.companies_id) {
            initialCompany = props.companies_id;
        }

        this.companyEmailValid = true;

        this.delivery_types = [
            ...(this.context.addons.ropocapital || this.context.addons.fennoa ? [{value: 0, label: this.tr("Automatic")}] : []),
            {value: 1, label: this.tr("Einvoicing")},
            {value: 2, label: this.tr("Paper")},
            ...(this.context.addons.ropocapital || this.context.addons.talenom ? [{value: 3, label: this.tr("Print")}] : []),
            {value: 4, label: this.tr("Email")},
            ...(context.addons.fennoa ? [{value: 5, label: this.tr("Manual")}] : []),
            ...(context.addons.efina ? [{value: 6, label: this.tr("No sending")}] : []),
        ];
        
        this.invoiceTypes = {
            '1': this.tr('Invoice'),
            '2': this.tr('Credit Note'),
            '3': this.tr('Payment Notice'),
        }

        this.billingLanguages = [
            {id: 'fi', label: this.tr("Finnish")},
            {id: 'fi_special1', label: this.tr("Finnish")},
            {id: 'en', label: this.tr("English")},
            {id: 'en_au', label: this.tr("English (AU)")},
            {id: 'en_ca', label: this.tr("English (CA)")},
            {id: 'en_special1', label: this.tr("English")},
            {id: 'se', label: this.tr("Swedish")},
            {id: 'ee', label: this.tr("Estonian")}
        ];
        this.reverseChargeFields = [
            {id: 0, label: this.tr("No reverse charge")},
            {id: 1, label: this.tr("Reverse charge applied")}
        ];         

        this.detailsTabs = [
            {
                id: 'details',
                label: this.tr("Details"),
            },
            {
                id: 'log',
                label: this.tr("Log"),
            }
        ];

        this.defaultCurrencyDetails = {
            currency_code: context.taimerAccount.currency,
            currency_rate: "1.0000",
            currency_rates_id: undefined,
        };

        this.gettingInvoice = false;

        const { taimerAccount: { defaulttermsofpayment, defaultannotation, defaultpenaltyinterest } } = this.context;
        const today = moment().format('YYYY-MM-DD');
        //console.log('ahoi', format(addDays(today, Number(defaulttermsofpayment)), 'YYYY-MM-DD'), format(addDays(new Date(), Number(defaulttermsofpayment)), 'YYYY-MM-DD'));

        this.initialAccountState = {
            invoiceData: {},
            selectedProjects: [],
            selectedBillingAddress: {},
            selectedShippingAddress: {},
        };

        this.initialProjectState = {
            selectedBillingAddress: {},
            invoiceData: {},
            rows: [],
            descriptionRows: [],
            noteData: [],
            identifierData: [],
            startDate: null,
            endDate: null,
            invoiceDetails: {
                reference: '',
                customerreference: ''
            }
        };
        
        this.initialState = {
            projectMenuOpen: false,
            saveInProgress: false,
            draftSaveInProgress: false,
            materialCache: {},
            data: {
                users_id: context.userObject.usersId,
            },
            bankaccount: {},
            invoiceData: {},
            invoiceDataLoading: false,
            accounts: [],            
            projects: [],
            invoiceDefaultDetails: [],
            billingAddresses: [],
            shippingAddresses: [],
            agreement_identifier:"",
            order_identifier:"",
            billingZones: [],
            account: undefined,
            delivery_type: {value: 1, label: this.tr("Einvoicing")},
            projectData: {},
            invoiceDetails: {
                ...this.defaultCurrencyDetails,
                bill_language: context.taimerAccount.ourCompanyLang,
                type: '1', // 1 = normal invoice, 2 = refund invoice, 3 = payment notice
                hide_quantity_column: 0,
                date_format: convertDateFormatToPHP(context.taimerAccount.companyDateFormat),
                default_print_lang: "en",
                invoice_barcode: false,
                already_invoiced_total_row: "0",
                already_invoiced_total_row_text: '',
                show_material_period_on_invoice: "0",
                creationdate: today, 
                duedate: format(addDays(new Date(), Number(defaulttermsofpayment)), 'YYYY-MM-DD'),
                deliverydate: today,
                terms: defaulttermsofpayment,
                annotation: defaultannotation,
                annotationinterest: defaultpenaltyinterest,
                voucher_date: today
            },
            selectedProjects: [],
            selectedBillingAddress: {},
            selectedShippingAddress: {},
            selectedBillingZone: {
                id: billingZone,
                label: billingZone},
            newAccount: 0,
            useRecipient: false,
            startDate: undefined,
            endDate: undefined,
            companyAddressData: undefined,
            total_no_vat: 0,
            total: 0,
            notes: '',
            useOwnHours: false,
            complexInvoicing: false,
            preSelect: false,
            materialGroupingOptions: {
                projects: '1',
                workhours: this.context.addons.procountor_accounting ? '4' : '5',
                worktrips: '1',
                costest: '1'
            },
            dialogData: {},
            customerEmailValid: true,
            showAccounting: false,
            companyCurrency: context.taimerAccount.currency,
            vatcodes: [],
            noteData: [],
            productRowSettings: [],
            selectedProjectsOwnWork: {},
            generatingHourReport: false,
            generatingInvoicingHistory: false,
            billingLanguages: [{label: this.tr("English"), value: "en", id: "en"}],
            contactPersonSaveDialogOpen: false,
            editedMaterialRowDimensions: [],
            selectedDetailsTab: 'details',
            fieldLimits: {},
            restrictedChars: "",
            activeVatcodes: [],
            showNotes: false,
            addressFieldErrors: [],
            overLimitRows: [],
            overLimitDescriptionRows: [],
            descriptionErrorRows: [],
            invalidDescriptionRows: [],
            fieldErrors: [],
            countryCode: "",
            allowedCountryCodes: [],
            allCountryCodes: [],
            countryVatCodes: [],
            selectedVatCode: {},
            hiddenFields: [],
            activeCurrencies: [],
            salesVatCodes: [],
            usesAccounting: false,
            countryFieldInfoTooltip: undefined,
            dimensionHeadersDiffer: false,
            defaultDimensionValues: [],
            dimensionHeaders: [],
            invoiceSettings: null,
            currencyChanged: false,
            branchOfBusinessDefaultDimensions: [],
            integrationSettings: {}
        };        

        this.state = {
            ...this.initialState,
            companies: [],
            company: initialCompany,
            rows: [],
            descriptionRows: [],
            invoiceType: '0',
            currentDialog: false, // Set after company info is loaded in componentDiMount
            editMode: props.editMode == 'true' ? JSON.parse(props.editMode) : false,
            editEnabled: false,
            updatingInProgress: false,
            has_multi_address_relation: false
        };
        
        this.container = React.createRef();
        this.footer = React.createRef();
        this.accountingRef = React.createRef();
        this.list = React.createRef();
        this.noteDrawer = React.createRef();
        this.attachments = React.createRef();
        this.invoiceMaterial = React.createRef();

        this.ongoingDataFetches = 0;

        this.accountingAddons = [
            "efina", "fivaldi", "netvisor", "procountor", "emce", "talenom", "fennoa", "wintime", "venda", "meritaktiva", "xero", "heeros", "datev", "navcsv", "tietotili"
        ];

        const addons = this.context.addons ? this.context.addons : {};

        this.useManualTerms = !!addons.simple_complex_invoicing;

        this.defaultRow = {
            content_type: "0",
            description: "",
            id: "",
            product_register_id: "",
            cpq_id: "",
            projects_id: "",
            quantity: 1,
            previous_quantity: 1,
            row_category: "1",
            roworder: 0,
            value: 0,
            currency_value: 0,
            vat: context.taimerAccount.defaultVat,
            vatcode: '',
            worktypes_id: '',
            total_marker: 0,
            total_no_vat: 0,
            currency_total_no_vat: 0,
            total: 0,
            currency_total: 0,
            manual_row: 1          
        };

        this.statuses = {
            "0": {"name": this.tr("all"), "color": "#dde3e8"},
            "5": {"name": this.tr("draft"), "color": "#979797", "trigger_id": 0},
            "1": {"name": this.tr("waiting"), "color": "#ffb822", "trigger_id": 1},
            "2": {"name": this.tr("sent"), "color": "#2d9ff7", "trigger_id": 2},
            "4": {"name": this.tr("paid"), "color": colors.greenish_cyan, "trigger_id": 3},
            "3": {"name": this.tr("credited"), "color": "#716aca", "trigger_id": 4},
            "6": {"name": this.tr("overdue"), "color": "#f52b2b", "trigger_id": 5},
            "7": {"name": this.tr("sending"), "color": "#003A78", "trigger_id": 6}
        };

        this.printSettings = [
            {id: "0", label: this.tr('Show all columns')},
            {id: "1", label: this.tr('Hide VAT and Total columns')},
            {id: "2", label: this.tr('Hide Qty, Unit Price, VAT and Total columns')},
        ];

        //vanhan mallin netvisor valuutta, poistetaan kun uusi valuutta tule käyttöön kaikille.
        // this.currencyCodes = [
        //     {id: "EUR", label: "EUR"},
        //     {id: "USD", label: "USD"},
        //     {id: "SEK", label: "SEK"},
        // ];

        this.nextInvoiceId = this.previousInvoiceId = 0;

        this.defaultHeaderRow = Object.assign(...Object.entries(this.defaultRow).map(([k, v]) => (
            {[k]: (k == 'row_category' ? "2" : v)}
        )));

        this.shippingAddressFields = (options, selectedOption) => [
            { label: this.tr("Shipping adressee"),
                name: "shipping_address",
                editorType: CreatableSelect, 
                options: options,
                value: selectedOption, 
                onChange: v => this.shippingAddressSet(v) },                                   
            { label: this.tr("Address"), name: "address", value: undefined, onChange: e => this.modifyShippingAddress(e, true, true) },
            { label: this.tr("Zip code"), name: "postalcode", value: undefined, onChange: e => this.modifyShippingAddress(e) },
            { label: this.tr("City"), name: "city", value: undefined, onChange: e => this.modifyShippingAddress(e, true, true) },
            { label: this.tr("Country"), name: "country", value: undefined, onChange: e => this.modifyShippingAddress(e, true, true) },
            { label: this.tr("Business id"), name: "vatid", value: undefined, onChange: e => this.modifyShippingAddress(e) },                            
            // { label: "Contact", name: "contact", value: undefined, onChange: e => this.modifyShippingAddress(e, true, true) },
            // { label: "Email", name: "email", value: undefined, onChange: e => this.modifyShippingAddress(e) },                                    
        ];

        this.invoiceDataTypes = [
            {id: "0", name: this.tr(' All')},
            {id: "1", name: this.tr('Hour entry')},
            {id: "2", name: this.tr('Scheduled invoicing')},
            {id: "4", name: this.tr('Expenses')},
            {id: "5", name: this.tr('Quote')},
            {id: "6", name: this.tr('Sub-contracting')},
            {id: "7", name: this.tr('Actual costs')},
            {id: "8", name: this.tr('Daily allowances')},
            {id: "9", name: this.tr('Mileage allowances')},
            {id: "10", name: this.tr('Travel expenses, other expenses')},
            {id: "11", name: this.tr('Mileage raises')},
            {id: "12", name: this.tr('Freelancer\'s hours')},
            {id: "13", name: this.tr('Joined hours')},
            {id: "14", name: this.tr('Informational hours')},
            {id: "15", name: this.tr('Refund')},
            {id: "100", name: this.tr('Traveling invoices')} //not actual invoiceDataType
        ].map(e => {e.label = e.name; return e});

        this.currencies = [];

        this.dateFormats = Array.from(new Set([
            context.userObject.dateFormat,
            'DD.MM.YYYY',
            'MM/DD/YYYY',
            'YYYY-MM-DD'
        ]));   

        this.definedVatCodes = {
            empty: { id: -1 },
            rowSpecific: { id: -2, vatpercent: 0, label: this.tr("Row specific VAT codes") }
        }

        this.createHourReportSnackbarKey = null;
        this.createInvoicingHistorySnackbarKey = null;
        this.reverseVatNotificationSnackbarKey = null;

        /* arguments doesnt work with arrow functions */
        this.buildState = this.buildState.bind(this);
    }

    isCompanyUsingVatCodes = () => {
        const { addons } = this.context;

        return (addons.nav && addons.nav.used_by_companies.indexOf(this.state.company) > -1) ||
            (addons.wintime && addons.wintime.used_by_companies.indexOf(this.state.company) > -1) ||
            (addons.heeros && addons.heeros.used_by_companies.indexOf(this.state.company) > -1) ||
            (addons.meritaktiva && addons.meritaktiva.used_by_companies.indexOf(this.state.company) > -1) ||
            this.useNetvisorVatCodes()
    } 

    isCompanyUsingRowSpecificVatcodes = () => {
        const { addons } = this.context;
        return (addons.meritaktiva && addons.meritaktiva.used_by_companies.indexOf(this.state.company) > -1) ||
            (addons.heeros && addons.heeros.used_by_companies.indexOf(this.state.company) > -1) ||
            (addons.netvisor && addons.netvisor.used_by_companies.indexOf(this.state.company) > -1)
    }

    getActiveVatcodeAddons = () => {
        const { addons } = this.context;
        const vatcodeAddons = ["nav", "wintime", "meritaktiva", "netvisor", "heeros"];
        const activeAddons = [];
        vatcodeAddons.forEach(v => {
            if (addons[v] && addons[v].used_by_companies.indexOf(this.state.company) > -1) {
                activeAddons.push(v);
            }
        });

        return activeAddons;
    }

    isCompanyUsingCountryCode = () => {
        const { addons } = this.context;
        return isCompanyUsingInvoiceCountryCode(addons, this.state.company);
    }

    isCompanyUsingTalenom = () => {
        const { addons } = this.context;
        return (addons.talenom && addons.talenom.used_by_companies.indexOf(this.state.company) > -1);
    }
    isCompanyUsingProcountor = () => {
        const { addons } = this.context;
        return (addons.procountor && addons.procountor.used_by_companies.indexOf(this.state.company) > -1);
    }

    isCompanyUsingFortnox = () => {
        const { addons } = this.context;
        return (addons.fortnox && addons.fortnox.used_by_companies.indexOf(this.state.company) > -1);
    }
    isCompanyUsingNetvisor = () => {
        const { addons } = this.context;
        return (addons.netvisor && addons.netvisor.used_by_companies.indexOf(this.state.company) > -1);
    }
    isCompanyUsingMeritAktiva= (company = false) => {
        const { addons } = this.context;
        const checkCompany = company ? company : this.state?.company;
        return (addons.meritaktiva && addons.meritaktiva.used_by_companies.indexOf(checkCompany) > -1);
    }
    isCompanyUsingNav = () => {
        const { addons } = this.context;
        return (addons.nav && addons.nav.used_by_companies.indexOf(this.state.company) > -1);
    }

    useVatcodeReverseVat = () => {
        const { addons } = this.context;
        return (addons.nav && addons.nav.used_by_companies.indexOf(this.state.company) > -1);
    }

    isCompanyUsingAccounting = () => {
        const { addons } = this.context;
        const { company } = this.state;
        let usesAccounting = false;

        this.accountingAddons.forEach(a => {
            if (addons[a] && addons[a].used_by_companies.indexOf(company) > -1) {
                usesAccounting = true;
            }
        });

        return usesAccounting;
    }

    useNetvisorVatCodes = () => {
        const { activeVatcodes } = this.state
        const netvisorVatcodes = activeVatcodes.filter(v => v.integration == "netvisor");
        return this.isCompanyUsingNetvisor() && netvisorVatcodes.length > 0;
    }

    emptyVatcodeSelected = () => {
        const { selectedVatCode } = this.state;
        return this.isCompanyUsingVatCodes() && selectedVatCode?.id == this.definedVatCodes?.empty?.id;
    };

    isRowDescriptionRequired = () => {
        const { addons } = this.context;
        return (addons.procountor && addons.procountor.used_by_companies.indexOf(this.state.company) > -1);
    }

    returnValidBillingLanguage = (newLang) => {
        const {invoiceDetails, selectedBillingAddress, data} = this.state;
        const {id} = this.props;

        if (id && id > 0 && data?.bill_language != '' && data?.bill_language !== undefined)
            return data.bill_language;
        if (selectedBillingAddress?.bill_language != '' && selectedBillingAddress?.bill_language !== undefined)
            return selectedBillingAddress.bill_language;
        if (id && id > 0 && invoiceDetails?.bill_language != '' && invoiceDetails?.bill_language !== undefined)
            return invoiceDetails.bill_language;
        if (newLang && newLang != '')
            return newLang;

        return this.context.taimerAccount.ourCompanyLang;
    }
    
    isMaterialRefreshAllowed = (prevState) => {
        const { selectedProjects } = this.state;
        
        /* we cannot fetch material if there are no selected projects */
        if (!selectedProjects.length)
            return false;
        
        /* payment reminders dont have material */
        if (this.state.invoiceDetails.type == '3')
            return false;
        
        /* refund invoices dont have material except if refund_material addon is on */
        if (this.state.invoiceDetails.type == '2' && !Number(this.state.invoiceDetails.collect_data))
            return false;
        
        if (prevState) {
            if (selectedProjects.length !== prevState.selectedProjects.length)
                return true;
        
            if (selectedProjects[0]?.id !== prevState.selectedProjects[0]?.id)
                return true;
            
            if (prevState.startDate !== this.state.startDate)
                return true;
            
            if (prevState.endDate !== this.state.endDate)
                return true;
        } else {
            return true;
        }
    }
    getBillingCustomersId = ({account = this.state.account, selectedProjects = this.state.selectedProjects, refProject = this.refProject({selectedProjects})} = {}) => { 
        return  refProject?.billing_customers_id || 
                account?.id || 
                "0";
    }

    getIdentifiers = async ({ company = this.state.company, selectedProjects = this.state.selectedProjects } = {}) => {
        const identifiers = await DataHandler.get({url: `invoices/invoice_identifier_data/${company}`, projects_id: selectedProjects.length > 0 && selectedProjects.map(e => e.id)})
        return { agreement_identifier: identifiers.agreement_identifier, order_identifier: identifiers.order_identifier };
    }

    getCompanyBankAccounts = async ({ company = this.state.company } = {}) => {
        const bankAccounts = await DataHandler.get({url: 'invoices/bank_accounts', company});

        let bankaccount = bankAccounts.find(e => e.default_account == 1);
            
            if (!bankaccount)
                bankaccount = bankAccounts[0] || {};

        return { bankAccounts, bankaccount };
    };

    // Get relevant settings for all companies.
    getMaterialPeriodIndicatorSettings = async () => {
        return await DataHandler.get({ url: `invoices/material_period_indicator` });
    }

    componentDidUpdate(prevProps, prevState) {
        //this.getObjectDiff(this.state, prevState); //for debugging only

        const { company, invoiceType, selectedProjects, data: {id}, startDate, endDate, selectedBillingAddress, countryCode } = this.state;

        if (prevState.editMode != this.state.editMode) {
            this.setKeyboardListeners();
        }

        if (prevState.invoiceType != this.state.invoiceType) {
            
            if (this.state.invoiceType == 1) {
                delete this.initialProjectState.rows;
                delete this.initialProjectState.descriptionRows;
            }
            else {
                this.initialProjectState.rows = [];
                this.initialProjectState.descriptionRows = [];
            }
        }

        // (don't call on initial change, is called manually inside getCompaniesAndMountInvoice) -> not calling on initial, but have to call if initialcompany is different from invoice's real company -vx
        if (prevState.companies.length > 0 && prevState.company != this.state.company) {
            this.onCompanyChanged();
        }

        if (countryCode != prevState.countryCode) {
            this.countryCodeChanged(countryCode, this.gettingInvoice ? true : false);
        }

        if (selectedBillingAddress?.id != prevState.selectedBillingAddress?.id) {
            this.countryCodeChanged(selectedBillingAddress?.country_code, this.gettingInvoice ? true : false);
            this.setState({addressFieldErrors: []});
        }

        // // only called when changed manually, not when invoice data is loaded
        // if (!this.gettingInvoice) {
        //     if (!isEqual(prevState.account, this.state.account) && !!this.state.account) {
        //         this.onAccountChanged();
        //     }
    
        //     if (prevState.selectedProjects != this.state.selectedProjects) {
        //         const curProjects = this.state.selectedProjects.map(e => e.id).sort();
        //         const prevProjects = prevState.selectedProjects.map(e => e.id).sort();
        //         const selectedProjectsChanged = !isEqual(curProjects, prevProjects);
        //         if (selectedProjectsChanged) {
        //             this.onProjectsChanged();
        //         }
        //     }
        // }

        // on initial load, get account and project data based on selected invoice type
        if (prevState.invoiceType == '0' && prevState.invoiceType != this.state.invoiceType) {
            if (this.isMaterialInvoice()) {
                this.getInvoiceableAccountsAndProjects().then(data => {
                    this.setState({
                        ...data
                    });
                });
            } else {
                this.getAccounts().then(data => {
                    this.setState({
                        ...data
                    });
                });
            }
        }

        if((!prevState.editMode && this.state.editMode) 
            || (
                this.state.editMode 
                && prevState.invoiceDetails.already_invoiced_total_row != this.state.invoiceDetails.already_invoiced_total_row 
                && !this.state.invoiceDetails.already_invoiced_total_row
            )
        ) {
            this.deleteAlreadyInvoicedRow(this.deleteMaterialPeriodRow);
        }

        // if (this.state.editMode && (!isEqual(prevState.selectedVatCode, this.state.selectedVatCode) || prevState.invoiceDetails.reverse_vat != this.state.invoiceDetails.reverse_vat) && this.isCompanyUsingVatCodes()) {
        //     this.onVatCodeChanged();
        // }

         if (prevState.default_accounting_accounts_id != this.state.default_accounting_accounts_id) {
           this.onAccountingAccountChanged();
        }

        // refresh invoice material if projects or timespan changes and invoice isnt credit invoice or payment notice 
        if (this.isMaterialInvoice() && this.isMaterialRefreshAllowed(prevState)) {
            this.getInvoiceData();
        }

        //if selected projects has changed and invoice material dialog is open, update dialog data
        if (this.state.dialogData?.projects?.length && !_.isEqual(this.state.selectedProjects, this.state.dialogData.projects)) {
            const selectedProjects = _.cloneDeep(this.state.selectedProjects);
            this.setState({
                dialogData: {
                    ...this.state.dialogData,
                    projects: selectedProjects,
                    selectedProjects
                }
            });
        }

    }

    getInvoiceData = () => {
        const { company, invoiceType, selectedProjects, data: { id }, startDate, endDate } = this.state;
        
        const tempState = { invoiceDataLoading: true };

        if (this.isNewInvoice())
            Object.assign(tempState, {rows: [], descriptionRows: []} );

        this.setState(tempState, () => {
            const { preselect, quote, preselect_quote_row_ids, preselect_material_rows } = this.props;
            const billLanguage = this.getSelectedBillLanguage();
            const transl = new InvoiceTranslations().returnTranslations([billLanguage]);

            if (this.invoiceDataRequest) {
                this.invoiceDataRequest.abort();
            }
            
            const params = {
                company,
                id,
                projects: selectedProjects.map(e => e.id),
                start: startDate,
                end: endDate,
                address: this.refProject()?.customer_address_id,
                preselect,
                preselect_ids: [quote],
                preselect_quote_row_ids: preselect_quote_row_ids ? decodeURIComponent(preselect_quote_row_ids).split(',') : null,
                preselect_material_rows: preselect_material_rows ? JSON.parse(sessionStorage.getItem("invoiceMaterialPreselect")) : null,
                str_quote_rows: transl[billLanguage]?.quote_rows,
            };
            if (this.isNewInvoice())
                Object.assign(params, { rowGenerationParams: this.getRowGenerationParams() });

            this.invoiceDataRequest = DataHandler.post({
                url: 'invoices/invoice_data',
            },
            {
                invoiceType,
                ...params,
            }).done(e => {
                this.handleInvoiceData(e);
            });
        });
    }

    getRowGenerationParams = () => {
        const { selectedVatCode, materialGroupingOptions, default_accounting_accounts_id, default_accounting_dimensions_id, default_accounting_products_id, activeVatcodes } = this.state;
        
        const invoiceHeaderType = this.state.companyAddressData ? this.state.companyAddressData.invoice_header_name_type : 1;
        const params = {
            materialGroupingOptions: materialGroupingOptions,
            billLanguage: this.getSelectedBillLanguage(),
            dateFormat: this.state.invoiceDetails?.date_format,
            vatPercent: this.getActiveVatPercent(),
            vatCode: selectedVatCode?.id > 0 ? selectedVatCode : (selectedVatCode?.id == -2 ? this.getDefaultVatcode() : undefined),
            reverse_vat: this.useVatcodeReverseVat() ? (selectedVatCode?.use_reverse_vat || "0") : this.state.invoiceDetails.reverse_vat,
            invoiceHeaderType: invoiceHeaderType,
            default_accounting_accounts_id,
            default_accounting_dimensions_id,
            default_accounting_products_id,
        }

        return params;
    }

    getDueDate = (state = this.state) => {
        const { invoiceDetails: {terms, creationdate} } = state;
        
        return addDays( Utils.localDate(creationdate) , Number(terms));
    }

    getFormattedDueDate = (state = this.state) => format( this.getDueDate(state), 'YYYY-MM-DD')

    updateRowDimensions = (updateRows = false) => {
        // Update rows to state, so doesn't wait timeout to changes in updateRows to show.
        if (updateRows) {
            this.setState({ rows: updateRows });
        }

        setTimeout(() => {
            const { rows, selectedProjects, dimensions, editedMaterialRowDimensions, branchOfBusinessDefaultDimensions } = this.state;
            const update = _.cloneDeep(updateRows || rows);

            // This wasn't working properly and now when this was fixed it overrides default dimension values that come from the backend.
            // Use only with Talenom now, as this works now with Talenom dimensions linked with project categories
            if (this.isCompanyUsingTalenom() && selectedProjects.length > 0) {
                update && update.length > 0 && update.forEach(e => {
                    const editedMaterialRowDimension = editedMaterialRowDimensions.find(mrd => mrd.material_id && mrd.projects_id && mrd.material_id == e.material_id && mrd.projects_id == e.projects_id);
                    if (Number(e.id) < 1 && !editedMaterialRowDimension && !e.dimension_item_edited) {
                        let projectDimensionItem = 0;
                        const rowProject = e.projects_id ? selectedProjects.find(s => s.id == e.projects_id) : false;
                        if (rowProject) {
                            if (branchOfBusinessDefaultDimensions.length > 0) {
                                projectDimensionItem = Number(rowProject.branchofbusiness_id) > 0 ? branchOfBusinessDefaultDimensions?.find(b => b.bob_id == rowProject.branchofbusiness_id)?.id : 0;
                            } else {
                                projectDimensionItem = Number(rowProject.branchofbusiness_id) > 0 ? dimensions?.find(d => d.branchofbusiness_id == rowProject.branchofbusiness_id)?.id : 0;
                            }
                        }
                        else if (selectedProjects.length == 1) {
                            const projectBranchOfBusiness = selectedProjects[0]?.branchofbusiness_id;
                            if (branchOfBusinessDefaultDimensions.length > 0) {
                                projectDimensionItem = Number(projectBranchOfBusiness) > 0 ? branchOfBusinessDefaultDimensions?.find(b => b.bob_id == projectBranchOfBusiness)?.id : 0;
                            } else {
                                projectDimensionItem = Number(projectBranchOfBusiness) > 0 ? dimensions?.find(d => d.branchofbusiness_id == projectBranchOfBusiness)?.id : 0;
                            }
                        }
                    
                        const dimensionItem = projectDimensionItem ? projectDimensionItem : this.state.default_accounting_dimensions_id;
                        if (selectedProjects.length == 1) 
                            this.defaultRow.dimension_item = dimensionItem;

                        e.dimension_item = dimensionItem;
                    }
                    else if (editedMaterialRowDimension) {
                        e.dimension_item = editedMaterialRowDimension.dimension_item;
                    }
                })
            }

            if (selectedProjects.length != 1) 
                this.defaultRow.dimension_item = this.state.default_accounting_dimensions_id;

            this.setState({ rows: update });
        }, 1000);
    }
    
    /* initial values for datalists etc */
    /* THIS FUNCTION CAN BE DANGEROUS, please dont expand it further than absolutely necessary */ 
    preselectSetter = (update) => {
        if (!this.isNewInvoice())
            return update;

        this.preselectVatCode(update);
        this.preselectProjects(update);
        this.preselectAccount(update);
        this.preselectBillingAddress(update);

        if (this.props.start)
            update.startDate = this.props.start;

        if (this.props.end)
            update.startDate = this.props.end;
        
        return update;
    }
    preselectBillingAddress = (update, state = this.state) => {
        const { billingAddresses, selectedBillingAddress = state.selectedBillingAddress, refProject = this.refProject(update) } = update;
      
        if (!billingAddresses?.length)
            return update;
        if (selectedBillingAddress?.id > 0)
            return update;
        
        Object.assign(update, { 
            refProject,
            selectedBillingAddress: this.billingAddress({ billingAddresses, refProject }),
        });
        this.addressRelatedStateUpdate(update);
        
        return update;
    }
    preselectVatCode = (update, state = this.state) => {
        const { activeVatcodes: vatcodes, selectedVatCode = state.selectedVatCode } = update;
       
        if (!vatcodes?.length || this.isCompanyUsingCountryCode())
            return update;
        if ( vatcodes?.find(e => e.id === selectedVatCode?.id) )
            return update; 

        update.selectedVatCode = this.getDefaultVatcode(vatcodes);
        return update;
    }
    preselectAccount = (update, state = this.state) => {
        const { customers_id } = this.props;
        const { accounts } = update;

        if (!customers_id)
            return update;
        if (!accounts?.length)
            return update;

        update.account = _.cloneDeep(
            accounts?.find(e => e.id === customers_id) || 
            {}
        );
        return update;    
    }
    preselectProjects = (update, state = this.state) => {
        const {projects_id, projects_ids } = this.props;
        const { projects, selectedProjects = state.selectedProjects } = update;

        if (!projects_id && !projects_ids)
            return update;
        if (selectedProjects?.length)
            return update;
        if (!projects?.length)
            return update;
        
        const search = projects_ids?.length ? decodeURIComponent(projects_ids).split(',') : [projects_id];

        update.selectedProjects = _.cloneDeep(
            projects.filter(e => search.includes(e.id))
        );
        this.projectRelatedStateUpdate(update);

        return update;
    }
    projectRelatedStateUpdate = (update, state = this.state) => {
        let {   startDate, endDate, selectedProjects, bill_language, invoice_notes = [],
                dialogData = state.dialogData, refProject = this.refProject(update) } = update;

        selectedProjects.forEach(e => {
            e.invoice_note && invoice_notes.push(e.invoice_note);

            if (e.bill_language)
               bill_language = e.bill_language;
            if (!startDate || (startDate > e.first))
                startDate = e.first;
            if (!endDate || (endDate < e.last))
                endDate = e.last;  
        });
        if (dialogData.startDate)
            /* invoice material dialog is open */
            Object.assign(dialogData, {
                startDate,
                endDate,
            });

        invoice_notes.sort();
        const uniqueNotes = invoice_notes.filter((str, index, arr) => {
            return index === 0 || str !== arr[index - 1];
        });

        // Reset address data to defaults if project has multiple addresses.
        if (selectedProjects?.length > 0 && selectedProjects[0]?.invoicing_addresses?.length > 0) {
            const details = this.getProjectMultiAddressDetails(selectedProjects);
            bill_language = details.bill_language;
            this.buildStateObj(update, this.getReverseVat(details.reverse_charge));
            this.buildStateObj(update, details.currencyState);
        }

        this.buildStateObj(update, {
            notes: uniqueNotes.join("; "),
            materialGroupingOptions: this.projectGroupingOptions(refProject),
            invoiceDetails: {
                ...this.getReferences(update),
                bill_language
            },
            startDate, 
            endDate,
            dialogData,
        });

        return update;
    }
    addressRelatedStateUpdate = (update, state = this.state) => {
        const { selectedBillingAddress, selectedProjects } = update;

        /* selectedBillingAddress should include reverse_charge and override invoiceDetails state values */
        let {   currency, bill_language, 
                reverse_charge = state.invoiceDetails.reverse_vat,
                reverse_vat = state.invoiceDetails.reverse_vat,
                currencyState = this.currencyDetails(currency) } = selectedBillingAddress;

        // Reset address data to defaults if project has multiple addresses.
        if (selectedProjects?.length > 0 && selectedProjects[0]?.invoicing_addresses?.length > 0) {
            const details = this.getProjectMultiAddressDetails(selectedProjects);
            bill_language = details.bill_language;
            reverse_charge = details.reverse_charge;
            currencyState = details.currencyState;
        }

        if (currencyState.invoiceDetails.currency_rates_id)
            this.buildStateObj(update, currencyState);
        
        if (reverse_charge != reverse_vat) 
            this.buildStateObj(update, this.getReverseVat(reverse_charge));

        if (bill_language)
            update.invoiceDetails.bill_language = bill_language;
        
        return update;
    }
    refProject = ({selectedProjects = this.state.selectedProjects} = {}) => selectedProjects?.[0];

    getProjectMultiAddressDetails = (selectedProjects) => {
        const addresses = selectedProjects[0]?.invoicing_addresses || [];

        let currency = addresses[0]?.currency;
        let bill_language = addresses[0]?.bill_language;
        const reverse_charge = false;

        let sameCurrency = true;
        let sameLanguage = true;
        (addresses || []).forEach(a => {
            if (a.currency != currency) {
                sameCurrency = false;
            }
            if (a.bill_language != bill_language) {
                sameLanguage = false;
            }
        });

        currency = sameCurrency ? currency : this.context.taimerAccount.currency;
        bill_language = sameLanguage ? bill_language : this.state.invoiceDetails?.default_print_lang;
        const currencyState = this.currencyDetails(currency);

        return {
            currency,
            bill_language,
            currencyState,
            reverse_charge
        }
    }

    currencyDetails = (preferredCurrency, state = this.state) => {
        const currencyCode =    preferredCurrency ||
                                state.invoiceDetails.currency_code ||
                                state.companyCurrency;

        if (!currencyCode)
            return {};
       
        const currencyDetails = CurrencyUtils.returnSpecificActiveCurrencyDetails(
            this.currencies, 
            {
                field: 'id', 
                searchValue: currencyCode
            }
        );
        
        return {
            invoiceDetails: currencyDetails.currency_rates_id ? currencyDetails : {}
        };
    }
    billingAddress = ({refProject = this.refProject(this.state.selectedProjects), billingAddresses = this.state.billingAddresses} = {}) => {
        const address = _.cloneDeep(
            billingAddresses.find(e => e.id === refProject?.customer_address_id) ||
            billingAddresses[0] ||
            {}
        );
        if (refProject?.invoice_contact_person?.length !== undefined)
            address.contact = refProject.invoice_contact_person;

        return address;
    }
    enforceFieldLimits = (update) => {
        const { fieldLimits } = this.state;

        const referenceLimit = Number(fieldLimits?.reference);
        const customerreferenceLimit = Number(fieldLimits?.customerreference);
        const notesLimit = Number(fieldLimits?.notes);

        if (customerreferenceLimit && update.invoiceDetails?.customerreference)
            update.invoiceDetails.customerreference = update.invoiceDetails.customerreference.customer_reference.slice(0, customerreferenceLimit);

        if (referenceLimit && update.invoiceDetails?.reference)
            update.invoiceDetails.reference = update.invoiceDetails.reference.reference.slice(0, referenceLimit);   

        if (notesLimit && update.notes)
            update.notes = update.notes.slice(0, notesLimit);
        
        return update;
    }


    /* for debugging */
    getObjectDiff(obj1, obj2) {
        const diff = Object.keys(obj1).reduce((result, key) => {
            if (!obj2.hasOwnProperty(key)) {
                result.push(key);
            } else if (_.isEqual(obj1[key], obj2[key])) {
                const resultKeyIndex = result.indexOf(key);
                result.splice(resultKeyIndex, 1);
            }
            return result;
        }, Object.keys(obj2));

        console.log(diff);
    }
    
    backToList = (evt) => {
        this.context.functions.goToPreviousView({module: 'invoices', action: 'main', selectedTab: 'invoices'});
        //this.props.updateView({module: 'invoices', action: 'main', selectedTab: 'invoices'}, evt?.button === 1);
    };

    getBillingZones = async () => {
        const billingZones = await DataHandler.get({url: 'invoices/billing_zones'});
        this.setState({ billingZones })
    };
    

    isNewInvoice = () => {
        return Number(this.props.id || 0) <= 0;
    }

    isEditingDisabled = () => {
        const {  userObject, functions: { checkPrivilege } } = this.context;

        const { data, selectedProjects, company } = this.state;
        let editRights = false;
        if ((checkPrivilege("invoices", "write_full", company) || (checkPrivilege("invoices", "write_simple", company) && (data.users_id == userObject.usersId || (selectedProjects && selectedProjects.length == selectedProjects.filter((project) => project.project_manager == userObject.usersId).length))))) {
            editRights = true;
        }

        return !(editRights && (this.isNewInvoice() || Number(data.state) < 2))
    }

    isMaterialInvoice = () => {
        return ['2', '3'].includes(this.state.invoiceType);
    }
    paperEditable = () => this.isNewInvoice() || Number(this.state.data.state) < 2

    getSelectedCompany = (companies = this.state.companies) => {
        return companies.find(c => c.id == this.state.company);
    }

    showInvoiceTypeDialog = () => {
        const { companies } = this.state;
        const company = this.getSelectedCompany();
        const dialogData = { onInitiate: this.onInvoiceTypeSelected, blankAllowed: company?.invoice_only_material != "1", massInvoicingAllowed: (companies.findIndex(c => c.allow_mass_invoicing == 1) != -1) };
        this.setState({ currentDialog: 'initial', dialogData });
    }

    componentDidMount(){
        super.componentDidMount();

        this.setKeyboardListeners();
        this.getCompaniesAndMountInvoice();
        this.getBillingZones()
    }

    getCompaniesAndMountInvoice = async () => {
        const companies = await DataHandler.get({url: `subjects/companies/invoices/write_full+write_simple`, currency: 1, date_format: 1, invoice_only_material: 1, billing_language: 1, print_lang: 1, print_options: 1, invoice_barcode: 1, allow_mass_invoicing: 1, already_invoiced_total_row: 1, defaultvat: 1,})
        
        const company = companies.find(c => c.id == this.state.company) || companies[0];
        
        let invoiceType = this.state.invoiceType;
        if (this.props.preselect && this.props.preselect !== "account")
            invoiceType = this.invoiceTypeFromProps();
        else if(company.invoice_only_material == "1")
            invoiceType = "2";

        this.setState({
            companies,
            company: company?.id,
            invoiceType,
        }, () => {
            this.onCompanyChanged(true);
        });
    }

    mountInvoice = () => {
        this.getAllCountryCodes();
        if (this.isNewInvoice()) {
            this.mountNewInvoice();
        } else {
            this.getInvoice();
        }
    }

    getAllCountryCodes = () => {
        if (!this.isCompanyUsingCountryCode()) {
            return;
        }
        const {company} = this.state;
        DataHandler.get({ url: `settings/autocomplete`, country_codes: 1 }).done(settings => {
            this.setState({ allCountryCodes: settings.country_codes })
        });
    }

    onCompanyChanged = (mountInvoiceAfter = false) => {
        const { addons } = this.context;
        this.setState({ editEnabled: false }, async () => {
            const company = this.getSelectedCompany()
            const onlyMaterial = company.invoice_only_material == "1";

            if (!mountInvoiceAfter && onlyMaterial && this.state.invoiceType == 1) {
                /* i think this should be done before getCompanyRelatedData() because it depends on invoiceType value */
                await this.onInvoiceTypeSelected(2);
            }

            const {account, selectedProjects, ...companyData} = await this.getCompanyRelatedData();
            if (!company) {
                // show some message here about company not being found
                return;
            }
            
            const countryFieldInfoTooltip = (this.isCompanyUsingProcountor() || this.isCompanyUsingNetvisor()) ? this.tr("e.g. FI, FINLAND, SUOMI") : undefined;
            let update = {};
            if (this.isNewInvoice()) {
                update = _.cloneDeep(this.initialState);
            }

            if (this.isNewInvoice()) {
                if (this.state.invoiceType != 1)
                    update.rows = [];

                update = {
                    ...update,
                    ...companyData,
                    editEnabled: !mountInvoiceAfter || this.isNewInvoice(),
                    billingLanguages: company.print_languages || [],
                    companyCurrency: company?.currency, 
                    show_agreement_order_identifier: company?.show_agreement_order_identifier_on_invoice == 2,
                    usesAccounting: this.isCompanyUsingAccounting(),
                    countryFieldInfoTooltip,
                    notes: companyData?.invoiceSettings?.default_invoice_note,
                    invoiceDetails: {
                        ...update.invoiceDetails,
                        ...companyData.invoiceDetails,
                        bill_language: this.returnValidBillingLanguage(company.print_lang), 
                        date_format: company?.date_format || this.state.invoiceDetails?.date_format, 
                        default_print_lang: company?.default_print_lang,
                        invoice_barcode: company?.invoice_barcode,
                        already_invoiced_total_row: company?.already_invoiced_total_row,
                    },
                }
            } else {
                update = {
                    ...update,
                    ...companyData,
                    editEnabled: !mountInvoiceAfter || this.isNewInvoice(),
                    billingLanguages: company.print_languages || [],
                    companyCurrency: company?.currency, 
                    show_agreement_order_identifier: company?.show_agreement_order_identifier_on_invoice == 2,
                    usesAccounting: this.isCompanyUsingAccounting(),
                    countryFieldInfoTooltip
                }
                delete update.invoiceDetails;
            }

            this.defaultRow.vat = company.defaultvat;

            this.setState(update, async () => {
                mountInvoiceAfter && this.mountInvoice();

                if (account)
                    await this.onAccountChanged(account, false);
                if (selectedProjects) {
                    this.onProjectsChanged(selectedProjects);
                }
            });
        });
    }
    /*
    * change event triggered by user.
    */
    onAccountChanged = async (account, useEditEnabled = true) => {
        if (!this.paperEditable() || 
            !account?.id)
            return;

        const getter = async () => {
            const accountData = await this.getAccountRelatedData({ account, selectedProjects: [] });
            const resetData =  _.cloneDeep({
                ...this.initialAccountState,
                ...this.initialProjectState,
            });
            const update = {
                ...resetData,
                ...accountData,
                account,
                editEnabled: true,
                invoiceDetails: {
                    ...this.state.invoiceDetails,
                    ...resetData.invoiceDetails,
                    ...accountData.invoiceDetails,
                }
            }

            if (!this.isMaterialInvoice()) {
                update.selectedBillingAddress = this.billingAddress(update);
                this.addressRelatedStateUpdate(update);
                Object.assign(update, await this.getProjects({account}) );
            }

            await this.setState(update);
        };

        if (useEditEnabled)
            await this.setState({ editEnabled: false }, getter);
        else
            await getter();
    }

    getNoteToInvoicer = ({ company = this.state.company, account = this.state.account, selectedProjects = this.state.selectedProjects } = {}) => {
        const noteData = [];
        selectedProjects.forEach(project => {
            let data = {
                project_id: project.id, 
                mainHeader: project.name + " (" + project.project_id + ")",
                mainHeaderAction: () => {    
                    this.context.functions.updateView({
                        "module": "projects",
                        "action": "view",
                        "id": project.id,
                        "company": company,
                    }, true);
                
                },
                subHeader: account.name,
                note: "",
                creator: "",
                creator_id: "",
                created_date: "",
                editor: "",
                editor_id: "",
                edited_date: "",
            };
            if (project.creator_id !== null && project.creator_id > 0 && project.message_for_invoicer !== "") {
                data = {   
                    ...data,
                    note: project.message_for_invoicer,
                    creator: project.creator,
                    creator_id: project.creator_id,
                    created_date: moment(project.message_creation_date).format(this.context.userObject.dateFormat),
                    editor: project.editor,
                    editor_id: project.editor_id,
                    edited_date: moment(project.message_edited_date).format(this.context.userObject.dateFormat),
                }
                
            }
            noteData.push(data);
        });
        return noteData
    }

    getReferences = (invoiceData = this.state) => {
        const references = [];
        const our_references = [];
        invoiceData.selectedProjects.forEach(e => {
            e.customer_reference && references.push(e.customer_reference);
            e.invoice_our_reference && our_references.push(e.invoice_our_reference);
        });
        return {
            customerreference: references.join("; "),
            reference: our_references.join("; ")
        }
    }

    getProjectRelatedData = async (invoiceData = this.state) => {
        const responses = await Promise.all([this.getIdentifiers(invoiceData), this.getReferences()])
        const noteData = this.getNoteToInvoicer(invoiceData);
        const identifierData = responses[0];
        const references = responses[1];
        const data = {
            ...identifierData,
            noteData,
            invoiceDetails: {
                ...references
            }
        };

        return data;
    }
    invoiceTypeFromProps = () => {
        const { template } = this.props;
        let invoiceType = Number(this.props.invoiceType);

        if (![1,2,3].includes(invoiceType))
            invoiceType = template === 'blank' ? 1 : 2;
        
        return invoiceType.toString();
    }

    getFieldlimitations = async () => {
        const { company } = this.state;
        const fieldMap = {
            company: 'customerName',
            customers_vatid_raw: 'vatid',
            customers_vatid_2: 'vatid_2',
            customers_email: 'customer_email'
        };

        const limitations = await DataHandler.get({ url: `invoices/field_limitations`, company: company});
        const {fieldLimits, hiddenFields, showNotes, restrictedChars} = limitations;

        Object.keys(fieldMap).forEach(fm => {
            if (fieldLimits[fm]) {
                fieldLimits[fieldMap[fm]] = fieldLimits[fm];
                delete fieldLimits[fm];
            }
        });        

        return {fieldLimits, hiddenFields, showNotes, restrictedChars};
    }

    isFieldHidden = (field) => {
        return this.state.hiddenFields.indexOf(field) > -1;
    }
    /*
    * Object assign for invoiceview state objects. takes unlimited number of params 
    * and concats them towards the most left parameter and returns the output. automaticly takes care of invoiceDetails
    */
    buildStateObj () {
        let args = Array.from(arguments), root = args.shift();
        
        if (!args.length)
            return root;
        if (!Object.isExtensible(root))
            root = {};
        if (!Object.isExtensible(root.invoiceDetails))
            root.invoiceDetails = {};

        args.forEach(e => {
            if (!Object.isExtensible(e))
                return;

            const { invoiceDetails, ...rest} = typeof e === 'function' ? e() : e;

            if ( Object.isExtensible(invoiceDetails) )
                Object.assign(root.invoiceDetails, invoiceDetails);

            Object.assign(root, rest);
        });

        if (!Object.keys(root.invoiceDetails)?.length)
            delete root.invoiceDetails;

        return root;
    }
    buildState () {
        const args = Array.from(arguments);
        return this.buildStateObj(_.cloneDeep(this.state), ...args);
    }

    /*
    * change event triggered by user
    */
    onProjectsChanged = async (selectedProjects) => {
        let nState = { 
            editEnabled: true,
            selectedProjects, 
            refProject: this.refProject({selectedProjects}) 
        };

        if (!this.paperEditable())
            /* invoice material can be edited and therefore projects should be set to state */
            return this.setState(nState);

        this.setState({ editEnabled: false }, async () => {
            let accountPromise, projectPromise, addressPromise, addressStatePromise, resetData, projectRelatedStatePromise,
                billingAccount = this.getBillingCustomersId(nState);

            if (this.isNewInvoice() && this.getBillingCustomersId(/* old state */) !== billingAccount) { 
                /* account data should not be updated after it has been saved atleast once */
                accountPromise = this.getAccountRelatedData(nState);
                
                addressPromise = accountPromise.then(
                    res => ({ 
                        selectedBillingAddress: this.billingAddress({...res, ...nState}) 
                    })
                );
                addressStatePromise = addressPromise.then( 
                    res => this.addressRelatedStateUpdate({...res, ...nState}) 
                );
            }  
            if (nState.selectedProjects?.length) {
                projectPromise = this.getProjectRelatedData(nState); 
                
                projectRelatedStatePromise = projectPromise.then( 
                    res => this.projectRelatedStateUpdate({...res, ...nState}) 
                );
                if (!this.state.selectedBillingAddress?.id && !addressPromise) {
                    nState = this.addressRelatedStateUpdate({
                        ...nState,
                        selectedBillingAddress: this.billingAddress(nState)
                    });
                }
            }
            else
                /* no projects selected, reset all project related data */
                resetData = _.cloneDeep(this.initialProjectState);

            nState = this.buildState( 
                ...await Promise.all([ projectPromise, projectRelatedStatePromise, accountPromise, addressPromise, addressStatePromise ]),
                nState, 
                resetData 
            );
            this.setState( nState, () => {
                const updatedRows = this.addProjectDimensionToRows(this.state.rows);
                this.updateRowDimensions(updatedRows);
            });
        });
    }

    /**
     * Gets default dimensions for multidimension headers. For headers that are shown in slider.
     * Gets info if rows order number dimension headers differ from settings order number headers.
     * @returns Array of header and dimension ids.
     */
    getDefaultMultiDimensions = (rows) => {
        const props = {
            isNewInvoice: this.isNewInvoice(),
            accountingData: this.state,
            invoiceRows: _.cloneDeep(rows),
            type: "sales_invoices"
        }
        const dimensionHeaderData = getMultiDimensionHeaders(props);
        const { dimensionHeaders, dimensionHeadersDiffer } = dimensionHeaderData;
        const defaultDimensionValues = getDefaultDimensionValuesForHeaders(dimensionHeaders);

        this.setState({ dimensionHeadersDiffer, defaultDimensionValues, dimensionHeaders });

        return defaultDimensionValues;
    }

    onAccountingAccountChanged = () => {
        const itemRowCategories = [1,3,4]; // Itemrow, Productrow, CPQrow

        this.defaultRow.sales_account = this.state.default_accounting_accounts_id;
        this.defaultRow.dimension_item = this.state.default_accounting_dimensions_id;
        this.defaultRow.accounting_product = this.state.default_accounting_products_id;
        
        const rows = _.cloneDeep(this.state.rows);
        const defaultMultiDimensions = this.getDefaultMultiDimensions(rows);
        this.defaultRow.dimension_values = defaultMultiDimensions;

        rows && rows.length > 0 && rows.forEach(e => {
            e.sales_account = e.sales_account > 0 ? e.sales_account : this.state.default_accounting_accounts_id;
            e.dimension_item = e.dimension_item > 0 ? e.dimension_item : this.state.default_accounting_dimensions_id;
            e.accounting_product = e.accounting_product ? e.accounting_product : this.state.default_accounting_products_id;

            const updateDimension = itemRowCategories.find(c => c == e.row_category);
            if (updateDimension) {
                e.dimension_values = e.dimension_values || defaultMultiDimensions;
            }
        })
        this.setState({ rows });
    }

    checkDimensionHeaders = (rows) => {        
        const itemRowCategories = [1,3,4]; // Itemrow, Productrow, CPQrow

        const defaultMultiDimensions = this.getDefaultMultiDimensions(rows);
        this.defaultRow.dimension_values = defaultMultiDimensions;
        if (this.isNewInvoice()) {
            rows && rows.length > 0 && rows.forEach(e => {
                const updateDimension = itemRowCategories.find(c => c == e.row_category);
                if (updateDimension) {
                    e.dimension_values = defaultMultiDimensions;
                }
            })
        }
        return rows;
    }

    getCompanyAddress = async () => {
        const { company } = this.state;
        const companyAddressData = await DataHandler.get({ url: 'invoices/company_address', company });
        return companyAddressData || {};
    }

    getVatCodes = async () => {
        const { company } = this.state;
        const activeVatcodeAddons = this.getActiveVatcodeAddons();
        if (activeVatcodeAddons.length < 1) return {};

        const vatcodes = await DataHandler.get({ url: `subjects/vat_codes/${company}`});
        const activeVatcodes = vatcodes?.filter(v => activeVatcodeAddons.includes(v.integration)).map(vat => {
            if (vat.use_reverse_vat == 1) {
                vat.subText = "(" + this.tr("reverse_vat_vatcode") + ")";
            }
            return vat;
        });
        const allowedCountryCodes = this.getAllowedCountryCodes(activeVatcodes);
        const salesVatCodes = _.cloneDeep(activeVatcodes).map(av => {
            av.topText = av.value + " %";
            av.subText = av.name;
            return av;
        })
        return {activeVatcodes, allowedCountryCodes, salesVatCodes};
    }

    getAllowedCountryCodes = (vatcodes) => {
        const allowedCountryCodes = [];
        const addedCodes = {};
        if (this.isCompanyUsingCountryCode()) {
            vatcodes.forEach(v => {
                if (v.country_code && !addedCodes[v.country_code]) {
                    allowedCountryCodes.push({label: v.country_code, name: v.country_code, id: v.country_code, value: v.country_code});
                    addedCodes[v.country_code] = 1;
                }
            });
        }
        return allowedCountryCodes;
    }

    getCPQParents = async () => {
        const { company } = this.state;
        const CPQParents = await DataHandler.get({ url: `cpq/parents/${company}`})
        return CPQParents || [];
    }

    getInvoiceSettings = async () => {
        const { company } = this.state;
        const response = await DataHandler.get({ url: `settings/invoicing/${company}`})
        return response || {};
    }

    getProductRowSettings = async () => {
        const { company } = this.state;
        const response = await DataHandler.get({ url: `settings/company/${company}/product/invoicerow`})
        return response?.invoiceRowSettingsData || {};
    }

    getAccounts = async () => {
        const { company } = this.state;
        const accounts = await DataHandler.get({ url: `invoices/accounts/${company}`});
        return { accounts };
    }

    getInvoiceableAccountsAndProjects = async () => {
        const { company, invoiceType, dialogData } = this.state;
        const data = await DataHandler.get({ url: `invoices/invoiceable_projects/${company}`, invoiceType});
        return data;
    }

    getCompanyRelatedData = async () => {
        let data = {}, currencyPromise;
        const isMaterialInvoice = this.isMaterialInvoice();
        const requests = [currencyPromise = this.getCurrencyRates(), this.getCompanyAddress(), this.getVatCodes(), this.getCPQParents(), this.getProductRowSettings(), this.getAccountingData(), this.getDefaultSendingType(), this.getCompanyBankAccounts(), this.getFieldlimitations()];
        requests.push(
            currencyPromise.then( () => this.getActiveCurrencies() )
        );
        requests.push(this.getInvoiceSettings())
        requests.push(this.getIntegrationSettings())

        if (isMaterialInvoice) {
            requests.push(this.getInvoiceableAccountsAndProjects());
        } else {
            requests.push(this.getAccounts());
            
            if (this.props.customers_id)
                requests.push(
                    this.getProjects({ account: {id: this.props.customers_id} })
                );

        }

        const responses = await Promise.all(requests);
        const currencyRates = responses[0];
        const companyAddressData = responses[1];
        const vatCodes = responses[2];
        const CPQParents = responses[3];
        const productRowSettings = responses[4];
        const accountingData = responses[5];
        const sendingTypeData = responses[6];
        const bankAccounts = responses[7];
        const fieldLimitations = responses[8];
        const activeCurrencies = responses[9];
        const invoiceSettings = responses[10];
        const integrationSettings = responses[11];

        let accountData;
        let invoiceableProjectsData;
        let projectData;
        if (isMaterialInvoice) {
            invoiceableProjectsData = responses[12];
        } else {
            accountData = responses[12];
            projectData = responses[13];
        }

        const materialPeriodSettings = await this.getMaterialPeriodIndicatorSettings();
        
        data = this.preselectSetter({
            ...vatCodes,
            ...CPQParents,
            ...accountingData,
            ...sendingTypeData,
            ...accountData,
            ...invoiceableProjectsData,
            ...projectData,
            ...bankAccounts,
            ...fieldLimitations,
            invoiceDetails: {
                ...currencyRates,
                ...invoiceableProjectsData?.invoiceDetails,
                ...materialPeriodSettings[this.state.company]
            },
            dialogData: {
                ...invoiceableProjectsData?.dialogData
            },
            companyAddressData,
            productRowSettings,
            activeCurrencies: activeCurrencies,
            invoiceSettings,
            integrationSettings,
        })
        return data;
    }

    getTermsOfPayment = async ({company = this.state.company, account = this.state.account, selectedProjects = this.state.selectedProjects} = {}) => {
        const billingCustomersId = this.getBillingCustomersId({company, account, selectedProjects});
        const terms = await DataHandler.get({url: `invoices/terms_of_payment/${billingCustomersId}/${company}`})
        terms.duedate = this.getFormattedDueDate({invoiceDetails: {...this.state.invoiceDetails, ...terms}});
        // this is now determined when row is added using this.getActiveVatPercent()
        //this.defaultRow.vat = terms.defaultvat;
        let rows = this.state.rows;
        if (!this.isCompanyUsingVatCodes() && (this.state.invoiceDetails.reverse_vat > 0 || this.props.quote > 0)) {
            rows = this.invoiceRowVat(terms.defaultvat);
        }

        return { rows, terms };
    }

    getProducts = async (invoiceData = this.state) => {
        const { company } = invoiceData;
        const billingCustomersId = this.getBillingCustomersId(invoiceData);
        const response = await DataHandler.get({ url: `products/array/${company}`, include_deleted: 1, account: billingCustomersId });
        const products = response?.products || [];
        return products;
    }

    getBillingAddresses = async (invoiceData = this.state) => {
        const { company } = invoiceData;
        const billingCustomersId = this.getBillingCustomersId(invoiceData);
        const billingAddresses = await DataHandler.get({url: `invoices/billing_addresses/${company}`, customers_id: billingCustomersId });
        return { billingAddresses };
    }

    getProjects = async ({company = this.state.company, account = this.state.account} = {}) => {
        const projects = await DataHandler.get({ url: `invoices/projects/${account.id}/${company}`});
        return { projects };
    }

    getAccountRelatedData = async ({company = this.state.company, account = this.state.account, selectedProjects = this.state.selectedProjects} = {}) => {
        const invoiceData = {company, account, selectedProjects};
        const requests = [this.getTermsOfPayment(invoiceData), this.getProducts(invoiceData), this.getBillingAddresses(invoiceData), this.getProjects({account: account})];
        const responses = await Promise.all(requests)
        const { terms, rows } = responses[0];
        const products = responses[1];
        const billingAddressData = responses[2];
        const projects = responses[3];
        let data = {};
        if (!this.isMaterialInvoice()) {
            data = {
                rows,
                products,
                ...projects,
                ...billingAddressData,
                invoiceDetails: {
                    ...terms,
                    ...billingAddressData?.invoiceDetails,
                }
            }
        } else {
            data = {
                rows,
                products,
                ...billingAddressData,
                invoiceDetails: {
                    ...terms,
                    ...billingAddressData?.invoiceDetails,
                }
            }
        } 
        
        return data;
    }
    getActiveCurrencies = async () => {
        return this.currencies?.filter(c => c.status === 'active') || [];
    }
    getCurrencyRates = async () => {
        const { company, data, invoiceDetails } = this.state;
        let rate = {};
        const currencies = await DataHandler.get({ url: `invoices/currency_rates`, company: company });
        this.currencies = CurrencyUtils.mapCurrenciesForAutocomplete(currencies);

        if (!this.isNewInvoice() && data.companies_id == company.id && invoiceDetails.currency_rates_id) { 
            const lastRate = CurrencyUtils.returnSpecificActiveCurrencyDetails(this.currencies, {field: 'rateId', searchValue: invoiceDetails.currency_rates_id});
            if (data.state < 2 && invoiceDetails.currency_rates_id > 0 && invoiceDetails.currency_rate !== lastRate?.currency_rate) {
                this.props.enqueueSnackbar(this.tr("Selected currency rate on invoice does not match to current active rate. Edit to apply updated rate."), { variant: "warning" });
            }                    
        } else if (this.isNewInvoice()) {
            rate = {...CurrencyUtils.returnFirstActiveCurrencyDetails(this.currencies, {defaultValue: this.defaultCurrencyDetails}), already_invoiced_total_row: company.already_invoiced_total_row};
        }

        return rate;
    }
    componentWillUnmount() {
        super.componentWillUnmount();

        this.props.updateView({template: undefined});
        document.body.removeEventListener("mousedown", this.ctrlEdit);
        document.body.removeEventListener("keydown", this.ctrlSave);
    }

    getDefaultRows = () => {
        const itemRow = {...this.defaultRow, total_marker: 1, id: -2, roworder: 2, manual_row: 0};
        itemRow.vat = this.getActiveVatPercent();
        if (this.isCompanyUsingRowSpecificVatcodes()) {
            const vatcode = this.getNewRowVatcode();
            itemRow.sales_vatcode = vatcode?.id;
            itemRow.vat = vatcode?.vatpercent;
        }

        const headerRow = {...this.defaultHeaderRow, id: -1, roworder: 1, manual_row: 0};
        return [headerRow, itemRow];
    };

    setKeyboardListeners = () => {
        if (this.state.editMode) {
            document.body.addEventListener("keydown", this.ctrlSave);
            document.body.removeEventListener("mousedown", this.ctrlEdit);
            
        } else {
            document.body.addEventListener("mousedown", this.ctrlEdit);
            document.body.removeEventListener("keydown", this.ctrlSave);
        }
    }

    getAccountingData = async () => {
        const { company } = this.state;
        const accountingData = await DataHandler.get({ url: `invoices/accounting/${company}`});
        return accountingData || {};
    }

    getIntegrationSettings = async () => {
        const { addons } = this.context;
        const { company } = this.state;
        let integrationSettings = {};

        if (addons['procountor'] && addons['procountor'].used_by_companies.indexOf(company) > -1) {
            const settings = [
                'add_project_dimension_automatically',
                'use_parent_project_dimension_for_subprojects',
                'project_dimension',
                'project_parent_data'
            ];
            integrationSettings = await DataHandler.post({ url: `/integrations/get_settings`}, { settings, company, integration: "procountor" });
        }
       
        return integrationSettings || {};
    }

    getDefaultSendingType = async () => {
        let data = {};
        if(this.state.data && this.isNewInvoice() && this.context.addons && (this.context.addons.talenom || this.context.addons.ropocapital || this.context.addons.fennoa)) {
            const { company } = this.state;
            const response = await DataHandler.get({ url: `settings/company/${company}/invoicedeliverytype` });
            if(response.delivery_type == '0') {
                data = { delivery_type: this.delivery_types[0] };
            } else {
                data = { delivery_type: this.delivery_types.find(e => e.value == response.delivery_type) };
            }
        }
        return data;
    };

    getInvoice = (id = this.props.id, getInvoiceData = false) => {
        this.setState({ editEnabled: false }, async () => {
            const { company } = this.state; 
            this.gettingInvoice = true;

            const resp = await DataHandler.get({url: `invoices/${id}/invoice`, company });

            if (!resp.data?.id)
                return this.backToList();

            this.nextInvoiceId = resp.next;
            this.previousInvoiceId = resp.previous;

            let rows = this.checkDimensionHeaders(resp.rows);
            rows = this.updateValuesForRows(rows, resp.invoice_details?.currency_rate, !!Number(resp.data?.has_old_currency_convert));

            const savedSenderAddress = {};
            if (resp.data.company_address?.length > 0 ||
                resp.data.company_city?.length > 0 ||
                resp.data.company_country?.length > 0 ||
                resp.data.company_email?.length > 0 ||
                resp.data.company_name?.length > 0 ||
                resp.data.company_postalcode?.length > 0 ||
                resp.data.company_state?.length > 0 ||
                resp.data.company_telephone?.length > 0 ||
                resp.data.company_vatid?.length > 0 ||
                resp.data.company_www?.length > 0 ) {
                    savedSenderAddress.address = resp.data.company_address;
                    savedSenderAddress.city = resp.data.company_city;
                    savedSenderAddress.country = resp.data.company_country;
                    savedSenderAddress.email = resp.data.company_email;
                    savedSenderAddress.name = resp.data.company_name;
                    savedSenderAddress.postalcode = resp.data.company_postalcode;
                    savedSenderAddress.state = resp.data.company_state;
                    savedSenderAddress.telephone = resp.data.company_telephone;
                    savedSenderAddress.vatid = resp.data.company_vatid;
                    savedSenderAddress.www = resp.data.company_www;
                }

            let data = {
                invoiceType: resp.invoice_type,
                data: resp.data, 
                bankaccount: resp.bankaccount,
                account: resp.account, 
                invoiceData: {},
                projectData: resp.project_data,
                startDate: resp.billing_start,
                endDate: resp.billing_end,
                agreement_identifier:resp.invoice_details.agreement_identifier,
                order_identifier:resp.invoice_details.order_identifier,
                invoiceDetails: resp.invoice_details,
                selectedBillingAddress: resp.billing_address,
                selectedShippingAddress: resp.shipping_address,
                useRecipient: resp.use_recipient > 0 ? true : false,
                notes: resp.notes,
                company: resp.data.companies_id,
                selectedProjects: resp.projects,
                selectedVatCode: resp.vat_code || {},
                rows: this.setTotalMarkers(rows),
                useOwnHours: resp.manual_ownwork,
                descriptionRows: resp.description_rows,
                selectedProjectsOwnWork: resp.selectedProjectsOwnWork,
                delivery_type:  resp.data.delivery_type == "0" ? 
                                this.delivery_types[resp.data.delivery_type] :
                                this.delivery_types.find(e => e.value == resp.data.delivery_type),
                materialCache: {},
                countryCode: resp.data.country_code,
                materialGroupingOptions: this.projectGroupingOptions(resp.projects[0]),
                savedSenderAddress: savedSenderAddress,
            };
            data.noteData = this.getNoteToInvoicer(data);

            if (this.isCompanyUsingVatCodes() && !resp.vat_code) {
                data.selectedVatCode = { ...this.definedVatCodes.empty, vatpercent: this.getActiveVatPercent() };
            }  
            if (resp.invoice_details.wintime_vatcode == -2) {
                data.selectedVatCode = this.definedVatCodes.rowSpecific;
            }  
            if (this.useVatcodeReverseVat && data.selectedVatCode?.use_reverse_vat == 1) {
                data.selectedVatCode.subText = "(" + this.tr("reverse_vat_vatcode") + ")";
            }

            const responses = await Promise.all([this.getAccountRelatedData(data), this.getProjectRelatedData(data)]);
            const accountData = responses[0];
            const projectData = responses[1];

            data = {
                ...accountData,
                ...projectData,
                ...data,
                invoiceDetails: {
                    ...accountData?.invoiceDetails,
                    ...projectData?.invoiceDetails,
                    ...data.invoiceDetails,
                }
            }

            if (resp.invoice_details.reverse_vat == 1) {
                const { invoiceDetails, rows } = this.getReverseVat(resp.invoice_details.reverse_vat, data.rows);
                data = {
                    ...data,
                    invoiceDetails: {
                        ...data.invoiceDetails,
                        ...invoiceDetails
                    },
                    rows
                }       
            }

            this.setState({
                ...data,
                has_multi_address_relation: resp.has_multi_address_relation,
                editEnabled: true,
                invoiceDetails: {
                    ...this.state.invoiceDetails,
                    ...data.invoiceDetails
                }
            }, () => {
                this.gettingInvoice = false;
                this.attachments.current?.updateComponentData();
                if (getInvoiceData && this.isMaterialInvoice() && this.isMaterialRefreshAllowed()) {
                    this.getInvoiceData();
                }
            });
        });
    };

    updateInvoiceView = (data) => {
        this.context.functions.updateView({ id: data.id, billid: data.bill_id });
        this.attachments.current && this.attachments.current.updateComponentData();
    }

    handleInvoiceData = (invoicedata) => {
        if (this.state.invoiceType === '1')
            return;
        const { data, materialCache } = this.state;
        const { rows = this.state.rows, 
                descriptionRows = this.state.descriptionRows } = invoicedata.row_info || {};

        let dialogSelections = {};
        if (this.invoiceMaterial.current) {
            dialogSelections = this.invoiceMaterial.current.getMaterialSelections();
        }
        invoicedata.invoice_data_rows.forEach(e => {
            if (e.row_type == 13)
                return

            if (dialogSelections[ e.id ] !== undefined) {
                e.selected = dialogSelections[ e.id ];
                return;
            }
            
            if (!materialCache[e.id])
                return
            
            e.selected = materialCache[e.id].selected;
            e.selected_price = materialCache[e.id].selected_price;
        });

        this.checkRowVatcodes(rows);

        let updatedRows = this.updateValuesForRows(rows);
        updatedRows = this.updateMaterialDataForRows(rows, invoicedata.invoice_data_rows);

        this.setState({
            invoiceData: invoicedata,
            invoiceDataLoading: false,
            dialogData: {...this.state.dialogData, invoiceData: invoicedata},
            rows: this.setTotalMarkers(updatedRows),
            descriptionRows
        }, () => {
            const newRows = this.isNewInvoice() 
                ? this.addProjectDimensionToRows(this.state.rows)
                : this.state.rows;
            this.updateRowDimensions(newRows);
        });
    };

    mountNewInvoice = () => {
        const company = this.getSelectedCompany();
        const { addons, functions: { checkPrivilegeAny } } = this.context;

        if (!checkPrivilegeAny("invoices", ["write_simple", "write_full"], this.state.company)) {
            this.setState({ noPermission: true });
            return;
        }

        if (addons.invoicing && addons.invoicing.limit && addons.invoicing.used >= addons.invoicing.limit) {
            this.props.toggleBuyDialog("invoicing");
            return;
        } 

        const onlyMaterial = company.invoice_only_material == "1";
        
        if (this.props.preselect && this.props.preselect !== "account")
            this.onInvoiceTypeSelected(
                this.invoiceTypeFromProps()
            );
        else if (onlyMaterial)
            this.onInvoiceTypeSelected(2);
        else
            this.showInvoiceTypeDialog();
    };

    addNew = () => {
        this.setState({invoiceType: 0}, () => {
            this.context.functions.updateView({ id: undefined, billid: undefined });
            this.onCompanyChanged(true);
        });
    }

    //edit displayed invoice
    edit = () => {
        this.checkSenderAddressChanges();
        this.setState({editMode: true});
        this.container.current.click();
    };    

    checkSenderAddressChanges = () => {
        const { companyAddressData, savedSenderAddress } = this.state;
        const { enqueueSnackbar } = this.props;

        if(this.state.data.state == 1 && Object.keys(savedSenderAddress).length > 0) {
            let isChanged = false;
            
            for (const [key, value] of Object.entries(savedSenderAddress)) {
                if (companyAddressData[key] !== value) {
                    isChanged = true;
                    break;
                }
            }

            if (isChanged === true) {
                enqueueSnackbar(this.tr('Sender address is updated!'), {
                    variant: "warning",
                    preventDuplicate: true
                });
            }
        }
    };

    //reload invoice as it was
    cancel = (evt) => {
        if (this.isNewInvoice()) {
            this.backToList(evt);
        } else {
            this.setState({ editMode: false, currencyChanged: false }, () => {
                this.getInvoice(this.props.id, true);
            });
        }
    };

    save = (draft, accountingSave = false, originalRows = false) => {
        if (accountingSave && !this.validateVatCode()) {
            if (originalRows) {
                this.setState({ rows: originalRows });
            }
            return;
        }
        if (!accountingSave && !this.validateFields(draft)) {
            return;
        }
        const fixedRate = this.currencies.find(c => c.rateId == this.state.invoiceDetails.currency_rates_id)?.rate;
        if (!accountingSave && this.state.invoiceDetails.currency_rates_id && this.state.invoiceDetails.currency_rates_id > 0 && Number(this.state.invoiceDetails.currency_rate) !== Number(fixedRate)) {
            this.setState({
                currentDialog: 'rateChanceForInvoiceDialog',
                dialogData: {
                    onSave: () => {
                        this.deleteAlreadyInvoicedRow(this.deleteMaterialPeriodRow);
                        this.saveInvoice(draft);
                    },
                    onSaveAndEdit: () => {
                        this.setState({invoiceDetails: {...this.state.invoiceDetails, currency_rate: fixedRate}}, () => {
                            this.deleteAlreadyInvoicedRow(this.deleteMaterialPeriodRow);
                            this.saveInvoice(draft);
                        });
                    },
                    text: this.tr("The currency has been updated since last save. Do you want to update the invoice with the new rates?`Note! this might change the total sum of the invoice.")
                }
            });
        } else {
            this.deleteAlreadyInvoicedRow(this.deleteMaterialPeriodRow);
            this.saveInvoice(draft, accountingSave, originalRows);
        }
    }

    validateVatCode = () => {
        if (!this.isCompanyUsingRowSpecificVatcodes()) {
            return true;
        }

        let { selectedVatCode, rows, salesVatCodes } = this.state;        
        const { enqueueSnackbar } = this.props;
        const commonErrorPart = this.tr("Invoice could not be saved") + ": ";

        if (selectedVatCode?.id == this.definedVatCodes?.rowSpecific?.id) {
            rows = _.cloneDeep(rows);
            let rowVatError = false;
            rows = rows.map(row => {
                const isDescriptionRow = row['row_category'] == 2 || row['row_category'] == 5 || row['row_type'] < 0;
                if (!isDescriptionRow && (!Number(row.sales_vatcode) || !salesVatCodes.find(va => va.id == row.sales_vatcode))) {
                    rowVatError = true;
                    row['sales_vatcode_invalid'] = true;    
                }
                return row;
            });
            if (rowVatError) {
                enqueueSnackbar(commonErrorPart + this.tr('VAT code missing from rows'), {
                    variant: "error",
                    preventDuplicate: true
                });
                this.setState({rows});
                return false;
            }
        }

        return true;
    }

    validateDeliveryMethod = () => {
        const { selectedBillingAddress, delivery_type } = this.state;
        const { enqueueSnackbar } = this.props;
        const errorDraftMessage = this.getErrorDraftMessage();
        let error_field = false;
        let msg = "";

        if (this.isCompanyUsingTalenom()) {
            if (delivery_type?.value == 1 && !selectedBillingAddress?.e_address) {
                error_field = "e_address";
                msg = this.tr('E-invoice address is required for E-invoice delivery method');
            }
            else if (delivery_type?.value == 4 && !selectedBillingAddress?.email) {
                error_field = "email";
                msg = this.tr('Email address is required for Email delivery method');
            }
        } 

        if (error_field) { 
            enqueueSnackbar(msg  + ". " + errorDraftMessage, {
                variant: "error",
                preventDublicate: true
            });
            const addressFieldErrors = _.cloneDeep(this.state.addressFieldErrors);
            addressFieldErrors.push(error_field);
            this.setState({ addressFieldErrors });
            return false;   
        }

        return true;
    }

    getErrorDraftMessage = () => {
        const { data } = this.state;        
        const errorDraftMessage = data.bill_type == 2 || this.isNewInvoice() ? this.tr("Only saving as pre invoice allowed.") : "";
        return errorDraftMessage;
    }

    validateFields = (draft) => {
        const { data, account, company, selectedBillingAddress, notes, selectedProjects, bankaccount, countryCode } = this.state;        
        const { enqueueSnackbar } = this.props;
        const errorDraftMessage = this.getErrorDraftMessage();       
        
        const invoicingAddresses = this.getInvoicingAddresses();
        const validateAddressData = invoicingAddresses?.length < 1;
        const billingAddresses = invoicingAddresses?.length > 0 ? invoicingAddresses : [selectedBillingAddress];

        if (validateAddressData) {
            if (!account || !account.id) {
                enqueueSnackbar(this.tr('Please select an account'), {
                    variant: "warning",
                    preventDublicate: true
                });
                return false;     
            }
            if (!selectedBillingAddress || !selectedBillingAddress.id) {
                enqueueSnackbar(this.tr('Please select a billing address'), {
                    variant: "warning",
                    preventDublicate: true
                });
                return false;     
            }
        }

        if(!this.companyEmailValid || (validateAddressData && !this.state.customerEmailValid)) {
            this.props.enqueueSnackbar(this.tr("Please enter a valid email."), { variant: "warning" });
            return false;
        }

        if (this.isNewInvoice() && (!bankaccount || bankaccount.id < 1)) {
            this.footer.current.scrollAnchor.current.scrollIntoView({behavior: 'smooth'});
            enqueueSnackbar(this.tr('You must have at least one bank account to proceed'), {
                variant: "warning",
                preventDublicate: true
            });
            return false;     
        }
        if (this.isMaterialInvoice() && selectedProjects.length < 1) {
            enqueueSnackbar(this.tr('Please select at least one project'), {
                variant: "warning",
                preventDuplicate: true
            });
            return false;     
        }
        if (!this.validateVatCode()) {
            return;
        }
        // Checked in backend for all addresses. So no need to do here if multiple addresses.
        if (!draft && validateAddressData && !this.validateDeliveryMethod()) {            
            return false;
        }
        if (!draft && !this.validateRowDescriptions()) {
            enqueueSnackbar(this.tr("Row descriptions cannot be empty") + ". " + errorDraftMessage, { variant: "error" });
            return false;
        }
      
        let dontSave = false;
        const descriptionsOverLimit = this.checkRowDescriptionLimits();
        const addressFieldErrors    = this.checkAddressFieldErrors(billingAddresses);
        const requiredErrors        = this.checkRequiredFieldErrors();

        if (descriptionsOverLimit?.descriptions) {
            enqueueSnackbar(this.tr("Row descriptions are too long. Limit: ${limit}.", {limit: descriptionsOverLimit?.descriptions}), { variant: "error" });
            dontSave = true;
        }
        if (descriptionsOverLimit?.descriptionRows) {
            enqueueSnackbar(this.tr("Description rows are too long. Limit: ${limit}.", {limit: descriptionsOverLimit?.descriptionRows}), { variant: "error" });
            dontSave = true;
        }
        if (addressFieldErrors?.overLimitErrors?.length > 0 && !draft) {
            enqueueSnackbar(this.tr("Customer address fields values are too long") + ". " + errorDraftMessage, { variant: "error" });
            dontSave = true;
        }
        if ((addressFieldErrors?.requiredErrors?.length > 0 || requiredErrors.length > 0) && !draft) {
            enqueueSnackbar(this.tr("Required fields not filled") + ". " + errorDraftMessage, { variant: "error" });
            dontSave = true;
        }
        const referenceLimit = this.checkReferenceLimit()
        if (referenceLimit) {
            this.props.enqueueSnackbar(this.tr("Reference fields can not contain more than ${limit} characters.", {limit: referenceLimit}), { variant: "warning" });
            dontSave = true;
        }        
        if (dontSave) {
            this.setState({ addressFieldErrors: addressFieldErrors.overLimitErrors.concat(addressFieldErrors.requiredErrors), fieldErrors: requiredErrors });
            return false;
        }

        if (this.isCompanyUsingNav() && !draft && validateAddressData) {     
            billingAddresses.forEach(b => {
                const check = this.validateAddressVatNumber(b.vatid, countryCode);
                if (check?.error) {
                    this.setState({ addressFieldErrors: ["vatid"] });
                    let message = check?.format ? this.tr('Business ID is not in correct format (e.g. ${exampleFormat})', {exampleFormat: check?.format}) : this.tr("Business ID is not in correct format (begin with correct countrycode and have only numbers or letters)");
                    message += ". " + errorDraftMessage;
    
                    enqueueSnackbar(message, {
                        variant: "error",
                        preventDuplicate: true
                    });
                    return false;
                }
            })       
        }
        this.setState({ addressFieldErrors: [], fieldErrors: [], selectedBillingAddress: selectedBillingAddress });

        const customerNameOverLimit = this.checkCustomerNameLimit(billingAddresses);
        // Show warning if customer name is too long or fields have values that does not go correctly to integration
        if (customerNameOverLimit) {
            this.props.enqueueSnackbar(this.tr("Customer name length is over integration limit (will be splitted when sent). Limit: ${limit}.", {limit: customerNameOverLimit}), { variant: "warning" });
        }
        if (this.state.restrictedChars?.notes && checkStringRestrictedChars(notes, this.state.restrictedChars?.notes)) {
            this.props.enqueueSnackbar(this.tr("Notes contains characters ${characters} which will not show correcly in accounting integration.", {characters: this.state.restrictedChars?.notes}), { variant: "warning" });
        }
        return true;
    }

    validateAddressVatNumber = (vatid, countryCode) => {
        vatid = formatVatNumber(vatid);
        return validateVatNumber(vatid, countryCode);
    }

    //save new invoice normally
    saveInvoice = async (draft, accountingSave = false, originalRows = false) =>  {
        const { data, rows, descriptionRows, account, company, newAccount, invoiceDefaultDetails, projectData, invoiceData,
             selectedBillingAddress, selectedShippingAddress, ownworkManual, selectedVatCode, agreement_identifier, order_identifier,
            selectedBillingZone, invoiceType, useRecipient, startDate, endDate, notes, delivery_type,
            selectedProjects, bankaccount, useOwnHours, saveInProgress, countryCode, companyAddressData, savedSenderAddress, currencyChanged } = this.state;
            

        let invoiceVatCode = _.cloneDeep(selectedVatCode);
        const invoiceDetails = {...this.state.invoiceDetails};
        const invoiceState = data.state || 1;
        /*if(this.context.addons.nav && selectedVatCode?.vatpercent == 0 && selectedVatCode?.id != -1) {
            invoiceDetails.reverse_vat = 1;
        }*/
        if(this.emptyVatcodeSelected()) {
            invoiceVatCode = {};
        }

        if (this.useVatcodeReverseVat()) {
            invoiceDetails.reverse_vat = invoiceVatCode?.use_reverse_vat || "0";
        }

        const billLanguage = this.getSelectedBillLanguage();
        invoiceDetails.bill_language = billLanguage;

        const invoicingAddresses = this.getInvoicingAddresses();
        const newRows = _.cloneDeep(rows);

        if (!saveInProgress) {
            this.setState({saveInProgress: true, draftSaveInProgress: draft, savedSenderAddress: invoiceState < 2 ? companyAddressData : savedSenderAddress}, async () => {
                projectData.project_ids = selectedProjects.map(e => e.id);

                if (!draft && !accountingSave && invoiceState < 2 && this.state.invoiceDetails.already_invoiced_total_row == 1 && projectData.project_ids.length > 0) {
                    const { taimerAccount } = this.context;
                    const transl = new InvoiceTranslations().returnTranslations([billLanguage]);
                    this.setState({saveInProgress: true});
                    const bill_id = data.bill_id ? data.bill_id : 0;
                    let alreadyInvoiced = await DataHandler.get({url: `projects/already_invoiced/ignore/${bill_id}`, project_ids: projectData.project_ids});
                    alreadyInvoiced = alreadyInvoiced * invoiceDetails.currency_rate;
                    const iRow = _.cloneDeep(this.defaultRow);
                    Object.assign(
                        iRow, {
                            value: null, 
                            vat: null, 
                            total_marker: 0, 
                            roworder: Number(newRows[newRows.length -1].roworder) + 1, 
                            row_category: 5, 
                            row_type: "-1", 
                            id: this.getNextTempIdForInvoiceRow(), 
                            manual_row: 1, 
                            dimension_values: [],
                            description: 
                                transl[invoiceDetails.bill_language]?.already_invoiced + 
                                ": " + Intl.NumberFormat(taimerAccount.numberFormat, {style: 'currency', currency: this.state.invoiceDetails.currency_code}).format(alreadyInvoiced) + 
                                " " + 
                                transl[invoiceDetails.bill_language]?.excluding_vat
                        }
                    );

                    newRows.push(iRow);
                }

                if(invoiceType == 2 && !accountingSave && invoiceState < 2 && this.state.invoiceDetails.show_material_period_on_invoice == 1 && projectData.project_ids.length > 0) {
                    const transl = new InvoiceTranslations().returnTranslations([billLanguage]);
                    const row    = _.cloneDeep(this.defaultRow);

                    const dateFormat = invoiceDetails && invoiceDetails?.date_format !== "" 
                        ? invoiceDetails.date_format
                        : this.state.companies.find(c => c.id == this.state.company)?.date_format;

                    const start = moment(new Date(startDate)).format(dateFormat.replace(/%/g, "").toUpperCase().replace("D", "DD").replace("M", "MM"));
                    const end   = moment(new Date(endDate)).format(dateFormat.replace(/%/g, "").toUpperCase().replace("D", "DD").replace("M", "MM"));
                    
                    Object.assign(
                        row, {
                            value: null, 
                            vat: null, 
                            total_marker: 0, 
                            roworder: Number(newRows[newRows.length -1].roworder) + 1, 
                            row_category: 5, 
                            row_type: "-2", 
                            id: this.getNextTempIdForInvoiceRow() - 1, 
                            manual_row: 1, 
                            dimension_values: [],
                            description: `${transl[invoiceDetails.bill_language]?.material_period} ${start} - ${end}`
                                
                        }
                    );

                    newRows.push(row);
                }

                await this._setState({ rows: newRows });

                let i = 0;
                const orderedRows = newRows.map(r => {
                    r.roworder = i;
                    i++;
                    return r;
                });
        
                const params = { ...data,
                    ...invoiceDefaultDetails, 
                    ...projectData, 
                    ...invoiceDetails,
                    ...invoiceData,
                    agreement_identifier,
                    order_identifier,
                    rows: {...orderedRows},
                    invoice_data: {...invoiceData.invoice_data_rows},
                    description_rows: {...descriptionRows},
                    company: company,
                    new_account: newAccount,
                    delivery_type: delivery_type && delivery_type.value > 0 ? delivery_type.value : 0,
                    billing_address: {...selectedBillingAddress},
                    use_recipient: useRecipient ? 1 : 0,
                    shipping_address: useRecipient ? {...selectedShippingAddress} : undefined,
                    invoice_type: invoiceType,
                    bill_type: draft ? 2 : 1,
                    billing_start: startDate,
                    billing_end: endDate,
                    billing_zone: selectedBillingZone.id,
                    vat_code: invoiceVatCode,
                    bank_account: bankaccount.id,
                    bank_account_2: false,
                    notes: notes,
                    manual_ownwork: useOwnHours,
                    customers_id: account.id,
                    selectedProjectsOwnWork: this.state.selectedProjectsOwnWork,
                    useOwnHours: this.state.useOwnHours,
                    selectedProjects,
                    countryCode,
                    companyAddressData,
                    has_old_currency_convert: this.hasOldCurrencyConvert(),
                    invoicingAddresses
                };

                const errorDraftMessage = data.bill_type == 2 || this.isNewInvoice() ? this.tr("Only saving as pre invoice allowed.") : "";
                const commonErrorPart = this.tr("Invoice could not be saved") + ": ";
                let hasError = false;

                DataHandler.post({url: 'invoices/invoice'}, params).done(response => {

                    if (response.error !== undefined) {
                        this.deleteAlreadyInvoicedRow(this.deleteMaterialPeriodRow);
                    }

                    if (response.error === 'billed_price') {
                        this.setState({saveInProgress: false});
                        this.props.enqueueSnackbar(this.tr('Unable to save invoice. Please check invoice material selling prices'), {
                            variant: "error",
                            preventDublicate: true
                        });
                        hasError = true;
                    }
                    else if (response.error === 'material_already_invoiced') {
                        this.setState({saveInProgress: false});
                        this.props.enqueueSnackbar(this.tr('Unable to save invoice. Some of the invoicing material has been invoiced in some other invoice'), {
                            variant: "error",
                            preventDublicate: true
                        });
                        hasError = true;
                    }
                    else if (response.error === 'material_belongs_to_different_project') {
                        this.setState({saveInProgress: false});
                        this.props.enqueueSnackbar(this.tr('Unable to save invoice. Some of the invoicing material does not belong to selected projects'), {
                            variant: "error",
                            preventDublicate: true
                        });
                        hasError = true;
                    }
                    else if (response.error === 'invalid_bankaccount') {
                        this.setState({saveInProgress: false});
                        this.props.enqueueSnackbar(commonErrorPart + this.tr('Selected bank account is not valid for this company.'), {
                            variant: "error",
                            preventDublicate: true
                        });
                        hasError = true;
                    }
                    else if (response.error === 'invalid_duedate') {
                        this.setState({saveInProgress: false});
                        this.props.enqueueSnackbar(commonErrorPart + this.tr('Selected duedate does not match the terms of payment or its before invoice date.'), {
                            variant: "error",
                            preventDublicate: true
                        });
                        hasError = true;
                    }
                    else if (response.error === 'required_fields_missing') {
                        this.setState({saveInProgress: false, fieldErrors: response.invalid_fields?.invalid_fields, addressFieldErrors: response.invalid_fields?.invalid_address_fields});
                        this.props.enqueueSnackbar(commonErrorPart + this.tr('Required fields not filled') + ". " + errorDraftMessage, {
                            variant: "error",
                            preventDublicate: true
                        });
                        hasError = true;
                    }
                    else if (response.error === 'project_customership_group_missing') {
                        this.setState({saveInProgress: false});
                        this.props.enqueueSnackbar(commonErrorPart + this.tr('Customership group missing from project(s)') + ". " + errorDraftMessage, {
                            variant: "error",
                            preventDublicate: true
                        });
                        hasError = true;
                    }
                    else if (response.error === 'billing_address_country_not_found') {
                        const addressFieldErrors = _.cloneDeep(this.state.addressFieldErrors);
                        addressFieldErrors.push("country");
                        this.setState({saveInProgress: false, addressFieldErrors});
                        this.props.enqueueSnackbar(commonErrorPart + this.tr('Billing address country is not valid') + ". " + errorDraftMessage, {
                            variant: "error",
                            preventDublicate: true
                        });
                        hasError = true;
                    }
                    else if (response.error === 'e_invoice_address_required_for_einvoice_delivery') {
                        const addressFieldErrors = _.cloneDeep(this.state.addressFieldErrors);
                        addressFieldErrors.push("e_address");
                        this.setState({saveInProgress: false, addressFieldErrors});
                        this.props.enqueueSnackbar(commonErrorPart + this.tr('E-invoice address is required for E-invoice delivery method') + ". " + errorDraftMessage, {
                            variant: "error",
                            preventDublicate: true
                        });
                        hasError = true;
                    }
                    else if (response.error === 'email_address_required_for_email_delivery') {
                        const addressFieldErrors = _.cloneDeep(this.state.addressFieldErrors);
                        addressFieldErrors.push("email");
                        this.setState({saveInProgress: false, addressFieldErrors});
                        this.props.enqueueSnackbar(commonErrorPart + this.tr('Email address is required for Email delivery method') + ". " + errorDraftMessage, {
                            variant: "error",
                            preventDublicate: true
                        });
                        hasError = true;
                    }
                    else if (response.error === 'row_descriptions_cannot_be_empty') {
                        const descriptionErrorRows = response.invalid_rows;
                        const invalidDescriptionRows = response.invalid_description_rows;

                        this.setState({saveInProgress: false, descriptionErrorRows, invalidDescriptionRows});
                        this.props.enqueueSnackbar(commonErrorPart + this.tr('Row descriptions cannot be empty') + ". " + errorDraftMessage, {
                            variant: "error",
                            preventDublicate: true
                        });
                        hasError = true;
                    }
                    else if (response.error !== undefined && response.error.length > 0) {
                        this.setState({saveInProgress: false});
                        this.props.enqueueSnackbar(commonErrorPart + response.error, {
                            variant: "error",
                            preventDublicate: true
                        });
                        hasError = true;
                    }

                    const invoiceTypes = {
                        '1': "Blank Invoice",
                        '2': "Material Invoice",
                        '3': "Refund Invoice",
                        '10': "Mass Invoicing"
                    };

                    const analyticsData = {
                        "invoices_created": response?.successAmount,
                        "invoice_type": invoiceTypes[this.state.invoiceType],
                        "taimer_version": this.context.versionId,
                        "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
                    }

                    if (hasError) {
                        if (accountingSave && originalRows) {
                            this.setState({ rows: originalRows, draftSaveInProgress: false });
                        }
                        if (response?.successAmount > 0) {
                            this.props.enqueueSnackbar(this.tr('${amount} invoices created.', {amount: response?.successAmount }), {
                                variant: "success",
                            });
                            this.props.updateView({ module: 'invoices', action: 'main', selectedTab: "invoices" })
                            this.context.functions.sendAnalytics("invoice_created", analyticsData);
                            return;
                        }
                        return;
                    }

                    else if (response.show_warning?.invalid_jobtypes) {
                        this.props.enqueueSnackbar(this.tr("Invoice material has following jobtypes without necessary accounting data: ${jobtypes}", {jobtypes: response.show_warning.invalid_jobtypes}), {
                            variant: "warning",
                            preventDublicate: true
                        });
                    }

                    if (accountingSave) {
                        this.props.enqueueSnackbar(this.tr("Invoice accounting saved"), {
                            variant: "success",
                            preventDublicate: true
                        });
                    }
            
                    const data = _.cloneDeep(this.state.data);
                    ["id",  "bill_id", "bill_type", "type", "state"].forEach(e => {
                        if (response[e])
                            data[e] = response[e];
                    });

                    let rows = _.cloneDeep(this.state.rows);
                    const idMap = {};
                    let invoiceTotal = 0;
                    let invoiceTotalVat0 = 0;
                    rows = rows.filter(e => {
                        if (e.deleted > 0)
                            return false;

                        if (response.rows && response.rows[e.id]) {
                            idMap[e.id] = response.rows[e.id];
                            e.id = response.rows[e.id];
                            invoiceTotal += e.total;
                            invoiceTotalVat0 += e.total_no_vat;
                        }

                        return e;
                    });

                    let descriptionRows = _.cloneDeep(this.state.descriptionRows);
                    descriptionRows = descriptionRows.filter(e => {
                        if (e.deleted > 0)
                            return false;

                        if (response.description_rows && response.description_rows[e.id]) {
                            e.id = response.description_rows[e.id];
                            if(idMap[e.billentries_id]) {
                                e.billentries_id = idMap[e.billentries_id];
                            }
                        }
                    
                        return e;
                    });
                    
                    const savedProjects = _.cloneDeep(selectedProjects);
                    savedProjects.map(e => { delete e.notSaved; return e });

                    if (invoicingAddresses?.length > 0) {
                        this.props.enqueueSnackbar(this.tr('${amount} invoices created.', {amount: response?.successAmount }), {
                            variant: "success",
                        });
                        this.props.updateView({ module: 'invoices', action: 'main', selectedTab: "invoices" })
                        this.context.functions.sendAnalytics("invoice_created", analyticsData);
                        return;
                    }
                    
                    this.setState({currentDialog: false, editMode: false, data, rows, descriptionRows, selectedProjects: savedProjects, invoiceDetails}, () => {
                        response.id && this.props.updateView({ module: 'invoices', action: 'view', id: response.id, editMode: false });
                        this.context.functions.sendAnalytics("invoice_created", analyticsData);
                    });
                }).then(() => {this.setState({saveInProgress: false, draftSaveInProgress: false});});
            } );
        }
    };

    checkRowDescriptionLimits = () => {
        const { rows, fieldLimits, descriptionRows } = this.state;
        const response = {
            descriptions: false,
            descriptionRows: false
        };
        const overLimitRows = [];
        const overLimitDescriptionRows = [];
        const checkRowCategories = [1,3,4]; // Itemrow, Productrow, CPQrow
        const checkHeaderCategories = [2,5]; // StyledHeaderRow

        if (fieldLimits?.rowItemDescription) {
            const limitValue = Number(fieldLimits.rowItemDescription);
            const checkRows = rows.filter(r => checkRowCategories.find(c => c == r.row_category));
            const checkHeaders = rows.filter(r => checkHeaderCategories.find(c => c == r.row_category));

            checkRows.forEach(row => {
                if (limitValue < row.description?.length) {
                    overLimitRows.push(row.id);
                    response.descriptions = limitValue;
                }
            });
            checkHeaders.forEach(header => {
                if (limitValue < header.description?.length) {
                    overLimitRows.push(header.id);
                    response.descriptions = limitValue;
                }
            });
        }

        if (fieldLimits?.descriptionRowDescription) {
            const limitValue = Number(fieldLimits.descriptionRowDescription);

            descriptionRows.forEach(description => {
                if (limitValue < description.description?.length) {
                    overLimitDescriptionRows.push(description.id);
                    response.descriptionRows = limitValue;
                }
            });
        }

        this.setState({ overLimitRows, overLimitDescriptionRows });
        return response;
    }

    validateRowDescriptions = () => {
        let response = true;

        if (!this.isRowDescriptionRequired()) {
            return response;
        }

        const { rows, descriptionRows } = this.state;
        const descriptionErrorRows = [];
        const invalidDescriptionRows = [];

        rows.forEach(row => {
            const descr = row.description?.toString();
            if (!(descr || "").trim() && descr !== "0") {
                descriptionErrorRows.push(row.id);
                response = false;
            }
        });
        descriptionRows.forEach(description => {
            const descr = description.description?.toString();
            if (!(descr || "").trim() && descr !== "0") {
                invalidDescriptionRows.push(description.id);
                response = false;
            }
        });
        
        this.setState({ descriptionErrorRows, invalidDescriptionRows });
        return response;
    }

    getRequiredAddressFields = () => {
        const fields = [];
        if (this.isCompanyUsingNav()) {
            fields.address = true;
            fields.postalcode = true;
            fields.vatid = true;
            fields.city = true;
        }
        return fields;
    }

    getRequiredFields = () => {
        const fields = {};
        if (this.isCompanyUsingNav()) {
            fields.vat_code = true;
            fields.countryCode = true;
        }
        return fields;
    }

    checkAddressFieldErrors = (billingAddresses) => {
        const { fieldLimits } = this.state;
        const requiredFields = this.getRequiredAddressFields();
        const overLimitErrors = [];
        const requiredErrors = [];
        const addedErrorFields = {};

        billingAddresses.forEach(b => {
            Object.keys(b).forEach(field => {
                if (addedErrorFields[field]) {
                    // Do nothing.
                }
                // If field value is over limit
                else if (fieldLimits[field] && b[field] && b[field].toString().length > fieldLimits[field]) {
                    overLimitErrors.push(field);
                    addedErrorFields[field] = true;
                }
                // If required field is not filled
                else if (requiredFields[field] && !b[field]) {
                    requiredErrors.push(field);
                    addedErrorFields[field] = true;
                }
            });
        })

        return {overLimitErrors, requiredErrors};
    }

    checkRequiredFieldErrors = () => {
        const { selectedVatCode, countryCode } = this.state;
        const requiredFields = this.getRequiredFields();
        const fieldErrors = [];
        const fieldValues = {
            vat_code: selectedVatCode?.id,
            countryCode: countryCode
        }
        Object.keys(requiredFields).forEach(field => {
            if (!fieldValues[field]) {
                fieldErrors.push(field);
            }
        });
        return fieldErrors;
    }

    checkCustomerNameLimit = (billingAddresses) => {
        const { fieldLimits } = this.state;

        const strippedAddressLabel = (address) => {
            return address?.label?.substring(0, address?.label?.indexOf(','));
        };

        (billingAddresses || []).forEach(b => {
            const accountName = b.id ? strippedAddressLabel(b) : "";
            const accountNameLength = accountName?.length || 0;
            
            if (fieldLimits?.customerName && accountNameLength > Number(fieldLimits.customerName)) {
                return fieldLimits.customerName;
            }
        });

        return false;
    }

    checkReferenceLimit = () => {
        const {invoiceDetails, company, fieldLimits} = this.state;

        let referenceError = false;

        ['reference', 'customerreference'].some(f => {
            if (invoiceDetails[f]?.length > fieldLimits?.[f])
                referenceError = fieldLimits?.[f];
            return f?.length > fieldLimits?.[f];
        })
        return referenceError;
    }
    
    //invoice material range select
    handleRangeSelect = async (selection) => {
        // DateRangePicker things.
        const { startDate, endDate } = selection.selection 
            ? selection.selection 
            : selection.range1;

        const start = format(startDate, 'YYYY-MM-DD');
        const end = format(endDate, 'YYYY-MM-DD');
        
        if (start === "Invalid Date" || end === "Invalid Date")
            return
        
        const dialogData = _.cloneDeep(this.state.dialogData);
        dialogData.startDate = start;
        dialogData.endDate = end;

        this.setState({startDate: start, endDate: end, dialogData});
        return;
    };

    handleRangeInputChange = (name, value) => {
        
        const val = format(value, 'YYYY-MM-DD');
        if (!isValid(val)) 
            return;

        const prop = name == 'start' ? 'startDate' : 'endDate';
        
        const dialogData = _.cloneDeep(this.state.dialogData);
        dialogData[prop] = val;
        
        this.setState({[prop]: val, dialogData});

    };

    handleMaterialGroupingChange = (name, id) => {

        this.setState(state => {
            state.materialGroupingOptions[name] = id;
            return state; 
        });

    };

//Project details
//if new account is created
    accountCreated = (account) => {      

        const { enqueueSnackbar } = this.props;
        enqueueSnackbar(this.tr('You have successfully created a new customer'), {
            variant: "success",
            preventDublicate: true
        });
        
        this.onAccountChanged(account);
    };

    projectGroupingOptions = (projectOptions) => {
        const options = _.cloneDeep(this.initialState.materialGroupingOptions);

        if (!projectOptions)
            return options;

        if (Number(projectOptions.invoicing_hour_task_grouping) > 0)
            options.projects = projectOptions.invoicing_hour_task_grouping;
        else if (Number(this.state.invoiceSettings?.invoicing_hour_task_grouping) > 0)
            options.projects = this.state.invoiceSettings.invoicing_hour_task_grouping;

        if (Number(projectOptions.invoicing_hour_grouping) > 0)
            options.workhours = projectOptions.invoicing_hour_grouping;
        else if (Number(this.state.invoiceSettings?.invoicing_hour_grouping) > 0)
            options.workhours = this.state.invoiceSettings.invoicing_hour_grouping;

        if (Number(projectOptions.invoicing_expense_grouping) > 0)
            options.worktrips = projectOptions.invoicing_expense_grouping;
        else if (Number(this.state.invoiceSettings?.invoicing_expense_grouping) > 0)
            options.worktrips = this.state.invoiceSettings.invoicing_expense_grouping;

        if (Number(projectOptions.invoicing_quote_grouping) > 0)
            options.costest = projectOptions.invoicing_quote_grouping;
        else if (Number(this.state.invoiceSettings?.invoicing_quote_grouping) > 0)
            options.costest = this.state.invoiceSettings.invoicing_quote_grouping;
    
        return options;
    }

    singleProjectAdded = (project) => {
        /* please be really careful when expanding this function, consider using onProjectsChanged() function instead */

        if (this.refProject()?.id === project.id)
            return;

        this.onProjectsChanged( [_.cloneDeep(project)] );
    };

    projectAdded = (project) => {
        /* please be really careful when expanding this function, consider using onProjectsChanged() function instead */

        const { selectedProjects } = this.state;
        if (selectedProjects.find(e => e.id === project.id))
            return;
            
        project = _.cloneDeep(project);
        project.notSaved = 1;       
        
        this.onProjectsChanged([...selectedProjects, project]);
    };
    projectRemoved = (id) => {
        /* please be really careful when expanding this function, consider using onProjectsChanged() function instead */

        const { selectedProjects } = this.state;
        const project = selectedProjects.find(p => p.id == id);

        if (!this.isNewInvoice() && !project.notSaved)
            return;
        
        this.onProjectsChanged(
            selectedProjects.filter(p => p.id != id)
        );
    };
 
    billingAddressSet = (address) => {
        if (address.__isNew__) {

            const { company, account, fieldLimits } = this.state;

            if (!account)
                return false;

            if (fieldLimits?.customerName && address.value.length > fieldLimits.customerName) {
                this.props.enqueueSnackbar(this.tr("Customer name is too long. limit: ${limit}", {limit: fieldLimits.customerName}), { variant: "error" });
                return;
            }

            const newAddress = {
                name: address.value,
                company: company,
                account: account.id,
                type: '1'
            };

            DataHandler.post({url: 'invoices/invoice_address'}, newAddress).done(resp => {
                this.addressCreated(resp);
            });

        } else {
            this.addressCreated(address);
        }

    };

    getSelectedBillLanguage = () => {
        const { billingLanguages, invoiceDetails, selectedBillingAddress, data } = this.state;
        let billLanguage = invoiceDetails.bill_language;
        if (!data.id) { // For new invoice: If bill language is not selected, select addresses language. If language is not found in company's languages, select company's default language.
            billLanguage = billLanguage ? billLanguage : selectedBillingAddress?.bill_language;
            billLanguage = billingLanguages.find(e => e.id == billLanguage) ? billLanguage : invoiceDetails.default_print_lang;
        }
        else if (!billLanguage) { // For saved invoices: If bill language is not saved, select company's default language.
            billLanguage = invoiceDetails.default_print_lang;
        }

        return billLanguage;
    }

    shippingAddressSet = (address) => {

        if (address.__isNew__) {

            const { company, account } = this.state;
            
            if (!account)
                return false;

            const newAddress = {
                name: address.value,
                company: company,
                account: account.id,
                type: '2'
            };

            DataHandler.post({url: 'invoices/invoice_address'}, newAddress).done(resp => {
                this.addressCreated(resp);
            });

        } else {
            this.addressCreated(address);
        }

    };

    changeBillingAddressValue = (evt) => {
        const { addressFieldErrors, fieldLimits } = this.state;
        const { name, value } = evt.target || evt;
    
        if (addressFieldErrors?.includes(name) && (!fieldLimits[name] || fieldLimits[name] >= value.toString().length)) { // Remove error from field if value is not over field limit.
            remove(addressFieldErrors, (key) => { return key == name });
        }

        this.setState({ addressFieldErrors, selectedBillingAddress:{ ...this.state.selectedBillingAddress, [name]: value} });
    }
    modifyBillingAddress = ({ name, value } = {}, caps = false) => {
        const { selectedBillingAddress, addressFieldErrors, fieldLimits } = this.state;

        if ( !this.billingAddressSpecialSetters(name, value) )
            return;
        if (caps)
            value = value.toUpperCase();

        if (addressFieldErrors?.includes(name) && (!fieldLimits[name] || fieldLimits[name] >= value.toString().length)) { // Remove error from field if value is not over field limit.
            remove(addressFieldErrors, (key) => { return key == name });
        }
        
        const update = {
            selectedBillingAddress: {...selectedBillingAddress, [name]: value}
        };
        this.setState( update );

        //DataHandler.post({url: `invoices/${selectedBillingAddress.id}/invoice_address`}, {column: name, value: value, type: '1'});
    };
    billingAddressSpecialSetters = (name, value) => {
        const { selectedBillingAddress } = this.state;

        if (!selectedBillingAddress?.id || value === undefined || value === null)
            return false;
        else if(name === "email") {
            const valid = this.isCompanyUsingMeritAktiva() ? validEmailMultiple(value, true) : validEmail(value, true)
            this.setState({
                customerEmailValid: valid
            });
            if (!valid) {
                this.props.enqueueSnackbar(this.tr("Please enter a valid email."), { variant: "warning" });
                return false;
            }        
        }
        else if (name == 'reverse_charge' && this.state.invoiceDetails.reverse_vat != value)
            this.handleReverseVatChange();
        else if (name === 'currency') 
            this.setInvoiceDetails( this.currencyDetails(value) );

        return selectedBillingAddress;
    }

    modifyBillingAddressContact = (evt) => {
        //update project invoice contact for projects which do not have invoice address warning
        const { name, value } = evt.target;
        let openDialog = false;
        const {selectedBillingAddress, selectedProjects} = this.state;

        if (!this.state.selectedBillingAddress)
            return;

        if (selectedProjects.length < 1) {
            this.modifyBillingAddress(evt);
            return;
        }

        const { id } = this.state.selectedBillingAddress;

        if (!id)
            evt.target.value = '';

        if (this.state.selectedBillingAddress[name] == value || value === undefined || value === null)
            return false;

        if (selectedProjects.length > 0) {
            openDialog = true;
        }

        this.setState({
            selectedBillingAddress:{...selectedBillingAddress, [name]: value},
            contactPersonSaveDialogOpen: openDialog
        });

    };

    saveBillingAddressContacts = () => {
        const {selectedBillingAddress, selectedProjects} = this.state;

        const projectIds = [];
        this.state.selectedProjects.forEach(project => projectIds.push(project.id));

        const updatedSelectedProjects = [];
        this.state.selectedProjects.forEach(project => updatedSelectedProjects.push({...project, invoice_contact_person: selectedBillingAddress.contact}));

        this.setState({
            selectedProjects: updatedSelectedProjects,
            contactPersonSaveDialogOpen: false
        }); 

        if (selectedProjects.length > 0) {
            DataHandler.post({url: `projects/invoice_address_contact`}, {projects: projectIds, value: selectedBillingAddress.contact, invoice_address_id: selectedProjects[0].customer_address_id}).done(response => {
            });
        }
    }

    modifyShippingAddress = (evt, capitalize, allcap) => {
        
        if (!this.state.selectedShippingAddress)
            return;

        const { id } = this.state.selectedShippingAddress;
        if (!id)
            evt.target.value = '';

        let { name, value } = evt.target;

        if (this.state.selectedShippingAddress[name] == value || !value)
            return false;

        value = capitalize ? (!allcap ? Utils.capitalize(value) : Utils.startCase(value)) : value;

        DataHandler.post({url: `invoices/${id}/invoice_address`}, {column: name, value: value, type: '2'}).done(response => {
            const { enqueueSnackbar } = this.props;
            this.setState({selectedShippingAddress: response});
            // enqueueSnackbar(this.tr('You have successfully modified the shipping address of the customer'), {
            //     variant: "success",
            //     preventDublicate: true
            // });
        });

    };    

    addressCreated = (address) => {

        const data = address.type > 1 ? 'shippingAddresses' : 'billingAddresses';
        const selected = address.type > 1 ? 'selectedShippingAddress' :'selectedBillingAddress';
        const update = address.type > 1 ? 'shipping_address_id' : 'billing_address_id';

        if (address.type == 1 && this.state.selectedProjects.length > 0) {
            if (this.state.selectedProjects[0].invoice_contact_person) {
                address.contact = this.state.selectedProjects[0].invoice_contact_person;
            }
        }

        this.setState({
            [data]: this.state[data].filter(a => a.id == address.id).length < 1 ? [...this.state[data], address] : this.state[data],
            [selected]: address,
            projectData: {
                ...this.state.projectData,
                [update]: address.id
            },
            invoiceDetails: {
                ...this.state.invoiceDetails,
                bill_language: address.bill_language || this.state.invoiceDetails.bill_language,
                ...this.currencyDetails(address.currency),
            }
        }, () => this.handleReverseVatChange(address.reverse_charge > 0 ? 0 : 1))
    };    

    onCompanyaddressChange = (name, value) => {
        DataHandler.post({url: 'invoices/company_address'}, {name: name, value: value, company: this.state.company}).done(response => {
            const companyAddressData = {...this.state.companyAddressData, [name]: response};
            this.setState({companyAddressData, savedSenderAddress: companyAddressData});
        });
    };

    onTermsChange = (evt) => {
        const { enqueueSnackbar } =  this.props;
        const { name, value } = evt.target;

        const invoiceDetails = { 
            ...this.state.invoiceDetails,
            terms: Number(value),
        };

        // If value didn't change, don't show snackbar
        if (isNaN(invoiceDetails.terms) || invoiceDetails.terms == this.state.invoiceDetails.terms)
            return;
        
        if (this.useManualTerms && !this.isNewInvoice()) {
            this.props.enqueueSnackbar(this.tr("Due date must be manually updated after updating terms of payment."), {
                variant: "info",
            });
        } else {
            invoiceDetails.duedate = this.getFormattedDueDate({invoiceDetails});
        }

        this.setState({invoiceDetails});
    }
    setCreationdate = (date) => {
        const invoiceDetails = { 
            ...this.state.invoiceDetails,
            creationdate: format(date, 'YYYY-MM-DD'),
            deliverydate: format(date, 'YYYY-MM-DD'),
        };
        invoiceDetails.duedate = this.getFormattedDueDate({invoiceDetails});

        this.setState({invoiceDetails});
    };
    setDuedate = (date) => {
        const invoiceDetails = { 
            ...this.state.invoiceDetails,
            duedate: format(date, 'YYYY-MM-DD'),
        };

        if (!this.useManualTerms)
            invoiceDetails.terms = differenceInCalendarDays(date, Utils.localDate(invoiceDetails.creationdate));

        this.setState({invoiceDetails});
    };
    setDeliverydate = (date) => {
        const invoiceDetails = { 
            ...this.state.invoiceDetails,
            deliverydate: format(date, 'YYYY-MM-DD'),
        };

        this.setState({invoiceDetails});
    };
    setVoucherDate = (date) => {
        const invoiceDetails = { 
            ...this.state.invoiceDetails,
            voucher_date: format(date, 'YYYY-MM-DD'),
        };

        this.setState({invoiceDetails});
    }

    onInvoiceDetailChange = (evt) => {

        let { name, value } = evt.target;
        const invoiceDetails = this.state.invoiceDetails;
        const agreement_identifier = this.state.agreement_identifier;
        const order_identifier = this.state.order_identifier;


        if (name === "annotationinterest")
            value = String(value.replace(',', '.'));

        this.setState({invoiceDetails: {...invoiceDetails,
            [name]: value
        }});

        if (name === "agreement_identifier"){
            this.setState({agreement_identifier: value});
    
        }
        if (name === "order_identifier"){
            this.setState({order_identifier: value});
        }
    };

    defineStatus = () => {
        const {data} = this.state;
        let statusData = this.statuses[data.state];
        if (data.bill_type == "2")
            statusData = this.statuses['5'];
        if (Utils.localDate(data.duedate) < new Date() && data.state == "2")
            statusData = this.statuses['6'];
        return statusData;

    };

    toggleShippingAddress = () => {
        this.setState({useRecipient: !this.state.useRecipient});
    };

    handleZoneChange = (zone) => {

        // let { rows } = this.state;

        // rows = rows.map(r => {
        //     r.nullvat = zone.id == "NON EU" ? 1 : 0});

        this.setState(state => {
            state.selectedBillingZone = zone;
            state.projectData.billing_zone = zone.id;
            // state.rows = rows;
            return state;
        });

    };

    hasOldCurrencyConvert = () => {
        const { currencyChanged, data: { has_old_currency_convert } } = this.state;
        return !!Number(has_old_currency_convert) && !currencyChanged;
    }

    // row functions
    countTotals = (name, inCompanyCurrency) => {
        const { rows, invoiceDetails: {currency_rate}, data: { has_old_currency_convert } } = this.state;
        if (!rows || rows.length < 1 || !Array.isArray(rows))
            return 0;        

        return CurrencyUtils.sumRowValues(rows, {fieldName: name, rate: currency_rate, inCompanyCurrency: inCompanyCurrency, oldCurrencyConvert: this.hasOldCurrencyConvert()});
    };    

    countSectionTotals = (id, name, convertable) => {
        const { rows, invoiceDetails: {currency_rate}, data: { has_old_currency_convert } } = this.state;
        if (!rows || rows.length < 1 || !Array.isArray(rows))
            return 0;

        const baseIndex = rows.findIndex(r => r.id == id);
        const sectionRows = [];
        for (let i = baseIndex; i >= 0; i--) {
            if (i !== baseIndex && rows[i]['total_marker'] > 0)
                break;
            sectionRows.push(rows[i]);
        }

        return CurrencyUtils.sumRowValues(sectionRows, {fieldName: name, rate: currency_rate, convertable: convertable, oldCurrencyConvert: this.hasOldCurrencyConvert()});
    };

    getNextTempIdForInvoiceRow = () => {
        const { rows } = this.state;

        let newId = -1;
        rows.forEach(e => {
            if (e.id <= newId)
                newId = e.id - 1;
        });
        return newId;
    }
    addRow = (id) => this._addRow(id, 1);
    addHeaderRow = () => this._addRow(null, 2);
    addProductRow = (id) => this._addRow(id, 3);
    addCPQRow = (id) => this._addRow(id, 4);
    _addRow = (id, row_category) => {
        let { rows, selectedVatCode, invoiceDetails } = this.state;
        
        const iRow = {...this.defaultRow};

        Object.assign(iRow, {id: this.getNextTempIdForInvoiceRow(), row_category});
        
        if (this.isCompanyUsingRowSpecificVatcodes()) {
            const vatcode = this.getNewRowVatcode();
            iRow.sales_vatcode = vatcode?.id || 0;
            iRow.vat = this.getActiveVatPercent(vatcode);
        }
        else {
            iRow.vat = this.getActiveVatPercent();
        }

        if (Number(id)) {
            const baseIndex = rows.findIndex(r => r.id == id);
            rows.splice(baseIndex + 1, 0, iRow);
        }
        else
            rows.push(iRow);
            
        rows = this.setTotalMarkers(rows);
        rows.forEach((e, i) => e.roworder = i);

        // Update project dimension to row. 
        // Row order is needed when material invoice so need to do it here and replace row in rows with updated row.
        const rowIndex = rows.findIndex(r => r.id == iRow.id);
        const updatedRow = this.addProjectDimensionToRows([rows[rowIndex]])[0];
        rows.splice(rowIndex, 1, updatedRow);

        this.setState({
            rows,
        }, () => this.updateRowDimensions());
    }
    
    updateData = (update) => {
        this.setState(update);
    }

    editRow = (name, value, id) => {

        let { rows, overLimitRows, fieldLimits, descriptionErrorRows, defaultDimensionValues, dimensionHeaders, invoiceDetails: {currency_rate}, data: { has_old_currency_convert } } = this.state;

        if (name == 'vat' && this.state.invoiceDetails.reverse_vat > 0)
            // dont allow vat change if reverse vat is active
            return;

        const i = rows.findIndex(r => r.id == id);
        if (i < 0)
            return;

        rows = _.cloneDeep(rows);

        if (['quantity', 'value', 'total'].includes(name))
            value = isNaN(Number(value)) ? value.toString().replace(/[^\d.-]/g, '') : value;

        let row = rows[i];
        let valueChanged = false;
        let descriptionChanged = false;

        if (name == "description" && value.toString() !== row["description"].toString()) {
            descriptionChanged = true;
        }
        
        if(name == "efina_product" || name == "efina_cost_pool" || name === "talenom_account") {
            if(name == "efina_product") {
                row['efina_product_id'] = value.value;
                row['efina_product_label'] = value.label;
            } 
            if(name == "efina_cost_pool") {
                row['efina_costpool_id'] = value.value;
                row['efina_costpool_label'] = value.label;
            }
            if(name === "talenom_account") {
                row['sales_account'] = value.id;
                row['vat'] = Number(value.vat_value);
                row = this.calculateRowValues(row, "vat");
            }
        } 
        else if (name == "sales_vatcode") {
            row['sales_vatcode'] = value.id;
            row['sales_vatcode_invalid'] = false;
            rows[i] = row;
            rows = this.checkRowVatcodes(rows);
            if (Number(value.id) && (this.state.invoiceDetails.reverse_vat < 1 || !this.state.invoiceDetails.reverse_vat)) {
                row['vat'] = value.vatpercent;
                row = this.calculateRowValues(row, "vat");
            }
        }
        else {
            const hasCurrencyConvert = this.state.invoiceDetails.currency_rate && this.state.invoiceDetails.currency_rate != 0;
            const margin = hasCurrencyConvert ? 0.0001 : 0.005;
            if ((name == 'value' && Math.abs(value - row[name]) >= margin) || (name == 'total' && Math.abs(value - row[name]) >= margin)) {
                valueChanged = true;
            }
            row[name] = value;
        }
        
        if (name == 'quantity') {
            if (row['quantity'] !== row['previous_quantity']) {
                row = this.calculateRowValues(row, "quantity");
            }
        }
        if (name == 'value' && valueChanged) {
            row = this.calculateRowValues(row, "value");
        }
        if (name == 'vat'){
            row = this.calculateRowValues(row, "vat");
        }
        if (name == 'total' && valueChanged) {
            row = this.calculateRowValues(row, "total");
        }
        if (name == 'dimension_item') {
            const editedMaterialRowDimensions = this.state.editedMaterialRowDimensions.concat({material_id: row.material_id, projects_id: row.projects_id, dimension_item: row.dimension_item});
            this.setState({ editedMaterialRowDimensions });
            row['dimension_item_edited'] = true;
        }
        if (name == "product_dimension_values") {
            const projectDimension =  this.getProjectDimensionForRow(row);
            const projectDimensions = projectDimension ? [projectDimension] : [];
            row["dimension_values"] = getProductDimensionValuesForHeaders(value, defaultDimensionValues, dimensionHeaders, projectDimensions);
        }

        if (name == "product_register_id" && this.context.addons?.heeros?.used_by_companies?.indexOf(this.state.company) > -1) {
            row['product_register_product'] = this.state.productRegisterProducts ? (this.state.productRegisterProducts.find(p => p.id == value) ? this.state.productRegisterProducts.find(p => p.id == value).id : 0) : 0;
        }

        if (overLimitRows?.includes(row.id) && fieldLimits['rowItemDescription'] && fieldLimits['rowItemDescription'] >= value.toString().length) {
            const index = overLimitRows.findIndex(r => r == row.id);
            overLimitRows.splice(index, 1);
        }

        if (descriptionChanged && descriptionErrorRows.includes(row.id)) {
            const index = descriptionErrorRows.findIndex(r => r == row.id);
            descriptionErrorRows.splice(index, 1);
        }

        rows[i] = row;

        this.setState({ rows, overLimitRows, descriptionErrorRows });

    };

    calculateRowValues = (row, fieldName, currency_rate = false, has_old_currency_convert = false) => {
        const { currencyChanged } = this.state;

        const oldCurrencyConvert = has_old_currency_convert || this.state.data?.has_old_currency_convert;
        const rate = currency_rate || this.state.invoiceDetails?.currency_rate || 1;
        return CurrencyUtils.calculateRowValues(row, {fieldName, rate, oldCurrencyConvert: has_old_currency_convert || this.hasOldCurrencyConvert()});
    }

    updateValuesForRows = (rows, currency_rate = false, has_old_currency_convert = false) => {
        const updatedRows = rows.map(r => {
            r = this.calculateRowValues(r, "value", currency_rate, has_old_currency_convert || this.hasOldCurrencyConvert());
            return r;
        })
        return updatedRows;
    }

    updateMaterialDataForRows = (rows, materialRows) => {
        const updatedRows = rows.map(r => {
            const materialRow = materialRows.find(i => i.material_id == r.content_id && i.row_type == r.content_type)
            if (materialRow) {
                r.from_quote_row = materialRow.from_quote_row;
            }
            return r;
        });

        return updatedRows;
    }

    editDescriptionRow = (name, value, id) => {
        const { descriptionRows, overLimitDescriptionRows, fieldLimits, invalidDescriptionRows } = this.state;
        const i = descriptionRows.findIndex(r => r.id == id);
        if (i < 0)
            return;

        const row = descriptionRows[i];
        let descriptionChanged = false;

        if (overLimitDescriptionRows?.includes(id) && fieldLimits['rowItemDescription'] && fieldLimits['rowItemDescription'] >= value.toString().length) {
            const index = overLimitDescriptionRows.findIndex(r => r == id);
            overLimitDescriptionRows.splice(index, 1);
        }

        if (name == "description" && value.toString() !== row["description"].toString()) {
            descriptionChanged = true;
        }

        descriptionRows[i] = {...descriptionRows[i], [name]: value};

        if (descriptionChanged && invalidDescriptionRows.includes(id)) {
            const index = invalidDescriptionRows.findIndex(r => r == id);
            invalidDescriptionRows.splice(index, 1);
        }

        this.setState({ descriptionRows: descriptionRows, invalidDescriptionRows, overLimitDescriptionRows });
    };

    editCPQ = (value, id) => {
        const { rows } = this.state;

        const i = rows.findIndex(r => r.id == id);
        
        if (i < 0)
            return;

        const row = rows[i];        

        DataHandler.get({ url: `cpq/parent`, parentId: value.id }).done(resp => {
            rows[i] = {...row, 
                cpq_id: value.id,
                description: value.name,
                manual_row: 1, 
                value: resp.CPQ.income_price,
                vat: value.vat > 0 ? value.vat : row.vat,
                previous_quantity: row.quantity,
            };
            rows[i] = this.calculateRowValues(rows[i], "value");
            this.setState({ rows: rows });
        }).fail(response => {});
    };          

    deleteRow = (target, id, callback = () => {}) => {
        const { state } = this;
        const { enqueueSnackbar } = this.props;
        
        let targetCollection = _.cloneDeep(state[target]);
        const i = targetCollection.findIndex(r => r.id == id);
        const targetRow = targetCollection[i];
        
        if (target != 'descriptionRows' && targetCollection.filter(r => !r.deleted || r.deleted < 1).length < 2)
            return;
        
        if (id < 0)
            targetCollection = targetCollection.filter(e => e.id !== id);
        else
            targetRow.deleted = 1;

        if (targetRow.content_id > 0 || targetRow.material_id > 0) {
            this.props.enqueueSnackbar(this.tr("Removed row is still invoiced. Manage invoiced material via Invoice material."), {
                variant: "warning",
                preventDuplicate: false
            });
        }
        
        let descRowsChanged = false;
        let descRows = [];
        if(target == "rows") {
            descRows = _.cloneDeep(state.descriptionRows);
            descRows = descRows.filter(el => {
                if(el.billentries_id == id) {
                    descRowsChanged = true;
                    return false;
                }
                return true;
            });
        }

        const update = {
            [target]: targetCollection
        };
        if(descRowsChanged)
            update.descriptionRows = descRows;

        if (update.rows) {
            update.rows = this.setTotalMarkers(update.rows);
        }
        
        this.setState(update, callback);
    };

    addDescriptionRow = (row) => {
        row.id = -(this.state.descriptionRows.length + 1);
        this.setState({
            descriptionRows: [...this.state.descriptionRows, row],
        });
    };

    // The callback parameter only exists so we can 
    // delete the material period row easily after 
    // the already invoiced row has been deleted
    // without having to muck about in 
    // state-mutating code for days on end.
    deleteAlreadyInvoicedRow = (callback = () => {}) => {
        const { rows, invoiceDetails } = this.state;
        const alreadyInvoicedRow = rows.find(row => row.row_type == "-1" && row.deleted != 1);

        if (alreadyInvoicedRow) {
            this.setInvoiceDetails({
                already_invoiced_total_row_text: alreadyInvoicedRow.description
            });
            this.deleteRow("rows", alreadyInvoicedRow['id'], callback);
        } else {
            callback();
        }
    }

    deleteMaterialPeriodRow = () => {
        const { rows } = this.state;

        const row = rows.find(r => Number(r.row_type) === -2 && Number(r.deleted) !== 1);

        if(!row) {
            return;
        }

        this.deleteRow("rows", row.id);
    }

    // dialog functions
    callInvoiceMaterialDialog = () => {

        const { id } = this.props;
        const { startDate, endDate, selectedProjects, materialGroupingOptions, invoiceData, editMode, useOwnHours, selectedProjectsOwnWork, has_multi_address_relation } = this.state;

        this.setState({
            dialogData: {
                id: id,
                invoiceData: invoiceData,
                groupingOptions: materialGroupingOptions,
                invoiceDataTypes: this.invoiceDataTypes,
                startDate: startDate,
                endDate: endDate,
                handleRangeSelect: this.handleRangeSelect,
                handleRangeInputChange: this.handleRangeInputChange,
                handleMaterialGroupingChange: this.handleMaterialGroupingChange,
                projects: _.cloneDeep(selectedProjects),
                saveFunc: 'setInvoiceMaterial',
                invoiceDataTypes: this.invoiceDataTypes,
                selectedProjects: _.cloneDeep(this.state.selectedProjects),
                netTotal: this.countTotals('total_no_vat'),
                canEditContent: editMode && !has_multi_address_relation,
                useManualOwnworkInvoicing: this.state.invoiceDetails.use_manual_ownwork_invoicing > 0,
                useOwnHours: useOwnHours-0,
                selectedProjectsOwnWork,
                projectRemoved: this.projectRemoved,
                chipTitle: this.chipTitle,
                invoiceableProjects: this.state.projects,
                selectedAccount: this.state.account,
                projectAdded: this.projectAdded,
                has_multi_address_relation,
                companyId: this.state.company
            }
        });
        this.openDialog('invoiceMaterial');
    };

    callInvoiceMaterialPdfDialog = () => {
        const { id } = this.props;
        const { startDate, endDate, selectedProjects, materialGroupingOptions,
                invoiceData, editMode, has_multi_address_relation, selectedBillingAddress
        } = this.state;

        this.setState({
            dialogData: {
                id: id,
                invoiceData: invoiceData,
                groupingOptions: materialGroupingOptions,
                invoiceDataTypes: this.invoiceDataTypes,
                startDate: startDate,
                endDate: endDate,
                handleRangeSelect: this.handleRangeSelect,
                handleRangeInputChange: this.handleRangeInputChange,
                handleMaterialGroupingChange: this.handleMaterialGroupingChange,
                projects: selectedProjects,
                saveFunc: 'addHourReportPdf',
                selectedProjects: this.state.selectedProjects,
                netTotal: this.countTotals('total_no_vat'),
                canEditContent: editMode && !has_multi_address_relation,
                billingAddressId: selectedBillingAddress?.id
            }
        });
        this.openDialog('invoiceMaterialPdf');
    }

    addHourReportPdf = (printData) => {
        this.setState({ generatingHourReport: true }, () => {
            //filter selected rows from all rows, leave out header rows etc.
            printData.rows = printData.rows.filter(row => (row.selected && row.main_type == 1)).map(r => {
                return r.material_id;
            });
            const mainHidden = printData.hideableColumns.filter(column => (column.selected == false && column.parent == 'entries_section')).map(c => {
                return c.key;
            });
            const extraHidden = printData.hideableColumns.filter(column => (column.selected == false && column.parent == 'summary_section')).map(c => {
                return c.key;
            });
            const projectHidden = printData.hideableColumns.filter(column => (column.selected == false && column.parent == 'summary_per_project')).map(c => {
                return c.key;
            });
            this.closeDialog('invoiceMaterialPdf');
            //Send print data to ReportsController

            const bill_language = this.returnValidBillingLanguage();
            const { date_format } = this.state.invoiceDetails;
            const transl = new InvoiceTranslations().returnTranslations([bill_language]);
            const mainColumns = [
                {
                    key: "date",
                    title: transl[bill_language].date,
                    maxWidth: 70,
                    formatDate: true,
                },
                {
                    key: "name",
                    title: transl[bill_language].user,
                    maxWidth: mainHidden.indexOf('description') == -1 ? 120 : undefined
                },
                {
                    key: "description",
                    title: transl[bill_language].description,
                },
                {
                    key: "worktype",
                    title: transl[bill_language].worktype,
                    maxWidth: 100
                },
                {
                    key: "resource_name",
                    title: transl[bill_language].task,
                    maxWidth: 100
                },
                {
                    key: "hours",
                    title: transl[bill_language].hours,
                    maxWidth: 60,
                    roundToDecimals: 2,
                    postfix: ' h'
                },
                {
                    key: "average_price",
                    title: transl[bill_language].average_price,
                    maxWidth: 80,
                    formatCurrency: true,
                    currency: this.context.taimerAccount.currency,
                    countryCode: this.context.taimerAccount.numberFormat,
                },
                {
                    key: "total",
                    title: transl[bill_language].total,
                    maxWidth: 100,
                    formatCurrency: true,
                    currency: this.context.taimerAccount.currency,
                    countryCode: this.context.taimerAccount.numberFormat,
                },
            ].filter(c => mainHidden.indexOf(c.key) == -1);
            mainColumns[mainColumns.length - 1].align = 'right';

            const reportSettings = {
                title: transl[bill_language].hours_report,
                filename: "hour_report",
                sectionTitle: transl[bill_language].entries_specified,
                columns: mainColumns,
                groupBy: "customers_id",
                groupTitleKey: "customers_name",
                subGroupBy: "projects_id",
                subGroupTitleKey: "projects_name",
                subGroupParentTitleKey: "parent_projects_name",
                noFooter: true,
                dateFormat: date_format,
                headerItems: [
                    {
                        title: transl[bill_language].account_total,
                        key: "hours",
                        postfix: " h",
                        roundToDecimals: 2
                    },
                ],
                subHeaderItems: [
                    {
                        title: transl[bill_language].project_total,
                        key: "hours",
                        postfix: " h",
                        roundToDecimals: 2
                    },
                ]
            }

            const extraColumns = [
                {
                    key: "name",
                    title: transl[bill_language].user,
                },
                {
                    key: "protitle_name",
                    title: transl[bill_language].professional_title,
                    maxWidth: 120
                },
                {
                    key: "hours",
                    title: transl[bill_language].hours,
                    maxWidth: 100,
                    roundToDecimals: 2,
                    postfix: ' h'
                },
                {
                    key: "average_price",
                    title: transl[bill_language].average_price,
                    maxWidth: 80,
                    formatCurrency: true,
                    currency: this.context.taimerAccount.currency,
                    countryCode: this.context.taimerAccount.numberFormat,
                },
                {
                    key: "total",
                    title: transl[bill_language].total,
                    maxWidth: 100,
                    formatCurrency: true,
                    currency: this.context.taimerAccount.currency,
                    countryCode: this.context.taimerAccount.numberFormat,
                },
            ].filter(c => extraHidden.indexOf(c.key) == -1);
            extraColumns[extraColumns.length - 1].align = "right"

            const projectColumns = [
                {
                    key: "projects_name",
                    title: transl[bill_language].project,
                },
                {
                    key: "your_reference",
                    title: transl[bill_language].your_reference,
                    maxWidth: 120
                },
                {
                    key: "worktype",
                    title: transl[bill_language].worktype,
                    maxWidth: 100
                },
                {
                    key: "hours",
                    title: transl[bill_language].hours,
                    maxWidth: 100,
                    roundToDecimals: 2,
                    postfix: ' h'
                },
                {
                    key: "average_price",
                    title: transl[bill_language].price,
                    maxWidth: 80,
                    formatCurrency: true,
                    currency: this.context.taimerAccount.currency,
                    countryCode: this.context.taimerAccount.numberFormat,
                },
                {
                    key: "total",
                    title: transl[bill_language].total,
                    maxWidth: 100,
                    formatCurrency: true,
                    currency: this.context.taimerAccount.currency,
                    countryCode: this.context.taimerAccount.numberFormat,
                },
            ].filter(c => projectHidden.indexOf(c.key) == -1);
            projectColumns[projectColumns.length - 1].align = "right"

            const extraReportSettings = {
                sectionTitle: transl[bill_language].summary_by_user,
                hideTotals: {
                    "average_price": true
                },
                columns: extraColumns,
                translations: {
                    totalTitle: transl[bill_language].total,
                    grandTotalTitle: transl[bill_language].grand_total
                },
                dateFormat: date_format           
            }

            const projectReportSettings = {
                sectionTitle: transl[bill_language].summary_by_project,
                hideTotals: {
                    "average_price": true,
                    "your_reference": true,
                    "worktype": true
                },
                columns: projectColumns,
                translations: {
                    totalTitle: transl[bill_language].total,
                    grandTotalTitle: transl[bill_language].grand_total
                },
                dateFormat: date_format           
            }

            this.printAndAttach({ rows: printData.rows, 
                hideMainSection: printData.hideableColumns.findIndex(c => c.selected == false && c.key == "entries_section") != -1, 
                hideExtraSection: printData.hideableColumns.findIndex(c => c.selected == false && c.key == "summary_section") != -1,
                hideProjectSection: printData.hideableColumns.findIndex(c => c.selected == false && c.key == "summary_per_project") != -1,
                reportSettings, 
                extraReportSettings, 
                projectReportSettings,
                billingAddressId: printData.billingAddressId, 
                invoice_nr: this.state.invoiceDetails?.bill_id,
                ...printData.dateRange }, 'reports/hour_report_pdf_attachment/' + this.props.id);
        });
    }

    printAndAttach = (data, url) => {
        this.createHourReportSnackbarKey = this.props.enqueueSnackbar(this.tr("Creating Hour report PDF..."), {
            variant: "info",
        });
        DataHandler.post({ url }, data).done(response => {
            this.setState({ generatingHourReport: false });
            if (response.html) {
                const printWindow = window.open('', 'PRINT');
                if (printWindow) printWindow.document.body.innerHTML = response.html;
                printWindow.focus();
            }
            
            if (response.result) {
                this.props.closeSnackbar(this.createHourReportSnackbarKey);
                this.props.enqueueSnackbar(this.tr("Hour report PDF added as attachment."), {
                    variant: "info",
                });
                this.attachments.current.updateComponentData();
            } else if (response.error) {
                this.props.enqueueSnackbar(this.tr("Failed to generate hour report PDF-file."), {
                    variant: "error",
                });
            } 
        });
    }

    setInvoiceMaterial = (materialRows, updateRows = true, selectedProjectsOwnWork, ownHours) => {
        this.closeDialog();

        //if invoice material gets an update, we use this cache to set selling prices etc.
        const {materialCache = {}, invoiceData} = this.state;
        const mergedInvoiceData = {...invoiceData, invoice_data_rows: materialRows};

        materialRows.forEach(ir => {
            materialCache[ir.id] = {
                selected: ir.selected,
                selected_price: ir.selected_price
            };
        });
        
        if (!updateRows) {
            this.setState({invoiceData: mergedInvoiceData, materialCache, selectedProjectsOwnWork, useOwnHours: ownHours}, () => {
                //user is editing sent invoice
                !this.state.editMode && this.save();
            });
        }
        else {
            const selectedRows = materialRows.filter(r => r.selected == 1);
            this.setState({invoiceData: mergedInvoiceData, materialCache, selectedProjectsOwnWork, useOwnHours: ownHours}, () => {
                this.generateRows(selectedRows);
            });
        }
    };

    generateRows = (materialRows) => {
        const {company, selectedProjects} = this.state;

        this.setState({invoiceDataLoading: true}, () => {
            const rowGenerationParams = this.getRowGenerationParams();

            this.invoiceDataRequest =  DataHandler.post(
                { url: 'invoices/generate_rows' },
                {
                    company, 
                    projects: selectedProjects.map(e => {return {id: e.id, name: e.name, project_id: e.project_id, parent_name: e.parent_name, customer_reference: e.customer_reference}}), 
                    rowGenerationParams: rowGenerationParams,
                    invoiceDataRows: materialRows,
                    metadata: {
                        invoice_join_data: this.state.invoiceData.invoice_join_data,
                        invoice_header_data: this.state.invoiceData.invoice_header_data
                    }
                }
            ).done(newRows => {   
                newRows.rows = this.updateValuesForRows(newRows.rows);
                newRows.rows = this.checkRowVatcodes(newRows.rows);
                this.updateRows(newRows);
            }).fail(err => {                   
                this.setState({invoiceDataLoading: false});
            });
        });
    }

    updateRows = (newRows) => {
        /* remove all non-saved rows and flag saved rows as deleted */
        let rows = _.cloneDeep(this.state.rows);
        rows = rows.filter(e => e.id > 0);
        rows.forEach(e => e.deleted = 1);

        let descriptionRows = _.cloneDeep(this.state.descriptionRows);
        descriptionRows = descriptionRows.filter(e => e.id > 0);
        descriptionRows.forEach(e => e.deleted = 1);

        const rowData = {
            rows: [...rows, ...this.setTotalMarkers(newRows.rows)],
            descriptionRows: [...descriptionRows, ...newRows.descriptionRows]
        }
        
        this.setState({
            ...rowData,
            invoiceDataLoading: false
        }, () => {
            const updatedRows = this.addProjectDimensionToRows(this.state.rows);
            this.updateRowDimensions(updatedRows);
        });
    }

    getDefaultVatcode = (vatcodes = false) => {
        const vatCodes = vatcodes ? vatcodes : this.state.activeVatcodes;
        const defaultVatcode = _.cloneDeep(
            vatCodes.find(e => e.is_default === '1') || 
            vatCodes[0] || 
            {}
        );
        return defaultVatcode;
    }

    /**
     * Gets vatcode that can be updated to new invoice rows
     * @returns selected vatcode, or defaultvatcode if selected vatcode is "rowspecific""
     */
    getNewRowVatcode = () => {
        const {selectedVatCode} = this.state;
        return selectedVatCode?.id == this.definedVatCodes.rowSpecific.id ? this.getDefaultVatcode() : selectedVatCode;
    }

    /**
     * Checks if invoice has rows with different vatcodes
     * @param {*} rows invoice rows
     * @returns true if rows have different vatcodes, false otherwise.
     */
    hasRowSpecificVatcodes = (rows) => {
        if (!this.isCompanyUsingRowSpecificVatcodes()) {
            return false;
        }
        const allRowVatcodes = this.getItemRowVatcodes(rows);
        return !allRowVatcodes.every( (val, i, arr) => val == arr[0] );
    }

    /**
     * Gets vatcode ids from invoice rows
     * @param {*} rows invoice rows
     * @returns array of vatcode ids from invoice rows.
     */
    getItemRowVatcodes = (rows) => {
        const itemRows = rows.filter(e => {
            const isDescriptionRow = e['row_category'] == 2 || e['row_category'] == 5 || e['row_type'] < 0;
            return !isDescriptionRow;
        })

        const allRowVatcodes = [];
        itemRows.forEach(r => { 
            allRowVatcodes.push(r.sales_vatcode);
        });
        return allRowVatcodes;
    }

    /**
     * Updates rows so that every row (except description rows) has a vatcode.
     * @param {*} rows invoice rows
     * @returns updated rows.
     */
    updateRowVatcodes = (rows) => {
        const defaultVatCode = this.getNewRowVatcode();
        rows = rows.map(r => {
            if (!Number(r.sales_vatcode)) {
                const isDescriptionRow = r['row_category'] == 2 || r['row_category'] == 5 || r['row_type'] < 0;
                if (isDescriptionRow) {
                    return r;
                }
                r.sales_vatcode = defaultVatCode.id;

                if (Number(defaultVatCode.id) && (this.state.invoiceDetails.reverse_vat < 1 || !this.state.invoiceDetails.reverse_vat)) {
                    r['vat'] = defaultVatCode.vatpercent;
                    r = this.calculateRowValues(r, "vat");
                }
            }
            return r;
        });

        return rows;
    }

    /**
     * updated invoice vatcode depending on what codes are selected for invoice rows. 
     * If rows have different vat codes, "Row specific vatcode" is selected.
     * Updates rows so that every row (except description rows) has some vatcode.
     * @param {*} rows invoice rows
     * @returns updated rows. updated rows. Where every row (except description rows) has vatcode.
     */
    checkRowVatcodes = (rows) => {
        if (!this.isCompanyUsingRowSpecificVatcodes()) {
            return rows;
        }
        const {selectedVatCode, activeVatcodes} = this.state;
        rows = _.cloneDeep(rows);
        const hasRowSpecificVatcodes = this.hasRowSpecificVatcodes(rows);
        const rowVatcodes = this.getItemRowVatcodes(rows);
        const rowVatcode = rowVatcodes.length > 0 ? rowVatcodes[0] : 0;
    
        rows = this.updateRowVatcodes(rows);

        const newVatCode = hasRowSpecificVatcodes ? this.definedVatCodes.rowSpecific : (Number(rowVatcode) ? activeVatcodes.find(a => a.id == rowVatcode) : selectedVatCode); 
        this.setState({ selectedVatCode: newVatCode });
        return rows;
    }

    openDialog = (name) => {
        this.setState({ currentDialog: name });
    };

    closeDialog = (evt) => {
        if (this.state.currentDialog === "initial" && this.isNewInvoice()) {
            this.backToList(evt);
            return;
        }
        
        
        this.setState({ currentDialog: false });
    };

    /*
    * small updates to state {invoiceDetails: update}
    */
    setInvoiceDetails = (update) => {
        if (!update.invoiceDetails)
            update = {invoiceDetails: update};

        this.setState( this.buildState(update), () => update.invoiceDetails?.currency_rate && this.setRowCurrencyValues(update.invoiceDetails?.currency_rate) );
    }

    saveDialog = (saveFunc, data, updateRows, selectedProjectsOwnWork, ownHours) => {
        this[saveFunc](data, updateRows, selectedProjectsOwnWork, ownHours);
    };

    onInvoiceTypeSelected = async (type) => {
        const { functions: { updateView } } = this.context;
        // mass invoicing
        if (type == 10) {
            updateView({ module: 'invoices', action: 'main', selectedTab: 'mass-invoicing' });
            return;
        }

        this.props.updateView({ template: type == 1 ? "blank" : "material", replace: true });

        this.setState({
            currentDialog: false,
            invoiceType: type.toString(), //componentDidUpdate reacts invoiceType change
            editMode: true,
            rows: type == 1 ? this.getDefaultRows() : [],
        });

        this.context.functions.sendMixpanelEvent('create_invoice', {
            'invoice_type': type == 1 ? 'blank' : 'project',
            'origin_point': this.props.origin_point ? decodeURI(this.props.origin_point) : 'invoice_view',
        });
        this.context.functions.sendMixpanelPeople('set_once', {
            'first_create_invoice_start': new Date().toISOString(),
        });
        this.context.functions.sendMixpanelPeople('set', {
            'last_create_invoice_start': new Date().toISOString(),
        });
        this.context.functions.sendMixpanelPeople('increment', {
            'lifetime_create_invoice': 1,
        });
    };

    ctrlSave = (e) => {
        if (e.keyCode === 83 && e.ctrlKey && this.state.editMode && !this.noteDrawer.current.state.open) {
            e.preventDefault();
            setTimeout(this.save, 500);
        }
    }

    ctrlEdit = (e) => {
        if (e.button === 0 && e.ctrlKey && !this.state.editMode) {
            e.preventDefault();
            this.edit();
        }
    }
    
    chipTitle = (project) => {
        const { userObject } = this.context;
        
        const title = [];
        
        if (!(project.customer_address_id-0))
            title.push(<div>{this.tr("This project does not have billing address selected")}</div>);
        
        if (project.first)
            title.push(<div>{this.tr("This project has invoiceable content between") + " " + format(project.first, userObject.dateFormat) + " " + this.tr("and") + " " + format(Utils.localDate(project.last), userObject.dateFormat)}</div>);
        
        return title.length ? title : "";
    }
    /*
    * clone state.rows, set newVat for every row and calculate row total
    * returns modified set of rows
    */
    invoiceRowVat = (newVat, rows = this.state.rows, reverseVat = -1, reverseVatChanged = false) => {
        if (!rows)
            return [];

        if (Number(reverseVat) < 0) {
            reverseVat = this.state.invoiceDetails.reverse_vat;
        }  
        return _.cloneDeep(rows).map(r => {
            r.vat = this.getRowVat(r, newVat, reverseVat, reverseVatChanged);
            r = this.calculateRowValues(r, "vat");
            return r;
        })
    }
    // onVatCodeChanged = () => {
    //     if (this.state.invoiceDetails.reverse_vat > 0)
    //         //dont change vats if reverse vat is activated
    //         return
            
    //     this.setState({ 
    //         rows: this.invoiceRowVat(this.state.selectedVatCode?.vatpercent) 
    //     });
    // }

    getRowVat = (row, newVat, reverseVat, reverseVatChanged) => {
        const vatCodeSelected = this.state.selectedVatCode?.id && Number(this.state.selectedVatCode?.id) > 0;
        const salesVatCodes = this.state.salesVatCodes ? this.state.salesVatCodes : [];
        const salesVatCode = row.sales_vatcode && Number(row.sales_vatcode) > 0 ? salesVatCodes.find(s => s.id == row.sales_vatcode) : null;
        let rowVat = salesVatCode && Number(reverseVat) < 1 ? salesVatCode.vatpercent : newVat; // If row has rowspecific vatcode selected, get vat % from vatcode.

        if (row.material_vat && !vatCodeSelected && !salesVatCode && reverseVatChanged && Number(reverseVat) < 1) { // If reverse vat has been switched off, get vat % from invoice material if row is from material.
            rowVat = row.material_vat;
        }
 
        return (rowVat || 0).toString(); //zeros will not be visible otherwise;
    }

    handleReverseVatChange = (definedValue) => {
        if (this.useVatcodeReverseVat()) {
            return;
        }
        const newVat = (definedValue > 0 || this.state.invoiceDetails.reverse_vat > 0) ? (this.state.selectedVatCode?.vatpercent || this.defaultRow.vat) : "0";
        // this is now determined when row is added using this.getActiveVatPercent()
        //this.defaultRow.vat = newVat;
        const reverseVat = (definedValue > 0 || this.state.invoiceDetails.reverse_vat > 0) ? '0': '1';
        this.setState({
            rows: this.invoiceRowVat(newVat, undefined, reverseVat, true),
            invoiceDetails: {
                ...this.state.invoiceDetails, 
                reverse_vat: reverseVat
            }
        });        
    }
    getReverseVat = (definedValue, rows = this.state.rows) => {
        const newVat = definedValue > 0 ? "0" : (this.state.selectedVatCode?.vatpercent || this.defaultRow.vat);
      
        // this is now determined when row is added using this.getActiveVatPercent()
        //this.defaultRow.vat = newVat;
        const reverseVat = definedValue > 0  ? '1': '0';
        return { 
            invoiceDetails: { 
                reverse_vat: reverseVat
            }, 
            rows: this.invoiceRowVat(newVat, rows, reverseVat, true) 
        } ;      
    }
    /*
    * Sets one vatPercent for every invoice row. triggered by user
    */
    setVatPercentForInvoice = ( vatPercent ) => {
        const rows = this.invoiceRowVat(vatPercent);
        this.setState({rows});

        // this is now determined when row is added using this.getActiveVatPercent()
        //this.defaultRow.vat = vatPercent;
    }
    getActiveVatPercent = (vatcode = null) => {
        const { selectedVatCode, invoiceDetails: { reverse_vat } } = this.state;
        
        if (reverse_vat > 0 && !this.useVatcodeReverseVat())
            return 0;

        if (vatcode) {
            return Number(vatcode.vatpercent);
        }
        
        return Number(selectedVatCode?.vatpercent || this.defaultRow.vat);
    }
    setSelectedVatCode = (selectedVatCode) => {
        let { invoiceDetails, rows, fieldErrors } = this.state;
        rows = _.cloneDeep(rows);

        if (this.isCompanyUsingRowSpecificVatcodes()) {
            rows = rows.map(r => {
                r['sales_vatcode'] = selectedVatCode.id;
                r['vat'] = selectedVatCode.vatpercent;
                r['sales_vatcode_invalid'] = false;
                return r;
            });
        }

        const vatPercertChanged = this.emptyVatcodeSelected() || Number(selectedVatCode.vatpercent) !== this.getActiveVatPercent();
        remove(fieldErrors, (key) => { return key == "vat_code" });

        this.setState({selectedVatCode, invoiceDetails, fieldErrors, rows}, async () => {
            if (vatPercertChanged)
                this.setVatPercentForInvoice( this.getActiveVatPercent() );
        });
    }

    uniqueSnacbar = (snackbarKey, ...enqueueSnackbarParams) => {
        const { enqueueSnackbar } = this.props;

        this.closeSnackbar(snackbarKey);
        this[snackbarKey] = enqueueSnackbar(...enqueueSnackbarParams);
    }
    closeSnackbar = (snackbarKey) => {
        const { closeSnackbar } = this.props;

        if (this[snackbarKey]) {
            closeSnackbar(this[snackbarKey]);
            this[snackbarKey] = null;
        }
    }

    openAccountingSlider = () => {
        this.setState({ showAccounting: true });
    }

    setRowCurrencyValues = (currency_rate) => {
        const rows = CurrencyUtils.setRowCurrencyValues(_.cloneDeep(this.state.rows), {rate: currency_rate, oldCurrencyConvert: false});
        this.setState({ rows, currencyChanged: true });
    }

    renderRowAccountingCell = (rowData, listCellProps) => {
        return <AccountingCell 
            listCellProps={{
                ...listCellProps,
                textAlign: "right", 
                innerStyle: { textAlign: "right" },
                className: "accounting-cell"
            }}
            type="sales_invoices" 
            module={"InvoiceView"}
            rowData={rowData} 
            accountingData={this.state} 
            showAccounting={this.openAccountingSlider} />
    }

    createDisabledTooltipField = (field, isDisabled, tooltip) => {
        if (tooltip && isDisabled) {
            return (
                <Tooltip classes={{ tooltip: 'darkblue-tooltip' }} title={tooltip} arrow placement="top">
                    <div>{field}</div>
                </Tooltip>
            )
        }
        return field;
    }
    
    renderInvoiceSettings = (billLanguage, billingLanguages) => {
        const { editMode, billingZones, selectedBillingZone, selectedVatCode, activeVatcodes, editEnabled, activeCurrencies, countryCode, allowedCountryCodes, allCountryCodes, countryVatCodes, fieldErrors, currencyChanged, has_multi_address_relation } = this.state;
        const { addons } = this.context;
        const settings = [];
        const dateFormatsMap = this.dateFormats.map(df => ({id: convertDateFormatToPHP(df), label: df}));

        const addressRelatedDataDisabled = this.isMultiInvoiceProject();
        const addressRelatedFielddisabledTooltip = this.getAddressRelatedFieldDisabledTooltip();
        
        addons?.sap && settings.push(
            <DatePicker
                className="dateWrapper date full"
                label={this.tr("Accounting date")}
                name="voucher_date"
                date={Utils.localDate(this.state.invoiceDetails.voucher_date)}
                onChange={(date) => this.setVoucherDate(date)}
                onInputChange={(type, date) => !!date && this.setVoucherDate(date)}
                disabled={editMode && editEnabled ? false : true}
                dateFormat={this.context.userObject.dateFormat}
            />
        );
        
        false && settings.push(
            <DataList 
                label={this.tr("Invoice area")}
                name="billing_zone"
                inputBaseProps={{classes: {root: styles.datalistInputRoot}}}
                options={billingZones}
                value={selectedBillingZone}
                isDisabled={editMode && editEnabled ? false : true}
                onChange={(zone)=> this.handleZoneChange(zone)} />
        );

        addons && (addons.talenom || addons.ropocapital || addons.fennoa || addons.efina) && settings.push(
            <DataList 
                label={this.tr("Delivery method")}
                name="delivery_method"
                value={this.state.delivery_type}
                options={this.delivery_types}
                isDisabled={editMode && editEnabled ? false : true}
                onChange={data => {
                    const addressFieldErrors = _.cloneDeep(this.state.addressFieldErrors);

                    if (addressFieldErrors?.includes("e_address") && data?.value != 1) {
                        remove(addressFieldErrors, (key) => { return key == "e_address" });
                    }
                    else if (addressFieldErrors?.includes("email") && data?.value != 4) {
                        remove(addressFieldErrors, (key) => { return key == "email" });
                    }
                    this.setState({delivery_type: data, addressFieldErrors});
                }}
                />
        );

        this.isCompanyUsingCountryCode() && settings.push(
            this.createDisabledTooltipField(
                <DataList 
                    label={this.tr("Country code")}
                    name="country_code"
                    value={allCountryCodes.find(c => c.id == countryCode)}
                    options={allowedCountryCodes}
                    error={fieldErrors.find(f => f == "countryCode")}
                    isDisabled={editMode && editEnabled ? false : true}
                    onChange={data => {
                        this.setState({countryCode: data.id});
                    }}
                />,
                editMode && editEnabled && !addressRelatedDataDisabled ? false : true,
                addressRelatedFielddisabledTooltip
            )
        );

        this.isCompanyUsingVatCodes() && settings.push(
            <DataList 
                label={this.tr("Invoice VAT code")}
                name="vat_code"
                value={selectedVatCode}
                error={fieldErrors.find(f => f == "vat_code")}
                inputBaseProps={{classes: {root: styles.datalistInputRoot}}}
                options={this.isCompanyUsingCountryCode() ? countryVatCodes : activeVatcodes}
                onChange={selectedVatCode => this.setSelectedVatCode(selectedVatCode)}
                isDisabled={editMode && editEnabled && !has_multi_address_relation ? false : true} 
                shownCount={20}
                multiLineValue={true}
            />
        );

        settings.push(<DataList 
            label={this.tr("Print settings")}
            name="hide_quantity_column"
            value={this.printSettings.find(e => e.id == this.state.invoiceDetails.hide_quantity_column)}
            inputBaseProps={{classes: {root: styles.datalistInputRoot}}}
            options={this.printSettings}
            onChange={(e) => this.setInvoiceDetails({ hide_quantity_column: e.id })}
            isDisabled={editMode && editEnabled ? false : true} 
        />);

        billingLanguages.length > 1 && settings.push(
            this.createDisabledTooltipField(
                <DataList 
                    label={this.tr("Invoice language")} 
                    name="bill_language"
                    inputBaseProps={{classes: {root: styles.datalistInputRoot}}}
                    options={billingLanguages}
                    value={this.billingLanguages.find(e => e.id == billLanguage)}
                    isDisabled={editMode && editEnabled && !addressRelatedDataDisabled ? false : true}
                    onChange={(e)=> this.setInvoiceDetails({ bill_language: e.id })} 
                />,
                editMode && editEnabled && !addressRelatedDataDisabled ? false : true,
                addressRelatedFielddisabledTooltip
            )
        );

        settings.push(
            <DataList 
                label={this.tr("Date format")} 
                name="date_format"
                inputBaseProps={{classes: {root: styles.datalistInputRoot}}}
                options={dateFormatsMap}
                value={dateFormatsMap.find(e => e.label == convertDateFormat(this.state.invoiceDetails.date_format)) || dateFormatsMap[0]}
                isDisabled={editMode && editEnabled ? false : true}
                onChange={(e)=> this.setInvoiceDetails({ date_format: e.id })} />
        );        

        settings.push(
            <div className="settings-switch">
                <Switch
                    name="invoice_barcode"
                    color="primary"
                    disabled={editMode && editEnabled ? false : true} 
                    onChange={(e)=> this.setInvoiceDetails({ invoice_barcode:  e.target.checked ? "1" : "0"})}
                    checked={this.state.invoiceDetails.invoice_barcode == 1}
                />
                <span>{this.tr('Print barcode')}</span>                
            </div>           
        );

        this.state.selectedProjects && this.state.selectedProjects.length > 0 && settings.push(
            <div className="settings-switch">
                <Switch
                    name="already_invoiced_total_row"
                    color="primary"
                    disabled={editMode ? false : true}
                    onChange={(e)=> this.setInvoiceDetails({ already_invoiced_total_row: e.target.checked ? "1" : "0" }) }
                    checked={this.state.invoiceDetails.already_invoiced_total_row == 1}
                />
                <span>{this.tr('Show already invoiced total row on invoice')}</span>
            </div>
        );

        this.state.selectedProjects && this.state.selectedProjects.length > 0 && settings.push(
            <div className="settings-switch">
                <Switch
                    name="show_material_period_on_invoice"
                    color="primary"
                    disabled={editMode ? false : true}
                    onChange={(e)=> this.setInvoiceDetails({ show_material_period_on_invoice: e.target.checked ? "1" : "0" })}
                    checked={Number(this.state.invoiceDetails.show_material_period_on_invoice) === 1}
                />
                <span>{this.tr('Show material period on invoice')}</span>
            </div>
        );

        // (addons.nav !== undefined) && settings.push(
        //     <div className="settings-switch">
        //         <Switch
        //             name="reverse_vat"
        //             color="primary"
        //             disabled={editMode && editEnabled && !this.isCompanyUsingCountryCode() ? false : true} 
        //             onChange={() => this.handleReverseVatChange()}
        //             checked={this.state.invoiceDetails.reverse_vat > 0}
        //         />
        //         <span>{this.tr('Reverse VAT')}</span>                
        //     </div>           
        // );

        let legacyCurrency = null;
        if (addons?.invoice_currency && !this.isNewInvoice() && this.state.data.has_old_currency_convert > 0 && !currencyChanged)
            legacyCurrency = {id: this.state.data.currency_code, label: this.state.data.currency_code};

        addons && addons.invoice_currency && settings.push(
            this.createDisabledTooltipField(
                <DataList 
                    label={this.tr("Invoice currency")}
                    name="currency_code"
                    value={legacyCurrency || CurrencyUtils.returnActiveCurrencyAutocompleteValue(this.currencies, this.state.invoiceDetails.currency_code, {defaultValue: this.defaultCurrencyDetails})}
                    inputBaseProps={{classes: {root: styles.datalistInputRoot}}}
                    options={activeCurrencies}
                    onChange={(e) => this.setInvoiceDetails(
                        this.currencyDetails(e.id)
                        )
                    }
                    isDisabled={editMode && editEnabled && !addressRelatedDataDisabled ? false : true} 
                    shownCount={20}
                />,
                editMode && editEnabled && !addressRelatedDataDisabled ? false : true,
                addressRelatedFielddisabledTooltip
            )
        );

        addons && addons.invoice_currency && settings.push(
            <OutlinedField 
                label={this.tr("Currency_rate")}
                name="currency_rate"
                value={this.state.data.has_old_currency_convert > 0 ? this.state.invoiceDetails.currency_rate : this.currencies.find(c => c.rateId == this.state.invoiceDetails.currency_rates_id)?.rate || '1.000000'}
                disabled />
        );

        addons && addons.invoice_currency && settings.push(
            <OutlinedField 
                label={this.tr("Total value in company's currency")}
                name="currency_total"
                value={`${Number(this.countTotals('total', true))?.toFixed(2) || 0} ${CurrencyUtils.getSymbol(this.state.companyCurrency)}`}
                disabled />
        );        
        
        settings.length && settings.unshift(
            <div className='column-header'>
                <Settings /> {this.tr("Invoice Settings")} 
            </div>
        )
        
        return settings;
    }

    renderInvoicingAddresses = () => {
        const invoicingAddresses = this.getInvoicingAddresses();

        const { taimerAccount } = this.context;

        const percentFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            useGrouping: false,
            minimumFractionDigits: 0,
            maximumFractionDigits: 4
        }).format;

        return (
            <div>
                <h4 className='toggle-header'>{this.tr("Billing address")}</h4>
                <ErrorsList 
                    type={"warning"}
                    typeClass={"warning-light"}
                    hideHeader={true}
                    data={[{
                        header: "",
                        message: this.tr("There are multiple invoicing addresses selected for this project.")
                    }]}
                /> 
                <SliderFieldGroup
                    editingDisabled
                    items={invoicingAddresses}
                    className='invoicing-addresses-list'
                    fields={[
                        {
                            key: 'customer_name',
                            title: this.tr('Account'),
                            disabled: true,
                            forceVisible: true
                        },
                        {
                            key: 'address_label',
                            title: this.tr('Address'),
                            disabled: true,
                            forceVisible: true
                        },
                        {
                            key: 'percentage',
                            title: this.tr('Share of invoicing'),
                            disabled: true,
                            forceVisible: true,
                            formatValue: (value, field) => percentFormatter(value) + " %" 
                        },
                        {
                            key: 'customer_reference',
                            title: this.tr('Your reference'),
                            disabled: true,
                            forceVisible: false
                        },
                    ]}
                />
            </div>
        );
    }

    countryCodeChanged = (newCode, initial = false) => {
        if (!this.isCompanyUsingCountryCode()) {
            return;
        }
        const { activeVatcodes, fieldErrors }  = this.state;
        const countryVatCodes = activeVatcodes.filter(v => v.country_code == newCode);
        let selectedVatCode = this.state.selectedVatCode;
        if (!initial) {
            let defaultVatCode = countryVatCodes.find(cv => cv.is_default == 1); 
            if (!defaultVatCode && countryVatCodes?.length == 1) {
                defaultVatCode = countryVatCodes[0];
            }
            selectedVatCode = defaultVatCode || {}; 
        }
        remove(fieldErrors, (key) => { return key == "countryCode" });
        remove(fieldErrors, (key) => { return key == "vat_code" });

        this.setState({ 
            countryCode: newCode, 
            countryVatCodes, 
            selectedVatCode, 
            fieldErrors, 
            rows: this.invoiceRowVat(selectedVatCode?.vatpercent || "0") 
        });
    }

    getAndAttachInvoicingHistory = () => {
        this.setState({ generatingInvoicingHistory: true }, () => {
            const { id, enqueueSnackbar, closeSnackbar } = this.props;
            const { selectedProjects, invoiceDetails } = this.state;
            const projects_ids = selectedProjects.map(p => p.id);

            const { bill_language, date_format } = this.state.invoiceDetails;
            const transl = new InvoiceTranslations().returnTranslations([bill_language]);

            const reportSettings = {
                title: transl[bill_language].invoicing_history,
                filename: "invoicing_history",
                headerDateMode: "day",
                dateFormat: date_format,
                hideTotals: {
                    "bill_id": true
                },
                hideLogo: this.context.taimerAccount.showLogo != 1,
                columns: [
                    {
                        key: "bill_id",
                        title: transl[bill_language].invoice_nr,
                    },
                    {
                        key: "deliverydate",
                        title: transl[bill_language].invoice_date,
                        formatDate: true,
                    },
                    {
                        key: "net_total",
                        title: transl[bill_language].total_no_vat,
                        align: "right",
                        formatCurrency: true,
                        currency: this.context.taimerAccount.currency,
                        countryCode: this.context.taimerAccount.numberFormat,
                        maxWidth: 100
                    },
                    {
                        key: "gross_total",
                        title: transl[bill_language].total,
                        align: "right",
                        formatCurrency: true,
                        currency: this.context.taimerAccount.currency,
                        countryCode: this.context.taimerAccount.numberFormat,
                        maxWidth: 100
                    },
                ],
                translations: {
                    totalTitle: transl[bill_language].total,
                    grandTotalTitle: transl[bill_language].grand_total
                }
            }
    
            this.createInvoicingHistorySnackbarKey = enqueueSnackbar(this.tr("Creating invoicing history PDF..."), {
                variant: "info",
            });
            DataHandler.post({ url: `invoices/invoicing_history_pdf_attachment` }, { reportSettings, id, perpage: 999999, day: moment().format("YYYY-MM-DD"), projects_ids } ).done(({ html, error }) => {
                if (error) {
                    enqueueSnackbar(this.tr("Failed to generate invoicing history PDF file."), {
                        variant: "error",
                    });
                    return;
                }
                if (html) {
                    const printWindow = window.open("", "PRINT");
                    printWindow.document.body.innerHTML = html;
                    printWindow.focus();
                }
                this.setState({ generatingInvoicingHistory: false });
                closeSnackbar(this.createInvoicingHistorySnackbarKey);
                enqueueSnackbar(this.tr("Invoicing history PDF added as attachment."), {
                    variant: "info",
                });
                this.attachments.current.updateComponentData();
            });
        });
    }

    editNote = (data, project, isExistingNote) => {
        const { userObject } = this.context;
        const params = { "message": data };
        const method = isExistingNote ? 'put' : 'post';
        if (isExistingNote)
            params.editor = userObject.usersId;
        else 
            params.creator = userObject.usersId;
        DataHandler[method]({url: `projects/${project}/note_to_invoicer`}, params).done((response) => {
            if (response && response.info && response.info == "too_long_for_netvisor") {
                this.props.enqueueSnackbar(this.tr("Maximum length of the comment in Netvisor is 500 characters."), {
                    variant: "warning" 
                });
            }
            
            const nd = [];
            this.state.noteData.forEach(note => {
                if(note.project_id == project) {
                    note.note = data;
                }
                nd.push(note);
            });
            this.setState({noteData: nd});
        });
    }

    onProjectMenuOpen = () => {
        this.setState({ projectMenuOpen: true });
    } 

    onProjectMenuClose = () => {
        this.setState({ projectMenuOpen: false });
    }

    onDraggedRowDrop = (droppedRow, onRow, currentOrder, dataMap, listRef) => {
        const dId        = Number(droppedRow.data.id);
        const onId       = typeof(onRow.data) === "object" ? Number(onRow.data.id) : null;
        const newOrder = [];
        const toMove   = [dId];
        let after      = false;

        if(onRow.lastInGroup) {
            after = true; 
        }

        const currentIndex = currentOrder.findIndex(id => id == dId);
        if (currentIndex != -1) {
            currentOrder.splice(currentIndex, 1);   
        }

        let cur;
        for(const i in currentOrder) {
            cur = currentOrder[i];

            if(cur == onId && !after) {
                toMove.forEach(id => newOrder.push(id))
            }

            newOrder.push(cur);

            if(cur == onId && after) {
                toMove.forEach(id => newOrder.push(id))            
            }
        }

        if(onRow.data.id === "BOTTOM_MARKER") {
            toMove.forEach(id => newOrder.push(id));
        }

        let rows = newOrder.map((id, i) => ({ ...dataMap[id], roworder: i }));
        rows = this.setTotalMarkers(rows);
        this.setState({ rows });
        return [rows];
    }

    setTotalMarkers = (rows) => {
        const filter = row => row.deleted != 1 && [-1, -2].indexOf(Number(row.row_type)) === -1;

        const visibleRows = rows.filter(filter);
        const deletedRows = rows.filter(r => !filter(r));

        const rowsWithMarkers = _.cloneDeep(visibleRows).map((row, i) => {
            let hasTotalMarker = false;

            if(i == visibleRows.length - 1) {
                hasTotalMarker = true; 
            } else if(i < visibleRows.length - 1) {
                const nextRow = visibleRows[i + 1];

                if(nextRow.row_category == 2 && (!nextRow.content_id || nextRow.content_id == 0)) {
                    hasTotalMarker = true;
                }
            } 

            return {
                ...row,
                total_marker: hasTotalMarker ? 1 : 0
            };
        });
        return [...rowsWithMarkers, ...deletedRows];
    }

    renderAttachmentButtons = () => {
        return (
            <div className="attachmentButtons">
                <LoaderButton loading={this.state.generatingHourReport} className="blue addHourReportButton" onClick={this.callInvoiceMaterialPdfDialog} size="large" text={this.tr('Add Hours report')} />
                <LoaderButton loading={this.state.generatingInvoicingHistory} className="blue addHourReportButton" onClick={this.getAndAttachInvoicingHistory} size="large" text={this.tr('Add invoicing history')} />
            </div>
        );
    }

    addressWarningIcon = (noAddress, p_customer_address_id, p_invoice_contact_person) => {
        const { selectedProjects, selectedBillingAddress, has_multi_address_relation } = this.state;
       
        let warningText = "";
        let showWarning = false;

        if (!has_multi_address_relation && (noAddress || p_customer_address_id != selectedProjects[0].customer_address_id)) {
            warningText = this.tr('Project has different invoicing address');
            showWarning = true;
        }

        if (p_invoice_contact_person !== selectedProjects[0].invoice_contact_person && p_invoice_contact_person !== selectedBillingAddress.contact) {
            if (showWarning) {
                warningText += ". ";
            }
            warningText += this.tr('Project has different contact person');
            if (showWarning) {
                warningText += ".";
            }
            showWarning = true;
        }

        if (showWarning) {
            return (
                <Tooltip placement="left" title={warningText}><ReportProblemRounded className="warning" /></Tooltip>
            );
        } 
        
        return (null); 
    }
    
    handleNoteDrawerOpen = () => {
        const {noteData, data, selectedProjects, account, company} = this.state;
        if (!account || _.isEmpty(account))
            return false;
        if (!selectedProjects || _.isEmpty(selectedProjects))
            return false;        
        if (noteData.length < 1 && (_.isEmpty(data) || data?.state < 2)) {
            const {dateFormat, usersId, fullName} = this.context.userObject;
            const {id, name, project_id} = selectedProjects[0];
            const defaultNoteData = {   
                project_id: id || null, 
                mainHeader: name ? name + " (" + project_id + ")" : '',
                mainHeaderAction: () => {
                    id && this.context.functions.updateView({
                        "module": "projects",
                        "action": "view",
                        "id": id,
                        "company": company,
                    }, true);
                },
                subHeader: account.name,
                note: '',
                creator: fullName,
                creator_id: usersId,
                created_date: moment().format(dateFormat),
                editor: fullName,
                editor_id: usersId,
                edited_date: moment().format(dateFormat),
            }

            this.setState({noteData: [defaultNoteData]},() => this.noteDrawer.current.open(this.state));
        } else
            this.noteDrawer.current.open(this.state);
    }

    renderMenuContent = (billLanguage, billingLanguages) => {
        const { userObject, functions: { checkPrivilege } } = this.context;
        const { classes } = this.props;
        
        const { data, invoiceData, startDate, endDate,
            accounts, projects, account, selectedProjects, selectedBillingAddress,
            editMode, invoiceType, company,
            companies, projectMenuOpen, selectedDetailsTab, companyCurrency, editEnabled, has_multi_address_relation} = this.state;

        const project_ids = selectedProjects.map(e => e.id);
        const projectMessages = selectedProjects.filter(p => p.comment).map(p => ({id: p.comment_id, label: `${p.project_id} ${p.comment}`}));

        const newInvoice = this.isNewInvoice();
        const invoicingAddresses = this.getInvoicingAddresses();

        switch (selectedDetailsTab) {
            case 'details':
                return (
                    <div className="invoice-details-container">
                        {companies.length > 1 && 
                            <OutlinedField 
                                label={this.tr("Company")} 
                                value={company} 
                                disabled={!newInvoice || !editEnabled} 
                                select 
                                onChange={e => this.setState({ company: e.target.value }, /*this.onCompanyChanged*/)}>
                        {companies.map(row => (
                            <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                        ))}
                        </OutlinedField>}
                        <DataList 
                            label={this.tr("Account")}
                            name="customers_id"
                            value={account}
                            inputBaseProps={{classes: {root: styles.datalistInputRoot}}}
                            options={accounts}
                            noOptions={invoiceType == '1' && AddAccount}
                            company={company}
                            companies_id={company}
                            onItemCreated={this.accountCreated}
                            menuMarginLeft={0}
                            accountCreated={this.accountCreated}
                            onChange={(account) => this.onAccountChanged(account)}
                            isDisabled={(editMode && editEnabled && (newInvoice || invoiceType == '1')) ? false : true}
                            shownCount={20}
                            multiLineValue={true}
                            key={invoiceType}/>                              
                        {invoiceType == '1' ? <React.Fragment>
                            <DataList 
                                label={this.tr("Projects")} 
                                name="project_id"
                                inputBaseProps={{classes: {root: styles.datalistInputRoot}}}
                                options={projects}
                                value={selectedProjects[0]}
                                onChange={this.singleProjectAdded}
                                isDisabled={(editMode && editEnabled) ? false : true}
                                multiLineValue={true}
                                shownCount={20}/>
                        </React.Fragment>
                            : 
                        <React.Fragment>
                            <DataList 
                                label={this.tr("Projects")} 
                                name="project_ids"
                                openMenuOnFocus={projectMenuOpen}   // There has to be a better way to achieve keeping the menu open,
                                autoFocus={projectMenuOpen}         // but because of all the rendering happening when selecting a project
                                onMenuOpen={this.onProjectMenuOpen} // nothing seems to work. This is the best I could come up with in this short timeframe.
                                onBlur={this.onProjectMenuClose}
                                inputBaseProps={{classes: {root: styles.datalistInputRoot}}}
                                options={account && projects.filter(e => e.customers_id === account.id && project_ids.indexOf(e.id) === -1 )}
                                onChange={this.projectAdded}
                                menuMarginLeft={0}
                                customOption={ProjectOption}
                                isDisabled={editMode && editEnabled && !has_multi_address_relation ? (this.context.addons.invoices_project_limit && selectedProjects.length > 0 ? true : false) : true}
                                disabledSubtext={"(" + this.tr("Different billing address") + ")"}
                                /* every selected project has to have same address selected */
                                isOptionDisabled={selectedProjects.length ? (option => option.customer_address_id !== selectedProjects[0].customer_address_id || option.invoicing_addresses?.length > 0) : undefined} 
                                shownCount={20}
                                />
                            <div className={styles.chips}>
                                { selectedProjects.map(p => (
                                    <Tooltip title={this.chipTitle(p)}>
                                    <Chip 
                                        icon={this.addressWarningIcon(!(p.customer_address_id-0), p.customer_address_id, p.invoice_contact_person)}
                                        classes={{root: styles.chip, label: styles.chipLabel, deleteIcon: (!p.notSaved ? styles.hidden : null)}} 
                                        name="selected projects" 
                                        value={p.id} 
                                        label={p.label} 
                                        key={p.id} 
                                        onDelete={(evt) => p.notSaved ? this.projectRemoved(p.id) : null} />
                                    </Tooltip>
                                ))}
                            </div>
                            <div className="projectmessages">
                                { projectMessages.map(pm => (
                                    <Chip
                                        classes={{root: styles.msChip, avatar: styles.msAvatar, label: styles.msLabel}}
                                        variant="outlined"
                                        avatar={<div><Message className={styles.msSvg} /></div>}                                        
                                        name="projectMessages" 
                                        value={pm.id} 
                                        label={pm.label} 
                                        key={pm.id} />
                                ))}
                            </div>
                        </React.Fragment>}
                        {['2','3'].includes(invoiceType) && <DateRangePicker
                            key={selectedProjects}
                            ranges={[{startDate: startDate, endDate: endDate}]}
                            onChange={this.handleRangeSelect}
                            onInputChange={this.handleRangeInputChange}
                            label={this.tr("Invoicing material period")}
                            dateFormat={userObject.dateFormat}
                            disabled={editMode && editEnabled && !has_multi_address_relation ? false : true}
                        />}
                        {this.renderInvoiceSettings(billLanguage, billingLanguages)}
                        {!newInvoice && 
                            <>
                            <div className='column-header'>
                                <Attachment /> {this.tr("Invoice Attachments")}
                            </div>
                            <TabAttachments
                            id={data.id}
                            ref={this.attachments}
                            editable
                            entity="invoices"
                            additionalContent={(invoiceType === '2' && invoiceData.invoice_data_rows) ? this.renderAttachmentButtons() : null}
                            /> 
                            </>
                        }
                    </div>
                );
                case 'log':
                    return <InvoiceLogView invoiceId={data.id} company={data.companies_id} currency={companyCurrency} />
                default:
                    return null;
        }
    }

    onDetailsTabChange = (event, selectedDetailsTab) => this.setState({ selectedDetailsTab });

    onOverLimit = (limit) => {
        this.props.enqueueSnackbar(this.tr("Field max length reached. Limit: ${limit}", {limit: limit}), { variant: "error", preventDuplicate: true });
    }

    emptyDisabledAddressFields = (eOperatorDisabled, eAddressDisabled) => {
        const billingAddress = _.cloneDeep(this.state.selectedBillingAddress);
        if (eOperatorDisabled) {
            billingAddress.e_operator = "";
        }
        if (eAddressDisabled) {
            billingAddress.e_address = ""; 
        }
        return billingAddress;
    }

    checkFieldNotTransferTooltip = () => {
        return this.isCompanyUsingMeritAktiva() ? this.tr("Does not transfer to accounting integration") : ""
    }


    onPrintInvoice = () => {
        this.context.functions.setOverlayComponent(
            <SendInvoiceWizard printMode={true} invoice={this.state.data} printLanguage={this.getSelectedBillLanguage()} printDateFormat={this.state.invoiceDetails.date_format} />
        );
    }

    onSendInvoice = () => {
        this.context.functions.setOverlayComponent(
            <SendInvoiceWizard invoice={this.state.data} onInvoiceSent={this.getInvoice} printLanguage={this.getSelectedBillLanguage()} printDateFormat={this.state.invoiceDetails.date_format} />
        );
    }

	callErrorHandleDialog = () => {
        const { data }= this.state;

		const dialogData = {
			handleError: (action) => this.handleIntegrationError(action),
            invoices: [data]
		}

        this.setState({ dialogData });
		this.openDialog('handleError');
	}

    handleIntegrationError = (action) => {
		switch(action) {
			case "edit_invoice":
				this.setIntegrationState(0, 1, action);
				break;
			case "send_again":
                this.setIntegrationState(0, 0, action);
				break;
			case "remove_error":
				this.setIntegrationState(1);
				break;
		  }
		
		this.closeDialog();
	}

    setIntegrationState = (integrationState, revertInvoices = 0, action = "") => {
		const params = { ids: [this.props.id], integrationState, revertInvoices };
        this.setUpdatingStatus(true);
		DataHandler.post({url: `invoices/set_integration_state`}, params).done(response => {

            this.context.functions.fetchNavigationNotificationData("invoices");
            this.setUpdatingStatus(false);

            const updatedIds = response.updated_ids;
            if (updatedIds.length < 1) {
                this.props.enqueueSnackbar(this.tr("Error in updating invoice"), {
                    variant: "error",
                });
                return;
            }

			const invoiceDetails = _.cloneDeep(this.state.invoiceDetails);
            const data = _.cloneDeep(this.state.data);

            invoiceDetails.integration_state = integrationState;

            if (revertInvoices == 1) {
                invoiceDetails.state = 1;
                data.state = 1;
            }

            let editMode = this.state.editMode;
            if (action == "edit_invoice") {
                editMode = true;
            }
            else if (action == "send_again") {
                setTimeout(() => {
                    this.sendInvoiceToIntegration("heeros", "Heeros");
                }, 1000);
            }

			this.setState({ invoiceDetails, data, editMode });
	    }).fail(err => {
			this.props.enqueueSnackbar(this.tr("Error in updating invoice"), {
				variant: "error",
			});
		});
	}

	sendInvoiceToIntegration = (func, analyticsKey = "") => {
		const { enqueueSnackbar, closeSnackbar } = this.props;
		const { company } = this.state;

        const sendInvoicesHelper = new SendInvoicesHelper({ enqueueSnackbar, closeSnackbar, updateData: this.updateInvoice });
        sendInvoicesHelper.send([this.props.id], company, func, analyticsKey);
	}

    updateInvoice = () => {
        setTimeout(() => {
            this.getInvoice(this.props.id, true);
        }, 1000);
    }

    setUpdatingStatus = (status) => {
		const message = this.tr("Updating invoice.");
		if (status == true) {
			const key = this.props.enqueueSnackbar(message, {
				variant: "info",
				persist: true
			});
			this.setState({updatingInProgress: status, snackBarKey: key});
		} else {
			this.props.closeSnackbar(this.state.snackBarKey);
			this.setState({updatingInProgress: status, snackBarKey: undefined});
		}
	}

    renderRightColumnNotifications = () => {
        const { account, selectedProjects, noteData, invoiceDetails } = this.state;
        const hideNotes = !account || _.isEmpty(account) || !selectedProjects || _.isEmpty(selectedProjects);
        const hasNotes = noteData.length > 0 && noteData[0].note !== '';
        const showWaitingForActions = invoiceDetails.integration_state == 2;

        return (
            <div data-testid="invoice_notifications_container" className={`notifications-container`}>
                {(!hideNotes && hasNotes ?
                    <span className={`notification warning ${!showWaitingForActions ? "one-notification" : ""}`}>
                        <NoteIcon />
                        {noteData.length + " " + this.tr("notes to invoice creator") + "."}
                        <span className="button" onClick={() => this.handleNoteDrawerOpen()}>{this.tr("View")}</span>
                    </span>
                    :
                    !hideNotes && <span className="blank-icon-button" onClick={() => this.handleNoteDrawerOpen()}><NoteIcon /><span className="button">{this.tr("Note to invoice creator")}</span></span>
                )}
                {showWaitingForActions && (
                    <span className={`notification serious ${hideNotes ? "one-notification" : ""}`}>
                        <WarningRounded />
                        {this.tr("Waiting for actions")}
                        <span className="button" onClick={() => this.callErrorHandleDialog()}>{this.tr("Handle error")}</span>
                    </span>
                )}
            </div>
        )
    }
    
    onAccountingSave = (data) => {
        const { originalRows, dimensionHeadersDiffer, dimensionHeaders } = data;
        const defaultDimensionValues = getDefaultDimensionValuesForHeaders(dimensionHeaders);
        this.defaultRow.dimension_values = defaultDimensionValues;
        this.setState({ dimensionHeadersDiffer,  dimensionHeaders, defaultDimensionValues, showAccounting: false }, () => !this.state.editMode && this.save(null, true, originalRows))
    }

    onAccountingClose = (data) => {
        this.setState({ showAccounting: false, rows: data.originalRows });
    }

    onAccountingRowsCopied = (accountingRows, callback = () => {}) => {
        const newRows = accountingRows.map(row => {
            if (row.sales_account) {
                const salesAccount = this.state.salesAccounts.find(s => s.id == row.sales_account);
                if (salesAccount && salesAccount.integration == "talenom") {
                    row['vat'] = Number(salesAccount.vat_value);
                    row = this.calculateRowValues(row, "vat");
                }
            }
            return row;
        });

        this.setState({rows: newRows}, () => callback && callback());
    }

    getProjectDimensions = () => {
        const { addons } = this.context;

        const { integrationSettings, dimensionHeaders, selectedProjects, company } = this.state;
        if (selectedProjects?.length < 1) {
            return [];
        }

        const projectDimensions = [];
        if (addons['procountor'] && addons['procountor'].used_by_companies.indexOf(company) > -1) {
            // Add project dimensions to array for each project id.
            for (const key in selectedProjects) {
                const dimension = getProcountorProjectDimension(selectedProjects[key]?.id, integrationSettings, dimensionHeaders);
                if (dimension) {
                    projectDimensions.push({ projectId: selectedProjects[key]?.id, dimension });
                }
            }
        }
        return projectDimensions;
    }

    addProjectDimensionToRows = (rows) => {
        const projectDimensions = this.getProjectDimensions();

        if (projectDimensions.length < 1) {
            return rows;
        }
   
        const rowProjectIds = this.getProjectIdsForInvoiceRows(rows);

        // Update dimension values to rows. Don't update if row has product default (type = 4).
        rows = updateProjectDimensionValueToRows(rows, projectDimensions, rowProjectIds, 4);
        return rows;
    }

    /**
     * Return project dimension for given row.
     * @param row row data.
     * @returns projectdimension or null
     */
    getProjectDimensionForRow = (row) => {
        const projectDimensions = this.getProjectDimensions();
        const rowProjectIds = this.getProjectIdsForInvoiceRows([row]);
        const projectId = rowProjectIds.find(p => p.rowId == row.id)?.projectId;

        if (projectId) {
            return projectDimensions.find(p => p.projectId == projectId)?.dimension;
        }
        return null;
    }

    getProjectIdsForInvoiceRows = (invoiceRows) => {
        const { selectedProjects, rows } = this.state;

        return getProjectIdsForInvoiceRows(
            invoiceRows, 
            rows, 
            selectedProjects, 
            this.isMaterialInvoice()
        );
    }

    renderAccountingButton = () => {
        const { dimensionHeadersDiffer } = this.state;
        const editable = !this.isEditingDisabled();

        return <AccountingButton
            dimensionHeadersDiffer={dimensionHeadersDiffer}
            editable={editable}
            text={this.tr('Show accounting')}
            icon={BookkeepingIcon}
            classname={styles.invoiceButton}
            openAccountingSlider={() => this.openAccountingSlider()}
        />
    }

    isMultiInvoiceProject = () => {
        const { selectedProjects } = this.state;
        return selectedProjects && selectedProjects[0]?.invoicing_addresses?.length > 0;
    }

    getAddressRelatedFieldDisabledTooltip = () => {
        return this.tr("Project has multiple addresses selected, address related data is added from address to created invoices.");
    }

    getInvoicingAddresses = () => {
        const { selectedProjects } = this.state;

        if ((selectedProjects?.length || []) < 1) {
            return [];
        }
        return selectedProjects[0]?.invoicing_addresses || [];
    }

    render () {
        const { taimerAccount, taimerAccount: { showState }, userObject, functions: { checkPrivilege }, addons } = this.context;

        const { data, rows, descriptionRows, projectData, invoiceData, invoiceDetails, notes,
            accounts, shippingAddresses, companyAddressData,
            account, selectedProjects, selectedShippingAddress, invoiceDefaultDetails,
            editMode, invoiceType, currentDialog, dialogData, useRecipient, 
            company, noPermission, companyCurrency, customerreference, saveInProgress, show_agreement_order_identifier, 
            fieldLimits, showNotes, addressFieldErrors, overLimitRows, editEnabled, activeCurrencies, countryFieldInfoTooltip, usesAccounting, showAccounting, savedSenderAddress, descriptionErrorRows, invalidDescriptionRows, overLimitDescriptionRows, selectedVatCode,
            has_multi_address_relation, draftSaveInProgress } = this.state;

        if (noPermission)
            return <NoPermissionOverlay />

        const fieldNotTransferTooltip = this.tr("Does not transfer to accounting integration");
        const eOperatorDisabled = this.isFieldHidden("e_operator");
        const eAddressDisabled  = this.isFieldHidden("e_address");
        const selectedBillingAddress = this.emptyDisabledAddressFields(eOperatorDisabled, eAddressDisabled);

        let billingAddresses = this.state.billingAddresses ? this.state.billingAddresses.map(addr => {
            addr.value = addr.id;
            return addr;
        }) : undefined;

        if (selectedProjects.length > 0) {
            billingAddresses = billingAddresses.filter(a => (a.deleted == 0 || selectedProjects[0].customer_address_id == a.id));
        }
        const addressRelatedDataDisabled = this.isMultiInvoiceProject();
        const addressRelatedFielddisabledTooltip = this.getAddressRelatedFieldDisabledTooltip();

        const projectDetails = { ...data, ...projectData };

        const currentInvoiceDetails = {...invoiceDefaultDetails, ...data, ...invoiceDetails };
        const invoiceRows = _.cloneDeep(rows);
        
        const contextButton = {
            className: 'option-menu-button',
            stickyIcon: true,
        }


        const strippedAddressLabel = (address) => {
            const mod = {...address};
            mod.label = address.label.substring(0, address.label.indexOf(','));
            return mod;
        };

        const columnConfig = { showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false };
        const rowColumns = [
            { name: "context", header: "", width: 15, ...columnConfig },
            ...(editMode && !has_multi_address_relation ? [{ name: "move", header: "", width: 15, ...columnConfig }] : []),
            { name: "description", header: this.tr("Description"), width: 100, ...columnConfig },
            { name: "product", header: '', width: 80, ...columnConfig },
            { name: "quantity", header: this.tr("Qty"), width: 30, ...columnConfig },
            { name: "value", header: this.tr('Unit Price'), width: 50,  ...columnConfig },
            { name: "total_no_vat", header: this.tr("Tot. 0%"), total: true, width: 50, ...columnConfig },
            { name: "vat", header: this.tr("Vat %"), width: this.isCompanyUsingRowSpecificVatcodes() ? 50 : 30, ...columnConfig },
            { name: "total", header: this.tr("Total"), width: 60, total: true, ...columnConfig },
            ...(usesAccounting ? [{ name: "accounting", header: "", width: 15, ...columnConfig, alignRight: true }] : []),
        ];

        const currencySymbol = CurrencyUtils.getSymbol(invoiceDetails.currency_code);

        const rowProps = {
            currency: companyCurrency,
            selectedCurrency: invoiceDetails.currency_code,
            symbol: currencySymbol,
            editMode: editMode && !has_multi_address_relation,
            descriptionRows: descriptionRows,
            type: data.type,
            countSectionTotals: this.countSectionTotals,
            addRow: this.addRow,
            addProductRow: this.addProductRow,
            addCPQRow: this.addCPQRow,
            addHeaderRow: this.addHeaderRow,
            editRow: this.editRow,
            editDescriptionRow: this.editDescriptionRow,
            deleteRow: this.deleteRow,
            addDescriptionRow: this.addDescriptionRow,
            products: this.state.products,
            accountingProducts: this.state.accountingProducts,
            productRegisterProducts: this.state.productRegisterProducts,
            CPQParents: this.state.CPQParents,
            editCPQ: this.editCPQ,
            salesAccounts: this.state.salesAccounts,
            salesVatCodes: this.state.salesVatCodes,
            productRowSettings: this.state.productRowSettings,
            currency_rate: parseFloat(invoiceDetails.currency_rate),
            hasOldCurrencyConvert: this.hasOldCurrencyConvert(),
            useVatCodes: this.isCompanyUsingVatCodes(),
            useRowSpecificCatcodes: this.isCompanyUsingRowSpecificVatcodes(),
            selectedVatCode: selectedVatCode,
            defaultVatcode: this.getDefaultVatcode(),
            talenomActive: this.isCompanyUsingTalenom(),
            reverseVat: this.state.invoiceDetails.reverse_vat == 1,
            emptyVatcodeSelected: this.emptyVatcodeSelected(),
            itemDescriptionLimit: fieldLimits?.rowItemDescription,
            descriptionRowLimit: fieldLimits?.descriptionRowDescription,
            onOverLimit: this.onOverLimit,
            overLimitRows,
            overLimitDescriptionRows,
            descriptionErrorRows,
            invalidDescriptionRows,
            renderRowAccountingCell: this.renderRowAccountingCell,
        };

        const totalRows = [];

        totalRows.push({ grey: true, name: this.tr("SUBTOTAL"), sum: this.countTotals('total_no_vat') });

        const vats = CurrencyUtils.calculateVats(rows);
        let totalVat = 0;
        const vatsAmount = Object.keys(vats)?.length || 0;
        if (vatsAmount) {
            for (const [vat, value] of Object.entries(vats).sort((a, b) => parseFloat(a[0]) - parseFloat(b[0]))) {
                totalVat += value;
                const percentage = formatInputNumber(vat, 'no-zero-decimals');
                if (vatsAmount == 1) {
                    totalRows.push({grey: true, name: this.tr("Vat") + ` ${percentage} %` , sum: (value).toFixed(2)});
                } else {
                    totalRows.push({greyvat: true, name: this.tr("Vat") + ` ${percentage} %` , sum: (value).toFixed(2)});
                }
            }
        }

        //add vat totals only if more than 1 different vat %
        if (vatsAmount !== 1) {
            totalRows.push({ grey: true, name: this.tr("VAT TOTAL"), sum: totalVat.toFixed(2) });
        }

        totalRows.push({ black: true, name: this.tr("Total"), sum: this.countTotals('total') });

        const noteDrawerProps = {
            title: this.tr("Note to invoice creator"),
            noteProps: {
                onCtrlS: this.editNote,
                actions: ["creatorChat", "projectChat"],
                unEditable: data?.state > 1
            },
            noteData: this.state.noteData
        }

        const Dialog = currentDialog ? this.dialogs[currentDialog] : undefined;

        function naviProps(dir, self) {
            return {
                className: self[dir] > 0 ? styles.pointer : null,
                onClick: self[dir] > 0 ? (() => self.navigate(self[dir])) : null,
                style: self[dir] < 1 ? null : {color: '#2d9ff7'}
            } 
        }

        const usMode = showState;   

        const billingLanguages = this.state.billingLanguages.map(e => {return {...e, label: this.tr(e.label)}});
        const billLanguage = this.getSelectedBillLanguage();
        rowProps.billLanguage = billLanguage;

        let editRights = false;
        if ((checkPrivilege("invoices", "write_full", company) || (checkPrivilege("invoices", "write_simple", company) && (data.users_id == userObject.usersId || (selectedProjects && selectedProjects.length == selectedProjects.filter((project) => project.project_manager == userObject.usersId).length))))) {
            editRights = true;
        }

        const limitAddressFieldProps = {
            editorType: TextFieldWithLimit, 
            onOverLimit: this.onOverLimit,
            hideLimit: !editMode, 
            showLimitOnlyWhenOver: true, 
            limitBesideField: true,
            onChange: e => this.changeBillingAddressValue(e),
            onBlur: e => this.modifyBillingAddress(e.target || e)
        }

        const newInvoice = this.isNewInvoice();
        const editDisabled = saveInProgress || !editEnabled;

        const accountingRows = (rows || []).filter(r => r['row_category'] != 2 && r['row_category'] != 5 && !(r['row_type'] < 0));
        const hasAccountingRows = (accountingRows || []).length > 0;
        let rowStyle = this.isCompanyUsingRowSpecificVatcodes() ? "increased_row_height" : "";
        if (this.isCompanyUsingAccounting()) {
            rowStyle += " has_accounting_cell"
        }

        const invoicingAddresses = this.getInvoicingAddresses();

        return (
            <div data-testid={editDisabled ? "invoice-edit-disabled" : "invoice-edit-enabled"} ref={container => this.invoiceView = container}  id="Invoice-view" className={styles.container}>
                <div className={`${styles.leftColumn} left-column ${rowStyle}`}>
                    <div className={styles.buttonContainer}>
                        <div className={styles.invoiceId} onClick={this.backToList}>
                            <NavigateBefore style={{cursor: 'pointer'}}/>
                            {this.tr('Back to list')}
                        </div> 
                        <div className="navigation" >
                            {!this.isNewInvoice() && <React.Fragment>
                                {/* <NavigateBefore {...naviProps('previousInvoiceId', this)} /> */}
                                <StatusTag text={this.defineStatus()?.name} color={this.defineStatus()?.color} />
                                <Typography className={styles.invoiceId} variant="h6" data-testid="invoice_id_field">{this.invoiceTypes[invoiceDetails.type]} {this.tr('no.:')} {data.bill_id > 0 ? data.bill_id : currentInvoiceDetails.bill_id} </Typography>
                                {/* <NavigateNext {...naviProps('nextInvoiceId', this)} />  */}
                            </React.Fragment>}
                        </div>
                        <div className="btns">
                            {!editMode && <DropdownMenuButton label={this.tr('Options')} items={[
                                {
                                    label: this.tr('New invoice'),
                                    icon: <AddCircleOutlined />,
                                    action: this.addNew
                                },
                                {
                                    label: this.tr(' Save as invoice '),
                                    icon: <AddCircleOutlined />,
                                    action: () => setTimeout(this.save, 100),
                                    disabled: saveInProgress || !editEnabled,
                                    visible: data.bill_type == "2"
                                },
                                {
                                    label: this.tr('Send by email'),
                                    icon: <Email />,
                                    action: this.onSendInvoice,
                                },
                                {
                                    label: this.tr('Print'),
                                    icon: <Print />,
                                    action: this.onPrintInvoice,
                                    disabled: !editEnabled
                                },
                            ]} />}
                            {editMode && <Button color="cancel" onClick={this.cancel} size="large">{this.tr('Cancel')}</Button>}
                            {editMode && (newInvoice || data.bill_type > 1) && <LoaderButton data-testid="save_pre-invoice_button" text={this.tr('Save as pre-invoice')} loading={draftSaveInProgress} disabled={saveInProgress || !editEnabled} className='blue' onClick={()=>setTimeout(() => this.save(true), 100)} size="large" progressPadding={3} />}
                            {editMode && <LoaderButton data-testid="save_invoice_button" text={(newInvoice || data.bill_type > 1) ? this.tr('Save as invoice') : this.tr('Save')} loading={!draftSaveInProgress && saveInProgress} disabled={saveInProgress || !editEnabled} onClick={() => setTimeout(this.save, 100)} className="blue" size="large" progressPadding={3} />}
                            {!editMode && editRights && (invoiceType === '2' || data.state < 2) && <Button data-testid="edit_invoice_button" disabled={!editEnabled} onClick={data.state < 2 ? this.edit : this.callInvoiceMaterialDialog} className="blue" size="large">{this.tr('Edit')}</Button>}
                            {false && editMode && !newInvoice && invoiceType === '2' && data.bill_type < 2 && <Button className="blue" onClick={this.addProject} size="large">{this.tr('Add project')}</Button>}

                            {false &&!editMode && <ContextMenu buttonProps={contextButton} variant="outlined" className="option-menu" label="Options" size="large" placement={"bottom-start"}>
                                <MenuItem onClick={() => this.sendmenuthing()}>...</MenuItem>
                            </ContextMenu>}
                        </div>
                    </div>
                    <div ref={this.container} style={{paddingRight: "24px"}}>
                        <Paper {...this.props.paperProps} container={this.container } style={{ paddingBottom: "200px" }}>
                            <div className="paper-header">
                                {taimerAccount.showLogo == 1 ? <Logo editMode={editMode} company={company} /> : <div></div>}
                                <CompanyAddress
                                    key={_.size((!newInvoice && !editMode && savedSenderAddress?.name) ? savedSenderAddress : companyAddressData)}
                                    company={company}
                                    data={(!newInvoice && !editMode && savedSenderAddress?.name) ? savedSenderAddress : companyAddressData}
                                    editMode={editMode}
                                    inputProps={{classes: {input: styles.alignRight}}}
                                    requireValidEmail
                                    onEmailValidityChange={valid => this.companyEmailValid = valid}
                                    onChange={this.onCompanyaddressChange} >
                                </CompanyAddress>
                                <div variant="h5" className={styles.headerText}>
                                    {this.tr('Invoice')}
                                    {(data.bill_id > 0 || currentInvoiceDetails.bill_id) && <span className={styles.headerNumber}>{this.tr('No.')} {data.bill_id > 0 ? data.bill_id : currentInvoiceDetails.bill_id} </span>}
                                </div>
                                <div className={styles.billingRequisites} >
                                    {invoicingAddresses?.length > 0 && newInvoice
                                    ? this.renderInvoicingAddresses()
                                    : ( 
                                    !account || !account.id ? 
                                    <div className="datalist-container">
                                        <DataList 
                                            className="billing-address-datalist"
                                            label={this.tr("Account")}
                                            name="customers_id"
                                            data-testid="datalist_paper_customers_id"
                                            value={account}
                                            inputBaseProps={{classes: {root: styles.datalistInputRoot}}}
                                            options={accounts}
                                            noOptions={invoiceType === '1' && AddAccount}
                                            noOptionsProps={{company: company}}
                                            company={company}
                                            accountCreated={this.accountCreated}
                                            onChange={(account) => this.onAccountChanged(account)}
                                            companies_id={company}
                                            onItemCreated={this.accountCreated}
                                            isDisabled={(newInvoice && editMode) ? false : true} 
                                            shownCount={20}
                                        /> 
                                    </div>
                                    :                                    
                                    <LabelFieldGroup
                                        editMode={editMode}
                                        title={this.tr("Billing address")}
                                        useEmptyStringValue
                                        values={selectedBillingAddress}
                                        errorsInFieldrowss={this.state.customerEmailValid ? [] : ["email"]}
                                        errorsInFields={addressFieldErrors}
                                        fields={[
                                            { label: this.tr("Customer"),
                                                name: "billing_address",
                                                editorType: CreatableSelect, 
                                                options: billingAddresses,
                                                value: selectedBillingAddress && selectedBillingAddress.id && strippedAddressLabel(selectedBillingAddress), 
                                                _onChange: v => this.billingAddressSet(v), get onChange() { return this._onChange; }, set onChange(value) { this._onChange = value; }, },                                   
                                            { label: this.tr("Address"), "data-testid": "address", name: "address", value: undefined,  limit: fieldLimits?.address, ...limitAddressFieldProps },
                                            addons && addons.fortnox && addons.fortnox.used_by_companies.indexOf(company) > -1 && { label: this.tr("Address 2"), name: "address_2", value: undefined, onChange: e => this.modifyBillingAddress(e) },
                                            usMode && { label: this.tr("City"), "data-testid": "city", name: "city", value: undefined,  limit: fieldLimits?.city, ...limitAddressFieldProps},
                                            usMode && { label: this.tr("State"), name: "state", value: undefined, onChange: e => this.modifyBillingAddress(e)},
                                            { label: this.tr("Zip code"), "data-testid": "postal_code", name: "postalcode", value: undefined,  limit: fieldLimits?.postalcode, ...limitAddressFieldProps},
                                            !usMode && { label: this.tr("City"),  "data-testid": "city", name: "city", value: undefined,  limit: fieldLimits?.city, ...limitAddressFieldProps},
                                            { label: this.tr("Country"), "data-testid": "billing_address_country", name: "country", value: undefined, limit: fieldLimits?.country, ...limitAddressFieldProps, infoTooltip: editMode ? countryFieldInfoTooltip : undefined},
                                            { label: this.tr("Business id"), name: "vatid", value: undefined,  limit: fieldLimits?.vatid, ...limitAddressFieldProps },   
                                            addons && ((addons.fortnox && addons.fortnox.used_by_companies.indexOf(company) > -1) || (addons.meritaktiva && addons.meritaktiva.used_by_companies.indexOf(company) > -1)) && { label: this.tr("Vat no."), name: "vatid_2", value: undefined,  limit: fieldLimits?.vatid_2, ...limitAddressFieldProps},                            
                                            { label: this.tr("E-invoice operator"), name: "e_operator", value: undefined, onChange: e => this.modifyBillingAddress({name: "e_operator", value: e.target.value}, true), title: eOperatorDisabled ? fieldNotTransferTooltip : "", 'aria-label': eOperatorDisabled ? fieldNotTransferTooltip : "", disabled: eOperatorDisabled },
                                            { label: this.tr("E-invoice address"), name: "e_address", value: undefined, onChange: e => this.modifyBillingAddress({name: "e_address", value: e.target.value}, true), title: eAddressDisabled ? fieldNotTransferTooltip : "", 'aria-label': eAddressDisabled ? fieldNotTransferTooltip : "", disabled: eAddressDisabled },
                                            { label: this.tr("Contact"), name: "contact", value: undefined, onBlur: e => this.modifyBillingAddressContact(e), limit: fieldLimits?.contact, ...limitAddressFieldProps },
                                            { label: this.tr("Email"), name: "email", value: undefined, limit: fieldLimits?.customer_email, ...limitAddressFieldProps },
                                            ...(addons && addons.invoice_currency ? [{ label: this.tr("Currency"), 
                                                name: "currency", 
                                                editorType: Select, 
                                                value: selectedBillingAddress?.currency || (selectedBillingAddress?.id && this.state.companyCurrency), 
                                                options: activeCurrencies, 
                                                onChange: c => this.modifyBillingAddress({name: 'currency', value: c.id}) }] : []),
                                            ...(false && addons?.invoice_language && this.state.billingLanguages.length > 1 ? [{ label: this.tr("Invoice language"), 
                                                name: "bill_language", 
                                                editorType: Select, 
                                                value: billLanguage, 
                                                options:billingLanguages, 
                                                onChange: c => this.modifyBillingAddress({name: 'bill_language', value: c.id}) }] : []),
                                            ...(addons?.nav == undefined ? [{ label: this.tr("Reverse charge"), 
                                                name: "reverse_vat", 
                                                editorType: Select, 
                                                value: selectedBillingAddress?.reverse_charge == '' ? this.state.invoiceDetails.reverse_vat : selectedBillingAddress?.reverse_charge,
                                                options: this.reverseChargeFields, 
                                                onChange: c => this.modifyBillingAddress({name: 'reverse_charge', value: c.id}) }] : [])                                                                                                                               
                                        ]} />)}
                                    {false && account && account.id ? <LabelFieldGroup
                                        editMode={editMode}
                                        title="Shipping address"
                                        headerIcon={editMode ? (useRecipient ? <RemoveIcon /> : <AddIcon />) : null}
                                        onHeaderClick={editMode ? this.toggleShippingAddress : null}
                                        values={selectedShippingAddress}
                                        fields={useRecipient ? this.shippingAddressFields(shippingAddresses, selectedShippingAddress) : []} /> : <div style={{flex: "1 1 0px"}} />}
                                    <LabelFieldGroup
                                        className="invoice-details"
                                        editMode={editMode}
                                        values={currentInvoiceDetails}
                                        fields={[
                                            { label: this.tr("Date"), 
                                                name: "creationdate", 
                                                date: Utils.localDate(currentInvoiceDetails.creationdate),
                                                editorType: DatePicker, 
                                                dateFormat: userObject.dateFormat, 
                                                onChange: date => this.setCreationdate(date),
                                                onInputChange: (type, date) => !!date && this.setCreationdate(date)},
                                            !this.isFieldHidden("delivery_date") && { label: this.tr("Delivery date"), 
                                                name: "creationdate", 
                                                date: Utils.localDate(currentInvoiceDetails.deliverydate), 
                                                editorType: DatePicker, 
                                                dateFormat: userObject.dateFormat, 
                                                onChange: date => this.setDeliverydate(date),
                                                onInputChange: (type, date) => !!date && this.setDeliverydate(date)},
                                            { label: this.tr("Terms of payment"), 
                                                "data-testid": "invoice_terms",
                                                className: "lbf-terms", 
                                                adornment: this.tr('days'), 
                                                adornmentPos: 'end', 
                                                name: "terms", 
                                                value: undefined, 
                                                disabled: addressRelatedDataDisabled,
                                                disabledTooltip: addressRelatedFielddisabledTooltip,
                                                onChange: e => this.onTermsChange(e) },
                                            { label: this.tr("Due date"), 
                                                name: "duedate", 
                                                date: Utils.localDate(currentInvoiceDetails.duedate), 
                                                editorType: DatePicker, 
                                                dateFormat: userObject.dateFormat, 
                                                disabled: addressRelatedDataDisabled,
                                                disabledTooltip: addressRelatedFielddisabledTooltip,
                                                onChange: date => this.setDuedate(date),
                                                onInputChange: (type, date) => !!date && this.setDuedate(date)},
                                            !this.isFieldHidden("notice_period") && { label: this.tr("Notice period"), disabled: addressRelatedDataDisabled, disabledTooltip: addressRelatedFielddisabledTooltip, "data-testid": "notice_period", className: "lbf-notice", adornment: this.tr('days'), adornmentPos: 'end', name: "annotation", value: undefined, onChange: e => this.onInvoiceDetailChange(e) },                          
                                            { label: this.tr("Interest rate"), disabled: addressRelatedDataDisabled, disabledTooltip: addressRelatedFielddisabledTooltip, "data-testid": "interest_rate", className: "lbf-interest-rate", adornment: '%', adornmentPos: 'end', name: "annotationinterest", value: formatInputNumber(this.state.invoiceDetails.annotationinterest), onChange: e => this.onInvoiceDetailChange(e) },
                                            { label: this.tr("Payment reference"), className: (editMode || newInvoice) ? "limit-textfield" : "", name: "reference", editorType: TextFieldWithLimit, limit: fieldLimits?.reference, hideLimit: !editMode && !newInvoice, value: invoiceDetails?.reference, onChange: e => this.onInvoiceDetailChange(e) },
                                            { label: this.tr("Your reference"), className: (editMode || newInvoice) ? "limit-textfield" : "", name: "customerreference", editorType: TextFieldWithLimit, limit: fieldLimits?.customerreference, hideLimit: !editMode && !newInvoice, value: customerreference, onChange: e => this.onInvoiceDetailChange(e) },
                                             show_agreement_order_identifier && !this.isFieldHidden("agreement_identifier") && { label: this.tr("Agreement identifier"), name: "agreement_identifier", value:this.state.agreement_identifier,  onChange: e => this.onInvoiceDetailChange(e) },
                                             show_agreement_order_identifier && !this.isFieldHidden("order_identifier") && { label: this.tr("Order identifier"), name: "order_identifier", value: this.state.order_identifier, onChange: e => this.onInvoiceDetailChange(e) }                                                                            
                                        ]} />                                    
                                </div>  
                                <div style={editEnabled ? {display:'none'} : {}} className="loading-indicator"><img src={require('../dashboard/insights/img/loading.svg').default} /></div>                            
                            </div>
                            <div className={styles.divider}/>
                            <div style={editEnabled ? {display:'none'} : {}} className="loading-indicator"></div> 
                            <div className={styles.invoiceButtonContainer} >
                                {has_multi_address_relation && editMode && <ErrorsList 
                                    className={styles.multi_address_editing_info}
                                    type={"warning"}
                                    typeClass={"warning-light"}
                                    hideHeader={true}
                                    data={[{
                                        header: "",
                                        message: this.tr("Editing this invoice is disabled because it's one part of a split invoice.")
                                    }]}
                                />}
                                {['2','3'].includes(invoiceType) && editMode && invoiceData.invoice_data_rows && 
                                    <>
                                    <div className={styles.invoiceButton} onClick={() => this.callInvoiceMaterialDialog()}>
                                        <span className="add-icon"><Assignment /></span>
                                        {this.tr('Invoice material')}
                                    </div>
                                    {false && <div className={styles.invoiceButton} onClick={() => this.callInvoiceMaterialDialog()}>
                                        <span className="add-icon"><Assignment /></span>
                                        {this.tr('Invoice workhours')}
                                    </div>
                                    }            
                                    </>
                                }
                                {usesAccounting && hasAccountingRows && (
                                    this.renderAccountingButton()
                                )}   
                            </div>
                            <div className={styles.paperContent}>
                                <List
                                    fluid
                                    height="auto"
                                    rowHeight={this.isCompanyUsingRowSpecificVatcodes() ? 36 + 6 : 26 + 6}
                                    ref={this.list}
                                    reverseNewData={true}
                                    listRowType={InvoiceRow}
                                    data={Array.isArray(rows) ? _.cloneDeep(rows) : []}
                                    rowProps={rowProps}
                                    noColorVariance={true}
                                    rowDragging={true}
                                    onDraggedRowDrop={this.onDraggedRowDrop}
                                    hideBottomMarker={false} 
                                    ignoreRowPropsChange={false}
                                    columns={rowColumns} />
                            </div>
                            {this.state.editMode && this.state.invoiceDetails.already_invoiced_total_row > 0 && invoiceDetails?.already_invoiced_total_row_text && invoiceDetails.already_invoiced_total_row_text?.length > 0 && <div className={styles.alreadyInvoicedRow}>{invoiceDetails.already_invoiced_total_row_text}</div>}
                            <div className="footer-area">
                                <Footer>
                                    <FlexChild weight={5} style={{ paddingRight: "32px" }}>
                                        {showNotes && <TextFieldWithLimit
                                            limit={fieldLimits?.notes}
                                            multiline
                                            disabled={!editMode}
                                            placeholder={this.tr("invoice notes")}
                                            className={"textarea"}
                                            containerClass="notes-container"
                                            value={notes}
                                            inputProps={{
                                                onFocus: (e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }
                                            }}
                                            onChange={e => this.setState({ notes: e.target.value })} />}
                                    </FlexChild>
                                    <FlexChild weight={3}>
                                        <InvoiceTotals
                                            className={styles.totals}
                                            rows={totalRows}
                                            currency={invoiceDetails.currency_code}
                                            symbol={currencySymbol}
                                            currency_rate={invoiceDetails.currency_rate} />
                                    </FlexChild>
                                </Footer>                          
                            </div>
                            {data.referencenumber && <div className={styles.referencenumber}>{this.tr('Reference number:')} {data.referencenumber} </div>}
                            <div className={styles.divider}/>
                            <div className={`${styles.footer} paper-footer`}>
                                <InvoiceFooter
                                    editMode={editMode}
                                    ref={this.footer}
                                    company={company}
                                    key={_.size((!newInvoice && !editMode && savedSenderAddress?.name) ? savedSenderAddress : companyAddressData)}
                                    addressData={(!newInvoice && !editMode && savedSenderAddress?.name) ? savedSenderAddress : companyAddressData}
                                    primary={this.state.bankaccount}
                                    secondary={data.bankaccounts_id_2}
                                    onAddressChange={this.onCompanyaddressChange}
                                    updateData={this.updateData}
                                    bankData={this.state.bankAccounts}
                                    settings={this.state.invoiceSettings} >
                                </InvoiceFooter>
                            </div>
                        </Paper>
                    </div>                    
                </div>

                <div className={`${styles.rightColumn} right-column`}>
                    {this.renderRightColumnNotifications()}
                    <div className={styles.menuContainer}>
                        <EntityCardTabs tabs={this.detailsTabs} onTabChange={this.onDetailsTabChange} selectedTab={this.state.selectedDetailsTab} />
                        <div className={styles.menuContentContainer}>
                            {this.renderMenuContent(billLanguage, billingLanguages)}
                        </div>
                    </div>
                </div>
                {Dialog && <Dialog
                    open
                    invoiceDataLoading={this.state.invoiceDataLoading}
                    onDialogClose={this.closeDialog}
                    onDialogSave={this.saveDialog}
                    data={dialogData}
                    ref={this.invoiceMaterial} />}                
                
                <NoteDrawer ref={this.noteDrawer} {...noteDrawerProps} />
                {this.state.contactPersonSaveDialogOpen && <ConfirmationDialog
                                            cancelText = {this.tr("No")}
                                            okText = {this.tr("Yes")}
                                            data={{
                                                text: this.tr("Do you want to save contact person to invoiced projects?")
                                             }}
                                            onDialogSave={async () => {
                                                this.saveBillingAddressContacts();
                                            }}
                                            onDialogClose={() => this.setState({ 
                                                contactPersonSaveDialogOpen: false 
                                            })}
                                        />}
                {showAccounting && <AccountingSlider
                    type="sales_invoices"
                    invoiceRows={invoiceRows}
                    dataRows={invoiceRows}
                    onSave={(data) => this.onAccountingSave(data)}
                    accountingData={this.state}                  
                    company={company}
                    open={true}
                    onClose={(data) => this.onAccountingClose(data)}
                    currency={invoiceDetails.currency_code}
                    isNewInvoice={this.isNewInvoice()}
                    editingDisabled={this.isEditingDisabled()}
                    editRow={this.editRow}
                    onRowsCopied={(rows, callback) => this.onAccountingRowsCopied(rows, callback)}
                    module={"InvoiceView"}
                />}
            </div>
        );
    }
}

InvoiceView.defaultProps = {
    enqueueSnackbar: PropTypes.func.isRequired
};

export default withSnackbar(InvoiceView);


/* css */
import './TaimerNavi.scss';

import withStyles from '@mui/styles/withStyles';

/* others */
import cn from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import TaimerComponent from "../TaimerComponent";
import ResourceDialog from "../dialogs/ResourceDialog";
import TaimerAvatar from '../general/TaimerAvatar';
import UpgradeButton from '../general/UpgradeButton';
import { SettingsContext } from './../SettingsContext';
import DataHandler from './../general/DataHandler';
import DevOptions from './DevOptions';
import WorkhourTimer from './WorkhourTimer';

import Link from '../general/Link';
import { ActionMenuButton, ActionsMenu, ReportMenu, UserMenu } from './ActionMenu';
import MenuGroup from './SidebarGroup';

import { 
    AddCircleOutline, 
    ArrowBack, 
    BuildOutlined, 
    ChatOutlined, 
    DescriptionOutlined, 
    KeyboardArrowRight, 
    MenuRounded, 
    NotificationsOutlined, 
    SettingsOutlined, 
    Star, 
    WarningRounded,
    Search,
} from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import Badge from '@mui/material/Badge';
import moment from 'moment';
import VersionContentManager from '../general/VersionContentManager';
import PreviewList from './PreviewList';
import customStyles from './TaimerNavi.module.scss';

import HelpIcon from '@mui/icons-material/HelpOutline';
import Lottie from 'react-lottie';
import TrialDialog from '../dialogs/TrialDialog';

const heerosLogo = require('./heerosLogo.svg').default;
const psaLogo = require('./psa_logo.svg').default;
const confetti = require('../general/animations/confettiAnimation.json');

const styles = theme => ({
    tooltip: {
        background: "#3a455b",
    },
});

class OnboardingIndicator extends TaimerComponent {
    constructor(props, context) {
        super(props, context, 'navigation/OnboardingIndicator');
        this.state = {
            playAnimation: false,
        }
    }

    componentDidMount = () => {
        super.componentDidMount();
        window.addEventListener(`onboardingCompleted_quote`, this.onQuoteCompleted);
    };

    componentWillUnmount = () => {
        super.componentWillUnmount();
        window.removeEventListener(`onboardingCompleted_quote`, this.onQuoteCompleted);
    };

    onQuoteCompleted = () => {
        if (this.props.module == "dashboard") return;
        this.setState({ playAnimation: true }, () => {
            setTimeout(() => {
                this.setState({ playAnimation: false });
            }, 5000);
        });
    };

    render() {
        const { userObject: { completedOnboardingItems, onboardingStatus }, functions: { showOnboardingSlider } } = this.context;
        const { playAnimation } = this.state;
        const onboardingItems = VersionContentManager.getOnboardingItems();
        const isOdin = window.location.href.indexOf(':8080') > -1;
        if ((this.context.taimerAccount.onboardingSeen == 1 && !isOdin) || !this.context.addons.new_stripe || onboardingItems.length == 0 || onboardingStatus > 1) return null;
        const completedItems = (completedOnboardingItems || []).length;
        return (
            <div className={customStyles.onboardingIndicatorContainer}>
                <span className={customStyles.trialIndicator}>
                    <Button onClick={showOnboardingSlider}>{this.tr("Onboarding progress: ${progress}", {progress: `${completedItems}/${onboardingItems.length}`})}</Button>
                </span>
                {playAnimation && (
                    <div className={customStyles.confetti}>
                        <Lottie
                            options={{
                                loop: false,
                                autoplay: true,
                                animationData: confetti,
                            }}
                            speed={0.7}
                            height={'100%'}
                            width={'100%'}
                        />
                    </div>
                )}
                <span className={customStyles.separator} />
            </div>
        );
    }
}
class TaimerNavi extends TaimerComponent {

    static contextType = SettingsContext;

    static defaultProps = {
        selectedPage: "invalid-invalid",
        pipeline_id: 0,
        selectedpipeline: "",
        url: {},
    }

    constructor(props, context) {
        super(props, context, "navigation/TaimerNavi");

        const { functions: { hasPrivilege }, userObject, userObject: { sidebarStyle, show_email, show_onedrive, show_calendar }, addons, privileges, allAddons, taimerAccount } = this.context;
        const { tr } = this;

        this.addonsInfo = {
            "collaborate": {
                title: tr("Boards"),
                description: tr("Get the add-on to create unlimited boards & cards."),
            },
            "projects": {
                title: tr("Leads & Deals"),
                description: tr("Get the add-on to track unlimited leads & deals."),
                limitReached: tr("You have reached your limit to create new leads/projects. You can create a maximum of 3 leads/Won Deal/Internal Projects."),
            },
            "expenses": {
                title: tr("Expenses Addon"),
                description: tr("Get the add-on to manage unlimited expenses."),
            },
            "invoicing": {
                title: tr("Invoicing"),
                description: tr("Get the add-on to create unlimited invoices."),
            },
            "timetracker": {
                title: tr("Time Tracker"),
                description: tr("Track, analyze & invoice hours with built-in timer."),
            },
            "resourcing": {
                title: tr("Resource Gantt"),
                description: tr("Allocate & manage resources. Visualize all in one place."),
            },
            "bills": {
                title: tr("Bills"),
                description: tr("Get a real-time overview of all your bills and where your business is spending money."),
            },
        }


        // NOT USED ANYMORE – USING SIDEBAR ITEMS FROM CONTEXT INSTEAD

        /**
         * name: uniikki id
         * icon: icon name from NavIcons
         * visible: visible or not
         * target: object with paramaters, passed to updateView
         * optional: array of parameters in target allowed to change (for highlight)
         * targets: array of pages for highlight detection
         * addonkey: linked to addon, will show lock if addon not bought
         */

        // if (sidebarStyle === '0') {
        //     this.allMenus = [
        //         {
        //             name: 'dashboard-my_day',
        //             icon: 'dashboard',
        //             target: {
        //                 module: 'dashboard',
        //                 action: 'main',
        //             },
        //             targets: [
        //                 {
        //                     module: 'dashboard',
        //                     action: 'my_day',
        //                 },
        //                 {
        //                     module: 'dashboard',
        //                     action: 'overview',
        //                 },
        //                 {
        //                     module: 'dashboard',
        //                     action: 'myday',
        //                 },
        //             ],
        //             label: this.tr("Dashboards"),
        //             visible: true,
        //             subitems: [
        //                 {
        //                     name: "my-day",
        //                     target: {
        //                         module: 'dashboard',
        //                         action: 'main',
        //                         selectedTab: 'my-day'
        //                     },
        //                     label: this.tr("My Day"),
        //                     visible: true
        //                 },
        //                 {
        //                     name: "profit-loss",
        //                     target: {
        //                         module: 'dashboard',
        //                         action: 'main',
        //                         selectedTab: 'profit-loss'
        //                     },
        //                     label: this.tr("Profit & Loss"),
        //                     visible: hasPrivilege('dashboard', 'profit_loss_read')
        //                 },
        //                 {
        //                     name: "hours-overview",
        //                     target: {
        //                         module: 'dashboard',
        //                         action: 'main',
        //                         selectedTab: 'hours-overview'
        //                     },
        //                     label: this.tr("Hours dashboard"),
        //                     visible: hasPrivilege('dashboard', 'hours_read')
        //                 },
        //                 {
        //                     name: "invoicing-overview",
        //                     target: {
        //                         module: 'dashboard',
        //                         action: 'main',
        //                         selectedTab: 'invoicing-overview'
        //                     },
        //                     label: this.tr("Invoicing dashboard"),
        //                     visible: hasPrivilege('dashboard', 'invoicing_read')
        //                 },
        //                 {
        //                     name: "leads-overview",
        //                     target: {
        //                         module: 'dashboard',
        //                         action: 'main',
        //                         selectedTab: 'leads-overview'
        //                     },
        //                     label: this.tr("Leads dashboard"),
        //                     visible: hasPrivilege('dashboard', 'sales_read')
        //                 },
        //                 {
        //                     name: "deals-overview",
        //                     target: {
        //                         module: 'dashboard',
        //                         action: 'main',
        //                         selectedTab: 'deals-overview'
        //                     },
        //                     label: this.tr("Deals dashboard"),
        //                     visible: hasPrivilege('dashboard', 'sales_read')
        //                 },
        //                 {
        //                     name: "activities-overview",
        //                     target: {
        //                         module: 'dashboard',
        //                         action: 'main',
        //                         selectedTab: 'activities-overview'
        //                     },
        //                     label: this.tr("Activities dashboard"),
        //                     visible: hasPrivilege('dashboard', 'activities_read')
        //                 },
        //                 {
        //                     name: "goals-overview",
        //                     target: {
        //                         module: 'dashboard',
        //                         action: 'main',
        //                         selectedTab: 'goals-overview'
        //                     },
        //                     label: this.tr("Goals dashboard"),
        //                     visible: hasPrivilege('dashboard', 'goals_read')
        //                 },
        //             ]
        //         },
        //         {
        //             name: 'contacts',
        //             icon: 'contacts_new',
        //             label: this.tr("Contacts"),
        //             subitems: [
        //                 {
        //                     name: "accounts-list",
        //                     target: {
        //                         module: 'contacts',
        //                         action: 'main',
        //                         selectedTab: 'accounts'
        //                     },
        //                     targets: [
        //                         {
        //                             module: 'customers',
        //                             action: 'list',
        //                         },
        //                         {
        //                             module: 'customers',
        //                             action: 'view',
        //                         },
        //                     ],
        //                     label: this.tr("Accounts"),
        //                     visible: userObject.customer_read_companies.length > 0 || hasPrivilege("customers", "read"),
        //                 },
        //                 {
        //                     name: "contacts-list",
        //                     target: {
        //                         module: 'contacts',
        //                         action: 'main',
        //                         selectedTab: 'contacts'
        //                     },
        //                     targets: [
        //                         {
        //                             module: 'contacts',
        //                             action: 'list',
        //                         },
        //                         {
        //                             module: 'contact',
        //                             action: 'view',
        //                         },
        //                     ],
        //                     label: this.tr("Contact persons"),
        //                     visible: hasPrivilege("persons", "read"),
        //                 },
        //                 {
        //                     name: "users-list",
        //                     target: {
        //                         module: 'contacts',
        //                         action: 'main',
        //                         selectedTab: 'users'
        //                     },
        //                     targets: [
        //                         {
        //                             module: 'users',
        //                             action: 'list',
        //                         },
        //                         {
        //                             module: 'users',
        //                             action: 'view',
        //                         },
        //                     ],
        //                     label: this.tr("Staff"),
        //                     visible: hasPrivilege("persons", "userlist_read"),
        //                 }
        //             ]
        //         },
        //         {
        //             name: 'projectManagement',
        //             icon: 'projects',
        //             label: this.tr("Projects"),
        //             optional: ['action', 'view'],
        //             visible: true,
        //             target: {
        //                 module: 'projects',
        //                 action: 'list',
        //                 view: 'list',
        //                 pipeline_id: 0
        //             },
        //             subitems: [
        //                 {
        //                     name: "allProjects",
        //                     target: {
        //                         module: 'projects',
        //                         action: 'list',
        //                         view: 'list',
        //                         pipeline_id: 0
        //                     },
        //                     label: this.tr("All"),
        //                     visible: userObject.project_read_companies.length > 0 || hasPrivilege("projects", "read"),
        //                     addonkey: 'projects',
        //                 },
        //                 {
        //                     name: "pipelines",
        //                     target: {
        //                         module: 'projects',
        //                         action: 'list',
        //                         view: 'kanban',
        //                         mode: 'pipeline'
        //                     },
        //                     optional: ['view', 'action', 'pipeline_id', 'mode'],
        //                     label: this.tr("Pipelines"),
        //                     visible: userObject.project_read_companies.length > 0 || hasPrivilege("projects", "read"),
        //                     addonkey: 'projects',
        //                 },
        //                 {
        //                     name: "projects-list-won",
        //                     target: {
        //                         module: 'projects',
        //                         action: 'list',
        //                         view: 'list',
        //                         pipeline_id: "-1",
        //                     },
        //                     optional: ['view', 'action'],
        //                     label: this.tr("Won Deals"),
        //                     addonkey: 'projects',
        //                     visible: userObject.project_read_companies.length > 0 || hasPrivilege("projects", "read"),
        //                 },
        //                 {
        //                     name: "projects-list-internal",
        //                     target: {
        //                         module: 'projects',
        //                         action: 'list',
        //                         view: 'list',
        //                         pipeline_id: "-5",
        //                     },
        //                     optional: ['view', 'action'],
        //                     label: this.tr("Internal Projects"),
        //                     visible: userObject.project_read_companies.length > 0 || hasPrivilege("projects", "read"),
        //                     addonkey: 'projects',
        //                 },
        //             ]
        //         },
        //         {
        //             name: 'timemanagement',
        //             icon: 'timemanagement',
        //             label: this.tr("Time Management"),
        //             subitems: [
        //                 {
        //                     name: "workhours-calendar",
        //                     target: {
        //                         module: 'timemanagement',
        //                         action: 'main',
        //                         selectedTab: 'time-tracker'
        //                     },
        //                     targets: [
        //                         {
        //                             module: 'workhours',
        //                             action: 'calendar'
        //                         },
        //                         {
        //                             module: 'workhours',
        //                             action: 'list'
        //                         },
        //                         {
        //                             module: 'workhours',
        //                             action: 'modified'
        //                         },
        //                     ],
        //                     label: this.tr("Time Tracker"),
        //                     addonkey: 'timetracker',
        //                     visible: hasPrivilege("workhours", "write"),
        //                 },
        //                 {
        //                     name: "calendar",
        //                     target: {
        //                         module: 'timemanagement',
        //                         action: 'main',
        //                         selectedTab: 'calendar'
        //                     },
        //                     targets: [
        //                         {
        //                             module: 'calendar',
        //                             action: 'login'
        //                         }
        //                     ],
        //                     optional: ['action'],
        //                     label: this.tr("Event Calendar"),
        //                     visible: show_calendar == 1,
        //                 },
        //                 {
        //                     name: "workhours-approval",
        //                     target: {
        //                         module: 'timemanagement',
        //                         action: 'main',
        //                         selectedTab: 'approvals'
        //                     },
        //                     label: this.tr("Hours Approval"),
        //                     visible: () => this.context.timeTracker?.show_approval,
        //                 },
        //             ]
        //         },
        //         {
        //             name: 'resourceplanning',
        //             icon: 'resourcing',
        //             label: this.tr("Resource Planning"),
        //             addonkey: 'resourcing',
        //             subitems: [
        //                 {
        //                     name: "resourcing-grid",
        //                     target: {
        //                         module: 'resourceplanning',
        //                         action: 'main',
        //                         selectedTab: 'grid'
        //                     },
        //                     targets: [
        //                         {
        //                             module: 'resourcing',
        //                             action: 'view'
        //                         }
        //                     ],
        //                     label: this.tr("Grid"),
        //                     addonkey: 'resourcing',
        //                     visible: true,
        //                 },
        //                 {
        //                     name: "resourcing-gantt",
        //                     target: {
        //                         module: 'resourceplanning',
        //                         action: 'main',
        //                         selectedTab: 'gantt'
        //                     },
        //                     targets: [
        //                         {
        //                             module: 'resourcing',
        //                             action: 'view'
        //                         }
        //                     ],
        //                     label: this.tr("Gantt"),
        //                     addonkey: 'resourcing',
        //                     visible: true,
        //                 },
        //                 {
        //                     name: "resourcing-list",
        //                     target: {
        //                         module: 'resourceplanning',
        //                         action: 'main',
        //                         selectedTab: 'list'
        //                     },
        //                     targets: [
        //                         {
        //                             module: 'resourcing',
        //                             action: 'view'
        //                         }
        //                     ],
        //                     label: this.tr("List"),
        //                     addonkey: 'resourcing',
        //                     visible: true,
        //                 },
        //                 {
        //                     name: "resourcing-usage",
        //                     target: {
        //                         module: 'resourceplanning',
        //                         action: 'main',
        //                         selectedTab: 'usage'
        //                     },
        //                     targets: [
        //                         {
        //                             module: 'resourcing',
        //                             action: 'view'
        //                         }
        //                     ],
        //                     label: this.tr("Utilization"),
        //                     addonkey: 'resourcing',
        //                     visible: true,
        //                 },
        //             ]
        //         },
        //         {
        //             name: 'invoices',
        //             icon: "receivedInvoices",
        //             label: this.tr("Invoices"),
        //             subitems: [
        //                 {
        //                     name: "invoices-list",
        //                     target: {
        //                         module: 'invoices',
        //                         action: 'main',
        //                         selectedTab: 'invoices'
        //                     },
        //                     targets: [
        //                         {
        //                             module: 'invoices',
        //                             action: 'list',
        //                         },
        //                         {
        //                             module: 'invoices',
        //                             action: 'view',
        //                         },
        //                     ],
        //                     label: this.tr("Invoices"),
        //                     addonkey: 'invoicing',
        //                     visible: hasPrivilege("invoices"),
        //                 },
        //                 {
        //                     name: "mass-invoicing",
        //                     target: {
        //                         module: 'invoices',
        //                         action: 'main',
        //                         selectedTab: 'mass-invoicing'
        //                     },
        //                     targets: [
        //                         {
        //                             module: 'invoices',
        //                             action: 'mass',
        //                         }
        //                     ],
        //                     label: this.tr("Mass Invoicing"),
        //                     addonkey: 'invoicing',
        //                     visible: this.context.addons.mass_invoicing && hasPrivilege("invoices") && this.context.userObject.mass_invoicing_companies?.length > 0,
        //                 },
        //                 {
        //                     name: "invoices-log",
        //                     target: {
        //                         module: 'invoices',
        //                         action: 'main',
        //                         selectedTab: 'invoices-log'
        //                     },
        //                     targets: [
        //                         {
        //                             module: 'invoices',
        //                             action: 'log',
        //                         }
        //                     ],
        //                     label: this.tr("Invoices Log"),
        //                     addonkey: 'invoicing',
        //                     visible: hasPrivilege("invoices"),
        //                 },
        //             ]
        //         },
        //         {
        //             name: 'costs',
        //             icon: 'costs',
        //             label: this.tr("Costs"),
        //             subitems: [
        //                 {
        //                     name: "worktrips-list",
        //                     target: {
        //                         module: 'costs',
        //                         action: 'main',
        //                         selectedTab: 'expenses'
        //                     },
        //                     targets: [
        //                         {
        //                             module: 'worktrips',
        //                             action: 'list'
        //                         },
        //                         {
        //                             module: 'worktrips',
        //                             action: 'modify',
        //                             expenseType: '1'
        //                         },
        //                     ],
        //                     label: this.tr("Expenses"),
        //                     addonkey: 'expenses',
        //                     visible: hasPrivilege("worktrips"),
        //                 },
        //                 {
        //                     name: "travelexpenses-list",
        //                     target: {
        //                         module: 'costs',
        //                         action: 'main',
        //                         selectedTab: 'travel-expenses'
        //                     },
        //                     targets: [
        //                         {
        //                             module: 'travelexpenses',
        //                             action: 'list'
        //                         },
        //                         {
        //                             module: 'worktrips',
        //                             action: 'modify',
        //                             expenseType: '2'
        //                         },
        //                     ],
        //                     label: this.tr("Travel Expenses"),
        //                     addonkey: 'expenses',
        //                     visible: hasPrivilege("worktrips"),
        //                 },
        //                 {
        //                     name: "bills-list",
        //                     target: {
        //                         module: 'costs',
        //                         action: 'main',
        //                         selectedTab: 'bills'
        //                     },
        //                     targets: [
        //                         {
        //                             module: 'bills',
        //                             action: 'list'
        //                         },
        //                         {
        //                             module: 'receivedinvoice',
        //                             action: 'view'
        //                         },
        //                     ],
        //                     label: this.tr("Bills"),
        //                     addonkey: 'bills',
        //                     visible: hasPrivilege("receivedinvoices", ["pre_approve", "approve"]),
        //                 },
        //                 { 
        //                     name: "purchase_orders", 
        //                     target: {
        //                         module: 'costs',
        //                         action: 'main',
        //                         selectedTab: 'purchase-orders'
        //                     },
        //                     targets: [
        //                         {
        //                             module: 'purchaseorder',
        //                             action: 'view'
        //                         },
        //                     ],
        //                     label: this.tr("Purchase Orders"),
        //                     addonkey: 'bills',
        //                     visible: hasPrivilege("purchaseorders", ["read"]), 
        //                 },
        //             ]
        //         },
        //         {
        //             name: 'reports-view',
        //             icon: 'reports',
        //             visible: !(userObject.companies_id == 0 && !this.context.privileges.reports),
        //             target: {
        //                 module: 'reports',
        //                 action: 'view',
        //             },
        //             label: this.tr("Reports"),
        //             subitems: [
        //                 {
        //                     name: "hour-reports",
        //                     target: {
        //                         module: 'reports',
        //                         action: 'view',
        //                         selectedTab: "hourReports"
        //                     },
        //                     label: this.tr("Hour reports"),
        //                     visible: true,
        //                 },
        //                 {
        //                     name: "invoicing-reports",
        //                     target: {
        //                         module: 'reports',
        //                         action: 'view',
        //                         selectedTab: "invoicing"
        //                     },
        //                     label: this.tr("Invoicing reports"),
        //                     visible: hasPrivilege("reports", "invoicing"),
        //                 },
        //                 {
        //                     name: "sales-reports",
        //                     target: {
        //                         module: 'reports',
        //                         action: 'view',
        //                         selectedTab: "sales"
        //                     },
        //                     label: this.tr("Sales reports"),
        //                     visible: hasPrivilege("reports", "invoicing"),
        //                 },
        //                 {
        //                     name: "billing-reports",
        //                     target: {
        //                         module: 'reports',
        //                         action: 'view',
        //                         selectedTab: "billingReports"
        //                     },
        //                     label: this.tr("Billing reports"),
        //                     visible: hasPrivilege("reports", "subcontracting"),
        //                 },                        
        //                 {
        //                     name: "product-reports",
        //                     target: {
        //                         module: 'reports',
        //                         action: 'view',
        //                         selectedTab: "productReports"
        //                     },
        //                     label: this.tr("Product reports"),
        //                     visible: hasPrivilege("reports", "products"),
        //                 },
        //                 {
        //                     name: "recognition-reports",
        //                     target: {
        //                         module: 'reports',
        //                         action: 'view',
        //                         selectedTab: "recognitionReports"
        //                     },
        //                     label: this.tr("Recognition reports"),
        //                     visible: hasPrivilege("reports", "recognition"),
        //                 },
        //                 {
        //                     name: "profitability-reports",
        //                     target: {
        //                         module: 'reports',
        //                         action: 'view',
        //                         selectedTab: "profitabilityReports"
        //                     },
        //                     label: this.tr("Profitability reports"),
        //                     visible: hasPrivilege("reports", "profitability"),
        //                 },
        //                 {
        //                     name: "forecast-reports",
        //                     target: {
        //                         module: 'reports',
        //                         action: 'view',
        //                         selectedTab: "forecastReports"
        //                     },
        //                     label: this.tr("Forecast reports"),
        //                     visible: hasPrivilege("reports", "forecast"),
        //                 },
        //                 {
        //                     name: "wintime-reports",
        //                     target: {
        //                         module: 'reports',
        //                         action: 'view',
        //                         selectedTab: "wintime"
        //                     },
        //                     label: this.tr("Wintime"),
        //                     visible: hasPrivilege("reports", "invoicing") && this.context.addons.nav,
        //                 },
        //                 {
        //                     name: "chief-reports",
        //                     target: {
        //                         module: 'reports',
        //                         action: 'view',
        //                         selectedTab: "chiefReports"
        //                     },
        //                     label: this.tr("Chief reports"),
        //                     visible: hasPrivilege("reports", "read_chief_reports") || hasPrivilege("reports", "read_chief_subjects"),
        //                 },
        //             ]
        //         },
        //         {
        //             name: 'more',
        //             icon: 'more',
        //             label: this.tr("More"),
        //             visible: true,
        //             subitems: [
        //                    {
        //                     name: "products-view",
        //                     target: {
        //                         module: 'products',
        //                         action: 'main',
        //                     },
        //                     targets: [
        //                         {
        //                             module: 'products',
        //                             action: 'view'
        //                         }
        //                     ],
        //                     label: this.tr("Products"),
        //                     visible: privileges.products,
        //                 },
        //                 {
        //                     name: "collaborate-view",
        //                     target: {
        //                         module: 'collaborate',
        //                         action: 'view',
        //                     },
        //                     optional: ['action'],
        //                     visible: taimerAccount.hasExtranet && hasPrivilege("projects", "collaborate"),
        //                     label: this.tr("Boards"),
        //                     addonkey: 'collaborate',
        //                     isBeta: true,
        //                 },
        //                 {
        //                     name: "mails-login",
        //                     target: {
        //                         module: 'mails',
        //                         action: 'login',
        //                     },
        //                     optional: ['action'],
        //                     label: this.tr("Email"),
        //                     addonkey: 'email',
        //                     visible: show_email == 1,
        //                 },
        //                 //{ name: "calendar", label: this.tr("Calendar"), visible: true, selected: false }, 
        //                 // { name: "google-login", label: this.tr("Google Drive"), visible: true, selected: false },
        //                 {
        //                     name: "onedrive-login",
        //                     target: {
        //                         module: 'onedrive',
        //                         action: 'login',
        //                     },
        //                     optional: ['action'],
        //                     label: this.tr("One Drive"),
        //                     addonkey: 'onedrive',
        //                     visible: this.context.versionId == 4 && show_onedrive == 1,
        //                 },
        //             ]
        //         },
        //     ];
        // } else {
        //     // Old Menu
        //     const addonsMenu = {
        //         name: "add-ons",
        //         icon: "addons",
        //         label: this.tr("Add-ons"),
        //         visible: this.context.versionId < 4 && origin !== "ingram",
        //         subitems: [],
        //     };

        //     _.map((allAddons), (addon, key) => {
        //         const current = addons[key];

        //         if (!addon.can_buy)
        //             return;

        //         addonsMenu.subitems.push({
        //             name: `add-ons-${key}`,
        //             label: this.addonsInfo[key].title + (!addon.unlimited ? " " + tr("Unlimited") : ''),
        //             visible: this.context.versionId < 4 && origin !== "ingram",
        //             addonkey: key,
        //             action: 'buy-addon',
        //         });
        //     });

        //     this.allMenus = [
        //         {
        //             name: 'dashboard-my_day',
        //             icon: 'dashboard',
        //             visible: true,
        //             target: {
        //                 module: 'dashboard',
        //                 action: 'main',
        //             },
        //             targets: [
        //                 {
        //                     module: 'dashboard',
        //                     action: 'my_day',
        //                 },
        //                 {
        //                     module: 'dashboard',
        //                     action: 'overview',
        //                 },
        //                 {
        //                     module: 'dashboard',
        //                     action: 'myday',
        //                 },
        //             ],
        //             label: this.tr("Dashboards"),
        //         },
        //         {
        //             name: "contacts-list",
        //             icon: "contacts",
        //             target: {
        //                 module: 'contacts',
        //                 action: 'main',
        //                 selectedTab: !hasPrivilege("persons", "read") ? 'users' : 'contacts'
        //             },
        //             optional: ['selectedTab'],
        //             targets: [
        //                 {
        //                     module: 'contacts',
        //                     action: 'list',
        //                 },
        //                 {
        //                     module: 'contact',
        //                     action: 'view',
        //                 },
        //                 {
        //                     module: 'users',
        //                     action: 'view',
        //                 },
        //             ],
        //             label: this.tr("Contacts List"),
        //             visible: hasPrivilege("persons", "read") || hasPrivilege("persons", "userlist_read"),
        //         },
        //         {
        //             name: "accounts-list",
        //             icon: "customers",
        //             target: {
        //                 module: 'contacts',
        //                 action: 'main',
        //                 selectedTab: 'accounts'
        //             },
        //             targets: [
        //                 {
        //                     module: 'customers',
        //                     action: 'list',
        //                 },
        //                 {
        //                     module: 'customers',
        //                     action: 'view',
        //                 },
        //             ],
        //             label: this.tr("Accounts"),
        //             visible: userObject?.customer_read_companies?.length > 0 || hasPrivilege("customers", "read"),
        //         },
        //         {
        //             name: 'projectManagement',
        //             icon: 'leads',
        //             label: this.tr("Projects"),
        //             target: {
        //                 module: 'projects',
        //                 action: 'list',
        //             },
        //             targets: [
        //                 {
        //                     module: 'projects',
        //                     action: 'view',
        //                 }
        //             ],
        //             optional: ['action'],
        //             addonkey: 'projects',
        //             visible: userObject?.project_read_companies?.length > 0 || hasPrivilege("projects", "read"),
        //         },
        //         {
        //             name: "workhours-calendar",
        //             icon: "workhours",
        //             target: {
        //                 module: 'timemanagement',
        //                 action: 'main',
        //                 selectedTab: 'time-tracker'
        //             },
        //             targets: [
        //                 {
        //                     module: 'workhours',
        //                     action: 'calendar'
        //                 },
        //                 {
        //                     module: 'workhours',
        //                     action: 'list'
        //                 },
        //                 {
        //                     module: 'workhours',
        //                     action: 'modified'
        //                 },
        //             ],
        //             label: this.tr("Time Tracker"),
        //             addonkey: 'timetracker',
        //             visible: hasPrivilege("workhours", "write"),
        //         },
        //         {
        //             name: "resourcing-view",
        //             icon: 'resourcing',
        //             target: {
        //                 module: 'resourceplanning',
        //                 action: 'main',
        //             },
        //             targets: [
        //                 {
        //                     module: 'resourcing',
        //                     action: 'view'
        //                 }
        //             ],
        //             label: this.tr("Resource Planning"),
        //             addonkey: 'resourcing',
        //             visible: true,
        //         },
        //         {
        //             name: "calendar",
        //             icon: "calendar",
        //             target: {
        //                 module: 'timemanagement',
        //                 action: 'main',
        //                 selectedTab: 'calendar'
        //             },
        //             targets: [
        //                 {
        //                     module: 'calendar',
        //                     action: 'login'
        //                 }
        //             ],
        //             optional: ['action'],
        //             label: this.tr("Event Calendar"),
        //             visible: show_calendar == 1,
        //         },
        //         {
        //             name: "worktrips-list",
        //             icon: "expenses",
        //             target: {
        //                 module: 'costs',
        //                 action: 'main',
        //                 selectedTab: 'expenses'
        //             },
        //             targets: [
        //                 {
        //                     module: 'costs',
        //                     action: 'main',
        //                     selectedTab: 'expenses'
        //                 },
        //                 {
        //                     module: 'costs',
        //                     action: 'main',
        //                     selectedTab: 'travel-expenses'
        //                 },
        //                 {
        //                     module: 'worktrips',
        //                     action: 'list'
        //                 },   
        //                 {
        //                     module: 'travelexpenses',
        //                     action: 'list'
        //                 },
        //                 {
        //                     module: 'worktrips',
        //                     action: 'modify',
        //                 },
        //             ],
        //             label: this.tr("Expenses"),
        //             addonkey: 'expenses',
        //             visible: hasPrivilege("worktrips"),
        //         },
        //         {
        //             name: "bills-list",
        //             icon: "costs",
        //             target: {
        //                 module: 'costs',
        //                 action: 'main',
        //                 selectedTab: 'bills'
        //             },
        //             targets: [
        //                 {
        //                     module: 'costs',
        //                     action: 'main',
        //                     selectedTab: 'bills'
        //                 },
        //                 {
        //                     module: 'costs',
        //                     action: 'main',
        //                     selectedTab: 'purchase-orders'
        //                 },   
        //                 {
        //                     module: 'bills',
        //                     action: 'list'
        //                 },
        //                 {
        //                     module: 'receivedinvoice',
        //                     action: 'view'
        //                 },
        //                 {
        //                     module: 'purchaseorder',
        //                     action: 'view'
        //                 },
        //             ],
        //             optional: ['action'],
        //             label: this.tr("Bills"),
        //             addonkey: 'bills',
        //             visible: hasPrivilege("receivedinvoices", ["pre_approve", "approve"]) || hasPrivilege("purchaseorders", ["read"]),
        //         },
        //         {
        //             name: "invoices-list",
        //             icon: "receivedInvoices",
        //             target: {
        //                 module: 'invoices',
        //                 action: 'main',
        //                 selectedTab: 'invoices'
        //             },
        //             targets: [
        //                 {
        //                     module: 'invoices',
        //                     action: 'list',
        //                 },
        //                 {
        //                     module: 'invoices',
        //                     action: 'mass',
        //                 },
        //                 {
        //                     module: 'invoices',
        //                     action: 'log',
        //                 },
        //                 {
        //                     module: 'invoices',
        //                     action: 'view',
        //                 },
        //             ],
        //             optional: ['selectedTab'],
        //             label: this.tr("Invoicing"),
        //             addonkey: 'invoicing',
        //             visible: hasPrivilege("invoices"),
        //         },
        //         {
        //             name: 'reports-view',
        //             icon: 'reports',
        //             visible: !(userObject.companies_id == 0 && !this.context.privileges.reports),
        //             target: {
        //                 module: 'reports',
        //                 action: 'view',
        //             },
        //             label: this.tr("Reports"),
        //         },
        //         {
        //             name: "products-view",
        //             icon: 'product',
        //             target: {
        //                 module: 'products',
        //                 action: 'main',
        //             },
        //             targets: [
        //                 {
        //                     module: 'products',
        //                     action: 'view',
        //                 },
        //                 {
        //                     module: 'cpq',
        //                     action: 'view',
        //                 },
        //                 {
        //                     module: 'catalog',
        //                     action: 'list',
        //                 },
        //             ],
        //             optional: ['selectedTab'],
        //             label: this.tr("Products list"),
        //             visible: privileges.products,
        //         },
        //         {
        //             name: "mails-login",
        //             icon: 'email',
        //             target: {
        //                 module: 'mails',
        //                 action: 'login',
        //             },
        //             optional: ['action'],
        //             label: this.tr("Email"),
        //             addonkey: 'email',
        //             visible: show_email == 1,
        //         },
        //         {
        //             name: "onedrive-login",
        //             icon: 'onedrive',
        //             target: {
        //                 module: 'onedrive',
        //                 action: 'login',
        //             },
        //             optional: ['action'],
        //             label: this.tr("One Drive"),
        //             addonkey: 'onedrive',
        //             visible: this.context.versionId == 4 && show_onedrive == 1,
        //         },
        //         {
        //             name: "collaborate-view",
        //             icon: 'collaborate',
        //             target: {
        //                 module: 'collaborate',
        //                 action: 'view',
        //             },
        //             optional: ['action'],
        //             visible: taimerAccount.hasExtranet && hasPrivilege("projects", "collaborate"),
        //             label: this.tr("Boards"),
        //             addonkey: 'collaborate',
        //             isBeta: true,
        //         },
        //         addonsMenu
        //     ];
        // }'

        const sidebarItems = this.formatSidebarItems(this.props.sidebarItems);

        const currentTree = this.getBestMenuTree(sidebarItems);

        this.state = {
            userOpen: false,
            reportsOpen: false,
            menuOffset: 0,
            hasNewMessages: false,
            currentDialog: false,
            unreadCount: 0,
            unseenCount: 0,
            unreadLiveChatCount: 0,
            currentTree: currentTree || [],
            resourcingAutoCompleteData: false,
            overrideHeaderTitles: undefined,
            sidebarItems
        };

        this.toggleTabletMode = this.toggleTabletMode.bind(this);
        this.userOpen = this.userOpen.bind(this);
        this.reportsOpen = this.reportsOpen.bind(this);
        this.startTimeTracker = this.startTimeTracker.bind(this);
        this.stopTimeTracker = this.stopTimeTracker.bind(this);
    }

    formatSidebarItems = (items) => {
        if (!items) return undefined;
        return items.map(this.formatSidebarItem);
    }

    formatSidebarItem = (item) => {
        return {...item, label: !item.noTr ? this.tr(item.label) : item.label, subitems: this.formatSidebarItems(item.subitems), items: this.formatSidebarItems(item.items)};
    }

    toggleTabletMode() {
        const { functions: { updateView } } = this.context;
        const newMode = this.props.tabletMode === 'On' ? 'Off': 'On';

        this.setChatWidgetOffset(newMode);

        updateView({ tabletMode: newMode });
    }
    dashboardOpen(evt) {
        this.setState({
            dashboardOpen: !this.state.dashboardOpen
        });
    }
    reportsOpen(evt) {
        if (!evt.ctrlKey && !evt.metaKey) {
            !evt.ctrlKey && !evt.metaKey && evt.preventDefault();
            this.context.functions.updateView({ module: "reports", action: "view" }, false, true, false, false, this.tr("Reports"));
        }
        /* TAIM9-575
        this.setState({
            reportsOpen: !this.state.reportsOpen,
            menuOffset: evt.currentTarget.offsetLeft,
        });*/
    }
    userOpen(evt) {
        // track mixpanel events
        this.context.functions.sendMixpanelEvent('click_profile_icon');
        this.setState({
            userOpen: !this.state.userOpen
        });
    }

    async startTimeTracker(data = {}, force = false) {
        const { functions: { getWorkhourTimer, startWorkhourTimer } } = this.context;

        if (!force) {
            const d = getWorkhourTimer();

            if (d) {
                return false;
            }
        }

        const res = await startWorkhourTimer(data);

        window.dispatchEvent(new Event('timersChanged'));

        return res;
    }

    async stopTimeTracker(callback = undefined) {
        const { functions: { getWorkhourTimer } } = this.context;

        const data = getWorkhourTimer();

        if (data) {
            this.openWorkhourDialog(data, true, callback);
        } else {
            callback && callback();
        }

        return data;
    }

    openWorkhourDialog = (data, isWorkhourTimer = false, callback) => {
        this.context.functions.addHours({
            start: data.start || new Date(),
                end: new Date(),
                customer: {
                    id: data.customers_id || 0,
                },
                project: {
                    id: data.projects_id || 0,
                },
                wh_projects_resource: {
                    id: data.wh_projects_resource || 0,
                },
        }, { 
            isWorkhourTimer,
            timer: data,
            afterSaveCallback: callback,
            parentComponent: "stopwatch button" 
        });
    }

    userClicked = (action, newTab, extra = {}) => {
        if (action === 'logout') {
            sessionStorage.clear();
            localStorage.setItem('taimerToken', "");
            localStorage.setItem('taimerPrivileges', "");
            localStorage.setItem('taimerVersion', "");
            localStorage.setItem('taimerVersionId', "");
            localStorage.setItem('taimerAddons', "");
            localStorage.setItem('onboardingEnded', "");
            localStorage.setItem('lastView', "");
            localStorage.setItem('taimerTokenIssued', "");
            localStorage.setItem('taimerTokenExpires', "");
            localStorage.setItem('taimerTokenRefresh', "");
            delete localStorage['activePreviewList'];
            
            this.context.functions.resetMixpanel();
            DataHandler.post({url: "auth/logout_saml"})

            action = "login-login";
            newTab = false;

            if (window.Intercom) {
                window.Intercom('shutdown');
            }

            if (window.HubSpotConversations) {
                window.HubSpotConversations.widget.remove();
            }

            // Zendesk logout
            if (window.zE) {
                window.zE('webWidget', 'logout');
            }

            this.context.cacher.reset();
            this.context.functions.cognitoSignOut();
        }

        this.props.updateView({ selectedPage: action, ...extra }, newTab, true, false, false, ((action == 'settings-index' && !newTab) ? this.tr("Settings") : undefined));
    }

    logOut = () => {
        this.userClicked("logout");
    }
   
    whTimerClicked = () => {
        const { workhourTimers } = this.props;

        if (workhourTimers.length > 0)
            this.stopTimeTracker();
        else
            this.startTimeTracker();
    }
    chatClicked = () => {
        const newEvent = new Event("chatClicked");
        document.dispatchEvent(newEvent);

        this.props.chatClicked();
    }

    personalNoteClicked = () => {
        this.props.personalNoteClicked();
    }

    notificationsClicked = () => {
        const newEvent = new Event("notificationsClicked");
        document.dispatchEvent(newEvent);

        this.props.notificationsClicked();
    }

    settingsClicked = (evt) => {
        this.userClicked("settings-index", evt.ctrlKey || evt.metaKey || evt.button === 1)
    }

    globalSearchClicked = () => {
        // GlobalSearch has a ClickAwayWrapper which
        // becomes active as soon as it opens, so we must
        // defer opening it to the end of the event queue,
        // so that this particular click event has been
        // fully handled before opening the search.
        setTimeout(this.handleGlobalSearchOpen);
    }

    handleGlobalSearchOpen = () => {
        this.context.functions.openGlobalSearch();

        this.context.functions.sendMixpanelEvent('open_quick_search', {
            open_method: "click"
        });
    }

    formatDiff(whStartTime) {
        let whTime;

        let diff = Math.round((new Date() - whStartTime) / 1000);
        const hours = Math.floor(diff / 3600);
        diff -= hours * 3600;
        const mins = Math.floor(diff / 60).toString().padStart(2, "0");
        diff = Math.round(diff - mins * 60).toString().padStart(2, "0");

        if (hours > 0)
            whTime = hours.toString().padStart(2, "0") + ':' + mins + ':' + diff;
        else
            whTime = mins.padStart(2, "0") + ':' + diff;

        return whTime;
    }

    whTimerTick = () => {
        if (this.props.workhourTimers.length > 0)
            this.forceUpdate();
    }

    dialogs = {
        trial: TrialDialog,
        task:ResourceDialog,
        milestone:ResourceDialog
    }

    openDialog = (name, dialogProps = {}) => {
        //if (name == "trial") {
            //this.context.mixpanel.track('Open Add-on Dialog', {'Clicked add-on': dialogProps.name, 'Trial dialog': true});
        //}
        this.setState({ currentDialog: name, dialogProps });
    }

    //tää sotku tullaan poistamaan heti kun saataan pikadialogit kuntoon
    openSection = (name, dialogProps = {}, newWindow = false) => {
        switch (name) {
            case 'account':
                this.context.functions.addAccount({origin_point: "action_menu"});
                break;
            case 'project':
                if (this.props.url.module == 'customers' && this.props.url.action == 'view') {
                    this.addEntityFromCurrentView("addProject");
                    return;   
                }
                this.context.functions.addProject({origin_point: "action_menu"});
                break;
            case 'contact':
                if ((this.props.url.module == 'projects' || this.props.url.module == 'customers') && this.props.url.action == 'view') {
                    this.addEntityFromCurrentView("addContact");
                    return;   
                }
                this.context.functions.addContact({origin_point: "action_menu"});
                break;
            case 'activity':
                if ((this.props.url.module == 'projects' || this.props.url.module == 'customers') && this.props.url.action == 'view') {
                    this.addEntityFromCurrentView("addActivity");
                    return;   
                }
                this.context.functions.openActivitySlider({origin_point: "action_menu"});
                break;
            default: 
                if (name == 'purchaseorders') {
                    this.props.updateView({
                        selectedPage: 'purchaseorder-view',
                    }, newWindow);
                } else if (name == 'task') {
                    if (this.props.url.module == 'projects' && this.props.url.action == 'view') {
                        this.addEntityFromCurrentView("addTask");
                        return;   
                    }
                    this.context.functions.addResource({origin_point: "action_menu"}, { mode: 'task' });
                } else  if (name == 'milestone') {
                    this.context.functions.addResource({origin_point: "action_menu"}, { mode: 'milestone' });
                }else if(name == "workhour"){
                    this.context.functions.addHours({}, { parentComponent: "Add new button" });
                } else if (name == "invoice" && this.context.addons.invoicing && this.context.addons.invoicing.limit && this.context.addons.invoicing.used >= this.context.addons.invoicing.limit) {
                    this.props.toggleBuyDialog("invoicing");
                } else if (name == "expense" && this.context.addons.expenses && this.context.addons.expenses.limit && this.context.addons.expenses.used >= this.context.addons.expenses.limit) {
                    this.props.toggleBuyDialog("expenses");
                } else if (name == "travel" && this.context.addons.expenses && this.context.addons.expenses.limit && this.context.addons.expenses.used >= this.context.addons.expenses.limit) {
                    this.props.toggleBuyDialog("expenses");
                } else if (name == "project" && this.context.addons.projects && this.context.addons.projects.limit && this.context.addons.projects.used >= this.context.addons.projects.limit) {
                    this.props.toggleBuyDialog("projects");
                } else if (name == "product"){
                    this.props.updateView({
                        selectedPage: 'products-view',
                        origin_point: "action_menu",
                    },  newWindow);
                
                    setTimeout(() => window.dispatchEvent(new Event("addNewProduct")), 2000);            
            }else {
                    if (name == "worktrips" || name == "travel") {
                        if (name == "worktrips") {
                            this.context.functions.addExpense({origin_point: "action_menu"});
                        } else {
                            this.context.functions.addTravelExpense({origin_point: "action_menu"});
                        }
                    }  else if (name == "invoice") {
                        this.context.functions.addInvoice({origin_point: "action_menu"});
                    } else {
                        const modules = {
                            account: 'customers-view',
                            project: 'projects-view',
                            /*invoice: 'invoices-view',*/
                            bills:   'receivedinvoice-view'
                            }
                            this.props.updateView({ selectedPage: modules[name], origin_point: "action_menu" }, newWindow);
                    }
                }
                break;
        }
    }

    closeDialog = () => {
        if (this.state.dialogProps?.afterClose) {
            this.state.dialogProps.afterClose();
        }
        this.setState({ currentDialog: false });
    }

    componentDidMount() {
        super.componentDidMount();

        this.updateMenu();
        this.context.functions.naviStartTimeTracker = this.startTimeTracker;
        this.context.functions.naviStopTimeTracker = this.stopTimeTracker;

        if (window.zE) {
            window.zE('webWidget:on', 'chat:unreadMessages', (number) => {
                this.setState({unreadLiveChatCount: number});
            });
        }
    }

    updateMenu = () => {
        const currentTree = this.getBestMenuTree();
        this.setState({ currentTree });
    }

    componentDidUpdate(prevProps, prevState) {
        
        if (!_.isEqual(prevProps.url, this.props.url)) {
            this.updateMenu();
        }
        if (!_.isEqual(prevProps.sidebarItems, this.props.sidebarItems)) {
            this.setState({
                sidebarItems: this.formatSidebarItems(this.props.sidebarItems)
            });
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    setUnreadCount = (unreadCount) => {
        const { unreadCount: lastCount } = this.state;

        if (unreadCount !== lastCount)
            this.setState({ unreadCount });
    }

    setUnseenCount = (unseenCount) => {
        const { unseenCount: lastCount } = this.state;
        if (unseenCount !== lastCount)
            this.setState({ unseenCount });
    }

    isMatchingCurrent = (target, optional = [], targetChecks = undefined) => {
        let matchSize = 0;

        for (const key in target) {
            if (target.hasOwnProperty(key)) {
                const match = typeof target[key] === 'function' ? target[key](this.props.url[key]) : String(this.props.url[key]) === String(target[key]);

                if (!match && optional.indexOf(key) === -1)
                    return false;

                matchSize += (match ? 2 : 1);
            }
        }

        if (targetChecks) {
			for (const key in targetChecks) {
				if (targetChecks.hasOwnProperty(key)) {
					const match = targetChecks[key](this.props.url[key]);

					if (!match && optional.indexOf(key) === -1) return false;

					matchSize += match ? 2 : 1;
				}
			}
		}

        return matchSize;
    }

    getBestMenuTree = (sidebarItems = this.state.sidebarItems) => {
        const res = _.sortBy(this.getMatchingMenuItems(sidebarItems), x => -x.weight);

        if (res.length)
            return res[0].tree;
        else
            return false;
    }

    getMatchingMenuItems = (sidebarItems = this.state.sidebarItems) => {
        const results = [];
        this.getMatchingMenuItemsTree(sidebarItems, results, []);
        return results;
    }

    getMatchingMenuItemsTree = (menu, results, path) => {
        for (const group of menu) {

            if (group.subitems) {
                this.getMatchingMenuItemsTree(group.subitems, results, [...path, group.id]);
            }

            if (group.target) {
                const match = this.isMatchingCurrent(group.target, group.optional, group.targetChecks);

                if (match !== false) {
                    results.push({
                        tree: [...path, group.id],
                        weight: match,
                    });
                }
            }

            if (group.targets) {
                for (const target of group.targets) {
                    const match = this.isMatchingCurrent(target);

                    if (match !== false) {
                        results.push({
                            tree: [...path, group.id],
                            weight: match,
                        });
                        break;
                    }
                }
            }
        }
    }

    sidebarClick = (item, newWindow, label) => {
        const { functions: { updateView } } = this.context;
        updateView({ ...item.target, nonUrlParams: { updateViewOriginPoint: "sidebar" } }, newWindow, true, true, false, newWindow ? undefined : (label || item.label));
    }

    getCurrentViewHeaderComponent = () => {
        const { currentViewRef } = this.props;
        if (currentViewRef.current && currentViewRef.current.headerComponent) {
            return currentViewRef.current.headerComponent();
        }
        return undefined;
    }

    addEntityFromCurrentView = (functionName) => {
        const { currentViewRef } = this.props;
        if (currentViewRef.current && currentViewRef.current[functionName]) {
            currentViewRef.current[functionName]({origin_point: "action_menu"});
        }
    }

    getBackButton = () => {
        const { currentViewRef } = this.props;
        if (currentViewRef.current && currentViewRef.current.backButton) {
            return currentViewRef.current.backButton;
        }
        return undefined;
    }

    getCurrentViewTreeLabels = () => {
        const currentTree = this.state.currentTree;
        const { additionalHeaders, currentViewRef } = this.props;
        let labels = [];
        if (this.state.overrideHeaderTitles) {
            if (additionalHeaders) {
                return [...this.state.overrideHeaderTitles, ...additionalHeaders];
            }
            return this.state.overrideHeaderTitles;
        } 
        if (currentViewRef.current && currentViewRef.current.headerTitle) {
            labels = [currentViewRef.current.headerTitle];
        }
        if (currentViewRef.current && currentViewRef.current.headerTitles) {
            labels = currentViewRef.current.headerTitles;
        }
        if (currentViewRef.current  && currentViewRef.current.getHeaderTitles) {
            const titles = currentViewRef.current.getHeaderTitles();
            if (titles) {
                labels = titles;
            }
        }
        if (labels.length == 0) {
            (currentTree || []).forEach(currentView => {
                for (let i = 0; i < this.state.sidebarItems.length; i++) {
                    const m = this.state.sidebarItems[i];
                    if (m.id == currentView && labels.length == 0) {
                        labels.push({ label: m.label, isBeta: m.isBeta });
                        break;
                    }
                    // At first wanted to have the full path shown, leaving this here for the future possibility
                    // const subItem = (m.subitems || []).find(si => si.name == currentView)
                    // if (subItem) {
                    //     labels.push(subItem.label);
                    //     break;
                    // };
                }
            });
        }

        if (additionalHeaders) {
            labels = [...labels, ...additionalHeaders];
        }

        return labels;
    }

    renderViewTreeLabel = label => {
        if (!label) return '';
        if (typeof label == 'string') {
            return <h1>{label}</h1>
        }
        if (typeof label == 'function') {
            return label();
        }
        const mainLabel = label.url || label.onClick ? <Link onClick={label.onClick} url={label.url}>{label.label}</Link> : <h1>{label.label}</h1>;
        if (label.sublabel) {
            return (
                <div>
                    {mainLabel}
                    <span className="sublabel">{this.renderViewTreeLabel(label.sublabel)}</span>
                </div>
            )
        }
        return mainLabel;
    }

    backToListPressed = (e) => {
        const { activePreviewList } = this.props;
        const { functions: { updateView } } = this.context;

        e.preventDefault();
        updateView(activePreviewList.returnUrl, {previewList: false});
    }

    backButtonPressed = (fallbackLocation) => {
        this.props.goToPreviousView(fallbackLocation);
    }

    setOverrideHeaderTitles = (overrideHeaderTitles) => {
        this.setState({ overrideHeaderTitles })
    }

    openStripePortal = () => {
        DataHandler.get({ url: `settings/subscription/portal` }).done((response) => {
            window.location = response;
        });
    }

    openChatWidget = () => {
        if (window.zE) {
            window.zE('webWidget', 'show');
            window.zE('webWidget', 'open');

            this.setState({unreadLiveChatCount: 0});
        }
    }
    
    setChatWidgetOffset = (mode) => {
        const horizontal = mode === 'On' ? '70px' : '230px';
        if (window.zE) {
            window.zE('webWidget', 'updateSettings', {
                webWidget: {
                    offset: {
                        horizontal
                    }
                }
            });
        }
    }

    renderActionMenu = () => {
        return <ActionsMenu menuClicked={this.openSection} />;
    }

    renderTopBarAction = (item) => {
        const { taimerAccount: { origin }, functions: { hasPrivilege }, versionId, addons } = this.context;
        const { workhourTimers } = this.props;
        const { unreadCount } = this.state;
        const workhourTimer = workhourTimers ? workhourTimers[0] : false;

        switch (item.id) {
            case 'upgrade':
                return origin !== "ingram" && hasPrivilege('admin', 'admin') && !addons.new_stripe && <UpgradeButton updateView={this.props.updateView} separator={<span className={customStyles.separator} />} />
            case 'add':
                return <ActionMenuButton data-testid="add-new-menu" className={customStyles.addNewMenu} tooltip={this.tr("Add new")} noIcon src="actions" desc={<AddCircleOutline />} menuContent={this.renderActionMenu()} />
            case 'separator':
                return <span className={customStyles.separator} />
            case 'notes':
                return (
                    <div data-testid="navi-personal-notes" className={customStyles.actionButton} onClick={this.personalNoteClicked}>
                        <Tooltip title={this.tr("Personal notes")}>
                            <DescriptionOutlined />
                        </Tooltip>
                    </div>
                );
            case 'timer':
                return <WorkhourTimer tooltip={this.tr("Work hours timer")} timer={workhourTimer} onClick={this.whTimerClicked} />
            case 'teamchat':
                return (
                        <Tooltip title={this.tr("Team chat")}>
                            <div data-testid="navi-teamchat" className={customStyles.actionButton} id="headerNewsfeed" onClick={item.upgrade ? this.context.functions.showUpgradeSlider : this.chatClicked}>
                                <Badge badgeContent={unreadCount > 0 && unreadCount} className={unreadCount > 0 ? customStyles.notificationBadge : ''}>
                                    <ChatOutlined />
                                </Badge>
                                {item.upgrade && <Star className={customStyles.upgradeIndicator} />}
                            </div>
                        </Tooltip>
                    );
            case 'notifications':
                return (
                    <div className={customStyles.actionButton} id="headerNotificationFeed" onClick={this.notificationsClicked}>
                        <Tooltip title={this.tr("Notifications")}>
                            <Badge badgeContent={this.state.unseenCount > 0 && this.state.unseenCount} className={this.state.unseenCount > 0 ? customStyles.notificationBadge : ''}>
                                <NotificationsOutlined />
                            </Badge>
                        </Tooltip>
                    </div>
                );
            case 'dev':
                return (
                    <DevOptions changeDevPerms={this.props.changeDevPerms} versionId={versionId}>
                        {(onClick) => (<div data-testid="navi-devoptions" className={customStyles.actionButton} onClick={onClick}>
                            <Tooltip title={this.tr("Dev options")}>
                                <BuildOutlined />
                            </Tooltip>
                        </div>)}
                    </DevOptions>
                );
            case 'settings':
                return (
                    <div data-testid="navi-settings" className={`${customStyles.actionButton} ${(this.props.url.module == 'settings' && this.props.url.action == 'index') ? customStyles.active : ''}`} onClick={this.settingsClicked}>
                        <Tooltip title={this.tr("Settings")}>
                            <SettingsOutlined />
                        </Tooltip>
                    </div>
                );
            case 'global-search':
                return (
                    <div data-testid="global-search" className={`${customStyles.actionButton}`} onClick={this.globalSearchClicked}>
                        <Tooltip title={this.tr("Global search")}>
                            <Search />
                        </Tooltip>
                    </div>
                );
        }
    }

    renderCancellationIndicator = () => {
        const { taimerAccount: { origin, daysUntilCancellation }, functions: { hasPrivilege }, addons } = this.context;
        const rights = hasPrivilege("admin", "admin") ? 'admin' : 'user';
        const companyName = Number(this.context.userObject.companies_id) > 0 ? this.context.taimerAccount.name : "Freelancer";
        if (daysUntilCancellation < 0 || daysUntilCancellation > 30 || !addons.new_stripe || origin == "ingram" || rights != 'admin' || companyName == "Freelancer") return null;
        return (
            <>
                <span className={`${customStyles.cancellationIndicator} ${daysUntilCancellation == 0 ? customStyles.serious : ''}`} onClick={() => this.openStripePortal()}>
                    <WarningRounded />
                    {daysUntilCancellation == 0 ? this.tr("Your subscription ends today") : this.tr("Your subscription ends in ${daysUntilCancellation} days", { daysUntilCancellation })}
                </span>
                <span className={customStyles.separator} />
            </>
        );
    }

    renderTrialIndicator = () => {
        const { taimerAccount: { origin, trialStatus }, functions: { showUpgradeSlider, hasPrivilege }, addons } = this.context;
        const rights = hasPrivilege("admin", "admin") ? 'admin' : 'user';
        const companyName = Number(this.context.userObject.companies_id) > 0 ? this.context.taimerAccount.name : "Freelancer";
        if (!addons.new_stripe || origin == "ingram" || rights != 'admin' || trialStatus <= 0 || companyName == "Freelancer") return null;
        const trialDays = this.context.taimerAccount.trialStatus > 0 ? moment(this.context.taimerAccount.trialEnds).endOf('day').diff(moment(new Date()).startOf('day'), 'days', false) : 0
        return (
            <>
                <span className={customStyles.trialIndicator}>{this.tr("Free trial ends in ${trialDays} days", { trialDays })}
                    <Button onClick={() => showUpgradeSlider(VersionContentManager.getCurrentVersion())}>{this.tr("Upgrade")}</Button>
                </span>
                <span className={customStyles.separator} />
            </>
        );
    }

    renderOnboardingIndicator = () => {
        return <OnboardingIndicator module={this.props.url.module} />
    }

    renderTopBarActions = () => {
        const { userObject } = this.context;
        const { userOpen } = this.state;
        return (
            <>
                {this.renderCancellationIndicator()}
                {this.renderTrialIndicator()}
                {this.renderOnboardingIndicator()}
                {VersionContentManager.getTopBarActions().map(this.renderTopBarAction)}
                <ActionMenuButton
                    className='user-button'
                    onClick={this.userOpen}
                    
                    img={<TaimerAvatar
                        id={userObject.usersId}
                        name={userObject.fullname}
                        color={userObject.color}
                        size="small"
                    />}
                    isOpen={userOpen}
                />
            </>
        );
    }

    renderPreviewList = () => {
        const { activePreviewList: { currentIndex, pages, returnUrl } } = this.props;

        const current = pages[currentIndex];

        const previous = pages[currentIndex - 1] ?? null;
        const next = pages[currentIndex + 1] ?? null;

        return <>
            <PreviewList
                currentPage={current}
                currentPageNumber={currentIndex + 1}
                previousPage={previous}
                nextPage={next}
                pages={pages.length}
                />
        </>
    }

    render() {
        const { userObject, taimerAccount: { origin }, functions: { hasPrivilege }, allAddons, versionId, userObject: { sidebarStyle } } = this.context;
        const { tabletMode, workhourTimers, activePreviewList, navigationNotifications } = this.props;
        const { unreadCount, userOpen, reportsOpen, menuOffset,
            currentDialog, dialogProps, currentTree } = this.state;
        const commonClasses = tabletMode === 'On' && 'hidden';
        const { tr } = this;
        const { functions: { checkPrivilege, urlify } } = this.context;

        const Dialog = currentDialog ? this.dialogs[currentDialog] : undefined;

        const workhourTimer = workhourTimers ? workhourTimers[0] : false;
        const viewTreeLabels = this.getCurrentViewTreeLabels();
        const backButton = this.getBackButton();

        const hasPreviewList = activePreviewList && activePreviewList.currentIndex > -1;
        const hasPreviewListReturn = hasPreviewList && activePreviewList.returnUrl;

        const hasBackButton = !hasPreviewListReturn && backButton?.visible;

        return (
            <div id='main-navigation'>
                <div className='navi-container tablet-mode'>
                    <div className={`header ${commonClasses}`}>
                        <div className={`sidebar  ${commonClasses}`}>
                            {/* <img className='logoWhite' src={heerosLogoWhite} alt='Heeros' /> */}
                            <img className='logo' src={psaLogo} alt='Heeros logo' />
                            {/* <img className='hide-button' src={menu} alt='Taimer' onClick={this.toggleTabletMode} /> */}
                            <button onClick={this.toggleTabletMode}>
                                <MenuRounded />
                            </button>
                            <span className={customStyles.divider} />
                        </div>
                        <div className={cn("header-button-container", hasPreviewList && "header-preview-list", hasPreviewList && customStyles.headerPreviewList)}>
                            <div className="tree-label-container">
                                {hasPreviewListReturn && <Tooltip title={this.tr("Go back to the list")}>
                                    <a href={urlify(activePreviewList.returnUrl)} onClick={this.backToListPressed} className="back-button">
                                        <ArrowBack />
                                    </a></Tooltip>}
                                {hasBackButton && <Tooltip title={this.tr("Go back to the previous view")}>
                                    <button data-testid="navi-back-button" className="back-button" onClick={() => this.backButtonPressed(backButton.fallbackLocation)}>
                                        <ArrowBack />
                                    </button></Tooltip>}
                                {this.getCurrentViewHeaderComponent() || viewTreeLabels.map((label, i) => (
                                    <>
                                        {this.renderViewTreeLabel(label)}
                                        {i < viewTreeLabels.length - 1 && (label.useLineAsSeparator ? <span className="line-separator" /> : <KeyboardArrowRight />)}
                                        {label.isBeta && <span className="beta" style={{marginLeft: 8}}>Beta</span>}
                                    </>
                                ))}
                            </div>
                            <div className="actions-right">
                                {hasPreviewList ? this.renderPreviewList() : this.renderTopBarActions()}
                            </div>
                        </div>
                        {reportsOpen && (<ReportMenu closeMenu={this.reportsOpen} menuClicked={this.reportClicked} menuOffset={menuOffset + 40} />)}
                        {userOpen && (<UserMenu userObject={userObject} closeMenu={this.userOpen} menuClicked={this.userClicked} updateView={this.props.updateView} />)}
                        {Dialog && <Dialog 
                                       checkPrivilege={checkPrivilege}
                                       autoCompleteData={Dialog == ResourceDialog ? this.state.resourcingAutoCompleteData : {}}
                                       {...dialogProps} 
                                       onClose={this.closeDialog} 
                                    />
                        }
                    </div>
                    <div className={cn(`sidebar ${commonClasses}`, !sidebarStyle && "sidebar-old")}><div className="sidebarInner">
                        {this.state.sidebarItems.map((group) => {

                            return <MenuGroup
                                key={`${group.id}_${sidebarStyle}`}
                                group={group}
                                allAddons={allAddons}
                                addonsInfo={this.addonsInfo}
                                onClick={this.sidebarClick}
                                openDialog={this.openDialog}
                                selected={currentTree[0] === group.id}
                                level={1}
                                tree={currentTree}
                                tabletMode={tabletMode}
                                showLocked={sidebarStyle}
                                navigationNotifications={navigationNotifications}
                            />
                        })}
                    </div>

                    <div id="chatLauncher" onClick={this.openChatWidget}>
                        <Badge badgeContent={this.state.unreadLiveChatCount > 0 && this.state.unreadLiveChatCount} className={this.state.unreadLiveChatCount > 0 ? customStyles.notificationBadge : ''}>
                            <div className="icon-container"><HelpIcon /></div>
                        </Badge>
                        <div className="text-container">{this.tr("Support")}</div> 
                    </div>

                    {/* <button onClick={this.context.functions.openCustomizeSlider}>
                        <DashboardCustomize />
                        {this.tr("Customize appearance")}
                    </button> */}
                    </div>
                </div>
            </div>
        );
    } // + (this.state.hasNewMessages ? 'green': '')
}
TaimerNavi.propTypes = {
    updateView: PropTypes.func.isRequired,
};

export default withStyles(styles)(TaimerNavi);

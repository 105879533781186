import React from 'react';
import { AGGridTranslations } from '@heeros/ag-grid';
import "@heeros/ag-grid/dist/styles/style.css";
import 'ag-grid-enterprise';
import { LicenseManager } from  'ag-grid-enterprise'
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import TaimerComponent from '../../TaimerComponent';
// import { divide, plus, roundPercent } from './../general/MathUtils';
import LoadingOverlay from '../LoadingOverlay';
import currencies from '../../dashboard/insights/currencies.json';
import styles from './AgGridWrapper.module.scss';
import cn from 'classnames';

import {
    ColDef,
    Column,
    ColumnApi,
    GridApi,
    GridReadyEvent,
    IRowNode,
    RowNode
} from 'ag-grid-community';

/* context */
import { SettingsContext } from './../../SettingsContext';

/* css */
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS  
import { format, isValid, parse } from 'date-fns';
import { isEqual, cloneDeep, isDate } from 'lodash';
import { AgColumnSeriesTooltipRendererParams, AgPieSeriesLabelFormatterParams, AgPieSeriesTooltipRendererParams } from 'ag-grid-enterprise/dist/lib/chart/agChartOptions';
import { divide, plus, roundPercent } from '../../general/MathUtils';
import { FilterState } from '../FiltersState';
import getColumnType, { sumAndGroup, getVariableDisplayValue, getStatusDisplayValue, getPivotModeAggValue, isPivotModeGroup } from '../ColumnTypes';
 
interface Props {
    viewName: string;
    selectedTemplateName: string;
    agGridProps?: Partial<Omit<AgGridReactProps, 'dataTypeDefinitions' | 'columnDefs'>>;
    dataTypeDefinitions: AgGridReactProps['dataTypeDefinitions'];
    columns: AgGridReactProps['columnDefs'];
    rows: Record<string, any>[];
    isLoading?: boolean;
    filterState?: FilterState | null;
    onFilterStateChanged?: (fromUI: boolean) => void;
    onFilterStateStartApplying?: (filterState: FilterState) => void;
    onFilterStateApplied?: (filterState: FilterState) => void;
    columnsSort?: (a: ColDef, b: ColDef) => number;
    activeFilterAmount: number;
}

interface ExcelNumberFormat {
    id: string,
    numberFormat: {
        format: string
    },
}

interface State {
    currentKey: number;
    filterState: FilterState;
}

const defaultColumn = {
    flex: 1,
    minWidth: 200,
    resizable: true,
    filter: true,
    floatingFilter: true,
    enableRowGroup: true,
    enableValue: true,
    enablePivot: true,
    sortable: true
}

class AgGridWrapper extends TaimerComponent<Props, State> {
    static contextType = SettingsContext;

    refAgGrid = React.createRef<AgGridReact>();

    translations = {};

    get gridApi(): GridApi|undefined {
        return this.refAgGrid.current?.api;
    }

    get columnApi(): ColumnApi|undefined {
        return this.refAgGrid.current?.columnApi;
    }

    private excelCurrencyFormats: ExcelNumberFormat[] = [];
    private excelPostfixFormats: ExcelNumberFormat[] = [];

    constructor(props, context) {
        super(props, context, 'new_reports/components/AgGridWrapper');

        const { functions: { getEnv } } = this.context;

        LicenseManager.setLicenseKey(getEnv('REACT_APP_AG_GRID_LICENSE_KEY'));

        let lang = 'en-US';
        switch(this.context.userObject.language) {
            case 'fi':
                lang = "fi-FI";
                break;
            case 'se':
                lang = 'sv-SE';
                break;
            case 'en':
            default:
                lang = 'en-US';
                break;
        }

        this.translations = AGGridTranslations[lang];
        this.translations['sumAndGroup'] = this.tr("Sum and group");
        
        this.excelCurrencyFormats = this.getExcelCurrencyFormats();
        this.excelPostfixFormats = this.getExcelPostfixFormats();

        this.state = {
            currentKey: 1,
            filterState: {
                columns: [],
                filterModel: [],
                grouping: [],
                pivotMode: false,
            },
        };
    }

    getExcelCurrencyFormats = (): ExcelNumberFormat[] => {
        const formats: ExcelNumberFormat[] = [];
        Object.entries(currencies).forEach(([key, value]) => {
            const format: ExcelNumberFormat = {
                id: "currencyFormat_" + key,
                numberFormat: {
                    format: value,
                },
            }
            formats.push(format);
        });
        return formats;
    }

    getExcelPostfixFormats = (): ExcelNumberFormat[] => {
        const formats: ExcelNumberFormat[] = [];
        const postFixes = ['d', 'km', 'h'];

        postFixes.forEach(e => {
            const format: ExcelNumberFormat = {
                id: "postfixFormat_" + e,
                numberFormat: {
                    format: (e == 'h' 
                        ? '0.00' // Put hours always in two decimals. Other number get max two decimals if needed.
                        : '0.##') 
                        + ' [$' + e + ']'
                },
            }
            formats.push(format);
        });        
        return formats;
    }

    shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): boolean {
        const { columns, dataTypeDefinitions, rows, isLoading, filterState } = this.props;
        
        return nextProps.columns !== columns 
            || nextProps.dataTypeDefinitions !== dataTypeDefinitions 
            || nextProps.rows !== rows 
            || nextProps.filterState !== filterState 
            || nextProps.isLoading !== isLoading;
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        const { columns, dataTypeDefinitions, rows, isLoading, filterState, viewName } = this.props;

        if (prevProps.columns !== columns) {
            console.log("columns changed")
        }

        if (prevProps.columns !== columns || prevProps.rows !== rows) {
            setTimeout( () => {
                this.updateTotals();
            });
        }

        if (prevProps.filterState !== filterState) {
            // if (prevProps.viewName !== viewName) {
            //     this.columnApi?.resetColumnState();
            //     this.columnApi?.resetColumnGroupState();
            // }

            if (filterState) {
                setTimeout( () => this.restoreFilterState(filterState));
            }
        }
    }

    restoreFilterState = (state: FilterState) => {
        const { onFilterStateStartApplying, onFilterStateApplied } = this.props;

        onFilterStateStartApplying?.(state);
        // console.log("restoring state", state)

        this.columnApi?.applyColumnState({
            state: state.columns,
            applyOrder: !state.pivotMode,
        });
        this.columnApi?.setColumnGroupState(state.grouping);
        this.gridApi?.setFilterModel(state.filterModel);
        this.columnApi?.setPivotMode(state.pivotMode);

        // console.log("restored state", state)
        onFilterStateApplied?.(state);
    }
    
    /**
     * Sorts filterState columns so that hidden columns are in correct place according to translated header.
     * So when hidden column is changed visible it goes to correct place.
     * Visible column order is not changed.
     * @param cols filterState columns.
     * @returns filterState columns in correct order.
     */
    sortFilterStateColumns = (cols) => {
        const { columns, columnsSort } = this.props;
        cols.sort((a, b) => {
            if (!a.hide && !b.hide) { // If not sorting hidden column, keep sorting as it is.
                return 0;
            }
            const aColdef = columns?.find(c => c['field'] == a.colId) || {}; 
            const bColdef = columns?.find(c => c['field'] == b.colId) || {}; 

            return columnsSort ? columnsSort(aColdef, bColdef) : 0;
        })
        return cols;
    }

    setFilterModel = (filterModel) => {
        this.gridApi?.setFilterModel(filterModel);
    }

    getFilterModel = () => {
        return this.gridApi?.getFilterModel();
    }

    createPivotTotals = (): object => {
        const totals: object = [];

        this.gridApi?.forEachNodeAfterFilter((node) => {
            if (node?.leafGroup) {
                Object.keys(node.aggData || {}).forEach(a => {
                    if (totals[a]) {
                        totals[a] += Number(node.aggData[a]) || 0;
                    }
                    else if (node.aggData[a] !== null) {
                        totals[a] = Number(node.aggData[a]) || 0;
                    }
                });
            }
        });

        return totals;
    }

    createTotals = (): object => {
        const { columns } = this.props;

        const rows: object[] = [];
        this.gridApi?.forEachNodeAfterFilter((el) => {
            rows.push(el.data);
        });

        const totalColumns = {};
        const countedPerFields = {};
        for (const i in columns) {
            if (columns[i].grandTotal) {
                totalColumns[columns[i].field] = {
                    sum: 0,
                    count: 0,
                    type: columns[i].grandTotal,
                    values: {},
                    ignoreFromAggregateType: columns[i].ignoreFromAggregateType
                };
                if (columns[i].grandTotal === 'sum_per') {
                    totalColumns[columns[i].field].perField = columns[i].grandTotalPerField;
                    countedPerFields[columns[i].field] = {};
                }
            }
        }
        rows.forEach((el, i) => {
            if (!el) {
                return;
            }
            for (const j in totalColumns) {
                const totalColumn = totalColumns[j];
                const value = el[j];

                if (countedPerFields[j]) {
                    if (!countedPerFields[j][el[totalColumns[j].perField]]) {
                        totalColumn.sum = plus(totalColumns[j].sum, isNaN(value) || typeof value === totalColumn.ignoreFromAggregateType ? 0 : Number(value));
                        countedPerFields[j][el[totalColumns[j].perField]] = true;
                        totalColumn.count++;
                    }
                    continue;
                }

                if (totalColumn.type === 'count') {
                    if (!totalColumn.values[value]) {
                        totalColumn.values[value] = true;
                        totalColumn.sum++;
                    }
                    totalColumn.count++;
                } else {
                    const val = value && value.replace ? value.replace(",", ".") : value;
                    totalColumn.sum = plus(totalColumn.sum, isNaN(val) || typeof val === totalColumn.ignoreFromAggregateType ? 0 : Number(val));
                    totalColumn.count++;
                }
            }
        });
        const totals = { billentries_id: 'Total', rowType: "totals_row" };
        for (const i in totalColumns) {
            const t = totalColumns[i];
            let value = t.sum;
            if (t.type === "avg") {
                value = t.count > 0 ? divide(value, t.count) : 0;
            }
            totals[i] = value.toNumber?.() ?? Number(value) ?? 0;

            if (t.type === 'none') {
                // Hide in total row
                totals[i] = null;
            }
        }

        return totals;
    }

    updateTotals = () => {
        const pivotMode = this.columnApi?.isPivotMode() ?? false;

        setTimeout(() => {
            const totals = pivotMode 
                ? this.createPivotTotals()
                : this.createTotals();

            this.gridApi?.setPinnedBottomRowData([totals]);
        }, 10);
    }

    onRowDataUpdated = (event) => {
        this.updateTotals();
    };

    onFilterChanged = (event) => {
        this.updateTotals();
        this.handleConfigChange(event);
        this.gridApi?.refreshCells(); // Needed so that grouped row shows correct data after filtering when no aggregation function is selected.
    };

    tooltipRenderer = (params: AgColumnSeriesTooltipRendererParams) => {
        const column = this.columnApi?.getColumn(params.yKey);
        const value = params.datum[params.yKey];
        const cell = column?.getColDef();
        const dataType = cell?.cellDataType ?? 'number';

        if (dataType === 'currency') {
            const formattedValue = this.context.functions.presentCurrency(Number(value));

            return {
                content: formattedValue,
            };
        } else if (dataType === 'hour') {
            return {
                content: `${Number(value).toFixed(2)} h`,
            };
        } else if (dataType === 'percentage') {
            return {
                content: `${roundPercent(Number(value).toFixed(2))} %`,
            };
        } else if (dataType === 'number') {
            return {
                content: `${Number(value).toFixed(cell?.decimalAmount ?? 2)} ${cell?.postfix ?? ''}`,
            };
        }

        return {
            content: String(value ?? ""),
        };
    }

    tooltipRendererPie = (params: AgPieSeriesTooltipRendererParams) => {
        const column = this.columnApi?.getColumn(params.sectorLabelKey);
        const value = params.sectorLabelKey ? Number(params.datum[params.sectorLabelKey]) : null;
        const cell = column?.getColDef();
        const dataType = cell?.cellDataType ?? 'number';

        if (dataType === 'currency') {
            const formattedValue = this.context.functions.presentCurrency(value);

            return {
                content: formattedValue,
            };
        } else if (dataType === 'hour') {
            return {
                content: `${Number(value).toFixed(2)} h`,
            };
        } else if (dataType === 'percentage') {
            return {
                content: `${roundPercent(Number(value).toFixed(2))} %`,
            };
        } else if (dataType === 'number') {
            return {
                content: `${Number(value).toFixed(cell?.decimalAmount ?? 2)} ${cell?.postfix ?? ''}`,
            };
        }

        return {
            content: value?.toFixed(2),
        };
    }

    labelRendererPie = (params: AgPieSeriesLabelFormatterParams<any>) => {
        const column = this.columnApi?.getColumn(params.sectorLabelKey);
        const value = params.sectorLabelKey ? Number(params.datum[params.sectorLabelKey]) : null;
        const cell = column?.getColDef();
        const dataType = cell?.cellDataType ?? 'number';

        if (dataType === 'currency') {
            const formattedValue = this.context.functions.presentCurrency(value);

            return formattedValue;
        } else if (dataType === 'hour') {
            return `${Number(value).toFixed(2)} h`;
        } else if (dataType === 'percentage') {
            return `${roundPercent(Number(value).toFixed(2))} %`;
        } else if (dataType === 'number') {
            return `${Number(value).toFixed(cell?.decimalAmount ?? 2)} ${cell?.postfix ?? ''}`;
        }

        return value?.toFixed(2);
    }

    onGridReady = (params: GridReadyEvent) => {
        const { filterState } = this.props;

        if (filterState) {
            setTimeout(() => this.restoreFilterState(filterState));
        }
    }

    getCurrentFilterState = (): FilterState => {
        const cols = this.columnApi?.getColumnState();
        const groups = this.columnApi?.getColumnGroupState();
        const filterModel = this.gridApi?.getFilterModel();
        const pivotMode = this.columnApi?.isPivotMode() ?? false;

        return {
            columns: cols ?? [],
            grouping: groups ?? [],
            filterModel: filterModel ?? {},
            pivotMode,
        };
    }

    getLoadingOverlay = () => {
       return <div />;
    }

    handleConfigChange = (event) => {
        const { onFilterStateChanged } = this.props;
        const pivotMode = this.columnApi?.isPivotMode() ?? false;

        const fromUI = event?.source && event?.source != "api" && event?.source != "flex" && event?.source != "gridOptionsChanged";

        onFilterStateChanged && onFilterStateChanged(fromUI);

        const updateTotalsEvents = pivotMode
            ? [
                'columnValueChanged',
                'columnPivotChanged',
                'columnRowGroupChanged'
            ]
            : [
                'columnValueChanged',
            ];

        if (updateTotalsEvents.find(e => e == event?.type)) {
            setTimeout( () => {
                this.updateTotals();
            });
        }

        const updatePivotAggDataEvents = [
            'filterChanged',
        ];
        if (pivotMode && updatePivotAggDataEvents.find(e => e == event?.type)) {
            setTimeout( () => {
                this.gridApi?.redrawRows();
            });
        }
    }

    onFirstDataRendered = () => {
        console.log("on first data rendered")
    }

    onPivotModeChanged = (isPivotActive: boolean) => {
        if (isPivotActive) {
            this.context.functions.sendMixpanelEvent("activate_reports_pivot_mode", {
                "report_module": this.props.viewName,
                "report_name": this.props.selectedTemplateName
            })
        }

        setTimeout( () => {
            this.updateTotals();
        });
    }

    onExport = (type: "xslx" | "csv") => {
        this.context.functions.sendMixpanelEvent('export_report', {
            "report_module": this.props.viewName,
            "report_name": this.props.selectedTemplateName,
            "export_format": type
        });
    }

    getExcelFile = () => {
        const file = this.gridApi?.getDataAsExcel();
        return file;
    }

    exportAsExcel = () => {
        this.gridApi?.exportDataAsExcel();
        this.onExport('xslx');
    }

    exportAsCSV = () => {
        this.gridApi?.exportDataAsCsv();
        this.onExport('csv');
    }

    onChartCreate = () => {
        this.context.functions.sendMixpanelEvent('generate_reports_chart', {
            "report_module": this.props.viewName,
            "report_name": this.props.selectedTemplateName
        });
    }

    getExportPercentageValue = (value) => {
        if (value === null) {
            return null;
        }
        // Atleast Avg aggregate function returns formatted value also when actual value is null. Check if the actual value is null and show empty if it is.
        if (typeof value === "object") {
            if (value?.value === null) {
                return null;
            }
        }
        // If value is grouped data one value, it comes formatted. So take percent rate from that.
        if (value?.toString().includes("%")) {
            const PERCENT_REGEXP = /-?\d*\.?,?\d+/g;
            value = value?.toString().match(PERCENT_REGEXP);
            value = Number(value) / 100;
        }

        return value;
    }

    getExportValue = (value, colDef, node, type: "xlsx" | "csv") => {

        // Empty value cells for expanded group's parent row (values only shown for children, makes summing easier)
        if (node.group && node.expanded && colDef.cellRenderer != "agGroupCellRenderer") {
            return '';
        }

        if (value?.startsWith?.('component_group_one_value_')) {
            value = value.substring(26);
        }
        if (value?.startsWith?.('[aggregate_multiple_values]_')) {
            value = value.substring(28);
            return this.tr("${amount} values", {amount: value});
        }

        if (colDef.cellDataType === 'number' && !!colDef.postfix && value?.replace) {
            value = value.replace(` ${colDef.postfix}`, "");
        }

        if (colDef.cellDataType == "percentage") {
            value = this.getExportPercentageValue(value);
            return type == "xlsx" || value === null
                ? value
                : isNaN(value) ? value : roundPercent(value);
        }

        if ((colDef.cellDataType === 'variable' || colDef.cellDataType === 'status') && colDef.cellRendererParams?.tr) {
            let id = node?.data ? node?.data[colDef?.field || ""] : "";
            let idAsNames = false;
            if (!id) { // e.g. grouped row.
                // Value comes here as comma separated string of displaydata names if groupdata has multiple values. So split it to array so values get translated correctly.
                id = typeof value === "string"
                    ? value.split(",")
                    : value;

                idAsNames = true;
            }
            return colDef.cellDataType === 'variable'
                ? getVariableDisplayValue(
                    id, 
                    colDef.cellRendererParams?.displayDatas,
                    colDef.cellRendererParams?.tr,
                    idAsNames
                )
                : getStatusDisplayValue(
                    id, 
                    colDef.cellRendererParams?.displayDatas,
                    true,
                    colDef.cellRendererParams?.tr,
                    idAsNames
                );
        }
        if (colDef.cellDataType === 'multi' && colDef.cellRendererParams?.tr && typeof value === "string" && value?.startsWith("[translate]")) {
            value = colDef.cellRendererParams?.tr(value.substring('[translate]'.length));
        }

        return value;
    }

    getExportGroupValue = (node, type: "xlsx" | "csv") => {
        const colDef = node.rowGroupColumn?.getColDef(); 
        let value = node.key;

        if (!colDef) {
            return value;
        }

        // Commented out to remove all units from exports
        // if (type == "xlsx" && colDef.cellDataType === 'currency' && typeof colDef.valueFormatter === 'function') {
        //     const formattedValue = this.context.functions.presentCurrency(Number(value));
        //     return formattedValue;
        // }

        if (colDef.cellDataType == "percentage") {
            value = this.getExportPercentageValue(value);

            value = isNaN(value) || value === null ? value : roundPercent(value);
            return value;
        }

        // Commented out to remove all units from exports
        // if (colDef.cellDataType == "number" || colDef.cellDataType == "hour") {
        //      // @ts-ignore
        //     const dataTypeDefinition = node.rowGroupColumn?.gridOptionsService?.gridOptions?.dataTypeDefinitions[colDef.cellDataType];
            
        //     return typeof dataTypeDefinition?.valueFormatter === "function"
        //         ? dataTypeDefinition?.valueFormatter({ value, colDef })
        //         : value;
        // }

        if ((colDef?.cellDataType === 'variable' || colDef?.cellDataType === 'status') && colDef.cellRendererParams?.tr) {
            // Value comes here as comma separated string of displaydata names if groupdata has multiple values. So split it to array so values get translated correctly.
            const values = typeof value === "string"
                ? value.split(",")
                : value;
            
            return colDef.cellDataType === 'variable'
                ? getVariableDisplayValue(
                    values, 
                    colDef.cellRendererParams?.displayDatas,
                    colDef.cellRendererParams?.tr,
                    true
                )
                : getStatusDisplayValue(
                    values , 
                    colDef.cellRendererParams?.displayDatas,
                    true,
                    colDef.cellRendererParams?.tr,
                    true
                );
        }
        if (colDef?.cellDataType === 'multi' && colDef?.cellRendererParams?.tr && typeof value === "string" && value?.startsWith("[translate]")) {
            value = colDef.cellRendererParams?.tr(value.substring('[translate]'.length));
        }

        return value;
    }

    processExportRowGroup = (params, type: "xlsx" | "csv") => {
        let node = params.node;

        const keys = [this.getExportGroupValue(node, type)];

        while (node.parent) {
            node = node.parent;
            keys.push(this.getExportGroupValue(node, type));
        }

        return keys.reverse().filter((val) => !!val).join(' -> ');
    }

    isAnyParentClosed = (node) => {
        let parent = node.parent;
        while (parent?.field) {
            if (!parent.expanded) {
                return true;
            }
            parent = parent.parent;
        }
        return false;
    }

    render() {
        const { dataTypeDefinitions, columns, rows, agGridProps, viewName, isLoading } = this.props;

        return (
            <div className='grid-container'>
                <div className={cn(styles.loadingOverlay, !isLoading && styles.hidden)}>
                    <LoadingOverlay />
                </div>
                <div className="ag-theme-alpine ag-grid">
                    <AgGridReact
                        key={viewName}
                        ref={this.refAgGrid}
                        enableRangeSelection
                        enableCharts
                        onGridReady={this.onGridReady}
                        rowData={rows}
                        defaultColDef={defaultColumn}
                        columnDefs={columns}
                        dataTypeDefinitions={dataTypeDefinitions}
                        groupDisplayType={"singleColumn"}
                        aggFuncs={{sumAndGroup}}
                        /*
                        groupDisplayType={"groupRows"}
                        groupRowRendererParams={{
                            innerRenderer: GroupRowInnerRenderer
                        }}
                        */
                        unSortIcon={true}
                        suppressAggFuncInHeader
                        suppressMenuHide={true}
                        autoGroupColumnDef={{
                            cellRendererParams: {
                                suppressCount: true,
                            } 
                        }}
                        //rowGroupPanelShow={"always"}
                        sideBar={{
                            toolPanels: [
                                {
                                    id: 'columns',
                                    labelDefault: 'Columns',
                                    labelKey: 'columns',
                                    iconKey: 'columns',
                                    toolPanel: 'agColumnsToolPanel',
                                    toolPanelParams: {
                                        suppressColumnMove: true,
                                        suppressSyncLayoutWithGrid: true
                                    }
                                },
                                {
                                    id: 'filters',
                                    labelDefault: 'Filters',
                                    labelKey: 'filters',
                                    iconKey: 'filter',
                                    toolPanel: 'agFiltersToolPanel',
                                }
                            ],
                        }}
                        //debug={true}
                        localeText={this.translations}
                        //pinnedBottomRowData={totals}
                        //onFirstDataRendered={this.rowDataUpdated}
                        //groupIncludeFooter={true}
                        //groupIncludeTotalFooter={true}
                        //onModelUpdated={this.onModelUpdated}
                        onFirstDataRendered={this.onFirstDataRendered}
                        onRowDataUpdated={this.onRowDataUpdated}
                        accentedSort
                        onFilterChanged={this.onFilterChanged}
                        onSortChanged={this.handleConfigChange}
                        onColumnVisible={this.handleConfigChange}
                        onColumnPinned={this.handleConfigChange}
                        onColumnResized={this.handleConfigChange}
                        onColumnMoved={this.handleConfigChange}
                        onColumnRowGroupChanged={this.handleConfigChange}
                        onColumnValueChanged={this.handleConfigChange}
                        onColumnPivotChanged={this.handleConfigChange}
                        onColumnPivotModeChanged={(e) => {
                            this.onPivotModeChanged(e?.columnApi?.isPivotMode())
                        }}
                        loadingOverlayComponent={this.getLoadingOverlay}
                        suppressNoRowsOverlay
                        statusBar={{
                            statusPanels: [
                              { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                              { statusPanel: 'agAggregationComponent', align: 'right' },
                            ],
                        }}
                        processPivotResultColDef={(colDef) => {
                            const origColdef = colDef.pivotValueColumn?.getColDef();
                            const cellDataType: string = typeof origColdef?.cellDataType === "string" 
                                ? origColdef?.cellDataType 
                                : "text";

                            colDef.valueFormatter = dataTypeDefinitions?.[cellDataType]  
                                ? dataTypeDefinitions[cellDataType].valueFormatter
                                : undefined;

                            const columnType = getColumnType(cellDataType);
                            colDef.valueGetter = columnType
                                ? columnType.valueGetter
                                : undefined;

                            colDef.cellRenderer = columnType
                                ? columnType.cellRenderer
                                : undefined;

                            colDef.cellRendererSelector = columnType
                                ? columnType.cellRendererSelector
                                : undefined;

                            return colDef;
                        }}
                        excelStyles={[
                            {
                                id: 'export_columnText',
                                dataType: 'String',
                            },
                            {
                                id: 'export_columnTextRight',
                                numberFormat: {
                                    format: ';;;* @',
                                },
                            },
                            {
                                id: 'export_columnPercentage',
                                dataType: 'Number',
                                numberFormat: {
                                    format: '0.##\%',
                                },
                            },
                            {
                                id: 'export_columnDate',
                                dataType: 'DateTime',
                                numberFormat: {
                                    format: this.context.userObject.dateFormat,
                                },
                            },
                        ]}
                        defaultCsvExportParams={{
                            fileName: `${this.props.selectedTemplateName.toLowerCase().split(' ').join('_')}_export_${format(new Date(), 'DD.MM.YYYY_HH:mm:ss')}.csv`,
                            shouldRowBeSkipped: params => {
                                const ranges = params.api.getCellRanges();
                                if (!!params.node?.parent?.field && this.isAnyParentClosed(params.node)) return true;

                                // Skip total row
                                if (params.node?.data?.rowType == "totals_row" || params.node?.rowPinned) {
                                    return true;
                                }

                                // No selection
                                if (ranges === null || ranges.length === 0) {
                                    return false;
                                }

                                 // Call onExport in first row's check.
                                 if (params?.node?.rowIndex === 0) {
                                    this.onExport("csv");
                                }

                                // if only one cell selected, export all
                                if (ranges.length === 1) {
                                    const range = ranges[0];

                                    if (range.startRow === range.endRow && range.columns.length === 1) {
                                        return false;
                                    }
                                }

                                if (params.node.rowIndex === null) {
                                    return true;
                                }

                                for (const range of ranges) {
                                    if (range.startRow !== undefined && range.endRow !== undefined) {
                                        const start = range.startRow.rowIndex > range.endRow.rowIndex ? range.endRow.rowIndex : range.startRow.rowIndex;
                                        const end = range.startRow.rowIndex > range.endRow.rowIndex ? range.startRow.rowIndex : range.endRow.rowIndex;

                                        if (start <= params.node.rowIndex && params.node.rowIndex <= end) {
                                            return false;
                                        }
                                    }
                                }

                                return true;
                            },
                            processCellCallback: params => {
                                let value = params.value;
                                const colDef = params.column.getColDef();

                                if (isPivotModeGroup(colDef, params?.node)) {
                                    value = getPivotModeAggValue(colDef, params?.node || undefined);
                                }

                                value = this.getExportValue(value, colDef, params?.node, "csv");
                                
                                if (value == '') return value;

                                if (colDef.postfix || colDef.cellDataType === "percentage") { // Don't show postfix or % in CSV.
                                    return value;
                                } 
                                if (colDef.cellDataType === "hour") {
                                    return Number(value).toFixed(2);
                                }
                                if (colDef.cellDataType === 'currency') {
                                    if (value === null) {
                                        return "";
                                    }
                                    // Atleast Avg aggregate function returns formatted value also when actual value is null. Check if the actual value is null and show empty if it is.
                                    if (typeof value === "object") {
                                        if (value?.value === null) {
                                            return "";
                                        }
                                    }
                                    return Number(value).toFixed(colDef.decimalAmount ?? 2);
                                }

                                if (colDef.cellDataType === 'date' && typeof value == 'string') {
                                    const date = parse(value, this.context.userObject.dateFormat, new Date());
                                    if (!isValid(date)) {
                                        value = "";   
                                    }
                            }

                                return value !== null && typeof params.formatValue === "function"
                                    ? params.formatValue(value)
                                    : value;
                            },
                            processRowGroupCallback: params => this.processExportRowGroup(params, "csv")
                        }}
                        defaultExcelExportParams={{
                            fileName: `${this.props.selectedTemplateName.toLowerCase().split(' ').join('_')}_export_${format(new Date(), 'DD.MM.YYYY_HH:mm:ss')}.xlsx`,
                            sheetName: this.props.selectedTemplateName,
                            rowGroupExpandState: 'collapsed',
                            suppressRowOutline: true,
                            shouldRowBeSkipped: params =>  {
                                const ranges = params.api.getCellRanges();
                                if (!!params.node?.parent?.field && this.isAnyParentClosed(params.node)) return true;

                                // Skip total row
                                if (params.node?.data?.rowType == "totals_row" || params.node?.rowPinned) {
                                    return true;
                                }

                                // No selection
                                if (ranges === null || ranges.length === 0) {
                                    return false;
                                }

                                // Call onExport in first row's check.
                                if (params?.node?.rowIndex === 0) {
                                    this.onExport("xslx");
                                }

                                // if only one cell selected, export all
                                if (ranges.length === 1) {
                                    const range = ranges[0];

                                    if (range.startRow === range.endRow && range.columns.length === 1) {
                                        return false;
                                    }
                                }

                                if (params.node.rowIndex === null) {
                                    return true;
                                }

                                for (const range of ranges) {
                                    if (range.startRow !== undefined && range.endRow !== undefined) {
                                        const start = range.startRow.rowIndex > range.endRow.rowIndex ? range.endRow.rowIndex : range.startRow.rowIndex;
                                        const end = range.startRow.rowIndex > range.endRow.rowIndex ? range.startRow.rowIndex : range.endRow.rowIndex;

                                        if (start <= params.node.rowIndex && params.node.rowIndex <= end) {
                                            return false;
                                        }
                                    }
                                }

                                return true;
                            },
                            processCellCallback: params => {
                                let value = params.value;
                                const colDef = params.column.getColDef();

                                if (isPivotModeGroup(colDef, params?.node)) {
                                    value = getPivotModeAggValue(colDef, params?.node || undefined);
                                }

                                value = this.getExportValue(value, colDef, params?.node, "xlsx");

                                if (!value && value !== 0) { // Show 0, put null as empty.
                                    value = "";
                                }

                                if ((colDef.cellDataType === 'time') && typeof params.formatValue === "function") {
                                    value = params.formatValue(value);
                                } 

                                if (colDef.cellDataType === 'date') {
                                    if (isDate(value) && isValid(value)) {
                                        value = format(value, "YYYY-MM-DDTHH:mm:ss") + '.000Z';
                                    } else {
                                        const date = parse(value, this.context.userObject.dateFormat, new Date());
                                        if (isValid(date)) {
                                            value = format(date, "YYYY-MM-DDTHH:mm:ss") + '.000Z';
                                        } else {
                                            value = "";
                                        }
                                    }
                                }

                                return value;
                            },
                            processRowGroupCallback: params => this.processExportRowGroup(params, "xlsx")
                        }}
                        getChartToolbarItems={(params) => { 
                            this.onChartCreate();
                            return ['chartLink' , 'chartDownload']; 
                        }}
                        chartThemes={['psa']}
                        customChartThemes={{
                            psa: {
                                baseTheme: 'ag-default',
                                overrides: {
                                    area: {
                                        series: {
                                            tooltip: {
                                                renderer: this.tooltipRenderer,
                                            },
                                        },
                                    },
                                    bar: {
                                        series: {
                                            tooltip: {
                                                renderer: this.tooltipRenderer,
                                            },
                                        },
                                    },
                                    column: {
                                        axes: {
                                            category: {
                                                label: {
                                                    formatter(params) {
                                                        return params.formatter?.(params.value) ?? "";
                                                    },
                                                },
                                            },
                                            groupedCategory: {
                                                label: {
                                                    formatter(params) {
                                                        return params.formatter?.(params.value) ?? "";
                                                    },
                                                },
                                            },
                                        },
                                        series: {
                                            tooltip: {
                                                renderer: this.tooltipRenderer,
                                            },
                                        },
                                    },
                                    histogram: {
                                        series: {
                                            tooltip: {
                                                renderer: this.tooltipRenderer,
                                            },
                                        },
                                    },
                                    line: {
                                        series: {
                                            tooltip: {
                                                renderer: this.tooltipRenderer,
                                            },
                                        },
                                    },
                                    pie: {
                                        series: {
                                            tooltip: {
                                                renderer: this.tooltipRendererPie,
                                            },
                                            sectorLabel: {
                                                formatter: this.labelRendererPie,
                                            },
                                            calloutLabel: {
                                                formatter: (params) => {
                                                    const value = params.calloutLabelKey ? params.datum[params.calloutLabelKey] : null;

                                                    if (params.calloutLabelKey === 'ag-Grid-AutoColumn') {
                                                        return value.labels.join(', ');
                                                    }
                                                    else if (value instanceof Date) {
                                                        return format(value, this.context.userObject.dateFormat);
                                                    } else if (params.calloutLabelKey === 'days') {
                                                        return `${value.toFixed(1)} d`;
                                                    } else if (params.calloutLabelKey?.endsWith('hours')) {
                                                        return `${value.toFixed(2)} h`;
                                                    }

                                                    return String(value ?? params.calloutLabelValue);
                                                }
                                            },
                                        },
                                    },
                                    scatter: {
                                        series: {
                                            tooltip: {
                                                renderer: this.tooltipRenderer,
                                            },
                                        },
                                    },
                                }
                            }
                        }}
                        {...agGridProps}
                    />
                </div>
            </div>
        );
    }
}

export default AgGridWrapper;

import { min, cloneDeep, isDate, uniqBy } from 'lodash';
import moment from 'moment';
import CustomPinnedRowRenderer from './CustomPinnedRowRenderer';
import CustomPivotPinnedRowRenderer from './CustomPivotPinnedRowRenderer';
import LinkCellRenderer from './LinkCellRenderer';
import LinksCellRenderer from './LinksCellRenderer';
import StatusCellRenderer from './StatusCellRenderer';
import VariableCellRenderer from './VariableCellRenderer';
import CurrencyCellRenderer from './CurrencyCellRenderer';
import TextLinkCellRenderer from "./TextLinkCellRenderer";
import EmailLinkCellRenderer from "./EmailLinkCellRenderer";
import FormattedValueCellRenderer from "./FormattedValueCellRenderer";
import ValueRenderer from "./ValueRenderer";
import IconCellRenderer from "./IconCellRenderer";
import MultiCellRenderer from './MultiCellRenderer';
import TranslateValueRenderer from './TranslateValueRenderer';
import { CurrencyConvertData, getCurrencyConvertData, ReportData } from './CurrencyUtils';
import { roundToFixedNumber } from '../general/MathUtils';
import calendarIcon from './img/calendar.svg';
import { isBefore, isValid, parse } from 'date-fns';
import { ColDef, IAggFunc, ICellRendererParams, IRowNode, ValueGetterParams, IAggFuncParams } from 'ag-grid-community';


export type DisplayDataObject = {
    id: number | string;
    name: string;
    color: string;
}
 
export interface UserColumnDefinition {
    field: string;
    headerName: string;
    headerComponent?: React.ComponentType;
    headerComponentParams?: {
        template: string;
    };
    overrideGrandTotal?: string;
    cellRendererParams?: {
        url?: string;
        displayDatas?: {
            id: number | string;
            name: string;
            color?: string;
        }[],
        displayLinkAsText?: (row: any) => boolean;
        displayArrayAsTextValues?: boolean;
        tr?: (text, replacers?: Record<string, string>) => string;
        getIconComponent?: (data: any) => any;
    };
    grandTotal?: 'avg' | 'sum' | 'sum_per' | 'none' | 'count';
    grandTotalPerField?: string;
    aggFunc?: ColDef['aggFunc']; 
    defaultAggFunc?: ColDef['defaultAggFunc'];
    calculateGroupedRate?: { dividend: string, divisor: string };
    postfix?: string;
    cellClass?: string|string[]|((params: any) => string|string[]|undefined);
    decimalAmount?: number;
    noCurrencyConvert?: boolean;
    ignoreFromAggregateType?: "string" | "number";
    sumAndGroupType?: 'sum' | 'group' | 'avg'; // Type of aggregation when sumAndGroup aggregation is used.
    showEmptyAggSum?: boolean; // If true, shows empty cell in parent if selected aggregation func is "sum"
    isCustomField?: boolean; // Is the field project or account custom field.
    hide?: boolean;
    wrapText?: boolean;
    autoHeight?: boolean;
    justifyContent?: "left" | "right" | "icon_text";
}

declare module "ag-grid-community" {
    interface ColDef<TData = any, TValue = any> {
        postfix?: string;
        decimalAmount?: number;
        overrideGrandTotal?: string;
    }
}

interface ProgramColumnDefinition {
    cellDataType: string;
    chartDataType?: 'category' | 'series' | 'time' | 'excluded';
    cellRenderer?:  typeof LinkCellRenderer |
                    typeof StatusCellRenderer |
                    typeof VariableCellRenderer |
                    typeof CurrencyCellRenderer |
                    typeof MultiCellRenderer | 
                    typeof FormattedValueCellRenderer |
                    typeof TranslateValueRenderer |
                    typeof IconCellRenderer |
                    typeof ValueRenderer;
    type?: string;
    filter?: string;
    filterValueGetter?: (params: {data, colDef}) => string | string[] | number;
    filterParams?: {
        textMatcher?: ({value, filterText}) => boolean;
        valueFormatter?: (params) => string;
        comparator?: ((valueA: any, valueB: any) => number) | ((filterLocalDateAtMidnight: Date, cellValue: string) => number);
        inRangeInclusive?: boolean;
        buttons?: string[];
        filters?: object[];
    }
    valueGetter?: (params) => string | string[] | number | Date | Date[] | null;
    cellRendererSelector?: ColDef['cellRendererSelector'];
    aggFunc?: ColDef['aggFunc']; 
    defaultAggFunc?: ColDef['defaultAggFunc'];
    useValueFormatterForExport?: boolean;
    sort?: null | 'asc' | 'desc';
    sortIndex?: number;
    cellClass?: string|string[]|((params: any) => string|string[]|undefined);
    comparator?: (valueA: any, valueB: any, nodeA?: any, nodeB?: any) => number;
    pivotComparator?: (valueA: any, valueB: any, nodeA?: any, nodeB?: any) => number;
    keyCreator?: (params: any) => string;
}

const cellRendererSelector = (params: ICellRendererParams, groupedValueRenderer: any = undefined) => {
    const node = params.node;
    const column = params.column?.getColId();
    
    // @ts-ignore
    const dataTypeDefinition = params.node.rowGroupColumn?.gridOptionsService?.gridOptions?.dataTypeDefinitions[params.colDef?.cellDataType];

    // In Pivot-mode value comes already as correctly formatted for leaf columns.
    const returnPivotAggValue = isPivotModeLeaf(params.colDef, params.node) &&
        typeof params.value === "string" && 
        !params.value?.startsWith?.('[aggregate_multiple_values]_') && 
        !params.value?.startsWith?.('component_group_one_value_') &&
        !isGroupedAutoColumn(node, column);

    // Have to get pivot mode group values here so shows correctly, at least for filtered data (sumAndGroup function isn't get called then), but had some problems also when not filtered. 
    if (isPivotModeGroup(params.colDef, params.node)) {
        params.value = getPivotModeAggValue(params.colDef, params.node);

        const returnAggValue = typeof params.value === "string" && 
            !params.value?.startsWith?.('[aggregate_multiple_values]_') && 
            !params.value?.startsWith?.('component_group_one_value_');

        // So correct value gets updated (for numeric values, it will get formatted later if valueFormatted doesn't exist).
        params.valueFormatted = null;

        if (returnAggValue) {
            // For some reason doesn't work if just return the value (if data is filtered), have to use renderer.
            return {
                component: ValueRenderer,
                params: {
                    value: params.value                
                },
            }
        }
    }
    else if (returnPivotAggValue) {
        return {
            component: ValueRenderer,
            params: {
                value: params.value                
            },
        }
    }

    if (typeof params.value === "string" && params.value?.startsWith?.('[aggregate_multiple_values]_')) {
        return {
            component: TranslateValueRenderer,
            params: {
                value: "${amount} values",
                replacers: {amount: params.value.substring(28)}
            },
        }
    }

    if (node.rowPinned) {
      return {
        component: params.colDef?.pivotValueColumn ? CustomPivotPinnedRowRenderer : CustomPinnedRowRenderer,
        params: {
          overrideGrandTotal: params.colDef?.overrideGrandTotal,
          cellClass: params.colDef?.cellClass,
          params
        },
      };
    } else if (isGroupedParent(node) || isGroupedAutoColumn(node, column)) {
        const isAutoColumn = isGroupedAutoColumn(node, column);

        const isComponentGroupOneValue = typeof params.value === "string" && params.value?.includes("component_group_one_value");

        const selectedAggFunc = params.column?.getAggFunc() || "";

        // If sum aggregation needs to return empty value for cell.
        // e.g. total_in_currency in costs (can be values from different currencies so no sense in showing sum).
        let showEmptyAggSum = params.colDef 
            && params.colDef['showEmptyAggSum']
            && ['sum', 'avg', 'min', 'max'].includes(typeof selectedAggFunc === "string" ? selectedAggFunc : "");
                   
        // If aggFunc is "Count" for currency cell: Return that value so it shows correctly (no need for currency convert icon then).
        // If sumAndGroupType is group for currency cell: return grouped value (e.g. "2 values").
        const returnAggFuncValue = params.colDef?.cellDataType == "currency" 
            ? (selectedAggFunc == "count" || params.colDef['sumAndGroupType'] == "group")
            : !params.colDef?.cellRendererParams?.getIconComponent;

        const cellDataType = isAutoColumn
            ? params.node.rowGroupColumn?.getColDef().cellDataType
            : params.colDef?.cellDataType;

        if (isAutoColumn) {
            params.colDef = params.node.rowGroupColumn?.getColDef(); // Autocolumn has correct colDef in rowGroupColumn.
        }

        // Avg aggregate function returns formatted value also when actual value is null. Check if the actual value is null and show empty if it is.
        if (selectedAggFunc == 'avg' && cellDataType == "percentage" && typeof params.value === "object") {
            if (params.value?.value === null) {
                showEmptyAggSum = true;
            }
        }

        // If no need to get groupedDataValue (first value from children or currency convert data for currency cell).
        if (!isAutoColumn && !isComponentGroupOneValue && returnAggFuncValue) {
            const sumAndGroupType = getSumAndGroupType(params);

            // If sumAndGroupType is "group" no need for formatting: Single value is already formatted and multiple values is e.g. "2 values".
            // Don't format when aggFunc is "count" (e.g. for currency cells).
            const formatValue = sumAndGroupType != "group" && selectedAggFunc != "count" && typeof dataTypeDefinition?.valueFormatter === "function";

            const translateValue = params.colDef?.cellRendererParams?.tr && typeof params?.value === "string" && params.value?.startsWith("[translate]");

            // Cell renderer needed to show the formatted value when no aggregation function is selected.
            // Cell renderer needed to show the translations.
            const useRenderer = groupedValueRenderer && (formatValue || translateValue);

            if (useRenderer) {
                params.valueFormatted = formatValue ? dataTypeDefinition?.valueFormatter(params) : params.value;
                return getCellRendererParams(
                    params,
                    groupedValueRenderer,
                    showEmptyAggSum
                );
            }
            return params.value; // Return value from aggFunc.
        }

        const groupedDataValue = cellDataType == "currency"
            ? getCurrencyGroupedDataValue(params, isAutoColumn)
            : getGroupedDataFirstValue(node, column, isAutoColumn, params);

        // Show correct format in grouped autocolumn (the column that has been grouped by).
        if (isAutoColumn && typeof dataTypeDefinition?.valueFormatter === "function") { 
            if (cellDataType != "currency") { // Currency column has the currency convert data in groupedDataValue (params.value has correct value).
                params.value = groupedDataValue;
            }
            
            // Maybe instead of using valueFormatter, formatted value could be got from params.node.aggData[params.coldDef.field]. At least currency shows correctly there.
            if (params.node.allLeafChildren?.length > 0 && params.node.allLeafChildren[0]['data'] && params.node.allLeafChildren[0]['data']['currency']) {
                params.data = {
                    currency: params.node.allLeafChildren[0]['data']['currency'],
                    useDataCurrency: true
                }
            }
            params.valueFormatted = dataTypeDefinition?.valueFormatter(params);
        }

        if (!params.valueFormatted && typeof dataTypeDefinition?.valueFormatter === "function") {
            params.valueFormatted = dataTypeDefinition?.valueFormatter(params);
        }

        if (params.colDef?.cellRendererParams?.getIconComponent) {
            params.data = params.node.allLeafChildren[0]['data'] || {};
        }

        return !groupedValueRenderer 
            ? (isAutoColumn ? params.valueFormatted : undefined) 
            : getCellRendererParams(
                params,
                groupedValueRenderer,
                showEmptyAggSum,
                groupedDataValue
            ); 
    } else {
      // rows that are not pinned don't use any cell renderer
      return undefined;
    }
};

export const isPivotModeGroup = (colDef: ColDef | null | undefined, node: IRowNode | null | undefined): boolean => {
    if (!node || !colDef) {
        return false;
    }

    const isPivotGroup = colDef?.pivotValueColumn && !node?.leafGroup;

    return isPivotGroup ? true : false;
}

export const isPivotModeLeaf = (colDef: ColDef | null | undefined, node: IRowNode | null | undefined): boolean => {
    if (!node || !colDef) {
        return false;
    }

    const isPivotLeaf = colDef?.pivotValueColumn && node?.leafGroup;

    return isPivotLeaf ? true : false;
}

const getPivotModeCustomFieldSum = (values) => {
    let hasTextValues = false;
    let numericValues: (number | null)[] = [];

    (values || []).forEach(val => {
        if (val && !Number.isFinite(Number(val.replace ? val.replace(",", ".") : val))) {
            hasTextValues = true;

        }
    })

    if (!hasTextValues) {
        numericValues = values.map(v => {
            return v ? Number(v.replace ? v.replace(",", ".") : v) : null; // If value is empty return null, so column shows empty if none of the children have value.
        })
    }

    if(!hasTextValues) {
        let sum: number | null = null;
        numericValues.forEach(n => {
            if (n !== null) {
                if (sum !== null) {
                    sum += n;
                }
                else {
                    sum = n;
                }
            }
        });
        return sum;
    }

    return null;
}

const getPivotModeSumAndGroup = (colDef: ColDef | undefined, node: IRowNode | undefined) => {
    let amount = 0;
    const values: any[] = [];
    const groupValues = getPivotModeGroupValues(colDef, node); 

    if (colDef?.['isCustomField'] && getSumAndGroupType({colDef}) != "group") {
        const sum = getPivotModeCustomFieldSum(groupValues);
        if (sum !== null) {
            return sum;
        }
    }

    groupValues.forEach(v => {
        // Check if leaf column has multiple values, add it to columns total values amount.
        if (typeof v === "string" && v?.startsWith?.('[aggregate_multiple_values]_')) {
            amount += Number(v.substring(28));
        }
        else {
            values.push(v);
        }
    })

    const unique = [...new Set(values)];
    const count = unique.length + amount;

    if(count == 1) {
        if (isComponentType({colDef})) { 
            return getComponentSingleValue(unique);
        }
        return getTextSingleValue(unique);
    }

    return count == 0
        ? ""
        : "[aggregate_multiple_values]_" + count;
}

const getPivotModeSum = (colDef: ColDef | undefined, node: IRowNode | undefined, values = null) => {
    const aggData = getAllPivotLeafAggData(node?.['childrenAfterAggFilter'] || []); 

    let sum: number | null = null;
    aggData.forEach(c => {
        const val = c[colDef?.colId || ""];
        const value = val && typeof val === "object"
            ? val.value
            : val;

        if (value !== null) {
            if (sum !== null) {
                sum += value || 0;
            }
            else {
                sum = value;
            }
        }
    });

    return sum;
}

const getPivotModeAvg = (colDef: ColDef | undefined, node: IRowNode | undefined) => {
    const sum = getPivotModeSum(colDef, node) || 0;
    const count = getPivotModeCount(colDef, node) || 0;

    return count > 0 ? sum / count : 0;
}

const getPivotModeFirst = (colDef: ColDef | undefined, node: IRowNode | undefined) => {
    const values = getPivotModeGroupValues(colDef, node); 

    return values.length > 0 ? values[0] : "";
}

const getPivotModeLast = (colDef: ColDef | undefined, node: IRowNode | undefined) => {
    const values = getPivotModeGroupValues(colDef, node); 

    return values.length > 0 ? values[values.length - 1] : "";
}

const getPivotModeCount = (colDef: ColDef | undefined, node: IRowNode | undefined) => {
    let count = 0;

    const values = getPivotModeGroupValues(colDef, node); 
    values.forEach(v => {
        const value = v && typeof v === "object"
            ? v.value
            : 0;

        count += value
    })

    return count;
}

const getPivotModeMax = (colDef: ColDef | undefined, node: IRowNode | undefined) => {
    let max = 0;

    const values = getPivotModeGroupValues(colDef, node); 
    values.forEach(val => {
        const v = Number(val || 0) || 0;
        if (v > max ) {
            max = v;
        }
    })

    return max;
}

const getPivotModeMin = (colDef: ColDef | undefined, node: IRowNode | undefined) => {
    let min: number | null = null;

    const values = getPivotModeGroupValues(colDef, node); 
    values.forEach(val => {
        const v = Number(val || 0) || 0;
        if (min === null || v < min) {
            min = v;
        }
    })

    return min;
}

const getPivotModeGroupValues = (colDef: ColDef | undefined, node: IRowNode | undefined) => {
    const values: any[] = [];

    const aggData = getAllPivotLeafAggData(node?.['childrenAfterAggFilter'] || []); 
    aggData.forEach(c => {
        const v = c[colDef?.colId || 0] || "";
        values.push(v);
    })
    
    return values;
}

export const getPivotModeAggValue = (colDef: ColDef | undefined, node: IRowNode | undefined) => {
    if (!node || !colDef) {
        return null;
    }
    const agg = colDef?.pivotValueColumn?.getAggFunc() == "sumAndGroup"
        ? colDef?.defaultAggFunc
        : colDef?.pivotValueColumn?.getAggFunc();

    let val: any = "";

    switch (agg) {
        case "sumAndGroup":
            val = getPivotModeSumAndGroup(colDef, node);
            break;
        case "sum":
            val = getPivotModeSum(colDef, node);
            break;
        case "first":
            val = getPivotModeFirst(colDef, node);
            break;
        case "last":
            val = getPivotModeLast(colDef, node);
            break;
        case "count":
            val = getPivotModeCount(colDef, node);
            break;
        case "min":
            val = getPivotModeMin(colDef, node);
            break;
        case "max":
            val = getPivotModeMax(colDef, node);
            break;
        case "avg":
            val = getPivotModeAvg(colDef, node);
            break;
    }

    return val;
}

const getCellRendererParams = (params: ICellRendererParams, renderer, showEmptyAggSum = false, groupedDataValue:  string | null | undefined = null) => {
    return {
        component: renderer,
        params: {
            ...params,
            groupedDataValue,
            showEmptyAggSum
        },
    };
}

const getCurrencyGroupedDataValue = (params: ICellRendererParams, isAutoColumn): CurrencyConvertData => {
    const node: any = params.node;
    let data: ReportData[] = [];
    let field = "";

    if (isPivotColumn(params)) {
        field = getPivotColumnField(params);
        data = getPivotColumnData(params)?.map(n => n['data'] || {});
    }
    else {
        const children = mergeAllLeafChildrenAfterFilter(params.node?.childrenAfterFilter); 

        data = !children?.[0] 
            ? [] 
            : children.map(n => n.data || {});

        field = isAutoColumn
            ? node.field
            : params.column?.getColId();
    }

    const convertData: CurrencyConvertData = field ? getCurrencyConvertData(data, field) : {amountConverted: 0, convertsNotFound: [], usedConverts: []};

    return convertData;
}

/**
 * Gets all data for Pivot cell.
 * @param params ICellRendererParams
 * @returns array of data for Pivot cell.
 */
const getPivotColumnData = (params: ICellRendererParams): any[] => {
    const node: any = params.node;
    const mapped = getLeafDataByKeys(node?.childrenMapped, params?.colDef?.pivotKeys || []);

    return mapped;
}
/**
 * Gets leaf rownode data array from data object where all keys given as parameter are used.
 * @param data data object where leaf data is got. 
 * @param keys array of keys to check from data.
 * @returns array of rownodes. empty array if some key(s) are not found in data or if array of rownode data is not found.
 */
export const getLeafDataByKeys = (data: any, keys: string[]) => {
    if (Array.isArray(data) && data[0] && data[0].hasOwnProperty('data')) {
        return data;
    }

    if (typeof data === 'object' && data !== null) {
        if (keys.length === 0) {
            // If keys array is empty, return leaf arrays from all keys in the object.
            return Object.values(data).flatMap((value) => getLeafDataByKeys(value, []) || []);
        }

        let key = "";
        // Check each key if some of them is found in data object.
        for (const k of keys) {
            if (data.hasOwnProperty(k)) {
                key = k;
                break;
            }
        }
        if (key) {
            const nextData = data[key];
            const remainingKeys = keys.filter(k => k !== key);
            return getLeafDataByKeys(nextData, remainingKeys);
        }
        
        return [];
    }
    return [];
}

const pickupFirstNode = (node) => {
    while (node && node.group) {
        node = node.childrenAfterFilter[0];
    }

    return node;
}

const getGroupedDataFirstValue = (node, column, isAutoColumn, params: ICellRendererParams | null = null) =>  {
    if (params && isPivotColumn(params)) {
        return typeof params.value === "string" && params.value?.includes("component_group_one_value")
            ? params.value.substring(26)
            : "";
    }

    const pickedNode = pickupFirstNode(node);

    if (!pickedNode?.data) {
        return "";
    } 

    return isAutoColumn
        ? pickedNode.data[node.field]
        : pickedNode.data[column]
}

export const isGroupedParent = (node: IRowNode): boolean =>  {
    return node?.group && !node?.footer ? true : false;
}

const isGroupedAutoColumn = (node, column): boolean =>  {
    return node.group && column === "ag-Grid-AutoColumn";
}

const isPivotColumn = (params: ICellRendererParams | IAggFuncParams | null) => {
    return (params?.colDef?.pivotKeys?.length || 0) > 0;
}

const getPivotColumnField = (params: ICellRendererParams | IAggFuncParams | null) => {
    return params?.colDef?.pivotValueColumn?.getColId() || "";
}

const localeTextComparator = (valueA, valueB): number => {
    if (valueA === null || valueB === null) {
        if (valueA === valueB) {
            return 0;
        } else if (valueA === null) {
            return -1;
        } else {
            return 1;
        }
    }

    return String(valueA).localeCompare(String(valueB));
}

const numberComparator = (valueA, valueB): number =>  {
    if (isNaN(valueA) || valueA == null) {
        return -1;
    }
    else if (isNaN(valueB) || valueB == null) {
        return 1;
    }

    valueA = Number(valueA);
    valueB = Number(valueB);

    if (valueA == valueB) return 0;

    return (valueA > valueB) ? 1 : -1;
}

const getStatusValue = (params, forFilter = false): string|string[] =>  {
    if (isGroupedParent(params?.node)) {
        return sumAndGroup(params);
    }

    if(!params || !params.data) {
        return '';
    }

    const value = params.data[params.colDef.field];

    if (Array.isArray(value)) {
        const values: string[] = [];

        for (const id of value) {
            const val = params.colDef.cellRendererParams.displayDatas?.find(el => el.id == id)
            val?.name && values.push(val.name);
        }
        return forFilter 
            ? values // For filter return array so filter value is shown for each status instead of status combination.
            : values.sort().join(","); // Return joined string so unique values show correctly, e.g. in group column if grouped by status.
    } else {
        const val = params.colDef.cellRendererParams.displayDatas?.find(el => el.id == value);

        return val?.name ?? '';
    }
}

/**
 * Gets all filtered leaf level children (=nodes that have the actual values) from children array.
 * @param children array of children 
 * @returns array of all filtered root level children.
 */
const mergeAllLeafChildrenAfterFilter = (children) => {
    const result: any[] = [];

    function collectChildren(c) {
        if (c.childrenAfterFilter && c.childrenAfterFilter.length > 0) {
            c.childrenAfterFilter.forEach(collectChildren);
        } else {
            result.push(c);
        }
    }

    children.forEach(collectChildren);
    return result;
}

/**
 * Gets all leaf node aggregation data for pivot column.
 * @param children array of children 
 * @returns array of all leaf node aggregation data for pivot column.
 */
const getAllPivotLeafAggData = (children) => {
    const result: any[] = [];

    function collectChildren(c) {
        if (c.childrenAfterAggFilter && c.childrenAfterAggFilter.length > 0 && !c.leafGroup) {
            c.childrenAfterAggFilter.forEach(collectChildren);
        } else {
            result.push(c.aggData || {});
        }
    }

    children.forEach(collectChildren);
    return result;
}

const getPivotResultColumnDisplayValues = (params: IAggFuncParams, noFormatting = false) =>  {
     // @ts-ignore
     const dataTypeDefinition = params.rowNode?.rowGroupColumn?.gridOptionsService?.gridOptions?.dataTypeDefinitions[params.colDef?.cellDataType];

    const values = params.values.map(v => {
        params['value'] = v;

        let displayValue = !noFormatting && typeof dataTypeDefinition?.valueFormatter === "function"
            ? dataTypeDefinition.valueFormatter(params)
            : v;

        if (Array.isArray(displayValue)) { // e.g. links-column return values as array, join them as string so unique values are got corectly.
            displayValue = displayValue.sort().join(", ");
        }
        return displayValue;
    })
    return values;
}

const getAllChildrenDisplayValues = (params: IAggFuncParams, noFormatting = false) =>  {
    if (params.pivotResultColumn) {
        return getPivotResultColumnDisplayValues(params, noFormatting);
    }
    
    const field = params.colDef?.field || "";

    const children = mergeAllLeafChildrenAfterFilter(params.rowNode?.childrenAfterFilter); 

    const values = children.map(d => {
        const value = d.data && d.data[field] ? d.data[field] : "";
        let displayValue = value;

        if (typeof params.colDef?.valueFormatter === "function" || typeof params.colDef?.valueGetter === "function") {
            const getterParams: any = params;
            getterParams.data = d.data;
            getterParams.node = d;
            getterParams.getValue = () => {};
            
            getterParams.value = typeof params.colDef?.valueGetter === "function"
                ? params.colDef.valueGetter(getterParams)
                : value;

            displayValue = !noFormatting && typeof params.colDef?.valueFormatter === "function"
                ? params.colDef.valueFormatter(getterParams)
                : getterParams.value;
        }

        if (Array.isArray(displayValue)) { // e.g. links-column return values as array, join them as string so unique values are got corectly.
            displayValue = displayValue.sort().join(", ");
        }
        return displayValue;
    });

    return values;
}

const dateFormats = ["DD.MM.YYYY",'MM/DD/YYYY', "YYYY-MM-DD"];

export const linkNumberComparator = (valueA, valueB): number =>  {
    if (isNaN(valueA) || valueA == null) {
        return -1;
    }
    else if (isNaN(valueB) || valueB == null) {
        return 1;
    }

    valueA = Number(valueA);
    valueB = Number(valueB);

    if (valueA == valueB) return 0;

    return (valueA > valueB) ? 1 : -1;
}

export const monthValueComparator = (valueA, valueB): number =>  {
    if (valueA == valueB) return 0;
    const today = new Date();
    // need to check whether the format from backend is 01/2024 or 1/2024
    const dateA = !valueA ? 0 : (valueA.startsWith('0') ? parse(valueA, 'MM/YYYY', today) : parse(valueA, 'M/YYYY', today));
    const dateB = !valueB ? 0 : (valueB.startsWith('0') ? parse(valueB, 'MM/YYYY', today) : parse(valueB, 'M/YYYY', today));
    return isBefore(dateA, dateB) ? -1 : 1;
}

export const quarterValueComparator = (valueA, valueB): number =>  {
    if (valueA == valueB) return 0;
    const today = new Date();
    const dateA = !valueA ? 0 : (parse(valueA.replace("Q", ""), 'M/YYYY', today));
    const dateB = !valueB ? 0 : (parse(valueB.replace("Q", ""), 'M/YYYY', today));
    return isBefore(dateA, dateB) ? -1 : 1;
}

const getTextSingleValue = (values) =>  {
    return (values[0] ? values[0] : '') + '';
}

const getComponentSingleValue = (values) => {
    if (!values[0]) {
        return '';
    }
    if (typeof values[0] === "string" && values[0]?.startsWith?.('component_group_one_value_')) {
        return values[0];
    }
    return 'component_group_one_value_' + values[0];
}

const getAggregateMultipleValues = (params: IAggFuncParams, isComponent = false, displayValues: any[] | null = null) => {
    const values = displayValues || getAllChildrenDisplayValues(params);
    const unique = [...new Set(values)];
   
    if(unique.length == 1) {
        if (isComponent) { 
            return getComponentSingleValue(values);
        }
        return getTextSingleValue(values);
    }

    return unique.length == 0 
        ? ""
        : "[aggregate_multiple_values]_" + unique.length;
}

const getAggregateSum = (params: IAggFuncParams, displayValues: any[] | null = null) => {
    const values = displayValues || getAllChildrenDisplayValues(params, true);

    let sum: number | null = null; // Put as null by default so doesn't show "0" if none of the children have value.
    (values || []).forEach(num => {
        if (typeof num === "number") { // Calculate only numbers into sum. Value is also not calculated if it is numeric string. So works same as "Sum" aggregate function. 
            if (sum === null) {
                sum = 0;
            }
            sum += num;
        }
    })
    return sum;
}

const getAggregateAvg = (params: IAggFuncParams, displayValues: any[] | null = null): number | null => {
    const values = displayValues || getAllChildrenDisplayValues(params, true);
    let sum: number | null = null; // Put as null by default so doesn't show "0" if none of the children have value.
    let valuesAmount = 0;

    (values || []).forEach(num => {
        if (typeof num === "number") { // Calculate only numbers into sum. Value is also not calculated if it is numeric string. So works same as "Avg" aggregate function. 
            if (sum === null) {
                sum = 0;
            }
            sum += num;
            valuesAmount++; // Calculate only added values to amount. So works same as "Avg" aggregate function. 
        }
    })

    const avg = sum === null 
        ? null
        : Number(sum / valuesAmount);

    return avg;
}

const getCustomFieldAggregateValue = (params: IAggFuncParams, isComponentType) => {
    const values = getAllChildrenDisplayValues(params, true);
    let hasTextValues = false;
    let numericValues: (number | null)[] = [];

    (values || []).forEach(val => {
        if (val && !Number.isFinite(Number(val.replace ? val.replace(",", ".") : val))) {
            hasTextValues = true;

        }
    })

    if (!hasTextValues) {
        numericValues = values.map(v => {
            return v ? Number(v.replace ? v.replace(",", ".") : v) : null; // If value is empty return null, so column shows empty if none of the children have value.
        })
    }

    return !hasTextValues
        ? getAggregateSum(params, numericValues)
        : getAggregateMultipleValues(params, isComponentType, values);
}

const calculateGroupedRateFromChildren = (children: IRowNode[], dividend: string, divisor: string): number | null => {
    const data = mergeAllLeafChildrenAfterFilter(children); 
    let a = 0, b = 0;

    data.forEach(d => {
        a += Number(d.data ? d.data[dividend] : 0) || 0;
        b += Number(d.data ? d.data[divisor] : 0) || 0;
    });

    if (b == 0) {
        return null;
    }

    return a / b;
}

const calculateGroupedRate = (node: IRowNode, colDef: ColDef): number | null => {
    /*
         // Doesn't work if aggfunc has been changed for dividend or divisor column.
         const data = params.node.aggData;
         const dividend = data[params.colDef.calculateGroupedRate.dividend] || 0;
         const divisor = data[params.colDef.calculateGroupedRate.divisor] || 1; 
         return `${roundPercent(dividend / divisor)} %`;
     */

    colDef = colDef || {};

    const val = calculateGroupedRateFromChildren(
        node?.childrenAfterFilter || [], 
        colDef['calculateGroupedRate'].dividend,
        colDef['calculateGroupedRate'].divisor
    )

    return val !== null ? roundToFixedNumber(val, 2) : null;
}

export const shouldCalculateGroupedRate = (node: IRowNode, colDef: ColDef) => {
    colDef = colDef || {};
    return node?.group 
        && (colDef.cellDataType === 'percentage' || colDef.cellDataType === 'currency')
        && colDef['calculateGroupedRate'];
}

export const sumAndGroup: IAggFunc = (params: IAggFuncParams) =>  {
    if (!params.rowNode && params['node']) {
        params.rowNode = params['node'];
    }

    if (shouldCalculateGroupedRate(params.rowNode, params.colDef)) {
        return calculateGroupedRate(params.rowNode, params.colDef);
    }

    const sumAndGroupType = getSumAndGroupType(params);

    if (sumAndGroupType == "avg") {
        return getAggregateAvg(params);
    }
    else if (params.colDef['isCustomField'] && sumAndGroupType != "group") {
        return getCustomFieldAggregateValue(params, isComponentType(params));
    }
    else if (sumAndGroupType == "group") {
        return getAggregateMultipleValues(params, isComponentType(params));
    }

    return getAggregateSum(params);
}

/**
 * Gets type that sumAndGroup function should return for column.
 * If column has no sumAndGroupType defined and is not in sum-types: "group" is returned (e.g. for percentage by default).
 * If column is in sum-types but has sumAndGroupType "group" defined: "group" is returned.
 * @param params 
 * @returns type for sumAndGroup function: "group" | "sum" | "avg"
 */
const getSumAndGroupType = (params: Partial<IAggFuncParams>): "group" | "sum" | "avg" =>  {
    if (params?.colDef && params?.colDef['sumAndGroupType'] == "avg") {
        return "avg";
    }

    const showGrouped = params.colDef && (
        params.colDef['sumAndGroupType'] == "group" || isComponentType(params)
    );
    const showSum = params.colDef && (
        params.colDef['sumAndGroupType'] == "sum" || isSumType(params)
    )

    return showGrouped || !showSum
        ? "group"
        : 'sum';
}

const isComponentType = (params: Partial<IAggFuncParams>): boolean =>  {
    const cellDataType = params.colDef?.cellDataType;

    // variable-type needed in component types so translations show in grouped rows. So when one value, cellRendererSelector takes the first value and uses VariableCellRenderer to show it.
    const componentMultipleValueTypes = ["link", "links", "status", "variable"];
    return componentMultipleValueTypes.find(c => c == cellDataType) ? true : false;
}

const isSumType = (params: Partial<IAggFuncParams>): boolean =>  {
    const cellDataType = params.colDef?.cellDataType;

    const sumTypes = ["number", "hour", "currency"]
    return sumTypes.find(c => c == cellDataType) ? true : false;
}

export const columnSorter = (columnOrder) => {

    return (a, b) => {
        let aVal = columnOrder.indexOf(a.field);
        let bVal = columnOrder.indexOf(b.field);
        if(aVal < 0) {
            aVal = columnOrder.length + 1;
        }
        if(bVal < 0) {
            bVal = columnOrder.length + 1;
        }
        return aVal - bVal;
    }
}

function parseDateTime(value: unknown): Date {

    if (value instanceof Date) {
        return value;
    }

    const str = String(value);

    if (str.length == 5) {
        return parse(str, 'HH:mm', new Date());
    }
    if (str.length == 8) {
        return parse(str, 'HH:mm:SS', new Date());
    }

    return parse(str, 'YYYY-MM-DD HH:mm:SS', new Date());
}

const isPivotTotalRow = (params: ICellRendererParams) => {
    return params.data?.rowType == "totals_row" && (params.colDef?.pivotKeys || []).length > 0;
}

export const getVariableDisplayValue = (value, displayDatas: DisplayDataObject[], tr: ((text, replacers?: Record<string, string>) => string) | null = null, valueHasNames = false) => {
    const displayDatasClone = cloneDeep(displayDatas); // Take cloneDeep from displayDatas so values don't get replaced by translated ones.
    let displayData: DisplayDataObject[] = [];

    if (Array.isArray(value)) {
      for (const id of value) {
        const val = displayDatasClone?.find(el => valueHasNames ? el.name == id : el.id == id)
        val?.name && displayData.push(val);
      }
    } else {
      const val = displayDatasClone?.find(el => valueHasNames ? el.name == value : el.id == value);
      val && displayData.push(val);
    }

    if(!displayData) {
        return "";
    }

    if (tr) {
      displayData = displayData.map((ddo: DisplayDataObject) => {
        ddo.name = tr ? tr(ddo.name) : ddo.name;
        return ddo;
      });
    }

    return displayData.map(dd => dd.name).join(", ");
}

export const getStatusDisplayValue = (value, displayDatas: DisplayDataObject[], displayAsTextValues, tr: ((text, replacers?: Record<string, string>) => string) | null = null, valueHasNames = false) => {
    const ids = Array.isArray(value) ? value.map(x => String(x)) : [String(value)];

    let displayData = uniqBy(cloneDeep(displayDatas?.filter(dd => ids.includes(String(valueHasNames ? dd.name : dd.id)))), d => valueHasNames ? d.name : d.id);

    if (!displayData || displayData.length === 0) {
        return "";
    }

    if (tr) {
        displayData = displayData.map((ddo: DisplayDataObject) => {
            ddo.name = tr(ddo.name);
            return ddo;
        });
    }

    if (Array.isArray(displayData) && displayAsTextValues) {
        return displayData.map(dd => dd.name).join(", ");
    }

    return displayData;
}

const hidePivotModeTotal = (params) => {
    return params?.colDef?.pivotValueColumn &&
        params?.node?.rowPinned && 
        (!params?.colDef?.grandTotal || params?.colDef?.grandTotal == "none")
}

export type ColumnDefinition = UserColumnDefinition & ProgramColumnDefinition;

/**
 * 
 * @param type 
 * @param tr Shoud be null if translations are not used.
 * @returns 
 */
export default function getColumnType(type, tr: ((text, replacers?: Record<string, string>) => string) | null = null, columnKey?: string): ProgramColumnDefinition {
    switch(type) {
        case 'link':
            return {
                cellDataType: 'link',
                cellRenderer: LinkCellRenderer,
                filter: 'agMultiColumnFilter',
                filterValueGetter: (params) => {
                    const val = params.data[params.colDef.field];

                    if (!val || val.hidden) {
                        return '';
                    }

                    return val?.name || val?.url;
                },
                filterParams: {
                    textMatcher: ({value, filterText}) => {
                        return value.toLowerCase().includes(filterText.toLowerCase());
                    },
                    filters: [
                        {
                            filter: 'agTextColumnFilter'
                        },
                        {
                            filter: 'agSetColumnFilter',
                            filterParams: {
                                buttons: ['reset'],
                                comparator: localeTextComparator,
                            }
                        }
                    ]
                },
                cellRendererSelector: (params) => cellRendererSelector(params, LinkCellRenderer),
                valueGetter: params => {
                    if (hidePivotModeTotal(params)) {
                        return "";
                    }
                    if (isGroupedParent(params?.node)) {
                        return sumAndGroup(params);
                    }

                    if(!params.data || !params.data[params.colDef.field]) {
                        if(params.node && params.node.group && params.node.groupData) {
                            return params.node.groupData["ag-Grid-AutoColumn"];
                        }
                        return '';
                    }
                    const val = params.data[params.colDef.field];
                    return val.name || val.url;
                },
                cellClass: 'export_columnText',
                defaultAggFunc: 'sumAndGroup',                
            };
        case 'links':
            return {
                cellDataType: 'link',
                cellRenderer: LinksCellRenderer,
                filter: 'agMultiColumnFilter',
                filterParams: {
                    filters: [
                        {
                            filter: 'agTextColumnFilter'
                        },
                        {
                            filter: 'agSetColumnFilter',
                            filterParams: {
                                buttons: ['reset'],
                                comparator: localeTextComparator,
                            }
                        }
                    ]
                }, 
                filterValueGetter: (params) => {
                    const val = params.data[params.colDef.field] ?? [];
                    return Array.isArray(val) ? val.map(x => x.name) : val.name;
                },
                cellRendererSelector: (params) => cellRendererSelector(params, LinksCellRenderer),
                valueGetter: params => {
                    if (hidePivotModeTotal(params)) {
                        return "";
                    }
                    if (isGroupedParent(params?.node)) {
                        return sumAndGroup(params);
                    }

                    if(!params.data || !params.data[params.colDef.field]) {
                        if(params.node && params.node.group && params.node.groupData) {
                            return params.node.groupData["ag-Grid-AutoColumn"];
                        }
                        return '';
                    }
                    const val = params.data[params.colDef.field] ?? [];
                    return Array.isArray(val) ? val.map(x => x.name) : val.name;
                },
                cellClass: 'export_columnText',
                defaultAggFunc: 'sumAndGroup',
            };
        case 'variable':
            return {
                cellDataType: 'variable',
                cellRenderer: VariableCellRenderer,
                filter: 'agMultiColumnFilter',
                filterParams: {
                    filters: [
                        {
                            filter: 'agTextColumnFilter',
                            filterParams: {
                                textMatcher: ({ value, filterText }) => {
                                    const val = tr && value ? tr(value) : value;
                                    return val?.toLowerCase().includes(filterText?.toLowerCase());
                                },
                                caseSensitive: true // Needed so value isn't changed to lowercase in textMatcher params and translations work.
                            }
                        },
                        {
                            filter: 'agSetColumnFilter',
                            filterParams: {
                                buttons: ['reset'],
                                keyCreator: params => params.value,
                                valueFormatter: params => tr && params.value // tr should come from props only if translations needed.
                                    ? tr(params.value)
                                    : params.value,
                                comparator: (a, b) => {
                                    if (tr) { // tr should come from props only if translations needed.
                                        a = !a ? a : tr(a);
                                        b = !b ? b : tr(b);
                                    }
                                    return localeTextComparator(a, b);
                                }  
                            }
                        }
                    ]
                },                
                cellRendererSelector: (params) => cellRendererSelector(params, VariableCellRenderer),
                valueGetter: params => {
                    if (hidePivotModeTotal(params)) {
                        return "";
                    }
                    if (isGroupedParent(params?.node)) {
                        return sumAndGroup(params);
                    }

                    if(!params || !params.data) {
                        return '';
                    }

                    const value = params.data[params.colDef.field];

                    if (Array.isArray(value)) {
                        const values: any[] = [];

                        for (const id of value) {
                            const val = params.colDef.cellRendererParams.displayDatas?.find(el => el.id == id)
                            val?.name && values.push(val.name);
                        }

                        return values;
                    } else {
                        const val = params.colDef.cellRendererParams.displayDatas?.find(el => el.id == value);

                        return val?.name ?? '';
                    }
                },
                cellClass: 'export_columnText',
                defaultAggFunc: 'sumAndGroup',
                keyCreator: params => tr && params.colDef?.enablePivot && params.value 
                    ? tr(params.value) 
                    : params.value,
            };
        case 'status':
            return {
                cellDataType: 'status',
                cellRenderer: StatusCellRenderer,
                filter: 'agMultiColumnFilter',
                filterParams: {
                    filters: [
                        {
                            filter: 'agTextColumnFilter',
                            filterParams: {
                                textMatcher: ({ value, filterText }) => {
                                    const val = tr && value ? tr(value) : value;
                                    return val?.toLowerCase().includes(filterText?.toLowerCase());
                                },
                                caseSensitive: true // Needed so value isn't changed to lowercase in textMatcher params and translations work.
                            }
                        },
                        {
                            filter: 'agSetColumnFilter',
                            filterParams: {
                                buttons: ['reset'],
                                keyCreator: params => params.value,
                                valueFormatter: params => tr && params.value  // tr should come from props only if translations needed.
                                    ? tr(params.value)
                                    : params.value,    
                                comparator: (a, b) => {
                                    if (tr) { // tr should come from props only if translations needed.
                                        a = !a ? a : tr(a);
                                        b = !b ? b : tr(b);
                                    }
                                    return localeTextComparator(a, b);
                                }                  
                            }
                        }
                    ]
                },
                cellRendererSelector: (params) => cellRendererSelector(params, StatusCellRenderer),
                filterValueGetter: (params) => getStatusValue(params, true),
                valueGetter: params => getStatusValue(params),
                cellClass: (params) => {
                    let autoHeightClass = "";
                    if (params.colDef?.autoHeight) {
                        autoHeightClass = " autoHeight-row-status";
                    }

                    return 'export_columnText' + autoHeightClass
                },
                defaultAggFunc: 'sumAndGroup',
                keyCreator: params => tr && params.colDef?.enablePivot && params.value 
                    ? tr(params.value) 
                    : params.value,
            };
        case 'multi':
            return {
                cellDataType: 'multi',
                cellRenderer: MultiCellRenderer,
                filter: 'agMultiColumnFilter',
                filterParams: {
                    filters: [
                        {
                            filter: 'agTextColumnFilter',
                            filterParams: {
                                textMatcher: ({ value, filterText }) => {
                                    const val = tr && typeof value === "string" && value?.startsWith("[translate]")
                                        ? tr(value.substring('[translate]'.length))
                                        : value;

                                    return typeof val === "string" ? val?.toLowerCase().includes(filterText?.toLowerCase()) : false;
                                },
                                caseSensitive: true // Needed so value isn't changed to lowercase in textMatcher params and translations work.
                            }
                        },
                        {
                            filter: 'agSetColumnFilter',
                            filterParams: {
                                buttons: ['reset'],
                                keyCreator: params => params.value,
                                valueFormatter: params => {
                                    return tr && typeof params?.value === "string" && params.value?.startsWith("[translate]")
                                        ? tr(params.value.substring('[translate]'.length))
                                        : params.value;
                                },                                
                                comparator: (a, b) => {
                                    if (tr && typeof a === "string" && a?.startsWith("[translate]")) { 
                                       a = tr(a.substring('[translate]'.length));
                                    }
                                    if (tr && typeof b === "string" && b?.startsWith("[translate]")) { 
                                        b = tr(b.substring('[translate]'.length));
                                    }
                                    return localeTextComparator(a, b);
                                }  
                            }
                        }
                    ],
                },
                cellRendererSelector: (params) => cellRendererSelector(params, MultiCellRenderer),
                valueGetter: params => {
                    if (hidePivotModeTotal(params)) {
                        return "";
                    }
                    if (isGroupedParent(params?.node)) {
                        return sumAndGroup(params);
                    }

                    if(!params || !params.data) {
                        return null;
                    }
                    const val = params.data[params.colDef.field];
                    return val;
                },
                defaultAggFunc: 'sumAndGroup',
                keyCreator: params => tr && params.colDef?.enablePivot && typeof params?.value === "string" && params.value?.startsWith("[translate]")
                    ? tr(params.value.substring('[translate]'.length))
                    : params.value
            };
        case 'date':
            return {
                cellDataType: 'date',
                chartDataType: 'time',
                filter: 'agDateColumnFilter',
                filterParams: {
                    comparator: (filterLocalDateAtMidnight: Date, cellValue: Date|Date[]|null|undefined) => {
                        const cellDate = Array.isArray(cellValue) ? min(cellValue) : cellValue;

                        if (!cellDate) {
                            return -1;
                        }

                        if (cellDate < filterLocalDateAtMidnight) {
                          return -1;
                        }
                    
                        if (cellDate > filterLocalDateAtMidnight) {
                          return 1;
                        }
                        return 0;
                    },
                    buttons: ['reset'],
                    inRangeInclusive: true,
                },
                comparator: (valueA: string|Date, valueB: string|Date, nodeA: any, nodeB: any) => {
                    if (nodeA?.groupData && nodeA?.groupData['ag-Grid-AutoColumn']) { // Autocolumn valueA is formatted string value. Get first value from node groupData so we know the date format "YYYY-MM-DD" and can parse Date object with that.
                        valueA = getGroupedDataFirstValue(nodeA, columnKey, false);
                    }
                    if (nodeB?.groupData && nodeB?.groupData['ag-Grid-AutoColumn']) {
                        valueB = getGroupedDataFirstValue(nodeB, columnKey, false);
                    }
                    
                    let a;
                    let b;

                    if (isDate(valueA) && isValid(valueA)) {
                        a = valueA;
                    } else {
                        dateFormats.forEach(dateFormat => {
                            if (!isValid(a)) {
                                //@ts-ignore
                                a = parse(valueA, dateFormat, new Date());
                            }
                        })
                    }

                    if (isDate(valueB) && isValid(valueB)) {
                        b = valueB;
                    } else {
                        dateFormats.forEach(dateFormat => {
                            if (!isValid(b)) {
                                //@ts-ignore
                                b = parse(valueB, dateFormat, new Date());
                            }
                        })
                    }

                    if (isValid(a) && !isValid(b)) {
                        return 1;
                    }

                    if (!isValid(a) && isValid(b)) {
                        return -1;
                    }

                    if (isBefore(b, a)) {
                        return 1;
                    }
                    else if (isBefore(a, b)) {
                        return -1;
                    }
                    return 0;
                },
                pivotComparator: (valueA: string|Date, valueB: string|Date) => {
                    let a;
                    let b;

                    if (isDate(valueA) && isValid(valueA)) {
                        a = valueA;
                    } else {
                        dateFormats.forEach(dateFormat => {
                            if (!isValid(a)) {
                                //@ts-ignore
                                a = parse(valueA, dateFormat, new Date());
                            }
                        })
                    }

                    if (isDate(valueB) && isValid(valueB)) {
                        b = valueB;
                    } else {
                        dateFormats.forEach(dateFormat => {
                            if (!isValid(b)) {
                                //@ts-ignore
                                b = parse(valueB, dateFormat, new Date());
                            }
                        })
                    }

                    if (isBefore(b, a)) {
                        return 1;
                    }
                    else if (isBefore(a, b)) {
                        return -1;
                    }
                    return 0;
                },
                valueGetter: params => {
                    if (hidePivotModeTotal(params)) {
                        return "";
                    }
                    if (isGroupedParent(params?.node)) {
                        return sumAndGroup(params);
                    }

                    if(!params || !params.data) {
                        return null;
                    }
                    const val = params.data[params.colDef.field];

                    // Required for CSV exports
                    if (val === '0000-00-00') {
                        return null;
                    }

                    if (Array.isArray(val)) {
                        return val.map(x => parse(x, 'YYYY-MM-DD', new Date())).filter(x => !isNaN(x.getTime()));
                    }

                    return val ? parse(val, 'YYYY-MM-DD', new Date()) : null;
                },
                cellRendererSelector: (params) => cellRendererSelector(params),
                cellClass: 'export_columnDate',
                defaultAggFunc: 'sumAndGroup',
            };
        case 'time':
            return {
                cellDataType: 'time',
                filter: 'agTextColumnFilter',
                filterParams: {
                    buttons: ['reset']
                },
                filterValueGetter: (params) => { 
                    if(!params || !params.data) {
                        return "";
                    }
                    const val = params.data[params.colDef.field];

                    if (Array.isArray(val)) {
                        const values = val.map(x => parseDateTime(x)).filter(x => !isNaN(x.getTime()));
                        return values.map(x => moment(x)?.format('LT'))?.join(", ") || "";
                    }

                    return val ? moment(parseDateTime(val))?.format('LT') : "";
                },
                valueGetter: params => {
                    if (hidePivotModeTotal(params)) {
                        return "";
                    }
                    if (isGroupedParent(params?.node)) {
                        return sumAndGroup(params);
                    }

                    if(!params || !params.data) {
                        return null;
                    }
                    const val = params.data[params.colDef.field];

                    // Required for CSV exports
                    if (val === '0000-00-00') {
                        return null;
                    }

                    if (Array.isArray(val)) {
                        return val.map(x => parseDateTime(x)).filter(x => !isNaN(x.getTime()));
                    }

                    return val ? parseDateTime(val) : null;
                },
                cellRendererSelector: (params) => cellRendererSelector(params),
                cellClass: "export_columnText",
                defaultAggFunc: 'sumAndGroup',
            };
        case 'number':
            return {
                cellDataType: 'number',
                filter: 'agNumberColumnFilter',
                filterParams: {
                    inRangeInclusive: true,
                    buttons: ['reset'],
                },
                cellRendererSelector: (params) => cellRendererSelector(params, FormattedValueCellRenderer),
                filterValueGetter: (params) => { 
                    const decimals = params.colDef['decimalAmount'] ?? 2;

                    const val = params.data[params.colDef.field];

                    if (val === null || val === undefined) {
                        return val;
                    }

                    return Number(Number(val).toFixed(decimals)) ?? ""; // Done so string values are found with filter. For example mileage for travel expenses need to be string so it is not calculated to totals but still needs to befound with filter.
                },
                cellClass: (params): string[]|undefined =>  {
                    // Align number value to left if not calculateable value e.g. Year.
                    const classes = params.colDef['aggFuncType'] == "group"
                        ? []
                        : ["ag-right-aligned-cell"]

                    if (params.colDef.postfix) {
                        classes.push("postfixFormat_" + params.colDef.postfix);
                    }
                    return classes;
                },
                valueGetter: (params) => {
                    if (hidePivotModeTotal(params)) {
                        return "";
                    }
                    if (isGroupedParent(params?.node)) {
                        return sumAndGroup(params);
                    }

                    if(!params || !params.data) {
                        return '';
                    }

                    const decimals = params.colDef['decimalAmount'] ?? 2;

                    let val = params.data[params.colDef.field];

                    if (val === null || val === undefined || val === '') {
                        return val;
                    }

                    const ignoreFromAggregate = typeof val === params.colDef.ignoreFromAggregateType;
                    val = Number(val);

                    return isNaN(val) ? '' : (
                        ignoreFromAggregate
                            ? val.toFixed(decimals)   // string is not calculated to aggregate sum.
                            : Number(val.toFixed(decimals))
                        );
                },
                defaultAggFunc: 'sum',
            };
        case 'hour':
            return {
                cellDataType: 'hour',
                filter: 'agNumberColumnFilter',
                filterParams: {
                    inRangeInclusive: true,
                    buttons: ['reset'],
                },
                useValueFormatterForExport: true,
                cellRendererSelector: (params) => cellRendererSelector(params, FormattedValueCellRenderer),
                filterValueGetter: (params) => { 
                    const val = params.data[params.colDef.field];
                    return Number(Number(val).toFixed(2)) ?? ""; // Done so string values are found with filter. For example mileage for travel expenses need to be string so it is not calculated to totals but still needs to befound with filter.
                },
                valueGetter: (params) => {
                    if (hidePivotModeTotal(params)) {
                        return "";
                    }
                    if (isGroupedParent(params?.node)) {
                        return sumAndGroup(params);
                    }

                    if(!params || !params.data) {
                        return '';
                    }

                    // Don't show totals in Pivot-mode
                    if (isPivotTotalRow(params)) {
                        return null;
                    }

                    let val = params.data[params.colDef.field];

                    if (val === null || val === '') {
                        return val;
                    }

                    val = Number(val);
                    return isNaN(val) ? '' : Number(val.toFixed(2));
                },
                cellClass: (params): string[]|undefined =>  {
                    return ["ag-right-aligned-cell", "postfixFormat_h"];
                },
                defaultAggFunc: 'sum',
            };
        case 'currency':
            return {
                cellDataType: 'currency',
                cellRenderer: CurrencyCellRenderer,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    inRangeInclusive: true,
                    buttons: ['reset'],
                },
                cellRendererSelector: (params) => cellRendererSelector(params, CurrencyCellRenderer),
                filterValueGetter: (params) => { 
                    const val = params.data[params.colDef.field];
                    if (val == null || isNaN(val)) {
                        return '';
                    }
                    return Number(val) || 0;
                },
                valueGetter: (params) => { 
                    if (hidePivotModeTotal(params)) {
                        return "";
                    }
                    if (isGroupedParent(params?.node)) {
                        return sumAndGroup(params);
                    }

                    const val = params.data ? params.data[params.colDef.field] : null;
                    if (val == null || isNaN(val)) {
                        return null;
                    }
                    return Number(val) || 0;
                },
                comparator: (valueA, valueB) => {
                    return numberComparator(valueA, valueB)
                },
                cellClass: (params) =>  {
                    const node = pickupFirstNode(params?.node) ?? params;
                    const data = node?.data || {};
                    const currency = params.colDef['noCurrencyConvert'] 
                        ? data?.currency
                        : data.default_currency; // default_currency is currency that is selected in report.

                    return params.value === null   
                        ? ["ag-right-aligned-cell"]
                        : ["ag-right-aligned-cell", "currencyFormat_" + currency?.toUpperCase()];
                },
                defaultAggFunc: 'sum',
                //cellRendererSelector: cellRendererSelector,
            };
        case 'percentage':
            return {
                cellDataType: 'percentage',
                filter: 'agNumberColumnFilter',
                filterParams: {
                    inRangeInclusive: true,
                    buttons: ['reset'],
                },
                filterValueGetter: (params) => { 
                    const val = params.data[params.colDef.field];
                    if (val === null || isNaN(val)) {
                        return '';
                    }
                    return (Number(val) * 100) || 0;
                },
                valueGetter: (params) => { 
                    if (hidePivotModeTotal(params)) {
                        return "";
                    }

                    if (shouldCalculateGroupedRate(params.node, params.colDef)) {
                        return calculateGroupedRate(params.node, params.colDef);
                    }

                    if (isGroupedParent(params?.node)) {
                        return sumAndGroup(params);
                    }

                    const val = params.data ? params.data[params.colDef.field] : null;
                    if (val === null || isNaN(val)) {
                        return null;
                    }
                    return Number(val) || 0;
                },
                comparator: (valueA, valueB) => {
                    return numberComparator(valueA, valueB)
                },
                cellClass: (params) =>  {
                    const data = params.data || {};
                    const val = data[params.colDef.field] || params.value; // params.value is used for grouped rows.

                    return (val === null || val === "" || isNaN(val)) 
                        ? ["ag-right-aligned-cell", "export_columnTextRight"]
                        : ["ag-right-aligned-cell", "export_columnPercentage"]
                },
                cellRendererSelector: (params) => cellRendererSelector(params, FormattedValueCellRenderer),
                defaultAggFunc: 'sumAndGroup',
                /*
                valueGetter: p => {
                    return `${p.data[p.colDef.field] * 100}%`;
                }
                */
                //cellRendererSelector: cellRendererSelector,
            };
        case 'textLink':
            return {
                filter: 'agMultiColumnFilter',
                filterParams: {
                    filters: [
                        {
                            filter: 'agTextColumnFilter',
                        },
                        {
                            filter: 'agSetColumnFilter',
                            filterParams: {
                                buttons: ['reset'],
                                comparator: localeTextComparator,
                            }
                        }
                    ],
                },
                cellDataType: 'textLink',
                cellRenderer: TextLinkCellRenderer, 
                cellRendererSelector: (params) => cellRendererSelector(params, TextLinkCellRenderer),
                valueGetter: (params) => { 
                    if (hidePivotModeTotal(params)) {
                        return "";
                    }
                    if (isGroupedParent(params?.node)) {
                        return sumAndGroup(params);
                    }

                    const val = params.data ? params.data[params.colDef.field] : null;
                    if (val == null) {
                        return '';
                    }
                    return val;
                },
                defaultAggFunc: 'sumAndGroup',
            };
        case 'emailLink':
            return {
                filter: 'agMultiColumnFilter',
                filterParams: {
                    filters: [
                        {
                            filter: 'agTextColumnFilter',
                        },
                        {
                            filter: 'agSetColumnFilter',
                            filterParams: {
                                buttons: ['reset'],
                                comparator: localeTextComparator,
                            }
                        }
                    ],
                },
                cellDataType: 'emailLink',
                cellRenderer: EmailLinkCellRenderer, 
                cellRendererSelector: (params) => cellRendererSelector(params, EmailLinkCellRenderer),
                valueGetter: (params) => { 
                    if (hidePivotModeTotal(params)) {
                        return "";
                    }
                    if (isGroupedParent(params?.node)) {
                        return sumAndGroup(params);
                    }

                    const val = params.data ? params.data[params.colDef.field] : null;

                    if (val == null) {
                        return '';
                    }

                    return val;
                },
                defaultAggFunc: 'sumAndGroup',
            };
        case 'month':
            return {
                filter: 'agMultiColumnFilter',
                cellDataType: 'text',
                cellRendererSelector: cellRendererSelector,
                cellClass: 'export_columnText',
                filterParams: {
                    filters: [
                        {
                            filter: 'agTextColumnFilter',
                        },
                        {
                            filter: 'agSetColumnFilter',
                            filterParams: {
                                buttons: ['reset'],
                                comparator: monthValueComparator
                            }
                        }
                    ],
                    comparator: monthValueComparator
                },
                valueGetter: (params) => { 
                    if (hidePivotModeTotal(params)) {
                        return "";
                    }
                    if (isGroupedParent(params?.node)) {
                        return sumAndGroup(params);
                    }

                    const val = params.data ? params.data[params.colDef.field] : null;
                    if (val == null) {
                        return '';
                    }
                    return val;
                },
                defaultAggFunc: 'sumAndGroup',
                comparator: monthValueComparator,
                pivotComparator: monthValueComparator,
            };
            case 'quarter':
                return {
                    filter: 'agMultiColumnFilter',
                    cellDataType: 'text',
                    cellRendererSelector: cellRendererSelector,
                    cellClass: 'export_columnText',
                    filterParams: {
                        filters: [
                            {
                                filter: 'agTextColumnFilter',
                            },
                            {
                                filter: 'agSetColumnFilter',
                                filterParams: {
                                    buttons: ['reset'],
                                    comparator: quarterValueComparator
                                }
                            }
                        ],
                        comparator: quarterValueComparator
                    },
                    valueGetter: (params) => { 
                        if (hidePivotModeTotal(params)) {
                            return "";
                        }
                        if (isGroupedParent(params?.node)) {
                            return sumAndGroup(params);
                        }
    
                        const val = params.data ? params.data[params.colDef.field] : null;
                        if (val == null) {
                            return '';
                        }
                        return val;
                    },
                    defaultAggFunc: 'sumAndGroup',
                    comparator: quarterValueComparator,
                    pivotComparator: quarterValueComparator,
                };
        case 'icon_text':
            return {
                cellDataType: 'icon_text',
                cellRenderer: IconCellRenderer,
                filter: 'agMultiColumnFilter',
                filterParams: {
                    filters: [
                        {
                            filter: 'agTextColumnFilter',
                        },
                        {
                            filter: 'agSetColumnFilter',
                            filterParams: {
                                buttons: ['reset'],
                                comparator: localeTextComparator,
                            }
                        }
                    ],
                },
                cellRendererSelector: (params) => cellRendererSelector(params, IconCellRenderer),
                valueGetter: (params) => { 
                    if (hidePivotModeTotal(params)) {
                        return "";
                    }
                    if (isGroupedParent(params?.node)) {
                        return sumAndGroup(params);
                    }

                    const val = params.data ? params.data[params.colDef.field] : null;
                    if (val == null) {
                        return '';
                    }

                    return val;
                },
                cellClass: 'export_columnText',
                defaultAggFunc: 'sumAndGroup',
            };
        case 'text':
        default:
            return {
                filter: 'agMultiColumnFilter',
                cellDataType: 'text',
                cellRendererSelector: cellRendererSelector,
                cellClass: (params) =>  {
                    let autoHeightClass = "";
                    if (params.colDef?.autoHeight) {
                        autoHeightClass = " autoHeight-cell";
                    }

                    // If not custom field: Show always as text in export.
                    if (!params.colDef?.isCustomField) {
                        return "export_columnText" + autoHeightClass;
                    }

                    const data = params.data || {};
                    const val = data[params.colDef.field] || params.value; // params.value is used for grouped rows.
                    
                    // If custom field: Show numeric as number in export and aligned to right in ag-grid column..
                    const isText = val && !Number.isFinite(Number(val));
                    return (val === null || val === "" || isText) 
                        ? "export_columnText" + autoHeightClass
                        : "ag-right-aligned-cell" + autoHeightClass;
                },
                filterParams: {
                    filters: [
                        {
                            filter: 'agTextColumnFilter',
                        },
                        {
                            filter: 'agSetColumnFilter',
                            filterParams: {
                                buttons: ['reset'],
                                comparator: localeTextComparator,
                                keyCreator: params => {
                                    return params.value?.replace(/(\r\n|\n|\r)/gm, " ");
                                },
                                valueFormatter: params => {
                                    return params.value?.replace(/(\r\n|\n|\r)/gm, " ");
                                }
                            }
                        }
                    ],
                },
                valueGetter: (params) => { 
                    if (hidePivotModeTotal(params)) {
                        return "";
                    }
                    if (isGroupedParent(params?.node)) {
                        return sumAndGroup(params);
                    }

                    let val = params.data ? params.data[params.colDef.field] : null;
                    if (val == null) {
                        return '';
                    }

                    // If custom field: Return numeric values as numbers, so Sum-aggregation works.
                    if (params.colDef?.isCustomField) {
                        const isText = val && !Number.isFinite(Number(val));
                        if (!isText) {
                            val = Number(val);
                        }
                    }

                    return val;
                },
                defaultAggFunc: 'sumAndGroup',
            };
    }
}

interface CalendarHeaderTemplateParams {
    tr: (text, replacers?: Record<string, string>) => string;
    startDate?: string | null;
    endDate?: string | null;
}

export const CalendarHeaderTemplate = ({tr, startDate, endDate}: CalendarHeaderTemplateParams) => `<div class="ag-cell-label-container" role="presentation">
<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
<div ref="eLabel" class="ag-header-cell-label" role="presentation">
    <div class="ag-calendar-header-label">
        <span ref="eText" class="ag-header-cell-text"></span>
        <!--<img src="${calendarIcon}" title="${startDate && endDate ? tr('Filtered to material timespan: ${start} - ${end}', {start: startDate, end: endDate}) : tr('Filtered to material timespan')}" />-->
        <img src="${calendarIcon}" title="${tr('Filtered to material timespan')}" />
    </div>
    <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
    <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
    <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
    <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
    <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
</div>
</div>`;

import React, { Component } from 'react';
import cn from 'classnames';
import FilterDropdownCPQ from '../../../general/FilterDropdownCPQ';
import OutlinedField from '../../../general/OutlinedField';
import { SettingsContext } from '../../../SettingsContext';
import TaimerComponent from '../../../TaimerComponent';
import { returnCurrencyList } from '../../../general/Currencies';

export default class InvoiceRowCPQ extends TaimerComponent {
	static contextType = SettingsContext;

	static defaultProps = {
		showValidationResult: false,
		cpqs: {},
		item: {},
	}

	constructor(props, context) {
		super(props, context, "dialogs/elements/InvoiceRowsEditor/InvoiceRowCPQ");

		this.currencySymbol = returnCurrencyList().find(rc => rc.id == this.context.taimerAccount.currency).symbol;

		this.state = {
			valid: false,
			validation: this.validate(this.props.item),
		};
	}

	delete = () => {
		this.props.onDelete(this.props.item);
	}

	onChangeCPQ = (e) => {
		const cpq = e.target.value;

		if (cpq) {
			const item = {
				...this.props.item,
				cpq: cpq,
				cpq_id: cpq.id,
				unit: cpq.unit,
				name: cpq.name,
				unit_price: cpq.unit_price,
			};

			const validation = this.validate(item, cpq);

			item.valid = validation.valid;

			this.setState({ validation });

			this.props.onChange(item.invoiceRowIndex, item, "cpq");
		}
		else {
			this.onChange(e);
		}
	}

	validate = (row, cpq) => {
		const result = {
			valid: true,
		};

		if (!row.cpq_id) {
			result.valid = false;
			result.name = true;
		}

		if (isNaN(row.amount) || row.amount <= 0) {
			result.valid = false;
			result.amount = true;
		}

		if (isNaN(row.unit_price) || row.unit_price < 0) {
			result.valid = false;
			result.unit_price = true;
		}

		return result;
	}

	onChange = (e) => {
		let value = e.target.value;

		if (e.target.name == 'amount' || e.target.name == 'unit_price') {
			value = e.target.value.replace(',', '.');
		}

		const item = {
			...this.props.item,
			[e.target.name]: value,
		};

		const validation = this.validate(item);

		item.valid = validation.valid;

		this.setState({ validation });

		this.props.onChange(item.invoiceRowIndex, item, e.target.name);
	}

	render() {
		const { tr } = this;
		const { item, showValidationResult, disabled: propDisabled } = this.props;
		const validation = showValidationResult ? this.validate(item) : {};
		const { taimerAccount } = this.context;

		const disabled = propDisabled || item.disabled;

		return (<React.Fragment key={item.invoiceRowIndex}>
			<div className={cn("third", "invoiceInput","colspan")}>
				<div className="fullwidth">
					<FilterDropdownCPQ
						label={this.tr("CPQ")}
						name="cpq"
						value={item.id ? { id: item.cpq_id, name: item.name } : item.cpq}
						company={this.props.company}
						onChange={this.onChangeCPQ}
						isDisabled={disabled}
						error={validation.cpq} />
				</div>
			</div>

			<OutlinedField name="amount"
				className="amount-field"
				InputLabelProps={{ shrink: true }}
				label={this.tr("Amount")}
				value={item.amount}
				onChange={this.onChange}
				fullWidth
				error={validation.amount}
				disabled={disabled} />

			<OutlinedField name="unit_price"
				label={`${this.currencySymbol}/${this.tr("unit")}`}
				value={item.unit_price}
				onChange={this.onChange}
				format="currency"
				currency={taimerAccount.currency}
				fullWidth
				error={validation.income_price}
				disabled={disabled} />

			<OutlinedField name="total"
				label={this.tr("Total")}
				value={item.total}
				onChange={this.onChange}
				format="currency"
				currency={taimerAccount.currency}
				fullWidth
				InputProps={{
					readOnly: true,
				}}
				error={validation.total}
				disabled={disabled} />

			<div className="invoiceRowDelete">{!disabled && <button onClick={this.delete}>X</button>}</div>
		</React.Fragment>)
	}
}